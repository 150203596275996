import React from 'react';
import { Checkbox } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';

export function CustomTrainingsSelector({ trainings, onSelect, setValidity }) {
  const { t } = useTranslation();

  const handleTrainingSelection = (training) => {
    const updatedTrainings = trainings.includes(training)
      ? trainings.filter((selectedTraining) => selectedTraining !== training)
      : [...trainings, training];
    onSelect(updatedTrainings);
    const valid = updatedTrainings.length > 0;
    setValidity('trainings', valid);
  };

  return (
    <div className="flex flex-col gap-2 dark:text-white">
      <div className="dark:text-white">{t('cf-trainingsTitle')}</div>
      <div className="flex flex-col gap-2">
        {/* Checkbox for Training 1 */}
        <div className="flex items-center gap-2">
          <Checkbox
            color="green"
            checked={trainings.includes('Training 1')}
            onChange={() => handleTrainingSelection('Training 1')}
          />
          <label>{t('training-route-1-title')}</label>
        </div>

        {/* Checkbox for Training 2 */}
        <div className="flex items-center gap-2 ">
          <Checkbox
            color="green"
            checked={trainings.includes('Training 2')}
            onChange={() => handleTrainingSelection('Training 2')}
          />
          <label>{t('training-route-2-title')}</label>
        </div>

        {/* Checkbox for Training 3 */}
        <div className="flex items-center gap-2">
          <Checkbox
            color="green"
            checked={trainings.includes('Training 3')}
            onChange={() => handleTrainingSelection('Training 3')}
          />
          <label>{t('training-route-3-title')}</label>
        </div>

        {/* Checkbox for Training 4 */}
        <div className="flex items-center gap-2">
          <Checkbox
            color="green"
            checked={trainings.includes('Training 4')}
            onChange={() => handleTrainingSelection('Training 4')}
          />
          <label>{t('training-route-4-title')}</label>
        </div>
      </div>
    </div>
  );
}
