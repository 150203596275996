import React from "react";
import { CustomSidebar } from "../components/customSideBar";

function Api({ content: Content }) {

  return (

    <div className="flex flex-row h-full w-full">
      <CustomSidebar />
      <div className="m-8">
        <Content />
      </div>
    </div>
  );
}

export default Api;
