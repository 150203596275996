import React, { useState, useEffect } from 'react';
import { IconButton, Button, Card, CardBody, Dialog, DialogHeader, DialogBody, DialogFooter, Accordion, AccordionHeader, AccordionBody, Chip } from "@material-tailwind/react";
import { CustomSwitch } from './switch';
import { useTranslation } from 'react-i18next';
import { getCategories } from '../data/cookiesData';
import RainbowLine from './rainbowline';
import { setCookie, getCookie } from '../handlers/cookieHandler'; // Import your cookie handling medivods
import { CloseIcon } from './svgIcons';

const CookiesDialog = ({ showCookieBanner, setShowCookieBanner, setShowSpeedDialMessage, initializeGA,  deinitializeGA }) => {
  const { t } = useTranslation();

  const categories = getCategories(t);
  const [showPreferences, setShowPreferences] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(null);
  const cookieValue = getCookie("user_cookie_choice_made");

  // Initialize state for active categories
  const [activeCategories, setActiveCategories] = useState({ "func": true, "ess": true, "perf-anal": false });

  useEffect(() => {

    setShowCookieBanner(!cookieValue); // Show banner if no cookie choice made
    setShowSpeedDialMessage(cookieValue !== ""); // Don't show message if no cookie choice has been made

    const storedActiveCategories = getCookie("user_cookies_accepted_categories");
    if (storedActiveCategories) {
      try {
        const parsedCategories = JSON.parse(storedActiveCategories);
        if (parsedCategories) {
          setActiveCategories(parsedCategories);
          
          // Check if 'perf-anal' category is enabled and initialize Google Analytics if it is
          if (parsedCategories['perf-anal']) {
            initializeGA();
          }
        }
      } catch (error) {
        console.error('Error parsing stored cookie categories:', error);
        // Handle parsing error or set to a default state if needed
      }
    }
  }, [cookieValue]);

  const handleAccept = () => {
    handleAcceptAll();
    setCookie("user_cookie_choice_made", 'true', 365);
    setShowCookieBanner(false);
    setShowPreferences(false);
  };

  const handleReject = () => {
    handleRejectAll();
    setCookie("user_cookie_choice_made", 'true', 365);
    setShowCookieBanner(false);
    setShowPreferences(false);
  };

  const handleCustomize = () => {
    setShowPreferences(true);
  };

  const handleClosePreferences = () => {
    setShowPreferences(false);
  };

  const handleAccordionOpen = (value) => {
    setAccordionOpen(accordionOpen === value ? null : value);
  };

  const handleSwitchClick = (e) => {
    e.stopPropagation();
  };

  const toggleSwitch = (tag) => {

    setActiveCategories(prevState => ({
      ...prevState,
      [tag]: !prevState[tag]
    }));

  };

  const handleRejectAll = () => {
    // Set all non-essential categories to false
    const updatedCategories = categories.reduce((acc, cat) => {
      acc[cat.tag] = cat.alwaysActive;
      return acc;
    }, {});

    setActiveCategories(updatedCategories);
    setCookie("user_cookies_accepted_categories", JSON.stringify(updatedCategories), 365);
    setCookie("user_cookie_choice_made", 'true', 365); // User made a choice by customizing
    setShowCookieBanner(false);
    setShowPreferences(false);
    deinitializeGA();
    setShowSpeedDialMessage(true);
  };

  const handleAcceptAll = () => {
    // Set all categories to true
    const updatedCategories = categories.reduce((acc, cat) => {
      acc[cat.tag] = true;
      return acc;
    }, {});
    setActiveCategories(updatedCategories);
    setCookie("user_cookies_accepted_categories", JSON.stringify(updatedCategories), 365);
    setCookie("user_cookie_choice_made", 'true', 365); // User made a choice by customizing
    setShowCookieBanner(false);
    setShowPreferences(false);
    // Initialize Google Analytics
    initializeGA();
    setShowSpeedDialMessage(true);
  };

  const handleSavePreferences = () => {
    setCookie("user_cookies_accepted_categories", JSON.stringify(activeCategories), 365);
    setCookie("user_cookie_choice_made", 'true', 365); // User made a choice by customizing
    setShowCookieBanner(false);
    setShowPreferences(false);

    // Initialize Google Analytics if analytics category is accepted
    if (activeCategories['perf-anal']) {
      initializeGA();
    } else {
      deinitializeGA();
    }
    setShowSpeedDialMessage(true);
  };

  return (
    <>
      {showCookieBanner && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <Card className="custom-card fixed bottom-5 left-5 right-5 md:w-1/3 md:min-w-[450px] z-50 rounded-t-lg">
            <RainbowLine />
            <CardBody className="custom-card-body text-justify">
              <div className="flex flex-col gap-4">
                <div className="font-bold"> {t('cookies-title')}</div>
                <div>{t('cookies-desc')}</div>
                <div>{t('cookies-privacy-policy')} <a href={"/privacy-policy"} target="_blank" rel="noopener noreferrer" className="text-blue-600"> {t('cookies-privacy-policy-link')}</a></div>
                <div className="flex flex-col-reverse md:flex-row gap-4 justify-between">
                  <Button size="sm" variant="outlined" color="blue" onClick={handleCustomize}>{t('cookies-custom-button')} ▼</Button>
                  <Button size="sm" variant="outlined" color="blue" onClick={handleReject}>{t('cookies-necessary-button')}</Button>
                  <Button size="sm" color="blue" onClick={handleAccept}>{t('cookies-all-button')}</Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      <Dialog open={showPreferences} handler={handleClosePreferences} className="custom-cookie-dialog">
        <RainbowLine />
        <DialogHeader className=" justify-between bg-gray-100 rounded-t-lg">
          <div className="text-blue-800">{t('cookies-custom-title')}</div>
          <IconButton
            color="blue-gray"
            size="sm"
            variant="text"
            onClick={handleClosePreferences}
          >
           <CloseIcon/>
          </IconButton>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-4">
            <div className="mb-4">
              {t('cookies-detailed-desc')} <a href={"/privacy-policy"} target="_blank" rel="noopener noreferrer" className="text-blue-600"> {t('cookies-privacy-policy-link')}</a>.
            </div>
          </div>
          {categories.map((cat) => (
            <Accordion key={cat.title} open={accordionOpen === cat.title} className=" mb-2 rounded-lg border border-blue-gray-100 px-4">
              <AccordionHeader
                onClick={() => handleAccordionOpen(cat.title)}
                className={`border-b-0 transition-colors flex justify-between items-center ${activeCategories[cat.title] ? "text-blue-500 hover:!text-blue-700" : ""
                  }`}
              >
                <div className="flex flex-col gap-4 w-full">
                  <div className="flex justify-between items-center">
                    <div className="flex flex-row gap-4">
                      {/* Display "-" if accordion is open, odiverwise display "+" */}
                      <span>{accordionOpen === cat.title ? "-" : "+"}</span>
                      <div>{cat.title}</div>
                    </div>
                    {cat.alwaysActive ? (
                      <Chip size="sm" color="teal" variant="ghost" value={t('cookies-always-active')} className=" dark:bg-white dark:text-teal" />
                    ) : (
                      <div onClick={(e) => handleSwitchClick(e)}>

                        <CustomSwitch
                          id={`switch-${cat.title}`}
                          checked={activeCategories[cat.tag] || false}
                          onChange={() => {
                            toggleSwitch(cat.tag);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </AccordionHeader>


              <AccordionBody className="pt-0 text-base font-normal">
                {cat.description}
                {/*
                {cat.cookies ? (
                  <div className="flex flex-col gap-4 p-4">

                    {cat.cookies.map((cookie, index) => (
                      <Card>
                        <div className="flex flex-row gap-2">
                          <div className="font-bold  text-left">{t('cookie_name')}</div>
                          <div className=" ">{cookie.name}</div>
                          <div className="font-bold  text-left">{t('cookie_domain')}</div>
                          <div className=" ">{cookie.domain}</div>
                          <div className="font-bold  text-left">{t('cookie_expiration')}</div>
                          <div className=" ">{cookie.expiration}</div>
                        </div>
                          <div className="font-bold  text-left">{t('cookie_purpose')}</div>
                          <div className=" ">{cookie.description}</div>
                        
                      </Card>
                    ))}
                  </div>
                ) : (
                  <p>{t('cookie_no_cookie')}</p>
                )} */}
              </AccordionBody>
            </Accordion>
          ))}

        </DialogBody>
        <DialogFooter className="justify-between gap-2 border-t border-blue-gray-50">
          <div className="flex w-full flex-col-reverse md:flex-row gap-4 justify-between">
            <Button size="sm" variant="outlined" color="blue" className="flex-1" onClick={handleRejectAll}>{t('cookies-necessary-button')}</Button>
            <Button size="sm" variant="outlined" color="blue" className="flex-1" onClick={handleSavePreferences}>{t('cookies-save-button')}</Button>
            <Button size="sm" color="blue" className="flex-1" onClick={handleAcceptAll}>{t('cookies-all-button')}</Button>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default CookiesDialog;
