import React, { useState } from 'react';

const CustomYesNoSwitch = ({ onChange, options, setShowCreateAccountSuggestion, setValidity }) => {

  const [selectedOption, setSelectedOption] = useState(); // State to store the selected option

  const handleButtonClick = (value,event) => {
      event.preventDefault();
      event.stopPropagation(); // Prevent click event from propagating to parent elements
      setSelectedOption(value); // Update the selected option
      onChange(value);
      if(value === 'non'){
        setShowCreateAccountSuggestion(true);
      }
      setValidity("versionUsed",true);
  };

  return (
      <div className="p-1 rounded-full">
        <div className="flex flex-row gap-2 justify-between">
          {options.map(({ label, value}) => (
            <button
              key={value}
              className={`flex items-center justify-center px-3 py-1 text-sm rounded-full text-blue-900 ${
                value === selectedOption ? "bg-yellow-700 " : "bg-gray-100"
              }`}
              onClick={(e) => handleButtonClick(value, e)}
            >
              {label}
            </button>
          ))}
        </div>
      </div>
  );
};

export default CustomYesNoSwitch;