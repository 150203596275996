import { useState, useEffect } from 'react';
import PredefinedElements from '../data/streetElementsData';
import StreetConfigurations from '../data/streetConfigurations';
import MobilityCutView from '../components/mbCut/mobilityCutView';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import scenario1 from "../img/mobilityCut/scenario1.svg";
import scenario2 from "../img/mobilityCut/scenario2.svg";
import scenario3 from "../img/mobilityCut/scenario3.svg";
import scenario4 from "../img/mobilityCut/scenario4.svg";
import { Message } from '../components/message';
import { useTranslation } from 'react-i18next';
import TransferButton from '../components/mbCut/transferDataButton';
import PrintButton from '../components/mbCut/printButton';
import PopupCancelValidate from '../components/mbCut/popupCancelValidate';

const MobilityCutApp = () => {
  const { t } = useTranslation();
  const predefinedElements = PredefinedElements();
  const zoomFactor = 70;
  const [easyView, setEasyView] = useState(true); // State to track if the green highlight is activated or not
  const [activeSection, setActiveSection] = useState("scenarios"); // State to track active section
  const [isMobile, setIsMobile] = useState(false); // State to track if the user is on a mobile device
  const [streetName, setStreetName] = useState(t('mobility-cut-app-streetNameDefault'));
  const [streetNumberL, setStreetNumberL] = useState('163');
  const [streetNumberR, setStreetNumberR] = useState('164');

  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState('success'); // Or 'error','info',..
  const [message, setMessage] = useState('');

  const [elementsBefore, setElementsBefore] = useState([]);
  const [elementsDuring, setElementsDuring] = useState([]);
  
  const [streetSizeBefore, setStreetSizeBefore] = useState(0);
  const [streetSizeDuring, setStreetSizeDuring] = useState(0);

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessageType, setPopupMessageType] = useState('info'); // Or 'error','success',..
  const [popupMessage, setPopupMessage] = useState('');
  


  const isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  useEffect(() => {
    let timeoutId;

    if (showMessage) {
      timeoutId = setTimeout(() => {
        setShowMessage(false);
      }, 7000);
    }

    // Cleanup function to clear the timeout when component unmounts or `showMessage` changes
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showMessage]);

  // Function to show a message with dynamic content and type
  const displayMessage = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  const handleScenarioSelect = (streetConfig) => {
    handleScenarioChange(streetConfig);
    setActiveSection("before");
  }

  const initialiseScenario = () => {
    handleScenarioChange(StreetConfigurations.streetConfiguration1);
    setActiveSection("scenarios");
  }

  const handleScenarioChange = (streetConfig) => {
    const newElements = streetConfig.map((element, index) => ({
      id: Math.random().toString(36).substr(2, 9),
      streetElement: element.elementName,
      minWidth: predefinedElements.find(el => el.streetElement === element.elementName)?.minWidth || '', // Use predefined min width if available
      width: element.elementWidth || '', // Use predefined width if available
      label: predefinedElements.find(el => el.streetElement === element.elementName)?.label || '',
      icons: predefinedElements.find(el => el.streetElement === element.elementName)?.icons || '',
      iconIndex: element.iconIndex,
    }));
    setElementsBefore(newElements);
    console.log(newElements);
    setElementsDuring(newElements);
  };

  useEffect(() => {
    // Initialize elementsBefore and elementsDuring based on a default scenario
   initialiseScenario();
  },[]); 

  const handleNamePDF = (streetName) => {
    // Trim, Normalize and remove accents (diacritical marks)
    let normalizedName = streetName.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // Replace spaces and other special characters with underscores
    let sanitizedName = normalizedName.replace(/[^a-zA-Z0-9]/g, "_");

    // Replace multiple underscores with a single underscore
    let finalName = sanitizedName.replace(/_+/g, "_");

    return finalName + '_mobility-cut';
  };

  useEffect(() => {
    calculateStreetSizes();
  },[elementsBefore, elementsDuring]); 

  const calculateStreetSizes = () => {
    // Calculer la somme des width 
    const totalWidthBefore = elementsBefore.reduce((sum, element) => sum + parseFloat(element.width || 0), 0);
    const totalWidthDuring = elementsDuring.reduce((sum, element) => sum + parseFloat(element.width || 0), 0);
  
    // Mettre à jour les tailles de rue
    setStreetSizeBefore(totalWidthBefore);
    setStreetSizeDuring(totalWidthDuring);
  };

  // Function to show the popup with the message
  const displayPopup = (type, msg) => {
    setPopupMessageType(type);
    setPopupMessage(msg);
    setShowPopup(true);
  };

  const handleValidatePopup = () => {
    generatePDF();
    setShowPopup(false);
  };

  const handleCancelPopup = () => {
    setShowPopup(false);
  };

  // Function to generate the PDF
  const generatePDF = async () => {
    const currentActiveSection = activeSection;
    setActiveSection("both");

    await new Promise((resolve) => setTimeout(resolve, 0));

    const { imageData: imgDataBefore, width: widthBefore, height: heightBefore } = await generateJPG('printAreaBefore');
    const { imageData: imgDataDuring, width: widthDuring, height: heightDuring } = await generateJPG('printAreaDuring');

    // Title text positions
    const titleX = widthBefore / 2;
    const titleY = 140;
    // Rectangle padding
    const paddingX = 20;
    const paddingY = 20;


    const titleFontSize = Math.min(heightBefore, heightDuring) * 0.1;
    const subtitleFontSize = Math.min(heightBefore, heightDuring) * 0.08;

    const totalWidth = Math.max(widthBefore, widthDuring);
    const totalHeight = heightBefore + heightDuring + 2 * subtitleFontSize + titleY + 6 * paddingY;

    const orientation = totalWidth > totalHeight ? 'landscape' : 'portrait';

    let pdf = new jsPDF({
      orientation: orientation,
      unit: 'px',
      format: [totalWidth, totalHeight] // Adjusted for title
    });

    // Subtitle text positions
    const textXBefore = widthBefore / 2;
    const textYBefore = titleY + titleFontSize + 40;
    const textXDuring = widthBefore / 2;
    const textYDuring = textYBefore + heightBefore + 80;

    // Title width
    pdf.setFontSize(titleFontSize);
    const titleWidth = pdf.getTextWidth(streetName);

    // Draw rectangle around the title
    pdf.rect(
      titleX - titleWidth / 2 - paddingX,
      titleY - titleFontSize - 0.5 * paddingY,
      titleWidth + 2 * paddingX,
      titleFontSize + 2 * paddingY
    );

    // Draw title
    pdf.text(streetName, titleX, titleY, { align: "center" });

    // Draw subtitles
    pdf.setFontSize(subtitleFontSize);
    pdf.text(t('mobility-cut-app-before'), textXBefore, textYBefore, { align: "center" });
    pdf.text(t('mobility-cut-app-during'), textXDuring, textYDuring, { align: "center" });

    // Add images to the PDF
    addImageToPDF(pdf, imgDataBefore, 0, textYBefore + paddingY, widthBefore, heightBefore);
    addImageToPDF(pdf, imgDataDuring, 0, textYDuring + paddingY, widthDuring, heightDuring);

    pdf.save(handleNamePDF(streetName));

    setActiveSection(currentActiveSection);
  };
  
  if (isMobile) {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center p-4">
        <Message type="info" message={t('mobility-cut-app-desktop-only')} />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col p-4 gap-8">
      {showMessage && (
        <><Message type={messageType} message={message} /></>
      )}
      {showPopup && (
        <><PopupCancelValidate messageType={popupMessageType} message={popupMessage} onValidate={handleValidatePopup} onCancel={handleCancelPopup}/></>
      )}
      <div className="flex flex-row items-center justify-between gap-4">
      <div className="flex-1"></div>
          <div className="flex flex-row p-1 justify-center items-center bg-gray-100 rounded-lg gap-4">
            <div className={`cursor-pointer py-2 px-8 ${activeSection === "scenarios" ? "bg-white rounded-lg shadow-sm" : ""}`} onClick={() => setActiveSection("scenarios")}>
              {t('mobility-cut-app-config')}
            </div>
            <div className={`cursor-pointer py-2 px-8 ${activeSection === "before" ? "bg-white rounded-lg shadow-sm" : ""}`} onClick={() => setActiveSection("before")}>
              {t('mobility-cut-app-before')}
            </div>
            <div className={`cursor-pointer  py-2 px-8 ${activeSection === "during" ? "bg-white rounded-lg shadow-sm" : ""}`} onClick={() => setActiveSection("during")}>
              {t('mobility-cut-app-during')}
            </div>
        </div>
        <div className="flex flex-row justify-start flex-1">
          <div className={`m-4 rounded-full ${activeSection === "during" ? "" : "hidden"}`}>
            <PrintButton generatePDF={generatePDF} streetName={streetName} displayMessage={displayMessage} displayPopup={displayPopup} streetSizeBefore={streetSizeBefore} streetSizeDuring={streetSizeDuring}/>
          </div>
          <div className={`m-4 ${activeSection === "before" ? "" : "hidden"}`}>
            <TransferButton activeSection={activeSection} sourceElements={elementsBefore} setDestinationElements={setElementsDuring} displayMessage={displayMessage} />
          </div>
          <div className={`m-4 ${activeSection === "during" ? "" : "hidden"}`}>
            <TransferButton activeSection={activeSection} sourceElements={elementsDuring} setDestinationElements={setElementsBefore} displayMessage={displayMessage} />
          </div>
        </div>
      </div>

      <div className={`flex flex-col items-center  ${(activeSection !== "scenarios") ? "hidden" : ""}`}>

        <div className="flex mb-8 inline-flex">
          <Message type="info" message={
            <div className="flex flex-col">
              <div> 1. {t('mobility-cut-app-infotext1')}</div>
              <div> 2. {t('mobility-cut-app-infotext2')}</div>
              <div> 3. {t('mobility-cut-app-infotext3')}</div>
              <div> 4. {t('mobility-cut-app-infotext4')}</div>
            </div>} />
        </div>

        <div className="flex flex-row gap-16">

          <button className="p-4 border-4 border-gray-300 rounded-md" onClick={() => handleScenarioSelect(StreetConfigurations.streetConfiguration1)}>
            <img src={scenario1} className="min-w-[50px] max-h-50" alt="scenario 1"></img>
          </button>
          <button className="p-4 border-4 border-gray-300 rounded-md" onClick={() => handleScenarioSelect(StreetConfigurations.streetConfiguration2)}>
            <img src={scenario2} className="min-w-[50px]" alt="scenario 2"></img>
          </button>
          <button className="p-4 border-4 border-gray-300 rounded-md" onClick={() => handleScenarioSelect(StreetConfigurations.streetConfiguration3)}>
            <img src={scenario3} className="min-w-[50px]" alt="scenario 3"></img>
          </button>
          <button className="p-4 border-4 border-gray-300 rounded-md" onClick={() => handleScenarioSelect(StreetConfigurations.streetConfiguration4)}>
            <img src={scenario4} className="min-w-[50px]" alt="scenario 4"></img>
          </button>
        </div>
      </div>
      {/*ElementsOther is giving as argument in order to be able to duplicate the other section from the click of a button*/}
      <div className={`${(activeSection === "before" || activeSection === "both") ? "" : "hidden"}`}>
        <MobilityCutView easyView={easyView} elements={elementsBefore} otherElements={elementsDuring} setElements={setElementsBefore} streetName={streetName} setStreetName={setStreetName} setStreetNumberL={setStreetNumberL} streetNumberL={streetNumberL} setStreetNumberR={setStreetNumberR} streetNumberR={streetNumberR} predefinedElements={predefinedElements} zoomFactor={zoomFactor} printAreaId={"printAreaBefore"} displayMessage={displayMessage}/>
      </div>

      <div className={`${(activeSection === "during" || activeSection === "both") ? "" : "hidden"}`}>
        <MobilityCutView easyView={easyView} elements={elementsDuring} otherElements={elementsBefore} setElements={setElementsDuring} streetName={streetName} setStreetName={setStreetName} streetNumberL={streetNumberL} setStreetNumberL={setStreetNumberL} setStreetNumberR={setStreetNumberR} streetNumberR={streetNumberR} predefinedElements={predefinedElements} zoomFactor={zoomFactor} printAreaId={"printAreaDuring"} displayMessage={displayMessage}/>
      </div>
    </div>

  );
};

// Function to generate JPG data from an element and return the canvas
const generateJPG = async (elementId) => {
  const input = document.getElementById(elementId);
  const canvas = await html2canvas(input);
  const imageData = canvas.toDataURL('image/jpeg', 1.0); // Use maximum quality
  return { imageData, width: canvas.width, height: canvas.height };
};

// Function to add an image to the PDF at a specific position
const addImageToPDF = (pdf, imageData, x, y, width, height) => {
  pdf.addImage(imageData, 'JPEG', x, y, width, height);
};

export default MobilityCutApp;
