import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './translations/en.json';
import fr from './translations/fr.json';
import nl from './translations/nl.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      fr: {
        translation: fr
      },
      nl: {
        translation: nl
      }
    },
    lng: "fr", // if you want to use French by default
    fallbackLng: "fr", // use French if detected language is not available
    interpolation: {
      escapeValue: false // React already safeguards against XSS
    }
  });

export default i18n;


