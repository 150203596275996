
export function FileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
    </svg>
  );
}



export function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}


export function GoToIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-5 w-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
      />
    </svg>
  );
}


export function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-5 w-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
      />
    </svg>
  );
}


export function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
  );
}

export function CarFrontIcon(){

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
	 width="800px" height="800px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
	<path fill="#231F20" d="M58.982,32.088C58.985,32.057,59,32.031,59,32c0-2.866-0.589-28-21-28H26C12.654,4,5,14.206,5,32
		c0,0.031,0.015,0.057,0.018,0.088C2.176,32.559,0,35.027,0,38v10c0,3.309,2.691,6,6,6v5c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1
		v-5h32v5c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1v-5c3.309,0,6-2.691,6-6V38C64,35.027,61.824,32.559,58.982,32.088z M26,6h12
		c18.467,0,19,23.339,19,26h-2c0-2.456-0.505-24-18-24H27C15.393,8,9,16.523,9,32H7C7,9.378,18.904,6,26,6z M24,24
		c-4.418,0-8,3.582-8,8h-5c0-18.184,8.701-22,16-22h10c6.34,0,10.909,3.16,13.581,9.394C52.825,24.632,53,30.367,53,32H32
		C32,27.582,28.418,24,24,24z M30,32H18c0-3.313,2.687-6,6-6S30,28.687,30,32z M14,58H8v-4h6V58z M56,58h-6v-4h6V58z M62,48
		c0,2.206-1.794,4-4,4H6c-2.206,0-4-1.794-4-4V38c0-2.206,1.794-4,4-4h52c2.206,0,4,1.794,4,4V48z"/>
	<path fill="#231F20" d="M11,39c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S13.206,39,11,39z M11,45c-1.103,0-2-0.897-2-2
		s0.897-2,2-2s2,0.897,2,2S12.103,45,11,45z"/>
	<path fill="#231F20" d="M53,39c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S55.206,39,53,39z M53,45c-1.103,0-2-0.897-2-2
		s0.897-2,2-2s2,0.897,2,2S54.103,45,53,45z"/>
	<path fill="#231F20" d="M43,40H21c-0.553,0-1,0.447-1,1s0.447,1,1,1h22c0.553,0,1-0.447,1-1S43.553,40,43,40z"/>
	<path fill="#231F20" d="M43,44H21c-0.553,0-1,0.447-1,1s0.447,1,1,1h22c0.553,0,1-0.447,1-1S43.553,44,43,44z"/>
	<path fill="#231F20" d="M42.707,15.294c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l2,2
		c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L42.707,15.294z"/>
	<path fill="#231F20" d="M37.707,15.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l7,7C43.488,23.902,43.744,24,44,24
		s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L37.707,15.293z"/>
</svg>
  );
}
