import React, { useState } from 'react';
import { Card, Button, Chip, CardBody } from '@material-tailwind/react';
import { useUserType } from '../UserContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LifebuoyIcon, TruckIcon, QuestionMarkCircleIcon, BugAntIcon, PhoneIcon, EnvelopeIcon, XCircleIcon } from '@heroicons/react/24/outline';


const HelpDesk = () => {

  const tel_guichet = process.env.REACT_APP_TEL_GUICHET;
  const opening_guichet = process.env.REACT_APP_OPENING_GUICHET;

  const navigate = useNavigate();

  const { userType } = useUserType();
  const [activeStep, setActiveStep] = useState(0);
  const [userPath, setUserPath] = useState({});
  const { t } = useTranslation();


  const [openPopover, setOpenPopover] = useState(false);
  const handleNext = (choice) => {
    setUserPath({ ...userPath, [activeStep]: choice });
    setActiveStep((prevStep) => prevStep + 1);
  };


  const LinkButton = ({ title, link, internal, icon, isPhoneButton }) => {
    const isMobileDevice = () => {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
      );
    };

    const handleClick = (event) => {
      event.stopPropagation();
      if (isPhoneButton) {
        if (isMobileDevice()) {
          window.location.href = `tel:${link}`;
        } else {
          setOpenPopover(true);
        }
      } else if (internal) {
        navigate(link);
      } else {
        window.open(link, '_blank', 'noopener noreferrer');
      }
    };


    return (
      <>

        <Button
          variant="outlined"
          size="sm"
          color="blue-gray"
          className=" bg-white flex flex-row gap-2 text-justify rounded text-sm normal-case dark:text-white dark:hover:bg-blue-1000 dark:bg-blue-900"
          onClick={(e) => handleClick(e)}
        >
          <div className="h-5 w-5 strokewidth=2">
            {icon}
          </div>

          {title}
        </Button>

        <div className="fixed bottom-20 right-4 z-40">
          {openPopover &&
            <div className="fixed bottom-20 right-4 z-40">
              <Card>
                <CardBody className="relative">
                  <div className="flex flex-row gap-4 items-center">
                    <PhoneIcon className="h-5 w-5" />
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row gap-2">{t('sd-phonepart')} <strong>{tel_guichet}</strong></div>
                      <div>{t('sd-phonepart2')} : {opening_guichet}</div>
                    </div>
                  </div>
                  <button onClick={() => setOpenPopover(false)} className="absolute top-0 right-0 p-2">
                    <XCircleIcon className="h-5 w-5" />
                  </button>
                </CardBody>
              </Card>
            </div>
          }
        </div>
      </>
    );
  }

  const HandleButton = ({ icon, title, toHandle }) => (
    <Button
      variant="outlined"
      size="sm"
      color="blue-gray"
      className="bg-white flex flex-row gap-2 text-justify rounded text-sm normal-case dark:text-white dark:hover:bg-blue-1000 dark:bg-blue-900"
      onClick={() => handleNext(toHandle)}
    >
      {icon}
      {title}
    </Button>
  );

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="flex flex-col items-center gap-8 p-4 max-w-screen-sm w-full mx-auto">
            <div className="flex flex-row gap-4 w-full">
              <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%]  w-7/8 max-w-[87.5%]" variant="filled" value={t('helpToday')} /></div>

            <div className="flex flex-col gap-4 p-4 rounded-lg bg-blue-gray-50">
              <HandleButton icon={< QuestionMarkCircleIcon className="h-5 w-5" />} title={t('UserQuestionOnOsiris')} toHandle="questionAboutOsiris" />
              {userType === "citizen" && (<LinkButton icon={< TruckIcon className="h-5 w-5" />} title={t('UserHelpMove')} link="/move" internal={true} />)}

              {/*if user type is other than institutional enterprise then the user can choose how to contact the helpdesk */}
              {userType !== "instEnterprise" && (<HandleButton icon={< LifebuoyIcon className="h-5 w-5" />} title={t('UserProblemWithSite')} toHandle="worksiteIssue" />)}
              {userType !== "instEnterprise" && (<HandleButton icon={< LifebuoyIcon className="h-5 w-5" />} title={t('UserProblemWithNewSite')} toHandle="worksiteIssue" />)}
              {userType === "instEnterprise" && (<LinkButton icon={< LifebuoyIcon className="h-5 w-5" />} title={t('UserProblemWithSite')} link="/help-assistance" />)}
              <LinkButton icon={< BugAntIcon className="h-5 w-5" />} title={t('UserReportABug')} link="/bug-report" internal={true} />
              {userType !== "instEnterprise" && (<LinkButton icon={<PhoneIcon className="h-5 w-5" />} title={t('sd-callHelpDesk')} link={tel_guichet} internal={false} isPhoneButton={true} />)}
            </div>
          </div>

        );
        break;
      case 1:
        if (userPath[0] === 'worksiteIssue') {
          return (
            <div className="flex flex-col items-center gap-8 p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full">
                <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%]  w-7/8 max-w-[87.5%]" variant="filled" value={t('howContactHelpdesk')} /></div>

              <div className="flex flex-col gap-4 p-4  rounded-lg bg-blue-gray-50 w-7/8 max-w-[87.5%] ml-auto">
                {userType === "citizen" && (
                  <>
                    <LinkButton icon={< EnvelopeIcon className="h-5 w-5" />} title={t('contactViaForm')} link="/help-assistance" internal={true} />
                    <LinkButton icon={< PhoneIcon className="h-5 w-5" />} title={t('contactViaCall')} link="/simply-meet" internal={true} />
                  </>
                )}
                {userType !== "citizen" && (
                  <>
                    <LinkButton icon={< EnvelopeIcon className="h-5 w-5" />} title={t('contactViaForm')} link="/help-assistance" internal={true} />
                    <LinkButton icon={< PhoneIcon className="h-5 w-5" />} title={t('contactViaCall')} link="/simply-meet" internal={true} />
                  </>
                )}


              </div>
            </div>
          );
        }
        if (userPath[0] === 'questionAboutOsiris') {
          return (
            <div className="flex flex-col items-center gap-8 p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full">
                <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('readManualQuestion')} /></div>
              <div className="flex flex-col gap-4 p-4  rounded-lg bg-blue-gray-50 w-7/8 max-w-[87.5%] ml-auto">
                <HandleButton title={t('readManualAlreadyNo')} toHandle="manualSuggestion" />
                <HandleButton title={t('readManualAlreadyYes')} toHandle="faqSuggestion" />
              </div>
            </div>
          );
        }
        break;
      case 2:
        if (userPath[1] === 'manualSuggestion') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full"> <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('manualSuggestion')} /></div>
              <div className="flex flex-col gap-4 p-4 rounded-lg bg-blue-gray-50  w-7/8 max-w-[87.5%] ml-auto">
                <LinkButton title={t('yes')} link="/manual" internal={true} />
                <HandleButton title={t('no')} toHandle="faqSuggestion" />
              </div>
            </div>
          );
        }
        if (userPath[1] === 'faqSuggestion') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full"> <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('faqSuggestion')} /></div>
              <div className="flex flex-col gap-4 p-4  rounded-lg bg-blue-gray-50 w-7/8 max-w-[87.5%] ml-auto">
                <LinkButton title={t('checkfaq')} link="/faq" internal={true} />
                <HandleButton title={t('nothankyou')} toHandle="sorryOtherHelp" />
              </div>
            </div>
          );
        }
        break;
      case 3:
        if (userPath[2] === 'sorryOtherHelp') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full"> <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('sorryOtherHelp')} /></div>
              <div className="flex flex-col gap-4 p-4  rounded-lg bg-blue-gray-50 w-7/8 max-w-[87.5%] ml-auto ">
                <HandleButton title={t('yes')} toHandle="howContactHelpdesk" />
                <HandleButton title={t('no')} toHandle="haveAGoodDay" />
              </div>
            </div>
          );
        }
        if (userPath[2] === 'faqSuggestion') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full"> <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('faqSuggestion')} /></div>
              <div className="flex flex-col gap-4 p-4  rounded-lg bg-blue-gray-50 w-7/8 max-w-[87.5%] ml-auto">
                <LinkButton title={t('checkfaq')} link="/faq" internal={true} />
                <HandleButton title={t('nothankyou')} toHandle="sorryOtherHelp" />
              </div>
            </div>
          );
        }
        break;
      case 4:
        if (userPath[3] === 'haveAGoodDay') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full">
                <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('haveAGoodDay')} />
              </div>
            </div>

          );
        }
        if (userPath[3] === 'howContactHelpdesk') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full"> <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('howContactHelpdesk')} /></div>
              <div className="flex flex-col gap-4 p-4  rounded-lg bg-blue-gray-50 w-7/8 max-w-[87.5%] ml-auto">
                <LinkButton title={t('contactViaForm')} link="/help-inquiry" internal={true} />
                <LinkButton title={t('contactViaCall')} link="/simply-meet" internal={true} />
              </div>
            </div>
          );
        }
        if (userPath[3] === 'sorryOtherHelp') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full"> <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('sorryOtherHelp')} /></div>
              <div className="flex flex-col gap-4 p-4  rounded-lg bg-blue-gray-50 w-7/8 max-w-[87.5%] ml-auto">
                <HandleButton title={t('yes')} toHandle="howContactHelpdesk" />
                <HandleButton title={t('no')} toHandle="haveAGoodDay" />
              </div>
            </div>
          );
        }
        break;
      case 5:
        if (userPath[4] === 'howContactHelpdesk') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full"> <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('howContactHelpdesk')} /> </div>
              <div className="flex flex-col gap-4 p-4  rounded-lg bg-blue-gray-50 w-7/8 max-w-[87.5%] ml-auto">
                <LinkButton title={t('contactViaForm')} link="/help-inquiry" internal={true} />
                <LinkButton title={t('contactViaCall')} link="/simply-meet" internal={true} />
              </div>
            </div>
          );
        }
        if (userPath[4] === 'sorryOtherHelp') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full"> <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('sorryOtherHelp')} />
              </div>
              <div className="flex flex-col gap-4 p-4  rounded-lg bg-blue-gray-50 w-7/8 max-w-[87.5%] ml-auto">
                <HandleButton title={t('yes')} toHandle="howContactHelpdesk" />
                <HandleButton title={t('no')} toHandle="haveAGoodDay" />
              </div>
            </div>
          );
        }
        if (userPath[4] === 'haveAGoodDay') {
          return (
            <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
              <div className="flex flex-row gap-4 w-full">
                <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('haveAGoodDay')} />
              </div>
            </div>

          );
        }
      default:
        return (
          <div className="flex flex-col gap-8 items-center p-4 max-w-screen-sm w-full mx-auto">
            <div className="flex flex-row gap-4 w-full">
              <Chip className="self-start whitespace-normal bg-blue-800 normal-case text-xl p-4 rounded-lg w-7/8 max-w-[87.5%] " variant="filled" value={t('haveAGoodDay')} />
            </div>
          </div>

        );
    }
  };

  return (
    <div className="flex flex-col md:p-4 items-center p-4">
      <Card className="dark:bg-blue-700">

        <CardBody>
          <div className="text-3xl font-bold text-blue-900 dark:text-yellow-800">
            <a href="/helpdesk" className="hover:underline">
              Helpdesk
            </a>
          </div>
          <div className="py-8 dark:bg-blue-700">{getStepContent(activeStep)}</div>
        </CardBody>
      </Card>
    </div>
  );
};

export default HelpDesk;
