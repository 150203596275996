import React from "react";
import Container from "./container";


const Benefits = (props) => {
  const { data } = props;
  return (
    <>
      <Container className="flex flex-wrap mb-20 lg:gap-10 lg:flex-nowrap ">
        <div
          className={`flex items-center justify-center w-full lg:w-1/2 ${
           data.imgPos === "right" ? "lg:order-1" : ""
          }`}>
          <div>
            <img
              src={data.image}
              width="650"
              height="auto"
              alt="Benefits"
              className={"object-cover rounded-full"} 
            />
          </div>
        </div>

        <div
          className={`flex flex-wrap items-center w-full lg:w-1/2 ${
            data.imgPos === "right" ? "lg:justify-end" : ""
          }`}>
          <div>
            <div className="flex flex-col w-full mt-4">
              <div className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-blue-900 lg:leading-tight lg:text-4xl dark:text-white">
                {data.title}
              </div>

              <div className="max-w-2xl py-4 text-lg leading-normal text-blue-900 lg:text-xl xl:text-xl dark:text-gray-200">
                {data.desc}
              </div>
            </div>

            <div className="w-full mt-5">
              {data.bullets.map((item, index) => (
                <Benefit key={index} title={item.title} icon={item.icon}>
                  {item.desc}
                </Benefit>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

function Benefit(props) {
  return (
    <>
      <div className="flex items-start mt-8 space-x-3">
        <div className="flex items-center justify-center flex-shrink-0 mt-1 bg-blue-800 rounded-md w-11 h-11 ">
          {React.cloneElement(props.icon, {
            className: "w-7 h-7 text-blue-gray-50",
          })}
        </div>
        <div>
          <div className="text-xl font-medium text-blue-900 dark:text-gray-200">
            {props.title}
          </div>
          <div className="mt-1 text-gray-700 dark:text-gray-200">
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}

export default Benefits;

