import React from "react";
import { useTranslation } from 'react-i18next';
import { Alert } from "@material-tailwind/react";

import geoserverAdmin from "../../img/api/geoserverAdmin.png";
import geoserverCapa from "../../img/api/geoserverCapa.png";
import geoserverLayers from "../../img/api/geoserverLayers.png";

function ApiTechnicalGeoserver() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-start gap-6">

      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('api-technical-geoserver-title')}
      </div>

      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-geoserver-1')}
      </div>

      <Alert variant="ghost">
        <a href="https://api.osiris.brussels/geoserver/" className="text-lg text-light-blue-800 dark:text-gray-200">
          {t('api-technical-geoserver-url')}
        </a>
      </Alert>
      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-geoserver-2')}
      </div>
      <img
        className="max-w-[800px] object-cover object-center"
        src={geoserverAdmin}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />




      <img
        className="max-w-[800px] object-cover object-center"
        src={geoserverCapa}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-geoserver-3')}
      </div>

      <img
        className="max-w-[800px] object-cover object-center"
        src={geoserverLayers}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

    </div>


  );
}

export default ApiTechnicalGeoserver;

