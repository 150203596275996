


export const pdfSlides = [
  {
    userType:"all",
    numb: "1",
    name: {
      en: "Introduction à la coordination des chantiers en voirie",
      fr: "Introduction à la coordination des chantiers en voirie",
      nl: "Inleiding tot de uitvoering van de bouwplaatsen"
    },
    url: {
      en: "/pdf/TRAINING_FR/M1_Introduction à la coordination des chantiers en voirie.pdf",
      fr: "/pdf/TRAINING_FR/M1_Introduction à la coordination des chantiers en voirie.pdf",
      nl: "/pdf/TRAINING_NL/M1_Inlieding tot de uitvoering van de bouwplaatsen.pdf"
    }
  },
  {
    userType:"instEnterprise",
    numb: "2",
    name: {
      en: "Avant Chantier_II",
      fr: "Avant Chantier_II",
      nl: "Voor de bouwplaats_IV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M2_Avant Chantier_II.pdf",
      fr: "/pdf/TRAINING_FR/M2_Avant Chantier_II.pdf",
      nl: "/pdf/TRAINING_NL/M2_Voor de bouwplaats_IV.pdf"
    }
  },
  { 
    userType:"non-instEnterprise",
    numb: "2",
    name: {
      en: "Avant Chantier_INI",
      fr: "Avant Chantier_INI",
      nl: "Voor de bouwplaats_NIV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M2_Avant Chantier_INI.pdf",
      fr: "/pdf/TRAINING_FR/M2_Avant Chantier_INI.pdf",
      nl: "/pdf/TRAINING_NL/M2_Voor de bouwplaats_NIV.pdf"
    }
  },
  {
    userType:"all",
    numb: "4",
    name: {
      en: "La Commission de Coordination des Chantiers",
      fr: "La Commission de Coordination des Chantiers",
      nl: "De Coördinatie Commissie van de Bouwplaatsen"
    },
    url: {
      en: "/pdf/TRAINING_FR/M4_La Commission de Coordination des Chantiers.pdf",
      fr: "/pdf/TRAINING_FR/M4_La Commission de Coordination des Chantiers.pdf",
      nl: "/pdf/TRAINING_NL/M4_De Coördinatie Commissie van de Bouwplaatsen.pdf"
    }
  },
  {
    userType:"instEnterprise",
    numb: "5",
    name: {
      en: "Osiris_Avant Chantier_II",
      fr: "Osiris_Avant Chantier_II",
      nl: "Osiris_Voor de bouwplaats_IV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M5_Osiris_Avant Chantier_II.pdf",
      fr: "/pdf/TRAINING_FR/M5_Osiris_Avant Chantier_II.pdf",
      nl: "/pdf/TRAINING_NL/M5_Osiris_Voor de bouwplaats_IV.pdf"
    }
  },
  {
    userType:"non-instEnterprise",
    numb: "5",
    name: {
      en: "Osiris_Avant Chantier_INI",
      fr: "Osiris_Avant Chantier_INI",
      nl: "Osiris_Voor de bouwplaats_NIV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M5_Osiris_Avant Chantier_INI.pdf",
      fr: "/pdf/TRAINING_FR/M5_Osiris_Avant Chantier_INI.pdf",
      nl: "/pdf/TRAINING_NL/M5_Osiris_Voor de bouwplaats_NIV.pdf"
    }
  },
  /*{ 
    userType:"all",
    numb: "6",
    name: {
      en: "Pendant et après Chantier",
      fr: "Pendant et après Chantier",
      nl: "Tijdens en Na de bouwplaats"
    },
    url: {
      en: "/pdf/TRAINING_FR/M6_Pendant et après Chantier.pdf",
      fr: "/pdf/TRAINING_FR/M6_Pendant et après Chantier.pdf",
      nl: "/pdf/TRAINING_NL/M6_Tijdens en Na de bouwplaats.pdf"
    }
  },*/
  {
    userType:"instEnterprise",
    numb: "7",
    name: {
      en: "Osiris_Pendant et après Chantier_II",
      fr: "Osiris_Pendant et après Chantier_II",
      nl: "Osiris_Tijdens en Na de bouwplaats_IV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M7_Osiris_Pendant et après Chantier_II Chantiers Autorisés (A).pdf",
      fr: "/pdf/TRAINING_FR/M7_Osiris_Pendant et après Chantier_II Chantiers Autorisés (A).pdf",
      nl: "/pdf/TRAINING_NL/M7_Osiris_Tijdens en Na de bouwplaats_IV Vergunde werven (V).pdf"
    }
  },
  {
    userType:"non-instEnterprise",
    numb: "7",
    name: { 
      en: "Osiris_Pendant et après Chantier_INI",
      fr: "Osiris_Pendant et après Chantier_INI",
      nl: "Osiris_Tijdens en Na de bouwplaats_NIV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M7_Osiris_Pendant et après Chantier_INI.pdf",
      fr: "/pdf/TRAINING_FR/M7_Osiris_Pendant et après Chantier_INI.pdf",
      nl: "/pdf/TRAINING_NL/M7_Osiris_Tijdens en Na de bouwplaats_NIV.pdf"
    }
  },
  {
    userType:"all",
    numb: "8",
    name: {
      en: "Tenue de Chantier",
      fr: "Tenue de Chantier",
      nl: "Beheer van de bouwplaats"
    },
    url: {
      en: "/pdf/TRAINING_FR/M8_Tenue de Chantier.pdf",
      fr: "/pdf/TRAINING_FR/M8_Tenue de Chantier.pdf",
      nl: "/pdf/TRAINING_NL/M8_Beheer van de bouwplaats.pdf"
    }
  },
  {
    userType:"instEnterprise",
    numb: "11",
    name: {
      en: "Conciliation, Recours et Contrôles de conformité_II",
      fr: "Conciliation, Recours et Contrôles de conformité_II",
      nl: "Toezicht, Verzoening en Beroep, Compensatie_IV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M11_Conciliation_Recours_et_Controles_de_conformité_II.pdf",
      fr: "/pdf/TRAINING_FR/M11_Conciliation_Recours_et_Controles_de_conformité_II.pdf",
      nl: "/pdf/TRAINING_NL/M11_Toezicht_Verzoening_en_Beroep_Compensatie_IV.pdf"
    }
  },
  {
    userType:"non-instEnterprise",
    numb: "11",
    name: {
      en: "Conciliation, Recours et Contrôles de conformité_INI",
      fr: "Conciliation, Recours et Contrôles de conformité_INI",
      nl: "Toezicht, Verzoening en Beroep, Compensatie_NIV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M11_Conciliation_Recours_et_Controles_de_conformité_INI.pdf",
      fr: "/pdf/TRAINING_FR/M11_Conciliation_Recours_et_Controles_de_conformité_INI.pdf",
      nl: "/pdf/TRAINING_NL/M11_Toezicht_Verzoening_en_Beroep_Compensatie_NIV.pdf"
    }
  },
  {
    userType:"all",
    numb: "12",
    name: {
      en: "Modifications induites par l'ordonnance 2018 et l'arrêté",
      fr: "Modifications induites par l'ordonnance 2018 et l'arrêté",
      nl: "Aanpassingen volgend uit de ordonnantie 2018 en besluit.pdf"
    },
    url: {
      en: "/pdf/TRAINING_FR/M12_Modifications_ordonnance.pdf",
      fr: "/pdf/TRAINING_FR/M12_Modifications_ordonnance.pdf",
      nl: "/pdf/TRAINING_NL/M12_Aanpassingen_ordonnantie.pdf"
    }
  },
  {
    userType:"all",
    numb: "13",
    name: {
      en: "Extensions Osiris_II&INI",
      fr: "Extensions Osiris_II&INI",
      nl: "Module uitbreidingen Osiris_IV&NIV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M13_Extensions Osiris_II&INI.pdf",
      fr: "/pdf/TRAINING_FR/M13_Extensions Osiris_II&INI.pdf",
      nl: "/pdf/TRAINING_NL/M13_Module uitbreidingen Osiris_IV&NIV.pdf"
    }
  },
  {
    userType:"all",
    numb: "14",
    name: {
      en: "Osiris_Modifications in the osiris system due to the change of ordinance and decrees_II&INI",
      fr: "Osiris_Modifications induites par l’ordonnance du 3 mai 2018 et son arrêté d’exécution du 4 avril 2019 dans le système informatique OSIRIS_II&INI",
      nl: "Wijzigingen in het OSIRIS-computersysteem als gevolg van de ordonnantie van 3 mei 2018 en het besluit van 4 april 2019_IV&NIV"
    },
    url: {
      en: "/pdf/TRAINING_FR/M14_Osiris_Modifications_II&INI.pdf",
      fr: "/pdf/TRAINING_FR/M14_Osiris_Modifications_II&INI.pdf",
      nl: "/pdf/TRAINING_NL/M14_Wijzigingen in het OSIRIS-computersysteem_IV&NIV.pdf"
    }
  },
   {
    userType:"instEnterprise",
    numb: "15",
    name: {
      en: "Hypercoordination",
      fr: "Hypercoordination",
      nl: "Hypercoordinatïe"
    },
    url: {
      en: "/pdf/TRAINING_FR/M15_HyperCoordination.pdf",
      fr: "/pdf/TRAINING_FR/M15_HyperCoordination.pdf",
      nl:  "/pdf/TRAINING_FR/M15_HyperCoördinatie.pdf",
    }
  },
  {
    userType:"all",
    numb: "16",
    name: {
      en: "Geogantt",
      fr: "Geogantt",
      nl: "Geogantt"
    },
    url: {
      en: "/pdf/TRAINING_FR/M16_Geogantt.pdf",
      fr: "/pdf/TRAINING_FR/M16_Geogantt.pdf",
      nl: "/pdf/TRAINING_NL/M16_Geogantt_NL.pdf"
    }
  }
];

