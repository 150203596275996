import React from 'react';
import PropTypes from 'prop-types';

function CustomAvatar({ src, alt, size }) {
  const sizes = {
    sm: 35,
    md: 100,
    lg: 150,
  };
  const imageSize = sizes[size] || sizes['sm']; // Default to 'sm' size if size is not recognized

  const styles = {
    container: {
      width: `${imageSize}px`,
      height: `${imageSize}px`,
      backgroundImage: `url(${src})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  };

  return <div style={styles.container} alt={alt}></div>;
}

CustomAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

CustomAvatar.defaultProps = {
  alt: '',
  size: 'sm',
};

export default CustomAvatar;
