import React from 'react';
import Container from './container';
import bm_en_sprb_Img from '../img/bm/logo--en.svg';
import bm_fr_sprb_Img from '../img/bm/logo--fr.svg';
import bm_nl_sprb_Img from '../img/bm/logo--nl.svg';
import sprb_Img from '../img/bm/sprb.svg';
import { useTranslation } from 'react-i18next';
import RainbowLine from './rainbowline';
import AccessibilityCharter from '../pages/accessibilityCharter';


const Footer = ({ showCookieBanner, setShowCookieBanner }) => {
 

  const logoMap = {
    'en': bm_en_sprb_Img,
    'fr': bm_fr_sprb_Img,
    'nl': bm_nl_sprb_Img,
  };
  const logoLinkMap = {
    'en': "https://servicepublic.brussels/",
    'fr': "https://servicepublic.brussels/",
    'nl': "https://overheidsdienst.brussels",
  };
  const privacyMap = {
    'en': "https://servicepublic.brussels/politique-de-confidentialite/",
    'fr': "https://servicepublic.brussels/politique-de-confidentialite/",
    'nl': "https://overheidsdienst.brussels/privacybeleid/",
  };
  const aboutMap = {

    'en': "https://mobilite-mobiliteit.brussels/en/about-brussels-mobility" ,
    'fr': "https://mobilite-mobiliteit.brussels/fr/qui-sommes-nous",
    'nl': "https://mobilite-mobiliteit.brussels/nl/wie-zijn-we",
  };
  const termsMap = {
    'en': "https://mobilite-mobiliteit.brussels/en/terms-of-use",
    'fr': "https://mobilite-mobiliteit.brussels/fr/conditions-dutilisation",
    'nl': "https://mobilite-mobiliteit.brussels/nl/gebruiksvoorwaarden",
  };

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const currentLang = i18n.language;

  const currentLogo = logoMap[currentLang] || bm_en_sprb_Img;
  const currentLogoLink = logoLinkMap[currentLang] ||"https://servicepublic.brussels/";
  const currentPrivacyLink = privacyMap[currentLang] ||"https://servicepublic.brussels/politique-de-confidentialite/";
  const currentAboutLink = aboutMap[currentLang] ||"https://mobilite-mobiliteit.brussels/en/about-brussels-mobility"
  const currentTermsLink = termsMap[currentLang] ||"https://mobilite-mobiliteit.brussels/en/terms-of-use";

  const handleCookieBanner = (e) => {
    e.preventDefault(); 
    setShowCookieBanner(true); // This will open the cookie preferences dialog
  };

  return (
    <div id="sprb-footer">

          <div className="flex flex-col "  >
          <div style={{ backgroundColor: '#3C8200' }} className="rounded-t-lg">
              <Container>
                <div className="flex flex-row gap-4">
                  <img src={currentLogo} alt="bm-logo" />

                  </div>
              </Container>
            
          </div>
          <div style={{ backgroundColor: '#ffffff' }}>
          <Container>

            <div   className="sprb-styles flex flex-wrap flex-row justify-between gap-6 uppercase">
              <div className="flex flex-row flex-wrap gap-4">
                      © 2023, {t('footer-sprb')}

                      <a href={currentPrivacyLink} target="_blank" rel="noreferrer" className="hover:underline">
                        {t('footer-privacy')}
                      </a>
                      -
                      <a href={currentAboutLink} target="_blank" rel="noreferrer" className="hover:underline">
                       {t('footer-about')}
                      </a>
                      -
                      <a href={currentTermsLink} target="_blank" rel="noreferrer" className="hover:underline">
                        {t('footer-terms')}
                      </a>
                      -
                      <a  onClick={handleCookieBanner} className="hover:underline">
                        {t('footer-cookies')}
                      </a>
                      -
                      <a href="/credits" className="hover:underline">
                        {t('footer-credits')}
                      </a>
                      -
                       <a href="/accessibility-charter" className="hover:underline">
                        {t('acc-title-main')}
                      </a>
               </div>
          
            
          
            <div className="flex items-center justify-center ">
               {/*<a href={currentLink} target="_blank" rel="noreferrer" className="h-100"> <img src={anysurfer_Img} alt="anysurfer" /> </a>*/}
              <a href={currentLogoLink} target="_blank" rel="noreferrer"> 
              <img
                   className="w-auto h-auto min-w-[100px]" 
                src={sprb_Img} 
                loading="eager"
                placeholder="blur"
                alt="sprb-logo"
              />
              </a>
            </div> 
          </div>
          </Container>
          
      </div>
    </div>
    <RainbowLine/>
    </div>
  );
};

export default Footer;
