import React from 'react';

const RainbowLine = () => {
  const rainbowStyle = {
    height: '0.46667em',
  };

  const individualRainbowStyle = {
    width: '16.66667%',
  };

  const colors = [
    '#3c8200',
    '#006d8b',
    '#2f3e8b',
    '#007bc4',
    '#c94d1b',
    '#b7182e',
    '#c92274',
    '#831f82'
  ];

  return (
    <>

    <div style={rainbowStyle} className="flex flex-row">
      {colors.map((color, index) => (
        <div
          key={index}
          style={{ ...individualRainbowStyle, backgroundColor: color }}
        ></div>
      ))}
    </div>
    </>
  );
};

export default RainbowLine;
