import AUD_IMG from "../img/mun_logo/AUD.svg";
import AND_IMG from "../img/mun_logo/AND.svg";
import BER_IMG from "../img/mun_logo/BER.svg";
import BRU_IMG from "../img/mun_logo/BRU.svg";
import ELS_IMG from "../img/mun_logo/ELS.svg";
import ETT_IMG from "../img/mun_logo/ETT.svg";
import EVE_IMG from "../img/mun_logo/EVE.svg";
import FOR_IMG from "../img/mun_logo/FOR.svg";
import GAN_IMG from "../img/mun_logo/GAN.svg";
import JET_IMG from "../img/mun_logo/JET.svg";
import KOEK_IMG from "../img/mun_logo/KOEK.svg";
import MOL_IMG from "../img/mun_logo/MOL.svg";
import SCH_IMG from "../img/mun_logo/SCH.svg";
import STG_IMG from "../img/mun_logo/STG.svg";
import STJ_IMG from "../img/mun_logo/STJ.svg";
import UCL_IMG from "../img/mun_logo/UCL.svg";
import WAT_IMG from "../img/mun_logo/WAT.svg";
import WSL_IMG from "../img/mun_logo/WSL.svg";
import WSP_IMG from "../img/mun_logo/WSP.svg";

const proceduresData = [
  {
    label: {
      fr: "Auderghem",
      nl: "Oudergem",
      en: "Auderghem"
    },
    img: AUD_IMG,
    value: "AUD",
    procedures: [
      {
        title: {
          fr: "Occupation de l'espace public",
          nl: "Bezetting van de openbare ruimte",
          en: "Occupation of the public space",
        },
        link: {
          fr: "https://www.auderghem.be/occupation-de-lespace-public",
          nl: "https://www.oudergem.be/bezetting-van-de-openbare-ruimte",
          en: "https://www.auderghem.be/occupation-de-lespace-public",
        },
        internal: false
      }, {
        title: {
          fr: "Déménagements et livraisons",
          nl: "Verhuis en levering",
          en: "Moving and deliveries",
        },
        link: {
          fr: "https://www.auderghem.be/demenager",
          nl: "https://www.oudergem.be/verhuis-levering",
          en: "https://www.auderghem.be/demenager",
        },
        internal: false
      },
      {
        title: {
          fr: "Effectuer des travaux ",
          nl: "(Ver)bouwen",
          en: "Carry out works / renovation works",
        },
        link: {
          fr: " https://www.auderghem.be/faire-des-travaux",
          nl: " https://www.oudergem.be/verbouwen",
          en: " https://www.auderghem.be/faire-des-travaux",
        },
        internal: false
      }],
    "forms": [
      {
        title: {
          fr: "Formulaire de demande d'occupation de l'espace public dans le cadre de travaux ayant un impact sur la mobilité",
          nl: "Aanvraagformulier voor het bezetten van de openbare ruimte in het kader van werken die een impact hebben op de mobiliteit",
          en: "Request form for the occupation of the public space in the context of works impacting mobility"
        },
        link: {
          fr: "https://www.auderghem.be/sites/default/files/content-files/Formulaires/oep_formulaire_fr.pdf",
          nl: "https://www.oudergem.be/sites/default/files/content-files/Formulaires/oep_formulaire_nl.pdf",
          en: "https://www.auderghem.be/sites/default/files/content-files/Formulaires/oep_formulaire_fr.pdf",
        },
        internal: false
      }
    ]
  },
  {
    label: {
      fr: "Anderlecht",
      nl: "Anderlecht",
      en: "Anderlecht"
    },
    img: AND_IMG,
    value: "AND",
    procedures: [{
      title: {
        fr: "Location de panneaux et occupations de la voirie publique",
        nl: "Verhuur van borden en bezettingen van de openbare weg",
        en: "Rental of signs and occupation of the public road",
      },
      link:
      {
        fr: "https://www.anderlecht.be/fr/travaux-publics",
        nl: "https://www.anderlecht.be/nl/openbare-werken",
        en: "https://www.anderlecht.be/fr/travaux-publics",
      },
      internal: false
    },
    {
      title: {
        fr: "Organiser un événement",
        nl: "Een evenement organiseren",
        en: "Organize an event",
      },
      link:
      {
        fr: "https://www.anderlecht.be/index.php/fr/organiser-un-evenement",
        nl: "https://www.anderlecht.be/index.php/nl/een-evenement-organiseren",
        en: "https://www.anderlecht.be/index.php/fr/organiser-un-evenement ",
      },
      internal: false
    }],
    forms: [

    ]
  },
  {
    label: {
      fr: "Berchem-Sainte-Agathe",
      nl: "Sint-Agatha-Berchem",
      en: "Berchem-Sainte-Agathe"
    },
    img: BER_IMG,
    value: "BER",
    procedures: [{
      title: {
        fr: "Déménagement, travaux, conteneur : réservation d’espace sur la voie publique",
        nl: "Verhuizen, werken, containers: reservering op de openbare weg",
        en:  "Moving, building work, containers: reserving space on the public road",
      },
      link:
      {
        fr: "https://berchem.brussels/fr/vous-citoyen/habiter-et-vivre/espace-public/demenagement-travaux-conteneur-reservation-despace-sur-la-voie-publique/",
        nl: "https://berchem.brussels/nl/u-inwoner/wonen-en-leven/openbare-ruimte/httpberchem-brusselsnlu-inwonerwonen-en-levenopenbare-ruimteverhuizen-werken-containers-reservering-op-de-openbare-wegverhuizen-werken-containers-reservering-op-de-openbare-weg/verhuizen-werken-containers-reservering-op-de-openbare-weg/",
        en: "https://berchem.brussels/fr/vous-citoyen/habiter-et-vivre/espace-public/demenagement-travaux-conteneur-reservation-despace-sur-la-voie-publique/",
      },
      internal: false
    },
    {
      title: {
        fr: "Organiser un événement sur voirie communale",
        nl: "Een evenement organiseren op de openbare weg",
        en: "Organize an event on municipal roads",
      },
      link:
      {
        fr: "https://berchem.brussels/fr/vous-citoyen/se-divertir/festivites-et-evenements/",
        nl: "https://berchem.brussels/nl/u-inwoner/ontspannen/festiviteiten-en-evenementen/",
        en: "https://berchem.brussels/fr/vous-citoyen/se-divertir/festivites-et-evenements/",
      },
      internal: false
    }],
    forms: [
            {
                title: {
                    fr: "Formulaire de réservation de panneaux d’interdiction de stationnement",
                    nl: "Toelatingsaanvraagformulier reservering van parkeerplaatsen",
                    en: "Request form for no-parking signs",
                },
                link: {
                    fr: "https://berchem.brussels/wp-content/uploads/2015/10/Occupation-espace-public_BIS-1.pdf",
                    nl: "https://berchem.brussels/wp-content/uploads/2015/10/Occupation-espace-public_BIS-1.pdf",
                    en: "https://berchem.brussels/wp-content/uploads/2015/10/Occupation-espace-public_BIS-1.pdf",
                },
                internal: false
            },
    ]
  },
  {
    label: {
      fr: "Villes de Bruxelles",
      nl: "Stad Brussel",
      en: "City of Brussels"
    },
    img: BRU_IMG,
    value: "BRU",
    procedures: [
      {
        title: {
          fr: "Demande d'autorisation d'occupation temporaire de la voie publique",
          nl: "Toelating tijdelijke bezetting van de openbare weg",
          en: "Road occupation permit"
        },
        link: {
          fr: "https://www.bruxelles.be/autorisation-doccupation-temporaire-de-la-voie-publique",
          nl: "https://www.brussel.be/toelating-tijdelijke-bezetting-van-de-openbare-weg",
          en: "https://www.brussels.be/road-occupation-permit"
        },
        internal: false
      }
    ],
    forms: [
      {
        title: {
          fr: "Formulaire de déclaration d'Occupation Temporaire de la Voie Publique",
          nl: "Formulier: verklaring van tijdelijke bezetting van de openbare weg",
          en: "Request form for the temporary occupation of the public road"
        },
        link: {
          fr: "https://www.bruxelles.be/sites/default/files/bxl/FORMULAIRE_DE_DECLARATION_-_FR-_Version_4_OTVP_2022.06.28.pdf",
          nl: "https://www.brussel.be/sites/default/files/bxl/taks_op_de_tijdelijke_bezetting_van_de_openbare_arrete_20220425_OTVP_ndls.pdf",
          en: "https://www.brussels.be/sites/default/files/bxl/FORMULAIRE_DE_DECLARATION_-_FR-_Version_4_OTVP_2022.06.28.pdf"
        },
        internal: false
      }
    ],
  },
  {
    label: {
      fr: "Ixelles",
      nl: "Elsene",
      en: "Ixelles"
    },
    img: ELS_IMG,
    value: "ELS",
    procedures: [
      {
        title: {
          fr: "Organiser un événement",
          nl: "Een evenement organiseren",
          en: "Organize an event"
        },
        link: {
          fr: "https://www.ixelles.be/site/100-Organiser-un-evenement",
          nl: "https://www.elsene.be/site/100-Organiser-un-evenement",
          en: "https://www.ixelles.be/site/100-Organiser-un-evenement"
        },
        internal: false
      },
      {
        title: {
          fr: "Réservation d'emplacements de stationnement",
          nl: "Parkeerplaatsen reserveren",
          en: "Reservation of parking space"
        },
        link: {
          fr: "https://www.ixelles.be/site/262-Reservation-d-emplacements-de-stationnement",
          nl: "https://www.elsene.be/site/262-Reservation-d-emplacements-de-stationnement",
          en: "https://www.ixelles.be/site/262-Reservation-d-emplacements-de-stationnement"
        },
        internal: false
      }
    ],
    forms: [
      {
        title: {
          fr: "Formulaire de demande d’autorisation pour l’organisation d’évènement",
          nl: "Aanvraagformulier voor de organisatie van evenemente",
          en: "Request form for authorisation to organise an event"
        },
        link: {
          fr: "https://www.ixelles.be/site/fr/download/events/demande-organisation-evenement.pdf",
          nl: "https://www.ixelles.be/site/nl/download/events/demande-organisation-evenement.pdf",
          en: "https://www.ixelles.be/site/fr/download/events/demande-organisation-evenement.pdf"
        },
        internal: false
      }
    ]
  },
  {
    label: {
      fr: "Etterbeek",
      nl: "Etterbeek",
      en: "Etterbeek"
    },
    img: ETT_IMG,
    value: "ETT",
    procedures: [
      {
        title: {
          fr: "Réservation temporaire de stationnement",
          nl: "Parkeerplaatsen reserveren",
          en: "Temporary reservation of parking space"
        },
        link: {
          fr: "https://etterbeek.brussels/fr/demarches/mobilite/reservation-temporaire-de-stationnement-hors-chantier",
          nl: "https://etterbeek.brussels/nl/administratieve-stappen/mobiliteit/parkeerplaats-reserveren-geen-werf",
          en: "https://etterbeek.brussels/fr/demarches/mobilite/reservation-temporaire-de-stationnement-hors-chantier"
        },
        internal: false
      },
      {
        title: {
          fr: "Occupation temporaire de la voie publique",
          nl: "Tijdelijke bezetting van de openbare weg",
          en: "Temporary occupation of the public road"
        },
        link: {
          fr: "https://etterbeek.brussels/fr/demarches/mobilite/occupation-temporaire-de-la-voie-publique-chantier",
          nl: "https://etterbeek.brussels/nl/administratieve-stappen/mobiliteit/tijdelijke-bezetting-van-de-openbare-weg-werf",
          en: "https://etterbeek.brussels/fr/demarches/mobilite/occupation-temporaire-de-la-voie-publique-chantier"
        },
        internal: false
      }
    ],
    forms: [
    ]
  },
  {
    label: {
      fr: "Evere",
      nl: "Evere",
      en: "Evere"
    },
    img: EVE_IMG,
    value: "EVE",
    procedures: [
      {
        title: {
          fr: "Réservation d'emplacements de stationnement",
          nl: "Terbeschikkingstelling van een parkeerplaats",
          en: "Temporary reservation of parking space"
        },
        link: {
          fr: "https://evere.brussels/fr/demarches/stationnement#section-313",
          nl: "https://evere.brussels/nl/administratie/parkeer#section-313",
          en: "https://evere.brussels/fr/demarches/stationnement#section-313"
        },
        internal: false
      },
      {
        title: {
          fr: "Organisation d'un événement sur voirie publique",
          nl: "Organisatie van een evenement op de openbare weg",
          en: "organize an event on public roads"
        },
        link: {
          fr: "https://evere.brussels/fr/demarches/evenements-et-tournages#section-291",
          nl: "https://evere.brussels/nl/administratie/evenementen-en-filmen-op-het-grondgebied#section-291",
          en: "https://evere.brussels/fr/demarches/evenements-et-tournages#section-291",
        },
        internal: false
      }
    ],
    forms: [
    ]
  },

  {
    label: {
      fr: "Forest",
      nl: "Vorst",
      en: "Forest"
    },
    img: FOR_IMG,
    value: "FOR",
    procedures: [
      {
        title: {
          fr: "Réservation d'emplacement & occupation temporaire de la voie publique",
          nl: "Reservering van een standplaats & tijdelijke bezetting van de openbare weg",
          en: "Reservation of parking space & temporary occupation of the public roadway",
        },
        link:
        {
          fr: "https://www.forest.brussels/fr/services-communaux/occupation-de-la-voie-publique",
          nl: "https://www.vorst.brussels/nl/gemeentediensten/bezetten-van-de-openbare-weg",
          en: "https://www.forest.brussels/fr/services-communaux/occupation-de-la-voie-publique",
        },
        internal: false
      }
    ],
    forms: [
      {
        title: {
          fr: "Formulaire de demande de réservation d'emplacement ou d'occupation temporaire de la voirie",
          nl: "Aanvraagformulier voor het bezetten van de openbare weg",
          en: "Reservation form for a parking space or a temporary occupation of the public road",
        },
        link:
        {
          fr: "https://www.forest.brussels/fr/services-communaux/occupation-de-la-voie-publique/fichiers/formulaire-de-demande-doccupation-de-lespace.pdf",
          nl: "https://www.vorst.brussels/nl/gemeentediensten/bezetten-van-de-openbare-weg/bestanden/aanvraagformulier-ovp-nl-2022.pdf",
          en: "https://www.forest.brussels/fr/services-communaux/occupation-de-la-voie-publique/fichiers/formulaire-de-demande-doccupation-de-lespace.pdf",
        },
        internal: false
      }
    ]
  },
  {
    label: {
      fr: "Ganshoren",
      nl: "Ganshoren",
      en: "Ganshoren"
    },
    img: GAN_IMG,
    value: "GAN",
    procedures: [
      {
        title: {
          fr: "Demande de placement de panneaux de signalisation",
          nl: "Verzoek om een parkeerverbod te plaatsen",
          en: "Request for traffic signs",
        },
        link:
        {
          fr: "https://www.ganshoren.be/mobilite/",
          nl: "https://www.ganshoren.be/nl/mobiliteit/",
          en: "https://www.ganshoren.be/mobilite/",
        },
        internal: false
      }
    ],
    forms: [

    ]
  },
  {
    label: {
      fr: "Jette",
      nl: "Jette",
      en: "Jette"
    },
    img: JET_IMG,
    value: "JET",
    procedures: [
      {
        title: {
          fr: "Panneaux de déménagement, conteneur ou élévateur",
          nl: "Signalisatieborden verhuis, container of lift",
          en: "Signs for move-in/move-out, container or lift",
        },
        link:
        {
          fr: "https://www.jette.irisnet.be/fr/a-votre-service/espace-public/panneaux-de-demenagement-conteneur-ou-delevateur",
          nl: "https://www.jette.irisnet.be/nl/tot-uw-dienst/openbare-ruimte/signalisatieborden-verhuis-container-of-lift",
          en: "https://www.jette.irisnet.be/fr/a-votre-service/espace-public/panneaux-de-demenagement-conteneur-ou-delevateur",
        },
        internal: false
      }
    ],
    forms: [

    ]
  },
  {
    label: {
      fr: "Koekelberg",
      nl: "Koekelberg",
      en: "Koekelberg"
    },
    img: KOEK_IMG,
    value: "KOEK",
    procedures: [
      {
        title: {
          fr: "Réserver un emplacement pour un emménagement, un déménagement ou une livraison",
          nl: "Een parkeerplaats reserveren om te verhuizen of voor een levering",
          en: "Reserve a parking space for a move-in/move-out or a delivery.",
        },
        link:
        {
          fr: "https://www.koekelberg.be/w/index.php?cont=2700&lgn=1",
          nl: "https://www.koekelberg.be/w/index.php?lgn=2&cont=2700",
          en: "https://www.koekelberg.be/w/index.php?cont=2700&lgn=1",
        },
        internal: false
      },
      {
        title: {
          fr: "Réserver un emplacement pour conteneur, élévateur ou échafaudage",
          nl: "Een plaats reserveren voor een container, een lift of een stelling",
          en: "Reserve a parking space for a container, a lift or a scaffolding",
        },
        link:
        {
          fr: "https://www.koekelberg.be/w/index.php?cont=2701&lgn=1",
          nl: "https://www.koekelberg.be/w/index.php?lgn=2&cont=2701",
          en: "https://www.koekelberg.be/w/index.php?cont=2701&lgn=1",
        },
        internal: false
      },
      {
        title: {
          fr: "Organiser un événément",
          nl: "Een evenement organiseren",
          en: "Organize an event",
        },
        link:
        {
          fr: "https://www.koekelberg.be/w/index.php?cont=2860&amp;lgn=1",
          nl: "https://www.koekelberg.be/w/index.php?lgn=2&cont=2860",
          en: "https://www.koekelberg.be/w/index.php?cont=2860&amp;lgn=1",
        },
        internal: false
      }
    ],
    forms: [

    ]
  },
  {
    label: {
      fr: "Molenbeek-Saint-Jean",
      nl: "Sint-Jans-Molenbeek",
      en: "Molenbeek-Saint-Jean"
    },
    img: MOL_IMG,
    value: "MOL",
    procedures: [
      {
        title: {
          fr: "Service signalisation",
          nl: "Dienst signalisatie",
          en: "Road signs service",
        },
        link:
        {
          fr: "https://www.molenbeek.irisnet.be/fr/je-vis/espace-public/signalisation/service-signalisation",
          nl: "https://www.molenbeek.irisnet.be/nl/ik-leef/openbare-ruimte/signalisatie/dienst-signalisatie?set_language=nl",
          en: "https://www.molenbeek.irisnet.be/fr/je-vis/espace-public/signalisation/service-signalisation",
        },
        internal: false
      }
    ],
    forms: [

    ]
  },
  {
    label: {
      fr: "Saint-Gilles",
      nl: "Sint-Gillis",
      en: "Saint-Gilles"
    },
    img: STG_IMG,
    value: "STG",
    procedures: [
      {
        title: {
          fr: "Réserver un emplacement de stationnement ou demander une occupation temporaire de la voie publique",
          nl: "Een parkeerplaats reserveren of aanvraag voor tijdelijke bezetting van het openbaar domein",
          en: "Reserve a parking space or request a temporary occupation of the public space",
        },
        link: {
          fr: "https://stgilles.brussels/services/espace-public/espace-public/",
          nl: "https://stgillis.brussels/services/espace-public/espace-public/",
          en: "https://stgilles.brussels/services/espace-public/espace-public/",

        },
        internal: false
      }
    ],
    forms: [
      {
        title: {
          fr: "Formulaire de demande de réservation de stationnement",
          nl: "Aanvraagformulier voor een parkeerplaats reserveren",
          en: "Request form for reserving a parking space",
        },
        link: {
          fr: "https://stgilles.brussels/wp-content/uploads/2022/10/Formulaire-de-demande.pdf",
          nl: "https://stgillis.brussels/wp-content/uploads//2022/10/Aanvraagformulier-nl.pdf",
          en: "https://stgilles.brussels/wp-content/uploads/2022/10/Formulaire-de-demande.pdf",
        },
        internal: false
      }
    ]
  },
  {
    label: {
      fr: "Saint-Josse-Ten-Noode",
      nl: "Sint-Joost-ten-Node",
      en: "Saint-Josse-Ten-Noode"
    },
    img: STJ_IMG,
    value: "STJ",
    procedures: [
      {
        title: {
          fr: "Occupation de la voirie publique",
          nl: "Bezetting van de openbare weg",
          en: "Occupation of the public road",
        },
        link: {
          fr: "https://sjtn.brussels/fr/la-commune/mobilite-stationnement/stationnement#occupation-de-la-voie-publique",
          nl: "https://sjtn.brussels/nl/de-gemeente/mobiliteit-parkeren/parkeren#bezetting-van-de-openbare-weg",
          en: "https://sjtn.brussels/en/municipality/mobility-works/parking#occupation-of-the-public-road",
        },

        internal: false
      },
      {
        title: {

          fr: "Déménagement et livraison",
          nl: "Verhuizing en levering",
          en: "Moving and delivery",
        },
        link: {
          fr: "https://sjtn.brussels/fr/la-commune/mobilite-stationnement/stationnement#demenagement-livraison",
          nl: "https://sjtn.brussels/nl/de-gemeente/mobiliteit-parkeren/parkeren#verhuizing-levering",
          en: "https://sjtn.brussels/fr/la-commune/mobilite-stationnement/stationnement#demenagement-livraison",
        },
        internal: false
      }
    ],
    forms: [
    ]
  },
  {
    label: {
      fr: "Schaerbeek",
      nl: "Schaarbeek",
      en: "Schaerbeek"
    },
    img: SCH_IMG,
    value: "SCH",
    procedures: [
      {
        title: {
          fr: "Demande d'occupation temporaire du domaine public (Panneaux de stationnement)",
          nl: "Aanvraag voor tijdelijke bezetting van het openbaar domein (Parkeerborden)",
          en: "Request for temporary occupation of public space (Parking signs)",
        },
        link: {
          fr: "https://www.1030.be/fr/content/demande-doccupation-temporaire-du-domaine-public-panneaux-de-stationnement",
          nl: "https://www.1030.be/nl/content/aanvraag-voor-tijdelijke-bezetting-van-het-openbaar-domein-parkeerborden",
          en: "https://www.1030.be/fr/content/demande-doccupation-temporaire-du-domaine-public-panneaux-de-stationnement",
        },
        internal: false
      }
    ],
    forms: [
    ]
  },
  {
    label: {
      fr: "Uccle",
      nl: "Ukkle",
      en: "Uccle"
    },
    img: UCL_IMG,
    value: "UCL",
    procedures: [
      {
        title: {
          fr: "Réserver un emplacement de stationnement pour un déménagement, un emménagement, une livraison...",
          nl: "Een parkeerplaats reserveren voor een verhuizing, een inhuizing, een levering...",
          en: "Reserve a parking space for a move, a delivery, etc.",
        },
        link: {
          fr: "https://www.uccle.be/fr/vie-pratique/mobilite-et-stationnement/stationnement/demander-un-stationnement-temporaire-0",
          nl: "https://www.uccle.be/nl/mensen/mobiliteit-en-parking/parkeren/een-tijdelijke-parkeerplaats-aanvragen/verhuizing-leveringen",
          en: "https://www.uccle.be/fr/vie-pratique/mobilite-et-stationnement/stationnement/demander-un-stationnement-temporaire-0",
        },
        internal: false
      },
      {
        title: {

          fr: "Réserver un emplacement de stationnement et/ou demander une occupation temporaire de la voie publique dans le cadre de travaux (conteneur, échafaudage, travaux…)",
          nl: "Een parkeerplaats reserveren en/of een tijdelijke bezetting van de openbare weg aanvragen in het kader van werkzaamheden (container, steiger, bouwwerkzaamheden…)",
          en: "Reserve a parking space and/or request temporary occupation of the public road for works (containers, scaffolding, works, etc.)",
        },
        link: {
          fr: "https://www.uccle.be/fr/vie-pratique/mobilite-et-stationnement/stationnement/demander-un-stationnement-temporaire/travaux",
          nl: "https://www.uccle.be/nl/mensen/mobiliteit-en-parking/parkeren/een-tijdelijke-parkeerplaats-aanvragen/werken-een-tijdelijke",
          en: "https://www.uccle.be/fr/vie-pratique/mobilite-et-stationnement/stationnement/demander-un-stationnement-temporaire/travaux",
        },
        internal: false
      },
      {
        title: {
          fr: "Informations sur les chantiers privés",
          nl: "Informatie over Privébouwplaatsen",
          en: "Information on private worksites",
        },
        link: {
          fr: "https://www.uccle.be/fr/vie-pratique/voirie-et-travaux/chantiers-prives-obligations",
          nl: "https://www.uccle.be/nl/mensen/wegen-en-werken/privebouwplaatsen-verplichtingen",
          en: "https://www.uccle.be/fr/vie-pratique/voirie-et-travaux/chantiers-prives-obligations",
        },
        internal: false
      },
      {

        title: {
          fr: "Informations sur la coordination des chantiers",
          nl: "Informatie over Coördinatie van bouwplaatsen",
          en: "Information on worksites coordination",
        },
        link: {
          fr: "https://www.uccle.be/fr/vie-pratique/voirie-et-travaux/chantiers-prives-obligations",
          nl: "https://www.uccle.be/nl/mensen/wegen-en-werken/coordinatie-van-bouwplaatsen",
          en: "https://www.uccle.be/fr/vie-pratique/voirie-et-travaux/chantiers-prives-obligations",
        },
        internal: false
      }
    ],
    forms: [{
      title: {
        fr: "Formulaire de demande d'occupation temporaire de la voie publique a l'occasion de travaux et d'interdiction de stationnement à l'occasion d'un déménagement ou emménagement",
        nl: "Aanvraagformulier voor het parkeerverbod ter gelegenheid van een verhuis, levering van meubilair, organisatie van een rommelmarkt organisatie van burenfeesten",
        en: "Request form for temporary occupation of the public road during works and for a parking ban during a move-in/move-out",
      },
      link: {
        fr: "https://www.uccle.be/sites/default/files/filemanager/Form/Parking-temp/Form_D%C3%A9m%C3%A9nagement_emm%C3%A9nagement_livraison%20FR_.pdf",
        nl: "https://www.uccle.be/sites/default/files/filemanager/Form/Parking-temp/Form_D%C3%A9m%C3%A9nagement_emm%C3%A9nagement_livraison%20NL_.pdf",
        en: "https://www.uccle.be/sites/default/files/filemanager/Form/Parking-temp/Form_D%C3%A9m%C3%A9nagement_emm%C3%A9nagement_livraison%20FR_.pdf",
      },
      internal: false
    },]
  },
  {
    label: {
      fr: "Watermael-Boitsfort",
      nl: "Watermaal-Bosvoorde",
      en: "Watermael-Boitsfort"
    },
    img: WAT_IMG,
    value: "WAT",
    procedures: [
      {
        title: {
          fr: "Réservation et occupation de la voirie",
          nl: "Reservatie en bezetting van de openbare weg",
          en: "Reservation and occupation of the road",
        },
        link: {
          fr: "https://www.watermael-boitsfort.be/fr/vivre-a-watermael-boitsfort/travaux-1/occupation-de-la-voirie",
          nl: "https://watermaal-bosvoorde.be/nl/leven-te-watermaal-bosvoorde/werfen/bezetting-van-weg?set_language=nl",
          en: "https://www.watermael-boitsfort.be/fr/vivre-a-watermael-boitsfort/travaux-1/occupation-de-la-voirie",

        },
        internal: false
      }, {
        title: {
          fr: "Pose d'un échafaudage",
          nl: "Plaatsen van een steiger",
          en: "Installing scaffolding",
        },
        link: {
          fr: "https://watermael-boitsfort.be/fr/vivre-a-watermael-boitsfort/travaux-1/pose-dun-echafaudage",
          nl: "https://watermaal-bosvoorde.be/nl/leven-te-watermaal-bosvoorde/werfen/plaatsen-van-een-steiger?set_language=nl",
          en: "https://watermael-boitsfort.be/fr/vivre-a-watermael-boitsfort/travaux-1/pose-dun-echafaudage",
        },
        internal: false
      }, {
        title: {
          fr: "Organiser un événement",
          nl: "Organisatie van evenementen",
          en: "Organize an event",
        },
        link: {
          fr: "https://watermael-boitsfort.be/fr/vivre-a-watermael-boitsfort/evenements",
          nl: "https://watermaal-bosvoorde.be/nl/leven-te-watermaal-bosvoorde/evenement?set_language=nl",
          en: "https://watermael-boitsfort.be/fr/vivre-a-watermael-boitsfort/evenements",
        },
        internal: false
      },
    ],
    forms: [
      {
        title: {
          fr: "Formulaire de demande d'occupation temporaire de la voie publique",
          nl: "Aanvraagformulier voor tijdelijke bezetting van de openbare weg",
          en: "Request form for temporary occupation of the public space",
        },
        link: {
          fr: "https://watermael-boitsfort.be/fr/fichiers/voiries/occupation.doc",
          nl: "https://watermaal-bosvoorde.be/fr/fichiers/voiries/bezetting.doc",
          en: "https://watermael-boitsfort.be/fr/fichiers/voiries/occupation.doc",
        },
        internal: false
      },
      {
        title: {
          fr: "Formulaire de reservation de stationnement",
          nl: "Parkeerreserveringsformulier",
          en: "Parking space reservation form",
        },
        link: {
          fr: "https://watermael-boitsfort.be/fr/fichiers/voiries/reservation-stationnement.doc",
          nl: "https://watermaal-bosvoorde.be/fr/fichiers/voiries/reservatie-parkeerverbod.doc",
          en: "https://watermael-boitsfort.be/fr/fichiers/voiries/reservation-stationnement.doc",
        },
        internal: false
      },
      {
        title: {
          fr: "Formulaire de demande d'autorisation pour l'organisation d'un événement",
          nl: "Oelatingsaanvraagformulier voor de organisatie van een evenement",
          en: "Authorization request form for organizing an event",
        },
        link: {
          fr: "https://watermael-boitsfort.be/fr/fichiers/evenement/formulaire-organisation-evenement-fr-complet-1-actualise-au-20231217.doc",
          nl: "https://watermaal-bosvoorde.be/fr/fichiers/evenement/formulaire-organisation-evenement-nl-complet-actualise-au-20231217.doc",
          en: "https://watermael-boitsfort.be/fr/fichiers/evenement/formulaire-organisation-evenement-fr-complet-1-actualise-au-20231217.doc",
        },
        internal: false
      },]
  },
  {
    label: {
      fr: "Woluwe-Saint-Lambert",
      nl: "Sint-Lambrechts-Woluwe",
      en: "Woluwe-Saint-Lambert"
    },
    img: WSL_IMG,
    value: "WSL",
    procedures: [
      {
        title: {
          fr: "Réserver un emplacement de stationnement",
          nl: "Een parkeerplaats reserveren",
          en: "Reserve a parking space",
        },
        link: {
          fr: "https://www.woluwe1200.be/demarches/reserver-un-emplacement-de-stationnement/",
          nl: "https://www.woluwe1200.be/nl/demarches/93792/",
          en: "https://www.woluwe1200.be/demarches/reserver-un-emplacement-de-stationnement/",
        },

        internal: false
      }
    ],
    forms: [
      {
        title: {
          fr: "Formulaire de demande d'occupation du domaine public",
          nl: "Aanvraag voor de bezetting van het openbaar domein",
          en: "Public space occupation request form",
        },
        link: {
          fr: "https://wbsrv.woluwe1200.be/WD230AWP/WD230Awp.exe/CONNECT/F_ODP",
          nl: "https://wbsrv.woluwe1200.be/WD230AWP/WD230Awp.exe/CONNECT/F_ODP?lng=N",
          en: "https://wbsrv.woluwe1200.be/WD230AWP/WD230Awp.exe/CONNECT/F_ODP",
        },
        internal: false
      }
    ]
  },
  {
    label: {
      fr: "Woluwe-Saint-Pierre",
      nl: "Sint-Pieters-Woluwe",
      en: "Woluwe-Saint-Pierre"
    },
    img: WSP_IMG,
    value: "WSP",
    procedures: [
      {
        title: {
          fr: "Informations sur l'occupation du domaine public",
          nl: "Informatie over het bezetting van het openbaar domein",
          en: "Information on the occupation of the public space",
        },
        link: {
          fr: "https://www.woluwe1150.be/services-communaux/occupation-du-domaine-public/",
          nl: "https://www.woluwe1150.be/nl/gemeentediensten/bezetting-van-het-openbaar-domein/",
          en: "https://www.woluwe1150.be/services-communaux/occupation-du-domaine-public/",
        },
        internal: false
      },
      {
        title: {
          fr: "Réserver un emplacement de stationnement",
          nl: "Een parkeerplaats reserveren",
          en: "Reserve a parking space",
        },
        link: {
          fr: "https://www.woluwe1150.be/services-communaux/stationnement/reserver-stationnement-fr/",
          nl: "https://www.woluwe1150.be/nl/gemeentediensten/parkeren/reserver-stationnement-nl/",
          en: "https://www.woluwe1150.be/en/municipal-services/parking/reserver-stationnement-en/",
        },
        internal: false
      },
      {
        title: {
          fr: "Informations sur les travaux impactant les voiries publiques",
          nl: "Informatie over werkzaamheden die van invloed zijn op de openbare wegen",
          en: "Information on works affecting public roads",
        },
        link: {
          fr: "https://www.woluwe1150.be/services-communaux/travaux-publics/osiris/",
          nl: "https://www.woluwe1150.be/nl/gemeentediensten/travaux-publics-nl/osiris/",
          en: "https://www.woluwe1150.be/services-communaux/travaux-publics/osiris/",
        },
        internal: false
      },
    ],
    forms: [
      {
        title: {
          fr: "Formulaire de réservation de panneaux d’interdiction de stationnement",
          nl: "Toelatingsaanvraagformulier reservering van parkeerplaatsen",
          en: "No-parking signs reservation form",
        },
        link: {
          fr: "https://www.woluwe1150.be/services-communaux/stationnement/reserver-stationnement-fr/reservation-de-panneaux-dinterdiction-de-stationnement-pour-un-demenagement-p-ex/",
          nl: "https://www.woluwe1150.be/nl/gemeentediensten/parkeren/reserver-stationnement-nl/reservering-van-parkeerplaatsen-verkeersborden/",
          en: "https://www.woluwe1150.be/services-communaux/stationnement/reserver-stationnement-fr/reservation-de-panneaux-dinterdiction-de-stationnement-pour-un-demenagement-p-ex/",
        },
        internal: false
      },
      {
        title: {
          fr: "Formulaire de demande d'autorisation dans le cadre d'une occupation du domaine public",
          nl: "Toelatingsaanvraagformulier in het kader van een bezetting van het openbaar domein",
          en: "Authorization request form for occupation of the public space",
        },
        link: {
          fr: "https://www.woluwe1150.be/app/uploads/2022/05/formulaire_occupation_voie_publique_fr.docx",
          nl: "https://www.woluwe1150.be/app/uploads/2022/05/occupation_voie_publique_nl.docx",
          en: "https://www.woluwe1150.be/app/uploads/2022/05/formulaire_occupation_voie_publique_fr.docx",
        },
        internal: false
      },
    ]
  }
];

export default proceduresData;

