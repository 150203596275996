import React from "react";
import { Link } from "react-router-dom";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    Accordion,
    AccordionHeader,
    AccordionBody,
    Chip,
} from "@material-tailwind/react";
import {
    UserCircleIcon,
    ArrowRightEndOnRectangleIcon,
    CodeBracketIcon,
    BoltIcon,

} from "@heroicons/react/24/solid";
import {
    PresentationChartBarIcon,
    PowerIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    KeyIcon,
    DocumentCheckIcon,
    MapPinIcon,
    MapIcon,
    PresentationChartLineIcon,
    CodeBracketSquareIcon,
    PlayCircleIcon,
    CircleStackIcon,
    GlobeAltIcon,
    CubeIcon,
    CalendarIcon,
} from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';

import apiLogo from "../img/api/apiLogo.png";

export const CustomSidebar = () => {

    const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();
    const [userEmail, setUserEmail] = useState(null);
    const [open, setOpen] = React.useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (user) {
            setUserEmail(user.email || "");
        }
    }, [user]);

    const login = () => {
        loginWithRedirect({
            scope: 'read:current_user update:current_user_metadata', // Add additional scopes as needed
        });
    };

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location,
            }
        });

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <Card className="flex flex-grow min-h-[90vh] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
            <Link to="/osiris-api/" key="/osiris-api/">
                <div className="mb-2 flex items-center gap-4 p-4 cursor-pointer hover:cursor-pointer">
                    {/* <CodeBracketIcon alt="brand" className="h-8 w-8" />*/}
                    <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
                        <img
                            className="max-w-[200px] object-cover object-center"
                            src={apiLogo}
                            loading="eager"
                            placeholder="blur"
                            alt="Logo"
                        />
                    </div>
                    <Chip size="sm" value="Beta" color="green" />
                </div>
            </Link>
            <Link to="/osiris-api/" key="/osiris-api/">
                <div className="mb-2 flex items-center gap-4 p-4 cursor-pointer hover:cursor-pointer">
                    <CodeBracketIcon alt="brand" className="h-8 w-8" />
                    <div className="font-bold text-xl text-gray-800 dark:text-gray-200">
                    {t('api-general')}
                    </div>
                </div>
            </Link>

            <List>
                
                <Link to="/osiris-api/access" key="/osiris-api/access">
                    <ListItem>
                        <ListItemPrefix>
                            <BoltIcon strokeWidth={3} className="h-3 w-5" />
                        </ListItemPrefix>
                        {t('api-doc-how-to')}
                    </ListItem>
                </Link>

                <Accordion
                    open={open === 2}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                        />
                    }
                >
                    <Link to="/osiris-api/technical-intro" key="/osiris-api/technical-intro">
                        <ListItem className="p-0" selected={open === 2} href="/osiris-api/technical-intro">
                            <AccordionHeader onClick={() => handleOpen(2)} className="border-b-0 p-3">
                                <ListItemPrefix>
                                    <MapIcon className="h-5 w-5" />
                                </ListItemPrefix>
                                <Typography color="blue-gray" className="mr-auto font-normal">
                                    {t('api-technical-title')}
                                </Typography>
                            </AccordionHeader>
                        </ListItem>
                    </Link>
                    <AccordionBody className="py-1">
                        <List className="p-0">
                            <Link to="/osiris-api/technical-geoserver" key="/osiris-api/technical-geoserver">
                                <ListItem>
                                    <ListItemPrefix>
                                        <div className="flex flex-row items-center gap-4"> <ChevronRightIcon strokeWidth={2} className="h-3 w-5" /><GlobeAltIcon strokeWidth={2} className="h-5 w-5" /></div>
                                    </ListItemPrefix>
                                    {t('api-technical-geoserver-title')}
                                </ListItem>
                            </Link>
                            <Link to="/osiris-api/technical-ogc" key="/osiris-api/technical-ogc">
                                <ListItem >
                                    <ListItemPrefix>
                                        <div className="flex flex-row items-center gap-4"> <ChevronRightIcon strokeWidth={2} className="h-3 w-5" /><CubeIcon strokeWidth={2} className="h-5 w-5" /></div>
                                    </ListItemPrefix>
                                    {t('api-technical-ogc-title')}
                                </ListItem>
                            </Link>
                            <Link to="/osiris-api/technical-data-models" key="/osiris-api/technical-data-models">
                                <ListItem>
                                    <ListItemPrefix>
                                        <div className="flex flex-row items-center gap-4"> <ChevronRightIcon strokeWidth={2} className="h-3 w-5" />   <CircleStackIcon strokeWidth={2} className="h-5 w-5" /></div>

                                    </ListItemPrefix>
                                    {t('api-technical-datamodels-title')}
                                </ListItem>
                            </Link>
                            <Link to="/osiris-api/technical-roadmap" key="/osiris-api/technical-roadmap">
                                <ListItem >
                                    <ListItemPrefix>
                                        <div className="flex flex-row items-center gap-4"> <ChevronRightIcon strokeWidth={2} className="h-3 w-5" /><CalendarIcon strokeWidth={2} className="h-5 w-5" /></div>
                                    </ListItemPrefix>
                                    {t('api-technical-roadmap-title')}
                                </ListItem>
                            </Link >

                        </List >
                    </AccordionBody >
                </Accordion >




                <Accordion
                    open={open === 1}
                    icon={
                        <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                        />
                    }
                >

                    <ListItem className="p-0" selected={open === 1}>
                        <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
                            <ListItemPrefix>
                                <MapIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="mr-auto font-normal">
                                {t('api-tutorial-title')}
                            </Typography>
                        </AccordionHeader>
                    </ListItem>

                    <AccordionBody className="py-1">
                        <List className="p-0">
                            <Link to="/osiris-api/use-case-qgis" key="/osiris-api/use-case-qgis">
                                <ListItem >
                                    <ListItemPrefix>
                                        <div className="flex flex-row items-center gap-4"> <ChevronRightIcon strokeWidth={2} className="h-3 w-5" /><MapPinIcon strokeWidth={2} className="h-5 w-5" /></div>
                                    </ListItemPrefix>
                                    {t('api-tutorial-qgis-title')}
                                </ListItem>
                            </Link>
                            <Link to="/osiris-api/use-case-endpoint" key="/osiris-api/use-case-endpoint">
                                <ListItem>
                                    <ListItemPrefix>
                                        <div className="flex flex-row items-center gap-4"> <ChevronRightIcon strokeWidth={2} className="h-3 w-5" /><CodeBracketIcon strokeWidth={3} className="h-5 w-5" /></div>
                                    </ListItemPrefix>
                                    {t('api-tutorial-endpoint')}
                                </ListItem>
                            </Link>
                        </List >

                    </AccordionBody >
                </Accordion >

                <Link to="/osiris-api/demo" key="/osiris-api/demo">
                    <ListItem>
                        <ListItemPrefix >
                            <PresentationChartBarIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        {t('api-demo')}

                    </ListItem>
                </Link>

                <Link to="/osiris-api/terms-of-use" key="/osiris-api/terms-of-use">
                    < ListItem >
                        <ListItemPrefix >
                            <DocumentCheckIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        {t('api-terms-of-use')}

                    </ListItem >
                </Link >

            </List >

            <hr className="my-2 border-blue-gray-50" />


            <List>
                {!isAuthenticated && (
                    <ListItem onClick={() => login()}>
                        <ListItemPrefix>
                            <ArrowRightEndOnRectangleIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        {t('signin')}
                    </ListItem>

                )}
                {isAuthenticated && (
                    <>
                        <ListItem>
                            <ListItemPrefix>
                                <UserCircleIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            {userEmail}
                        </ListItem>
                        <Link to="/osiris-api/keys" key="/osiris-api/keys">
                            <ListItem href="/osiris-api/keys">
                                <ListItemPrefix >
                                    <KeyIcon className="h-5 w-5" />
                                </ListItemPrefix>
                                {t('api-keys')}
                            </ListItem>
                        </Link>
                        <ListItem onClick={() => logoutWithRedirect()}>
                            <ListItemPrefix>
                                <PowerIcon className="h-5 w-5" />
                            </ListItemPrefix>
                            {t('signout')}
                        </ListItem>

                    </>
                )}
            </List>
        </Card >
    );
}