import React from 'react';
import { Button, Card, CardBody} from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';
import CustomAvatar from './customAvatar';

const LinkButton = ({ item, index, currentLang }) => {


  const title = item.title[currentLang] || item.title['fr'];
  const link = item.link[currentLang] || item.link['fr'];


  return (
    <Button
      key={index}
      variant="outlined"
      size="sm"
      color="blue-gray"
      className="text-justify rounded text-sm md:text-base dark:text-white dark:hover:bg-blue-1000 dark:bg-blue-900"
      onClick={() => window.open(link, item.internal ? '_self' : '_blank')}
      rel={item.internal ? "" : "noopener noreferrer"}
    >
      {title}
    </Button>
  );
};

function MunicipalityCard({ municipality }) {


  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const label = municipality.label[currentLang] || municipality.label['en'];

  return (

    <Card className="dark:bg-blue-800">

      <CardBody >
        <div className="flex flex-row gap-8 items-center">
          <CustomAvatar src={municipality.img} alt={label} size="sm" />
          <div className="font-bold text-2xl text-blue-800 py-4 dark:!text-yellow-800">{label}</div>
        </div>
        <div className="my-3">
          <div className="font-bold dark:text-white py-6">
            {t('Procedures')} {/* Using t() here for translation */}
          </div>
        </div>
        <div className="flex flex-col gap-6">
          {municipality.procedures.map((proc, index) => (
            <LinkButton item={proc} key={index} currentLang={currentLang} />
          ))}
        </div>

        {/* {['Procedures', 'Forms'].map((sectionTitle) => (
          <section className="my-3" key={sectionTitle}>
            <div  className="font-medium dark:text-white py-6">
              {t(sectionTitle)}
            </div>
            <div className="flex flex-col gap-6">
              {(sectionTitle === 'Procedures' ? municipality.procedures : municipality.forms).map((item, index) => (
                <LinkButton item={item} key={index} currentLang={currentLang} />
              ))}
            </div>
          </section>
        ))} */}
      </CardBody>
    </Card>
  );
}

export default MunicipalityCard;
