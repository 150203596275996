// CustomAddressInput.js
import React, { useState, useEffect } from 'react';
import { Input, List, ListItem, Alert } from "@material-tailwind/react";
import { MapPinIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import {addressService} from '../../services/addressService';
import { useTranslation } from 'react-i18next';

export function CustomAddressInput({ label, onChange, setValidity }) {

  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionSelected, setSuggestionSelected] = useState(false);
  const [selectionHasHouseNumber, setSelectionHasHouseNumber] = useState(false);
  const { t } = useTranslation();

  const handleAddressInputChange = async (event) => {
    const newAddress = event.target.value;
    setAddress(newAddress);
    setSuggestionSelected(false);

    if (newAddress.length > 2) { // Assuming we want at least 3 characters to search
      const newSuggestions = await addressService.fetchAddressSuggestions(newAddress);
      setSuggestions(newSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setSuggestionSelected(true);
    setAddress(suggestion.address.string);
    onChange({
      address: suggestion.address.string,
      latitude: suggestion.address.latitude,
      longitude: suggestion.address.longitude
    });
    setSelectionHasHouseNumber(!!suggestion.address.houseNumber);
    setSuggestions([]);
  };

  useEffect(() => {
    const valid = suggestionSelected && selectionHasHouseNumber;
    setValidity('address', valid);
  }, [suggestionSelected, selectionHasHouseNumber]);


  return (
    <div>
      {(suggestionSelected && !selectionHasHouseNumber) &&
        <Alert variant="outlined" color="red" className="mb-4 bg-white dark:bg-blue-900" icon={<ExclamationCircleIcon className=" h-6 w-6" />}>
          {t('cf-specifyHouseNumber')}
        </Alert>
      }

      <Input
        color={'blue-gray'}
        name="address"
        autoComplete="street-address"
        type="text"
        label={label}
        value={address}
        className="dark:text-white bg-white dark:bg-blue-900"
        onChange={handleAddressInputChange}
        error={suggestionSelected && !selectionHasHouseNumber}
        success={suggestionSelected && selectionHasHouseNumber}
      />
      {suggestions.length > 0 && (
        <List className="dark:text-white bg-white dark:bg-blue-900">
          {suggestions.map((suggestion, index) => (
            <ListItem key={index} onClick={() => handleSuggestionSelect(suggestion)}>
              <MapPinIcon className="h-4 w-4" />
              {suggestion.address.string}
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}
