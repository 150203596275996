import {
  FaceSmileIcon,
  ChartBarSquareIcon,
  CursorArrowRaysIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/solid";

import benefitOneImg from "../img/home/digital_admin.jpg";
import benefitTwoImg from "../img/home/colab.jpg";

export const getBenefits = (t) => [
  {
                  title: t('osi_advantages_citizen_title'), 
                  desc: t(''),
                  image: benefitOneImg,
                  imgPos: "left",
                  bullets: [
                    {
                      title: t('osi_advantages_1_title'),
                      desc: t('osi_advantages_1_desc'),
                      icon: <FaceSmileIcon />,
                    },
                    {
                      title: t('osi_advantages_2_title'),
                      desc: t('osi_advantages_2_desc'),
                      icon: <ChartBarSquareIcon />,
                    },
                  ]
    },

  {
        title: t('osi_advantages_admin_title'),
        desc: t(''),
        image: benefitTwoImg,
        imgPos: "right",
        bullets: [
          {
            title: t('osi_advantages_3_title'),
            desc: t('osi_advantages_3_desc'),
            icon: <AdjustmentsHorizontalIcon />,
          },
          {
            title: t('osi_advantages_4_title'),
            desc: t('osi_advantages_4_desc'),
            icon: <CursorArrowRaysIcon />,
          }
          ]
    },
];
