

export const pdfManuals = [
  {
    name: {
      en: "User manual",
      fr: "Manuel d'utilisateur",
      nl: "Gebruikershandleiding"
    },
    url: {
      en: "/pdf/MANUAL_FR/0.Manuel utilisateur.pdf",
      fr: "/pdf/MANUAL_FR/0.Manuel utilisateur.pdf",
      nl: "/pdf/MANUAL_FR/0.Manuel utilisateur.pdf"
    }
  },
  {
    name: {
      en: "Occupation of the roadway exempt from authorization and specific execution conditions",
      fr: "Occupation de la voirie dispensée d'autorisation et de conditions particulières d'éxecution",
      nl: "Weggebruik vrijgesteld van vergunning en specifieke uitvoeringsvoorwaarden"
    },
    url: {
      en: "/pdf/MANUAL_FR/1.Occupation de type D.pdf",
      fr: "/pdf/MANUAL_FR/1.Occupation de type D.pdf",
      nl: "/pdf/MANUAL_FR/1.Occupation de type D.pdf"
    }
  },
  {
    name: {
      en: "Road occupancy exempt from authorization",
      fr: "Occupation de la voirie dispensée d'autorisation",
      nl: "Weggebruik vrijgesteld van vergunning"
    },
    url: {
      en: "/pdf/MANUAL_FR/2.Occupation de type E.pdf",
      fr: "/pdf/MANUAL_FR/2.Occupation de type E.pdf",
      nl: "/pdf/MANUAL_FR/2.Occupation de type E.pdf"
    }
  },
  {
    name: {
      en: "Road occupancy that requires an authorization",
      fr: "Occupation de la voirie nécessitant une autorisation",
      nl: "Weggebruik die een vergunning vereist"
    },
    url: {
      en: "/pdf/MANUAL_FR/3.Occupation de type A.pdf",
      fr: "/pdf/MANUAL_FR/3.Occupation de type A.pdf",
      nl: "/pdf/MANUAL_FR/3.Occupation de type A.pdf"
    }
  },
  {
    name: {
      en: "Move and delivery",
      fr: "Déménagement et livraison",
      nl: "Verhuis en levering"
    },
    url: {
      en: "/pdf/MANUAL_FR/4.Déménagement-livraison.pdf",
      fr: "/pdf/MANUAL_FR/4.Déménagement-livraison.pdf",
      nl: "/pdf/MANUAL_FR/4.Déménagement-livraison.pdf"
    }
  },
  {
    name: {
      en: "Occupation of the public road for less than 24 hours",
      fr: "Occupation de la voirie publique de moins de 24 heures",
      nl: "Een inname van de openbare weg van minder dan 24 uur"
    },
    url: {
      en: "/pdf/MANUAL_FR/5.Moins de 24h.pdf",
      fr: "/pdf/MANUAL_FR/5.Moins de 24h.pdf",
      nl: "/pdf/MANUAL_FR/5.Moins de 24h.pdf"
    }
  },
  {
    name: {
      en: "Reserve a parking spot",
      fr: "Réserver une place de stationnement",
      nl: "Een parkeerplaats reserveren"
    },
    url: {
      en: "/pdf/MANUAL_FR/6.Zone de stationnement.pdf",
      fr: "/pdf/MANUAL_FR/6.Zone de stationnement.pdf",
      nl: "/pdf/MANUAL_FR/6.Zone de stationnement.pdf"
    }
  },
  {
    name: {
      en: "Place a scaffolding",
      fr: "Placer un échaffaudage",
      nl: "Een steiger plaatsen"
    },
    url: {
      en: "/pdf/MANUAL_FR/7.Echaffaudage.pdf",
      fr: "/pdf/MANUAL_FR/7.Echaffaudage.pdf",
      nl: "/pdf/MANUAL_FR/7.Echaffaudage.pdf"
    }
  } 
];
