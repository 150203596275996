import React from "react";
import {
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";

import Container from "../components/container";
import { useTranslation } from 'react-i18next';
import PDFImg from "../img/icons/pdfIcon.svg"


function Legal() {

  const legal_link1_map = {
    'en': "http://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=fr&amp;la=F&amp;cn=2018050308&amp;table_name=loi",
    'fr': "http://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=fr&amp;la=F&amp;cn=2018050308&amp;table_name=loi",
    'nl': "http://www.ejustice.just.fgov.be/cgi_loi/article.pl?language=nl&lg_txt=n&type=&sort=&numac_search=&cn_search=2018050308&caller=SUM&&view_numac=2018050308n",
  };
  const legal_link2_map = {
    'en': "http://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=fr&amp;la=F&amp;table_name=loi&amp;cn=2019040434",
    'fr': "http://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=fr&amp;la=F&amp;table_name=loi&amp;cn=2019040434",
    'nl': "http://www.ejustice.just.fgov.be/cgi_loi/article.pl?language=nl&lg_txt=n&type=&sort=&numac_search=&cn_search=2019040434&caller=SUM&&view_numac=2019040434n",
  };
   const legal_link3_map = {
    'en': "https://mobilite-mobiliteit.brussels/sites/default/files/2023-06/AGRBC%20du%204%20avril%202019%20ex%C3%A9cutant%20l%27ordonnance%20du%203%20mai%202018%20relative%20aux%20chantiers%20en%20voirie%20publique-avec%20annexe.pdf",
    'fr': "https://mobilite-mobiliteit.brussels/sites/default/files/2023-06/AGRBC%20du%204%20avril%202019%20ex%C3%A9cutant%20l%27ordonnance%20du%203%20mai%202018%20relative%20aux%20chantiers%20en%20voirie%20publique-avec%20annexe.pdf",
    'nl': "https://mobilite-mobiliteit.brussels/sites/default/files/2023-06/Besluit%20van%20de%20Brusselse%20Hoofdstedelijke%20Regering%20van%204%20april%202019%20tot%20uitvoering%20van%20de%20ordonnantie%20van%203%20mei%202018%20betreffende%20de%20bouwplaatsen%20op%20de%20openbare%20weg.pdf",
  };
   const legal_link4_map = {
    'en': "http://www.ejustice.just.fgov.be/eli/ordonnance/2008/07/03/2008031362/justel", 
    'fr': "http://www.ejustice.just.fgov.be/eli/ordonnance/2008/07/03/2008031362/justel",
    'nl': "http://www.ejustice.just.fgov.be/eli/ordonnantie/2008/07/03/2008031362/justel",
  };
   const legal_link5_map = {
    'en': "https://mobilite-mobiliteit.brussels/sites/default/files/aps-chantiers.pdf",
    'fr': "https://mobilite-mobiliteit.brussels/sites/default/files/aps-chantiers.pdf",
    'nl': "https://mobilite-mobiliteit.brussels/sites/default/files/aps-chantiers.pdf",
  };
    const legal_link6_map = {
    'en': "http://www.ejustice.just.fgov.be/eli/arrete/2020/12/17/2020016482/justel",
    'fr': "http://www.ejustice.just.fgov.be/eli/arrete/2020/12/17/2020016482/justel",
    'nl': "http://www.ejustice.just.fgov.be/eli/besluit/2020/12/17/2020016482/justel",
  };
    const legal_link7_map = {
    'en': "http://www.ejustice.just.fgov.be/eli/ordonnance/2011/07/20/2011031386/moniteur",
    'fr': "http://www.ejustice.just.fgov.be/eli/ordonnance/2011/07/20/2011031386/moniteur",
    'nl': "http://www.ejustice.just.fgov.be/eli/ordonnantie/2011/07/20/2011031386/moniteur",
  };
   const legal_link8_map = {
    'en': "http://www.ejustice.just.fgov.be/eli/arrete/2013/07/11/2013031625/moniteur",
    'fr': "http://www.ejustice.just.fgov.be/eli/arrete/2013/07/11/2013031625/moniteur",
    'nl': "http://www.ejustice.just.fgov.be/eli/besluit/2013/07/11/2013031625/moniteur",
  };

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const annex4_page = "/bypass-lane";

  const current_legal_link1 = legal_link1_map[currentLang];
  const current_legal_link2 = legal_link2_map[currentLang];
  const current_legal_link3 = legal_link3_map[currentLang];
  const current_legal_link4 = legal_link4_map[currentLang];
  const current_legal_link5 = legal_link5_map[currentLang];
  const current_legal_link6 = legal_link6_map[currentLang];
  const current_legal_link7 = legal_link7_map[currentLang];
  const current_legal_link8 = legal_link8_map[currentLang];

  return (
    <Container className="flex flex-col gap-6 ">

  <div  className="text-gray-700 dark:text-white">
      {t('legal-rules-title')}
  </div>
   <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800"> 
      {t('legal-rules-active')}
    </div>
      <List>
        <ListItem className="dark:hover:bg-blue-1000"> 
          <a  target="_blank" rel="noreferrer" href={current_legal_link1 } className="text-blue-500 dark:text-white">
            <div  className="text-xl font-bold text-blue-700 dark:text-white">
             {t('legal-active-title1')}
            </div>
            <div  className="text-gray-700 dark:text-white">
              {t('legal-active-sub1')}
            </div>              
          </a>
        </ListItem>
        <ListItem className="dark:hover:bg-blue-1000"> 
          <a  target="_blank" rel="noreferrer" href={current_legal_link2}>
            <div className="text-xl font-bold text-blue-700 dark:text-white">
                {t('legal-active-title2')}
            </div>
            <div className="font-boldtext-gray-700 dark:text-white">
              {t('legal-active-sub2')} 
            </div>              
          </a>
        </ListItem>
        <ListItem className="dark:hover:bg-blue-1000"> 
          <ListItemPrefix>
                <a target="_blank" rel="noreferrer" href={current_legal_link3} className=" text-blue-700 dark:text-white"> <img src={PDFImg} className="w-6 h-6" alt="pdf download" /> </a>         
          </ListItemPrefix>
          <a target="_blank" rel="noreferrer" href={current_legal_link3}>
            <div className="font-bold text-blue-700 dark:text-white">
                {t('legal-active-title3')}
            </div>           
          </a>
        </ListItem>
        <ListItem className="dark:hover:bg-blue-1000"> 
          <a href={annex4_page}>
            <div className="text-xl font-bold text-blue-700 dark:text-white">
                {t('annexes-title')}
            </div>
            <div className="font-boldtext-gray-700 dark:text-white">
              {t('annexes-desc')} 
            </div>              
          </a>
        </ListItem>
        
      </List>

  <div className="flex flex-row gap-2">
  <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
      {t('legal-rules-previous')}
  </div>
  <div className="text-blue-800 text-small dark:text-white">
      {t('legal-rules-previous_small')}
  </div>
 </div>

  <List>
        <ListItem className="dark:hover:bg-blue-1000"> 
          <a  target="_blank" rel="noreferrer" href={current_legal_link4} >
            <div className="text-xl font-bold text-blue-700 dark:text-white">
               {t('legal-previous-title1')}
            </div>
            <div  className="font-normal text-small text-gray-700 dark:text-white">
               {t('legal-previous-sub1')} 
            </div>              
          </a>
        </ListItem>
        <ListItem className="dark:hover:bg-blue-1000"> 
          <ListItemPrefix>
                <a target="_blank" rel="noreferrer" href={current_legal_link5} className=" text-blue-700 dark:text-white"> <img src={PDFImg} className="w-6 h-6" alt="pdf download"  /> </a>         
          </ListItemPrefix>
          <a  target="_blank" rel="noreferrer" href={current_legal_link5}>
            <div className="font-bold text-blue-700 dark:text-white">
               {t('legal-previous-title2')}
            </div>
            
            <div  className="font-normal text-small text-gray-700 dark:text-white">
              {t('legal-previous-sub2')}
            </div>              
          </a>
        </ListItem>
        <ListItem className="dark:hover:bg-blue-1000"> 
          <a  target="_blank" rel="noreferrer" href={current_legal_link6}>
            <div className="text-xl font-bold text-blue-700 dark:text-white">
               {t('legal-previous-title3')}
            </div>
            <div  className="font-normal text-small text-gray-700 dark:text-white">
              {t('legal-previous-sub3')}
            </div>              
          </a>
        </ListItem>
      </List>

  <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
      {t('legal-rules-modified')}
  </div>
   <List>
        <ListItem className="dark:hover:bg-blue-1000"> 
          <a  target="_blank" rel="noreferrer" href={current_legal_link7} >
            <div className="text-xl font-bold text-blue-700 dark:text-white">
               {t('legal-modified-title1')} 
            </div>
            <div  className="font-normal text-small text-gray-700 dark:text-white">
              {t('legal-modified-sub1')} 
            </div>              
          </a>
        </ListItem>
        <ListItem className="dark:hover:bg-blue-1000"> 
          <a  target="_blank" rel="noreferrer" href={current_legal_link8}>
            <div className="text-xl font-bold text-blue-700 dark:text-white">
                 {t('legal-modified-title2')} 
            </div>
            <div  className="font-normal text-small text-gray-700 dark:text-white">
             {t('legal-modified-sub2')} 
            </div>              
          </a>
        </ListItem>
      </List>
    </Container>
  );
}

export default Legal;


