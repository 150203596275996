import React, { useState } from 'react';
import { Button, Input } from '@material-tailwind/react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

const CustomMultiEmailInput = ({ onEmailChange, label, title }) => {
    // State to manage the list of emails
    const [emailList, setEmailList] = useState(['']);
    // State to track the validity of each email in the list
    const [isValidList, setIsValidList] = useState([false]);
    // State to track whether each email input has been touched
    const [touchedList, setTouchedList] = useState([false]);

    // Regular expression to validate email format
    const validateEmail = (email) =>
        /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/.test(email);

    // Function to handle changes in the email input
    const handleEmailChange = (index, value) => {
        // Validate the entered email
        const valid = validateEmail(value);

        // Update the validity state for the current email
        setIsValidList((prev) => {
            const newList = [...prev];
            newList[index] = valid;
            return newList;
        });

        // Update the email list with the new value
        const newList = [...emailList];
        newList[index] = value;
        setEmailList(newList);
    };

    // Function to handle blur events on the email input
    const handleBlur = (index) => {
        // Set the touched state to true for the current email
        setTouchedList((prev) => {
            const newList = [...prev];
            newList[index] = true;
            return newList;
        });
    };

    // Function to add a new email to the list when the plus button is clicked
    const handleAddEmail = () => {
        // Get the last email in the list
        const lastEmail = emailList[emailList.length - 1];

        // Check if the last email is non-empty before adding a new empty email
        if (lastEmail.trim() !== '') {
            const newEmail = ''; // Initialize a new empty email
            setEmailList([...emailList, newEmail]);
            setIsValidList([...isValidList, false]);
            setTouchedList([...touchedList, false]); // Set touched to false for the newly added email
            onEmailChange([...emailList, newEmail]);
        }
    };

    // Function to remove an email from the list when the minus button is clicked
    const handleRemoveEmail = (index) => {
        // Create new lists without the removed email
        const newList = [...emailList];
        newList.splice(index, 1);

        const newValidList = [...isValidList];
        newValidList.splice(index, 1);

        // Update the email list and validity state
        setEmailList(newList);
        setIsValidList(newValidList);
        // Call the external handler with the updated email list
        onEmailChange(newList);
    };

    // JSX structure of the component
    return (
        <div className="flex flex-col gap-2 dark:text-white">
            {/* Display the title */}
            {title}
            {/* Map through the email list and render input fields for each email */}
            {emailList.map((email, index) => (
                <div key={index} className="flex items-center gap-2">
                    {/* Input field for the email */}
                    <Input
                        color="blue-gray"
                        name={`email-${index}`}
                        type="email"
                        label={label}
                        value={email}
                        autoComplete="email"
                        // Event handlers for input changes
                        onChange={(e) => handleEmailChange(index, e.target.value)}
                        onBlur={() => handleBlur(index)}
                        // Apply styles based on validity and touch status
                        className={`dark:text-white ${!isValidList[index] && touchedList[index] ? '' : 'bg-gray-200 dark:bg-blue-800'}`}
                        // Display error and success states
                        error={!isValidList[index] && touchedList[index] && email.trim() !== ''}
                        success={isValidList[index] && touchedList[index]}
                        // Disable input for the last email (to allow adding a new one)
                        disabled={index !== emailList.length - 1}
                    />

                    {/* Display a plus button for the last email in the list */}
                    {index === emailList.length - 1 && (
                        <Button
                            className="rounded-full"
                            color="green"
                            variant="text"
                            size="sm"
                            onClick={handleAddEmail}
                            disabled={!email.trim() || !isValidList[index]}
                        >
                            <PlusCircleIcon className="h-5 w-5 text-green-600" />
                        </Button>
                    )}
                    {index !== emailList.length - 1 && (
                        <Button
                            className="rounded-full"
                            color="red"
                            variant="text"
                            size="sm"
                            onClick={() => handleRemoveEmail(index)}
                            disabled={!email.trim() || !isValidList[index]}
                        >
                            <MinusCircleIcon className="h-5 w-5 text-red-600" />
                        </Button>
                    )}
                </div>
            ))}
        </div>
    );
};

export default CustomMultiEmailInput;
