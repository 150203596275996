// CustomTextInput.js

import React, { useState } from 'react';
import { Textarea } from '@material-tailwind/react';


// A simple validation function for text input
const validateInput = (value) => {
  return value.trim().length > 0; // Basic validation: non-empty strings
};


const CustomTextarea = ({ label, name, value, onChange, setValidity }) => {
  const [inputedText, setInputedText] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [touched, setTouched] = useState(false);


  // Mark the input as touched when it loses focus
  const handleBlur = () => {
    setTouched(true);
    setIsValid(validateInput(inputedText)); // Validate on blur
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputedText(newValue);
    onChange(newValue);
    if (touched) { // Only validate if the field has been touched
      setIsValid(validateInput(newValue));
    }
    // Report validity back to the parent component
    setValidity(name, validateInput(newValue));
  };


  return (
    <Textarea
      color={'blue-gray'}
      name={name}
      label={label}
      value={inputedText}
      onChange={handleChange}
      className="dark:text-white flex-grow"
      onBlur={handleBlur}
      error={!isValid && touched}
      success={isValid && touched}

    />
  );
};

export default CustomTextarea;
