import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../customDatePicker'; // Adjust the path as needed

const CustomDateRangePicker = ({ name, initialStartDate, initialEndDate, onStartDateChange, onEndDateChange }) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [isStartPopoverOpen, setIsStartPopoverOpen] = useState(false);
  const [isEndPopoverOpen, setIsEndPopoverOpen] = useState(false);

    // Toggle function for the start date popover
  const toggleStartPopover = (isOpen) => {
    setIsStartPopoverOpen(isOpen);
    if (isOpen) setIsEndPopoverOpen(false); // Close end date popover when opening start date popover
  };

  // Toggle function for the end date popover
  const toggleEndPopover = (isOpen) => {
    setIsEndPopoverOpen(isOpen);
    if (isOpen) setIsStartPopoverOpen(false); // Close start date popover when opening end date popover
  };

  const { t } = useTranslation();

  const formatDateToDDMMYYYY = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Convert month to 2 digits
    const day = date.getDate().toString().padStart(2, '0'); // Convert day to 2 digits
    return `${day}-${month}-${year}`;
  };


  useEffect(() => {

    const newStartDate = startDate;
    const newStartDateFormated = formatDateToDDMMYYYY(newStartDate);
    const newEndDate = endDate;
    const newEndDateFormated = formatDateToDDMMYYYY(newEndDate);

    onStartDateChange(newStartDateFormated);
    onEndDateChange(newEndDateFormated);

    if (newStartDate > newEndDate) {
      setEndDate(newStartDate);
      onEndDateChange(newStartDateFormated);
    }
  }, [startDate, endDate]);

  return (
    <div className="my-4 flex flex-wrap sm:flex-nowrap items-center gap-4">
      <CustomDatePicker
        onDateChange={setStartDate}
        label={t('cf-startDate')}
        setDate={setStartDate}
        initialDate={startDate}
        isPopoverOpen={isStartPopoverOpen}
        togglePopover={toggleStartPopover}
      />
      <CustomDatePicker
        onDateChange={setEndDate}
        label={t('cf-endDate')}
        setDate={setEndDate}
        initialDate={endDate}
        disabledDays={{ before: startDate }} // Disable days before start date
        isPopoverOpen={isEndPopoverOpen}
        togglePopover={toggleEndPopover}
      />
    </div>
  );
};

export default CustomDateRangePicker;
