const fetchWSData = async (username, password, limit, item, filter) => {
  let data;

  try {
    // Construct the URL for the backend endpoint
    const url = `/api/getWorksiteData?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&limit=${encodeURIComponent(limit)}&item=${encodeURIComponent(item)}&filter=${encodeURIComponent(filter)}`;

    // Make the fetch request
    const response = await fetch(url);

    // Check if the response status is 401 (Unauthorized)
    if (response.status === 401) {
      throw new Error('Unauthorized'); // or handle 401 error as needed
    }

    // Check if the response is not OK (status code not in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Check if the response is not empty
    const text = await response.text();
    if (text) {
      data = JSON.parse(text);
    } else {
      console.error("Empty response");
    }

  } catch (error) {
    // Handle errors
    console.error("Error fetching objects:", error);
    throw error; // rethrow the error to be handled by the caller
  }

  return data;
};

// Assign the exported functions to a variable
const osirisService = {
  fetchWSData,
};

// Export the variable as the default export
export default osirisService;
