import { useState, useEffect} from 'react';
import { IconButton, Card } from "@material-tailwind/react";
import { MinusCircleIcon, ArrowsUpDownIcon} from '@heroicons/react/24/outline';

const StreetElementCard = ({element, elements, setElements, handleIconChange, handleIsHovered, handleIsNotHoveredAnymore, removeElement, predefinedElements }) => {

  const [newLabel, setNewLabel] = useState(element.label);
  const [newWidth, setNewWidth] = useState(element.width);

  const handleLabelChange = (e) => {
    setNewLabel(e.target.value);
  };

  const handleWidthChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
    setNewWidth(value);
  };

  useEffect(() => {
    setNewLabel(element.label);
    setNewWidth(element.width);
  }, [element, elements]);
  
  const handleStreetElementChange = (e, id) => {
    if (!e) return;
    const value = e.target.value;
    const updatedElements = elements.map(element =>
      element.id === id ? {
        ...element,
        streetElement: value,
        label: predefinedElements.find(el => el.streetElement === value)?.label || '',
        minWidth: predefinedElements.find(el => el.streetElement === value)?.minWidth || '',
        width: predefinedElements.find(el => el.streetElement === value)?.width || '',
        icons: predefinedElements.find(el => el.streetElement === value)?.icons || '',
        iconIndex: 0, // Reset to display front icon when street element is changed
        isHovered: false,
      } : element
    );
    setElements(updatedElements);
    setNewLabel(predefinedElements.find(el => el.streetElement === value)?.label);
  };

  const handleLabelBlurOrMouseLeave = () => {
    const trimmedLabel = newLabel.trim();
    if (trimmedLabel !== "") {
      const updatedElements = elements.map(el =>
        el.id === element.id ? { ...el, label: trimmedLabel } : el
      );
      setElements(updatedElements);
    } else {
      setNewLabel(element.label);
    }
  };

  const handleWidthBlurOrMouseLeave = (e, id) => {
    if (!e) return; // Ensure e exists
    const newValue = parseFloat(newWidth);
    let updatedWidth;

    // Check if the entered value is not a number or less than the minimum width
    if (isNaN(newValue) || newValue < parseFloat(element.minWidth)) {
      updatedWidth = element.minWidth || 0; // Use minWidth if available, otherwise default to 0
    } else {
      updatedWidth = newValue;
    }

    const updatedElements = elements.map((element) =>
      element.id === id ? { ...element, width: updatedWidth } : element
    );

    setElements(updatedElements);
    setNewWidth(updatedWidth);
  };

  const decreaseElementWidth = (id) => {
    let decreasedWidth;
    const updatedElements = elements.map((el) => {
      if (el.id === id) {
        console.log(parseFloat(el.width));
        console.log(parseFloat(el.width));
        decreasedWidth = Math.round(Math.max(parseFloat(el.width) - 0.1, 0) * 10) / 10;
        const minElementWidth = parseFloat(el.minWidth);
        console.log(minElementWidth);
        return {
          ...el,
          width: Math.max(decreasedWidth, minElementWidth || 0).toFixed(1),
        };
      } else {
        return el;
      }
    });
    setElements(updatedElements);
    setNewWidth(decreasedWidth);
  };

  const increaseElementWidth = (id) => {
    let newWidth;
    const updatedElements = elements.map((el) => {
      if (el.id === id) {
        newWidth = Math.round(Math.max(parseFloat(el.width) + 0.1, 0) * 10) / 10;
        return {
          ...el,
          width: newWidth,
        };
      } else {
        return el;
      }
    });
    setElements(updatedElements);
    setNewWidth(newWidth);
  };

  return (
    <Card className={`flex flex-col justify-center items-center gap-4 p-4 z-20 box-border border-4 ${element.isHovered ? '  border-green-300 rounded-lg' : ''}`} onMouseEnter={() => handleIsHovered(element.id)} onMouseLeave={() => handleIsNotHoveredAnymore(element.id)} >
      <div className="flex flex-row w-full gap-4">
        <button className="absolute top-2 right-2 hover:bg-red-500 hover:text-white hover:font-bold rounded-full w-6 h-6 flex justify-center items-center" onClick={() => removeElement(element.id)}>
          <MinusCircleIcon className="w-4 h-4" />
        </button>
      </div>
      <div className="flex flex-row w-full gap-4">
        <input
          type="text"
          value={newLabel}
          onChange={handleLabelChange}
          onBlur={handleLabelBlurOrMouseLeave}
          onMouseLeave={handleLabelBlurOrMouseLeave}
          className="text-lg font-bold truncate"
        />
        <div>
          {/* Switch to toggle between front and back icons */}
          {element.icons.length >= 2 && (
            <div className="flex items-center">
              <IconButton
                size="sm"
                variant="text"
                className="rounded-full bg-gray-300 hover:bg-blue-500 hover:text-white hover:font-bold"
                onClick={() => handleIconChange(element.id)}
              >
                <ArrowsUpDownIcon className="w-4 h-4" />
              </IconButton>
            </div>
          )}
        </div>
      </div> {/* Display selected street element as title */}

      <div className="flex flex-row inline-flex gap-2 items-center">
        <button className="bg-gray-400 rounded w-6 h-6 flex justify-center items-center" onClick={() => decreaseElementWidth(element.id)}>
          -
        </button>

        <input
          type="text"
          value={newWidth + " m"}
          onBlur={(e) => handleWidthBlurOrMouseLeave(e, element.id)}
          onMouseLeave={(e) => handleWidthBlurOrMouseLeave(e, element.id)}
          onChange={handleWidthChange}
          className="inline-block py-2 px-3 mt-1 rounded-md border border-gray-300  text-base text-blue-gray-700 "
        />


        <button className="bg-gray-400 rounded w-6 h-6 flex justify-center items-center" onClick={() => increaseElementWidth(element.id)}>
          +
        </button>
      </div>
      <div className="relative inline-block">
        <select
          className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight"
          value={element.streetElement}
          onChange={(e) => handleStreetElementChange(e, element.id)}
        >
          {predefinedElements.map((el) => (
            <option key={el.id + el.streetElement} value={el.streetElement}>
              {el.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10.293 14.293a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L10 11.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4z" />
          </svg>
        </div>
      </div>
    </Card >
  );
};
export default StreetElementCard;
