// Categories data
export const getCategories = (t) => [
  { tag:"func", title: t('cookies-pol-func-title'), alwaysActive: true, description: t('cookies-func-desc'), 
            cookies:[{name:"user_lang",domain:"osiris.com",expiration:t('cookie_user_lang_valid'),description:t('cookie_user_lang_desc')},
                     {name:"user_theme",domain:"osiris.com",expiration:t('cookie_user_theme_valid'),description:t('cookie_user_theme_desc')},
                     {name:"user_cookie_choice_made",domain:"osiris.com",expiration:t('cookie_user_choice_valid'),description:t('cookie_user_choice_desc')},
                     {name:"user_cookies_accepted_categories",domain:"osiris.com",expiration:t('cookie_user_cat_accepted_valid'),description:t('cookie_user_cat_accepted_desc')}
                    ] },
  {tag:"ess", title: t('cookies-pol-ess-title'), alwaysActive: true, description: t('cookies-ess-desc') },
  {tag:"perf-anal", title: t('cookies-pol-perf-anal-title'), alwaysActive: false, description: t('cookies-perf-anal-desc') , 
           cookies:[{name:"_ga",domain:"google.com",expiration:t('cookie__ga_valid'),description:t('cookie__ga_desc')},
                    ] },
];

