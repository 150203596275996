import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import tutorialVideos from '../data/tutorialVideoData'; // Tutorial videos data
import trainingVideos from '../data/trainingVideoData'; // Training videos data

import {
  PlayCircleIcon
} from "@heroicons/react/24/solid";
import {
  Button,
} from "@material-tailwind/react";
import { useTheme } from '../DarkThemeContext';

function Videos({ isTrainingMode }) {
  const { i18n } = useTranslation();
  const { theme } = useTheme();
  const currentLang = i18n.language;

  const [currentVideo, setCurrentVideo] = useState(isTrainingMode ? trainingVideos[0] : tutorialVideos[0]);

  useEffect(() => {
    if (isTrainingMode) {
      setCurrentVideo(trainingVideos[0]);
    } else {
      setCurrentVideo(tutorialVideos[0]);
    }

    console.log(currentVideo.src[currentLang]);

  }, [isTrainingMode]);

  const handleVideoChange = (video) => {
    setCurrentVideo(video);
  };

  return (
   
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-3/4 p-4">
          <div className="relative" style={{ paddingTop: '50%' }}>
            <video key={currentVideo.id} className="absolute top-0 left-0 h-full w-full rounded-lg max-h-screen" controls>
              <source src={currentVideo.src[currentLang]} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="w-full md:w-1/4 p-4 overflow-y-auto">
          <div className="flex flex-col gap-6">
            <div className="text-xl font-bold text-blue-800 dark:text-white  text-left">{currentVideo.title[currentLang]}</div>
            <div>
              {(isTrainingMode ? trainingVideos : tutorialVideos).map(video => (
                <div
                  key={video.id}
                  className="flex items-center mb-4 cursor-pointer text-left"
                  onClick={() => handleVideoChange(video)}
                >
                  <Button fullWidth className="rounded flex flex-row gap-2 items-start justify-start" color={theme === "dark" ? "superdeepblue" : "deepblue"} size="sm">
                    <div> {/* the div is added to make sure the child doesn't inherit of the parent's behavior (which was making the icons not a fixed size) */}
                      <PlayCircleIcon className="w-6 h-6 dark:!text-yellow-800" strokeWidth={2} />
                    </div>
                    <div className="flex-grow text-left">
                      {video.title[currentLang]}
                    </div>
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
}

export default Videos;
