const tutorialVideos = [
  {
    id: "0",
    src:
    {
      en: "/vids/tutorials_fr/createAccount_fr.mp4",
      fr: "/vids/tutorials_fr/createAccount_fr.mp4",
      nl: "/vids/tutorials_nl/createAccount_nl.mp4"
    },
    title: {
      en: "Create an account",
      nl: "Account aanmaken",
      fr: "Créer un compte"
    }
  },
  {
    id: "1",
    src:
    {
      en: "/vids/tutorials_fr/forgotPass_fr.mp4",
      fr: "/vids/tutorials_fr/forgotPass_fr.mp4",
      nl: "/vids/tutorials_nl/forgotPass_nl.mp4"
    },
    title: {
      en: "Lost password",
      nl: "Wachtwoord vergeten",
      fr: "Mot de passe oublié"
    }
  },
  {
    id: "2",
    src:
    {
      en: "/vids/tutorials_fr/TypeD_fr.mp4",
      fr: "/vids/tutorials_fr/TypeD_fr.mp4",
      nl: "/vids/tutorials_nl/TypeD_nl.mp4"
    },
    title: {
      en: "Create a one day road occupancy",
      nl: "Een nieuwe openbare weg bezetting van max 1 dag aanmaken",
      fr: "Créer une occupation de la voirie d'une journée"
    }
  },
  {
    id: "3",
    src:
    {
      en: "/vids/tutorials_fr/TypeE_fr.mp4",
      fr: "/vids/tutorials_fr/TypeE_fr.mp4",
      nl: "/vids/tutorials_nl/TypeE_nl.mp4"
    },
    title: {
      en: "Create a road occupancy",
      nl: "Maak een nieuwe openbare weg bezetting aan",
      fr: "Création d'occupation de la voirie"
    }
  },
  {
    id: "4",
    src:
    {
      en: "/vids/tutorials_fr/DrawSite_fr.mp4",
      fr: "/vids/tutorials_fr/DrawSite_fr.mp4",
      nl: "/vids/tutorials_nl/DrawSite_nl.mp4"
    },
    title: {
      en: "How to draw a road occupancy footprint?",
      nl: "De omvang van een openbare weg bezetting tekenen",
      fr: "Comment dessiner l'emprise d'une occupation de voirie?"
    }
  },
  {
    id: "5",
    src:
    {
      en: "/vids/tutorials_fr/avisDem_fr.mp4",
      fr: "/vids/tutorials_fr/avisDem_fr.mp4",
      nl: "/vids/tutorials_nl/avisDem_nl.mp4"
    },
    title: {
      en: "Send a start of works notice",
      nl: "Bericht van opstarting doorsturen",
      fr: "Envoyer un avis de démarrage"
    }
  },
  {
    id: "6",
    src:
    {
      en: "/vids/tutorials_fr/FlashMsg_fr.mp4",
      fr: "/vids/tutorials_fr/FlashMsg_fr.mp4",
      nl: "/vids/tutorials_nl/FlashMsg_nl.mp4"
    },
    title: {
      en: "Send a flash notice",
      nl: "Een flash bericht doorsturen",
      fr: "Envoyer un avis flash"
    }
  },

];

export default tutorialVideos;