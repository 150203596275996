
import { Switch } from "@material-tailwind/react";

export function CustomSwitch({ checked, onChange, id }) {
  return (
    <Switch
      id={id}
      checked={checked}
      onChange={onChange}
      ripple={false}
      className="h-full w-full checked:bg-[#0284c7]"
      containerProps={{
        className: "w-9 h-5",
      }}
      circleProps={{
        className: "before:hidden left-0.5 border-none w-4 h-4",
      }}
    />
  );
}
