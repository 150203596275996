// CustomTextInput.js

import React, { useState, useEffect } from 'react';
import { Input } from "@material-tailwind/react";
import { useTheme } from '../../DarkThemeContext';  

// A simple validation function for text input
  const validateInput = (value) => {
    return value.trim().length > 0; // Basic validation: non-empty strings
  };


const CustomTextInput = ({ label, autocomplete, name, value, onChange, setValidity, isNumber }) => {
  const [inputedText, setInputedText] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [touched, setTouched] = useState(false);

  const { theme } = useTheme();


  // Mark the input as touched when it loses focus
  const handleBlur = () => {
    setTouched(true);
    setIsValid(validateInput(inputedText)); // Validate on blur
  };

   const handleChange = (e) => {
    const newValue = e.target.value;
    setInputedText(newValue);
    onChange(newValue);
    if (touched) { // Only validate if the field has been touched
      setIsValid(validateInput(newValue));
    }
    // Report validity back to the parent component
    setValidity(name, validateInput(newValue));
  };


  const handleKeyDown = (e) => {
    if (isNumber && (e.key < '0' || e.key > '9') && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Tab' && e.key !== 'Delete' && e.key !== 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Input
      color={'blue-gray'}
      name={name}
      autocomplete={autocomplete}
      type={'text'}
      label={label}
      value={inputedText}
      onChange={handleChange}
      onKeyDown={handleKeyDown} 
      className = "dark:text-white" 
      containerProps={{ className: "min-w-[72px]" }}
      onBlur={handleBlur}
      error={!isValid && touched}
      success={isValid && touched}
      // The pattern attribute is used for input validation on form submission
      pattern={isNumber ? "\\d*" : undefined}
    />
  );
};

export default CustomTextInput;
