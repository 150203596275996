import React from "react";
import { useTranslation } from 'react-i18next';

import ogcHome from "../../img/api/ogcHome.png";
import ogcSwagger from "../../img/api/ogcSwagger.png";
import ogcCollection from "../../img/api/ogcCollection.png";


function ApiTechnicalOGC() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-start gap-6">

      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('api-technical-ogc-title')}
      </div>

      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t('api-technical-ogc-subtitle-1')}
      </div>


      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-ogc-1')}
      </div>
      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-ogc-1-2')}
      </div>
      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-ogc-1-3')}
      </div>

      <li className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-ogc-1-4')}
      </li>
      <li className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-ogc-1-5')}
      </li>

      <li className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-ogc-1-6')}
      </li>


      <img
        className="max-w-[800px] object-cover object-center"
        src={ogcHome}
        loading="eager"
        placeholder="blur"
        alt="ogcHome"
      />

      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t('api-technical-ogc-subtitle-2')}
      </div>


      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-ogc-2')}
      </div>

      <img
        className="max-w-[800px] object-cover object-center"
        src={ogcSwagger}
        loading="eager"
        placeholder="blur"
        alt="ogcSwagger"
      />


      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t('api-technical-ogc-subtitle-3')}
      </div>

      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-technical-ogc-3')}
      </div>
      <img
        className="max-w-[800px] object-cover object-center"
        src={ogcCollection}
        loading="eager"
        placeholder="blur"
        alt="ogcCollection"
      />

    </div>


  );
}

export default ApiTechnicalOGC;

