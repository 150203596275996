import React, { useState } from "react";
import { Tooltip } from "@material-tailwind/react";
import { useCopyToClipboard } from "usehooks-ts";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';

export const CopyButton = ({ text }) => {
    const { t } = useTranslation();
    const [value, copy] = useCopyToClipboard('');
    const [copied, setCopied] = useState(false);

    return (
        <Tooltip content={copied ? t('copied') : t('copy')}>
            <div
                onMouseLeave={() => setCopied(false)}
                onClick={() => {
                    copy(text);
                    setCopied(true);
                }}
                className="flex items-center justify-between gap-x-3 px-4 py-2.5 lowercase bg-gray-100 rounded shadow"
            >
                <div
                    className="code-like-font"
                    variant="small"
                >
                    {text}
                </div>
                <div className=" border-l border-gray-400 pl-3 w-8 h-8 flex-shrink-0">
                {copied ? (
                    <CheckIcon className="w-full h-full" />
                ) : (
                    <DocumentDuplicateIcon className="w-full h-full" />
                )}
                 </div>
            </div>
        </Tooltip>
    );
}
