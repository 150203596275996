import React from "react";
import { useTranslation } from 'react-i18next';
import { Message } from "../message";
import apiLogo from "../../img/api/apiLogo.png";

function ApiGeneralPresentation() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-start gap-6">

      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('api-general-title')}
      </div>

      <Message type="warning" message={t('api-general-warrning')} />

      <img
        className="max-w-[300px] object-cover object-center"
        src={apiLogo}
        loading="eager"
        placeholder="blur"
        alt="Logo"
      />

      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-general-explanation')}
      </div>

      <div className="text-lg text-gray-800 dark:text-gray-200">
        {t('api-general-explanation-2')}
      </div>

      <p className="text-lg  text-gray-800 dark:text-gray-200">
        {t('api-general-data')}
      </p>

      <p className="text-lg  text-gray-800 dark:text-gray-200">
        {t('api-general-data2')}
      </p>


    </div>
  );
}

export default ApiGeneralPresentation;
