import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Input,
  Button,
} from "@material-tailwind/react";
import faqData from "../data/faqData";
import { useTranslation } from 'react-i18next';
import { useTheme } from '../DarkThemeContext';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { PageViewTracker } from "../handlers/GoogleAnalyticsHandler";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';


function Faq() {
  // Track page view for Google Analytics
  PageViewTracker({ path: "/faq", title: "FAQ" });

  // Get translation and theme information
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { theme } = useTheme();

  // State variables
  const [openTab, setOpenTab] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [faqIndex, setFaqIndex] = useState(null);


  // Get parameters from the URL using React Router's useParams
  const { sectionValue, faqValue } = useParams();


  // Helper function to find the topic based on a value
  const findTopicByValue = (value) => {
    for (const topicData of faqData) {
      for (const faq of topicData.faqs) {
        if (faq.value === value) {
          return topicData.topic;
        }
      }
    }
    return null; // Return null if the value is not found
  }

  // Handler for opening/closing accordion items
  const handleAccordionOpen = (index, faq) => {
    // Determine whether the tab is open or closed based on the index
    const isTabClosed = openTab === index;

    // Toggle the state of the tab
    setOpenTab(isTabClosed ? null : index);

    // Construct the URL based on the open accordion question and the selected topic
    let url = '/faq';
    if (selectedTopic) {
      url += `/${selectedTopic}`;
      if (faq && faq.value && !isTabClosed) {
        url += `/${faq.value}`;
      }
    } else {
      url += `/${findTopicByValue(faq.value)}`;
      url += `/${faq.value}`;
    }

    // Update the browser URL without triggering a page reload
    window.history.replaceState(null, "", url);
  };

  // Handler for updating the search term
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handler for selecting a topic
  const handleTopicSelect = (value) => {
    setSelectedTopic(value);
    setOpenTab(null); // Reset openTab when selecting a topic

    let url = '/faq';
    url += `/${value}`;

    // Update the browser URL without triggering a page reload
    window.history.replaceState(null, "", url);
  };

  // Handler for going back to topics
  const handleBackToTopics = () => {
    setSelectedTopic(null);
    setOpenTab(null); // Reset openTab when going back to topics

    // Update the browser URL without triggering a page reload
    window.history.replaceState(null, "", '/faq');
  };

  // Filter FAQs based on the search term and current language
  const filteredFaqs = (faqs, currentLang) => {
    return faqs.filter(faq =>
      currentLang
      && faq.question[currentLang]
      && faq.question[currentLang].toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Get recommended topics based on the search term and current language
  const recommendedTopics = faqData.flatMap(({ faqs }) => filteredFaqs(faqs, currentLang));


  // Check if the URL contains either the topic or the topic + question value
  const onLoad = () => {
    if (sectionValue) {
      // If sectionValue is present in the URL, select the corresponding topic
      setSelectedTopic(sectionValue);

      if (faqValue) {
        // If faqValue is present, find the index of the FAQ and open it
        const topicIndex = faqData.findIndex((topicData) => topicData.topic === sectionValue);
        const foundFaqIndex = faqData[topicIndex]?.faqs.findIndex((faq) => faq.value === faqValue);

        if (topicIndex !== -1 && faqIndex !== -1) {
          setFaqIndex(foundFaqIndex);
          setOpenTab(foundFaqIndex);
        }
      }
    }
  }


  useEffect(() => {
    const element = document.getElementById(`faq${faqIndex}`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [faqIndex]);

  // useLayoutEffect for immediate execution with a delay
  useLayoutEffect(() => {
    const rafId = requestAnimationFrame(() => {
      onLoad();
    });

    return () => cancelAnimationFrame(rafId);
  }, [sectionValue, faqValue]);

  return (
    <div className="flex flex-col gap-6 m-2 sm:m-8">
      {/* First Row */}
      <div className="flex flex-col md:flex-row gap-6">
        {/* Search Input or Back button - full width on mobile, 1/3 width on larger screens */}
        <div className="w-full md:w-1/3">
          {selectedTopic ? (
            <Button className="w-full flex items-center gap-3" onClick={handleBackToTopics}>
              <ArrowUturnLeftIcon className="h-5 w-5" />
              {t('faq-back-all')}
            </Button>
          ) : (
            <div className="bg-gray-100 dark:bg-blue-900 rounded p-4">
              <div className="text-gray-800 mb-2 dark:text-white">{t('faq-search')}</div>
              <Input
                name="search"
                type="text"
                placeholder={t('faq-search-input')}
                color={theme === "dark" ? "white" : "gray"}
                onChange={handleSearch}
              />
            </div>
          )}
        </div>
        {/* Topic Buttons or FAQ Accordions - full width on mobile, 2/3 width on larger screens */}
        <div className="w-full md:w-2/3 flex flex-col gap-6 sm:p-4">
          {searchTerm || selectedTopic ? (
            <div className="flex flex-col gap-6 rounded">
              <div className="text-2xl font-bold text-blue-900 dark:!text-yellow-800 mb-4">{t('faq-recommended')}</div>
              {(searchTerm ? recommendedTopics : faqData.filter(({ topic }) => topic === selectedTopic)[0]?.faqs || []).map((faq, index) => (
                <div id={`faq${index}`} key={`faq${index}`}>
                  <Accordion key={index} open={openTab === index} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                    <AccordionHeader
                      onClick={() => handleAccordionOpen(index, faq)}
                      className={`border-b-0 transition-colors text-blue-900 hover:text-blue-700 ${openTab === index ? "text-blue-700 dark:text-white" : "dark:text-white"
                        }`}
                    >
                      <div className="flex flex-row gap-8">
                        <div>
                          {openTab === index ? <ChevronDownIcon className="h-6 w-6" /> : <ChevronRightIcon className="h-6 w-6" />}
                        </div>
                        <div>
                          {faq.question[currentLang]}
                        </div>
                      </div>
                    </AccordionHeader>
                    <AccordionBody className="pt-0 text-base font-normal dark:text-white">
                      <div dangerouslySetInnerHTML={{ __html: faq.answer[currentLang] }}></div>
                    </AccordionBody>
                  </Accordion>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <div className="text-blue-800 text-2xl font-bold mb-4 dark:!text-yellow-800">{t('faq-cat')}</div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {faqData.map(({ label, topic }) => (
                  <Button
                    variant="outlined"
                    color="blue-gray"
                    key={topic}
                    className="rounded text-sm md:text-base overflow-ellipsis overflow-hidden whitespace-nowrap dark:text-white dark:hover:bg-blue-1000 dark:bg-blue-900"
                    onClick={() => handleTopicSelect(topic)}
                  >
                    {label[currentLang]}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Faq;
