// ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { setCookie, getCookie } from './handlers/cookieHandler'; // Import your cookie handling methods

const ThemeContext = createContext();

const DarkThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const storedTheme = getCookie("user_theme");
    if (storedTheme) {
      setTheme(storedTheme);
      document.body.classList.toggle("dark", storedTheme === "dark");
    } else {
      setTheme(document.body.classList.contains("dark") ? "dark" : "light");
    }
  }, []);

  const toggleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
      document.body.classList.remove("dark");
      setCookie("user_theme", "light", 365);
    } else {
      setTheme("dark");
      document.body.classList.add("dark");
      setCookie("user_theme", "dark", 365);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export { DarkThemeProvider, useTheme };
