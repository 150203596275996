import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, WMSTileLayer, GeoJSON, useMap, ZoomControl, FeatureGroup, Circle} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { CustomAddressInput } from '../components/formInputs/CustomAddressInput';
import reg from '../data/reg.json';
import art33 from '../data/art33.json';


function SetViewOnClick({ coords, zoom }) {
    const map = useMap();
    map.setView(coords, zoom);
    return null;
}

function Legend() {
      const { t } = useTranslation();
    return (
        <div className="flex flex-row gap-2" style={{ position: "absolute", bottom: "10px", left: "10px", backgroundColor: "white", padding: "10px", borderRadius: "5px", zIndex: "1000" }}>
                <div className="legend-marker" style={{ backgroundColor: 'red', width: '10px', height: '10px', borderRadius: '50%', marginRight: '5px' }}></div>
                 <h4>{t('map-legend')}</h4>
        </div>
    );
}


const Map = () => {
    const { t } = useTranslation();
    const [mapCenter, setMapCenter] = useState([50.85, 4.35]);
    const defaultZoomLevel = 13;
    const zoomLevelAfterInput = 20;
    const [zoomLevel, setZoomLevel] = useState(defaultZoomLevel);
    //const [geoJsonData, setGeoJsonData] = useState(null);
    //const [geoJsonKey, setGeoJsonKey] = useState(1);


    const handleInputChange = (value) => {
        if (value.latitude && value.longitude) {
            setMapCenter([value.latitude, value.longitude]);
            setZoomLevel(zoomLevelAfterInput);
        }
    };

    const handleValidityChange = (fieldName, isValid) => {
        // Validity change logic
    };
    /* // those two use effects are used for fetching and rendering the WFS data
         useEffect(() => {
    
            //setGeoJsonData(geoJsonDataStatic);
    
            const fetchData = async () => {
                const data = await getWFSDataService.fetchWFSData();
                setGeoJsonData(data);
                setGeoJsonKey(geoJsonKey+1);
            };
    
            fetchData();
        }, []); // Dependency array is empty, so this runs once after the component mounts
    
        // Use another useEffect to run code after geoJsonData updates
        useEffect(() => {
            if (geoJsonData) {
                console.log("GeoJSON data updated:", geoJsonKey, geoJsonData);
                // Any other code that needs to run after geoJsonData updates
            }
        }, [geoJsonData]); // This useEffect will run whenever geoJsonData changes
    
        console.log("static file: ", geoJsonData);
    
    */


    return (
        <div className="relative w-full" style={{ height: '80vh' }}>
            <div className="absolute z-20 p-4 md:w-1/3 md:min-w-[250px]">
                <CustomAddressInput
                    label={t('cf-address')}
                    onChange={(value) => handleInputChange(value)}
                    setValidity={handleValidityChange}
                />
            </div>
            
            <MapContainer center={mapCenter} zoom={zoomLevel} className="z-10 h-full w-full" zoomControl={false}>



                <WMSTileLayer
                    url="https://geoservices-urbis.irisnet.be/geoserver/ows?"
                    layers="urbisFR"
                    format="image/png"
                    transparent={true}
                    version="1.0.0"
                    opacity={0.5}
                />
                 <Legend />

                {/*<GeoJSON  key='my-geojson'  data={geoJsonData} /> the key allows for re-render */}


                <FeatureGroup pathOptions={{ color: 'red', weight: 0, fillOpacity: 0.7 }}>

                    <GeoJSON data={art33} />
                    <GeoJSON data={reg} />
                </FeatureGroup>

                <Circle center={mapCenter} radius={0.5} fillColor="blue" />

                <ZoomControl position="topright" />
                <SetViewOnClick coords={mapCenter} zoom={zoomLevel} />
               
            </MapContainer>

        </div>
    );
}

export default Map;
