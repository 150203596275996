// CustomEmailInput.js
import React, { useState } from 'react';
import { Input } from "@material-tailwind/react";
import { useTheme } from '../../DarkThemeContext';  

const validateEmail = (email) => /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/.test(email);

const CustomEmailInput = ({name, value, label, onChange,  setValidity}) => {
  const [email, setEmail] = useState(value);
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const { theme } = useTheme();


    // Mark the input as touched when it loses focus
  const handleBlur = () => {
    setTouched(true);
  };

  const handleChange = (e) => {
    
    setEmail(e.target.value);
    onChange(e.target.value);
    const valid = validateEmail(e.target.value);
    setIsValid(valid);
    setValidity(name, valid);
  };

  return (
    <Input
      color={theme=== "dark" ? "blue-gray" : "blue-gray"}
      name={name}
      type="email"
      label={label}
      value={email}
      autoComplete="email" 
      onChange={handleChange}
      className = "dark:text-white" 
      onBlur={handleBlur}
      error={!isValid && touched}
      success={isValid && touched}
    />
  );
};

export default CustomEmailInput;
