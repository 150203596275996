
const TwoOptionsSwitch = ({ options, handleButtonClick, selectedOption}) => {

  return (
    <div className="bg-gray-100 p-2 rounded-full shadow-inner-lg inline-block">
    <div className="flex flex-row gap-4 justify-between">
      {options.map(({ label, value, icon: Icon }) => (
        <button
          key={value}
          className={`flex items-center justify-center px-4 py-1 rounded-full text-blue-900 ${value === selectedOption ? "bg-yellow-700 " : "bg-gray-100"
            }`}
          onClick={(event) => handleButtonClick(value,event)}
        >
          <Icon className="w-4 h-4 mr-2" />
          {label}
        </button>
      ))}
    </div>
  </div>
  );
};

export default TwoOptionsSwitch;