import React, { useEffect, useState } from 'react';
import { setCookie, getCookie } from '../handlers/cookieHandler'; // Import your cookie handler functions
import {
  Dialog,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react"; // Import Material Tailwind components
import {
  BuildingOfficeIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useUserType } from '../UserContext'; // Import the custom hook
import { useTranslation } from 'react-i18next';
import { Message } from './message';
import TwoOptionsSwitch from './twoOptionsSwitch';





const CustomUserTypeSwitch = () => {
  const { t, i18n } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false); // State to control the dialog visibility
  const [institutionalChoice, setInstitutionalChoice] = useState(null); // State to store institutional choice
  const [selectedOption, setSelectedOption] = useState("citizen"); // State to store the selected option
  const { userType, updateUserType } = useUserType();

  const pdfLinks =
  {
    en: "/pdf/ImpInst032023.pdf",
    fr: "/pdf/ImpInst032023.pdf",
    nl: "/pdf/InstVerzoekers032023.pdf"
  }
    ;

  const currentLang = i18n.language;

  useEffect(() => {
    // Retrieve the user type from the cookie when the component is mounted using your getCookie function
    const storedUserType = getCookie("user_type");
    if (storedUserType) {
      if (storedUserType !== "citizen") {
        updateUserType(storedUserType);
        setInstitutionalChoice(storedUserType);
        setSelectedOption("enterprise");
      }
    }
  }, []);


  const handleButtonClick = (value) => {
    if (value === "enterprise") {
      setSelectedOption("enterprise"); // Update the selected option
      setOpenDialog(true); // Open the Dialog when "Enterprise" is selected
    } else {
      // If the user reverts back to citizen, set the user_type cookie to "citizen"
      setCookie('user_type', "citizen", 365);
      updateUserType("citizen");
      setSelectedOption("citizen"); // Update the selected option
      setOpenDialog(false); // Close the Dialog
    }
  };

  const handleInstitutionalChoice = (choice) => {
    // Store the selected institutional choice in a cookie
    setCookie('user_type', choice, 365);
    setInstitutionalChoice(choice); // Set the institutionalChoice state
    updateUserType(choice);
    setOpenDialog(false); // Close the Dialog
  };

  const userTypes = [
    { label: t('citizen'), value: "citizen", icon: UserCircleIcon },
    { label: t('company'), value: "enterprise", icon: BuildingOfficeIcon },
  ];


  return (
    <>
      <TwoOptionsSwitch handleButtonClick={handleButtonClick} options={userTypes} selectedOption={selectedOption}/>
      <Dialog  open={openDialog} handler={setOpenDialog}>
        <DialogBody className="dialog-body">
          <div className="flex flex-wrap p-2 justify-between gap-4">
            <div className="text-blue-800">
              {t('instCompanyorNormal')}
            </div>
            <Message type={"info"} message={t('instCompExplanation')} />
            <a href={pdfLinks[currentLang]} download>
              <Button variant="outlined" size="sm" color="blue-gray" className="text-justify rounded text-sm normal-case md:text-base dark:text-white dark:hover:bg-blue-1000 dark:bg-blue-900">
                {t('instCompLink')}
              </Button>
            </a>
          </div>
        </DialogBody>
        <DialogFooter>
          <div className="flex gap-4">
            <Button
              variant="outlined"
              color="blue"
              size="sm"
              onClick={() => handleInstitutionalChoice("instEnterprise")}
            >
              {t('yesInst')}
            </Button>
            <Button
              variant="filled"
              color="blue"
              size="sm"
              onClick={() => handleInstitutionalChoice("non-instEnterprise")}
            >
              {t('noNonInst')}
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default CustomUserTypeSwitch;


