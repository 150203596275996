import {
  ChatBubbleOvalLeftIcon,
  FolderIcon,
  QuestionMarkCircleIcon,
  BugAntIcon,
  BookOpenIcon,
  AcademicCapIcon,
  MapIcon,
  PlayCircleIcon,
  BuildingLibraryIcon,
  ArrowUturnRightIcon,
  DocumentCheckIcon,
  BellIcon
} from "@heroicons/react/24/outline";

export const colors = {
  blue: "bg-blue-50 text-blue-500",
  orange: "bg-orange-50 text-orange-500",
  green: "bg-green-50 text-green-500",
  "blue-gray": "bg-blue-gray-50 text-blue-gray-500",
  purple: "bg-purple-50 text-purple-500",
  teal: "bg-teal-50 text-teal-500",
  cyan: "bg-cyan-50 text-cyan-500",
  pink: "bg-pink-50 text-pink-500",
  red: "bg-red-50 text-pink-500"
};

export function getNavListRessourceItems(t) {
  return [

    {
      color: "blue",
      icon: BookOpenIcon,
      title: t('manual-title'),
      description: t('manual-desc'),
      href: "/manual",
      internal: true,
    },
    {
      color: "orange",
      icon: PlayCircleIcon,
      title: t('tutorials-title'),
      description: t('tutorials-desc'),
      href: "/tutorials",
      internal: true,
    },
    {
      color: "green",
      icon: MapIcon,
      title: t('mobilitycut-title'),
      description: t('mobilitycut-desc'),
      href: "/mobility-cut-app",
      internal: true,
    },
    {
      color: "red",
      icon: BuildingLibraryIcon,
      title: t('cccsessions-title'),
      description: t('cccsessions-desc'),
      href: "https://www.osiris.brussels/Seances/Index.aspx",
      internal: false,
    },
    {
      color: "teal",
      icon: ArrowUturnRightIcon,
      title: t('cgp-title'),
      description: t('cgp-desc'),
      href: "https://via.osiris.brussels/#/",
      internal: false,
    },
    {
      color: "blue-gray",
      icon: BellIcon,
      title: (
        <div className="flex items-center gap-1">
          {t('releases-title')}{" "}
        </div>
      ),
      description: t('releases-desc'),
      href: "/releases",
      internal: true,
    },
  ];
}

export function getNavListSupportItems(t) {
  return [
    {
      color: "green",
      icon: QuestionMarkCircleIcon,
      title: t('faq-title'),
      description: t('faq-desc'),
      href: "/faq",
      internal: true,
    },
    {
      color: "orange",
      icon: ChatBubbleOvalLeftIcon,
      title: t('help-title'),
      description: t('help-desc'),
      href: "/helpdesk",
      internal: true,
    },
    {
      color: "blue",
      icon: AcademicCapIcon,
      title: (
        <div className="flex items-center gap-1">
          {t('training-title')}{" "}
        </div>
      ),
      description: t('training-desc'),
      href: "/training",
      internal: true,
    },
    {
      color: "blue-gray",
      icon: FolderIcon,
      title: t('legal-title'),
      description: t('legal-desc'),
      href: "/legal",
      internal: true,
    },
    {
      color: "red",
      icon: BugAntIcon,
      title: t('reportabug-title'),
      description: t('reportabug-desc'),
      href: "/bug-report",
      internal: true,
    },
    {
      color: "teal",
      icon: DocumentCheckIcon,
      title: t('procedures-title'),
      description: t('procedures-desc'),
      href: "/procedures",
      internal: true,
    },
    

  ];
}

export const login_url_v1 = "https://www.osiris.brussels/";
export const login_url_v2 = "https://apps.osiris.brussels/";
export const signup_url_v2 = "https://apps.osiris.brussels/Organization/#/imp/my_actor";
export const signup_url_v1 = "https://www.osiris.brussels/Modules/Management/Pages/NewOrganization/ChooseType.aspx";
export const osiris_at_a_glance_url ="/osiris-at-a-glance";

export const languages = [

  { label: "Français", code: "fr" },
  { label: "Nederlands", code: "nl" },
  /* { label: "English", code: "en" },*/
];
