import React, { useState, useEffect } from "react";
import { HiddenKey } from "../formInputs/CustomHiddenKey";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { Message } from "../message";
 
function ApiKeys() {
  const { t } = useTranslation();
  const { isAuthenticated, user } = useAuth0();
  const [userGeoserverUsername, setUserGeoserverUsername] = useState(null);
  const [userGeoserverPassword, setUserGeoserverPassword] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  // Check if user is authenticated and email is verified
  const isUserVerified = isAuthenticated && user && user.email_verified;
  const isAdminVetted =  userStatus === "granted";
 
  // useEffect to update avatar and email when the user changes
  useEffect(() => {
    if (user) {
      const user_status = user["user_status"];
      const geoserverUsername = user["geoserver_username"];
      const geoserverPassword = user["geoserver_password"];
 
      setUserGeoserverUsername(geoserverUsername || "");
      setUserGeoserverPassword(geoserverPassword || "");
      setUserStatus(user_status || "");
    }
 
  }, [user]);
 
  // Render API keys if user is authenticated and email is verified
  return (
    <div className="flex flex-col items-center gap-6">
      {isUserVerified ? (
        isAdminVetted ? (<>
          <h2>{t('api-keys')}</h2>
          <HiddenKey input={userGeoserverUsername} label="User name" />
          <HiddenKey input={userGeoserverPassword} label="Password" />
        </>) : (
           <Message type="info" message={t('api-key-admin-vet') + process.env.REACT_APP_MAIL_OSIRIS}/>
        )
       
      ) : (
        <Message type="info" message={t('api-key-please-auth')}  />
      )}
    </div>
  );
}
 
export default ApiKeys;