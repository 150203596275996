import React from 'react';
import irisImage from '../img/bm/iris.svg';
import { useTranslation } from 'react-i18next';

const TopSignature = () => {
const sprbStyles = {
    fontSize: '15px',
    fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif'
  };

  const {t} = useTranslation();
  const { i18n } = useTranslation();

  const linkMap = {
    'en': "https://servicepublic.brussels/",
    'fr': "https://servicepublic.brussels/",
    'nl': "https://overheidsdienst.brussels",
  };

  const currentLang = i18n.language;
  const currentLink= linkMap[currentLang] ||"https://servicepublic.brussels/";


  return (
    <div id="sprb-header" style={sprbStyles}>
      <div className="site-bar spacing container-fluid d-flex align-items-center">
        <a href={currentLink} target="_blank" rel="noreferrer" className="site-bar__link d-flex align-items-center">
          <img src={irisImage} alt="" className="site-bar__image" />
          <div className="site-bar__separator d-flex flex-wrap align-items-center truncate">
            <span className="site-bar__title truncate">{t('bm-header-title')}</span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default TopSignature;
