import React, { useState, useRef } from 'react';
import { Button, List, ListItem } from "@material-tailwind/react";
import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../DarkThemeContext';
import { DownloadIcon } from '../svgIcons';
import { Message } from '../message';
import { useTranslation } from 'react-i18next';

const CustomFileUpload = ({ files, text, onChange }) => {
    const { t } = useTranslation();
    const [fileData, setFileData] = useState(files);
    const [totalSize, setTotalSize] = useState(0);
    const [message, setMessage] = useState(""); // State for the message
    const [messageType, setMessageType] = useState(""); // State for the message type

    const { theme } = useTheme();
    const fileInputRef = useRef(null);

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };

const handleFileUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    const validFiles = [];
    const sizeExceededFiles = [];
    const wrongTypeFiles = [];
    const maxFileSize = 5000000; // 5 MB
    const maxTotalSize = 5000000; // 5 MB
    let newTotalSize = totalSize;

    newFiles.forEach(file => {
        newTotalSize += file.size;
        const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/jpeg", "image/png", "video/mp4"];
        if (!allowedTypes.includes(file.type)) {
            wrongTypeFiles.push(file);
        } else if (file.size > maxFileSize) {
            sizeExceededFiles.push(file);
        } else {
            validFiles.push(file);
        }
    });

    let messageParts = [];
    if (wrongTypeFiles.length > 0) {
        messageParts.push(t('file-upload-wrong-type'));
    }
    if (sizeExceededFiles.length > 0 || newTotalSize > maxTotalSize) {
        messageParts.push(t('file-upload-total-size'));
    }

    if (messageParts.length > 0) {
        const alertMessage = t('file-upload-no-file-uploaded') + ' ' + messageParts.join(' ');
        setMessage(alertMessage);
        setMessageType("error"); // Assuming 'error' is a valid message type
    } else {
        setMessage(""); // Clear the message
        setMessageType(""); // Clear the message type
        setFileData(prevData => {
            const updatedFiles = [...prevData, ...validFiles];
            onChange(updatedFiles);
            return updatedFiles;
        });
        setTotalSize(newTotalSize);
    }
};

    const removeFile = (index) => {
        const fileToRemove = fileData[index];
        const newSize = totalSize - fileToRemove.size;
        setTotalSize(newSize);

        const newFileData = [...fileData];
        newFileData.splice(index, 1);
        setFileData(newFileData);
        onChange(newFileData);
    };

    return (
        <>
            {/* File Upload */}
            <div className="my-2">
                <div className="mt-1 flex items-center">
                    <Button
                        fullWidth
                        variant="outlined"
                        color={theme === "dark" ? "white" : "blue-gray"}
                        size="sm"
                        className="rounded-md flex items-center gap-3 justify-center"
                        onClick={handleFileUploadClick}
                    >
                        <DownloadIcon/>
                        {text}
                    </Button>

                    {/* The actual file input element (hidden) */}
                    <input
                        ref={fileInputRef}
                        type="File"
                        name="files"
                        onChange={handleFileUpload}
                        multiple
                        className="sr-only"
                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png, video/mp4" // Specify the allowed file types here
                    />
                </div>
            </div>
                   {/* Conditionally render the message component */}
            {message && <Message type={messageType} message={message} />}


            <List>
                {fileData.map((data, index) => (
                    <ListItem key={index} className="flex flex-row gap-2 items-center justify-between overflow-hidden flex-grow truncate textOverflow-ellipsis dark:text-white">
                        <div className="max-w-[15%]" > <ArrowUpTrayIcon className="h-5 w-5 " /> </div>
                        <div className="max-w-[70%]  flex-grow truncate  overflow-hidden textOverflow-ellipsis whiteSpace-nowrap">{data.name} </div>
                        <div className="max-w-[15%]">  <TrashIcon className="h-5 w-5 hover:text-red-500" onClick={() => removeFile(index)} /></div>
                    </ListItem>
                ))}
            </List>
        </>
    );
}
export default CustomFileUpload;
