import { IconButton } from "@material-tailwind/react";
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import StreetElement from './streetElement';
import StreetElementMeasurement from './streetElementMeasurement';
import StreetElementCard from './streetElementCard';
import EditableTextDiv from './editableTextDiv';
import { useTranslation } from 'react-i18next';
import { StartHouseSVG, EndHouseSVG, VerticalDashedLineSVG } from './svgComponents';

// this si the view of either the before or during configuration. It gathers the cards and the rendering of them (+ measurements). 

const MobilityCutView = ({ easyView, elements, setElements, streetName, setStreetName, streetNumberL, setStreetNumberL, streetNumberR, setStreetNumberR, predefinedElements, zoomFactor, printAreaId, displayMessage}) => {

    const { t } = useTranslation();

    const handleStreetNameChange = (newText) => {
        if (newText.trim() !== "") {
            setStreetName(newText);
        } else {
            displayMessage('error', t('mobility-cut-app-streetNameEmpty'));
        }
    };  
    
    const handleStreetNumberL = (newText) => {
        if (newText.trim() !== "") {
            setStreetNumberL(newText);
        } else {
            setStreetNumberL("|");
        }
    };

    const handleStreetNumberR = (newText) => {
        if (newText.trim() !== "") {
            setStreetNumberR(newText);
        } else {
            setStreetNumberR("|");
        }
    };
    
  const handleIconChange = (id) => {
    const updatedElements = elements.map(element => {
      if (element.id === id) {
        const newIndex = (element.iconIndex + 1) % element.icons.length;
        return { ...element, iconIndex: newIndex };
      }
      return element;
    });
    setElements(updatedElements);
  };

    const handleIsHovered = (id) => {

        if(easyView){

        const updatedElements = elements.map(element => ({ ...element, isHovered: false }));
        // find the index to update.
        const index = updatedElements.findIndex(element => element.id === id);
        if (index !== -1) {
            updatedElements[index].isHovered = true;
            setElements(updatedElements);
        }
        }
    };


    const handleIsNotHoveredAnymore = () => {
        // Update all elements to set isHovered to false
    const updatedElements = elements.map(element => ({ ...element, isHovered: false }));
    setElements(updatedElements);
    };

    const handleAddElementAtIndex = (index) => {
        const newId = Math.random().toString(36).substr(2, 9);
        const newElement = {
            id: newId,
            streetElement: 'carLane',
            minWidth: predefinedElements.find(el => el.streetElement === 'carLane')?.minWidth || '',
            width: predefinedElements.find(el => el.streetElement === 'carLane')?.width || '',
            label: predefinedElements.find(el => el.streetElement === 'carLane')?.label || '',
            icons: predefinedElements.find(el => el.streetElement === 'carLane')?.icons || '',
            iconIndex: 0, // Reset to display front icon when street element is changed
            isHovered: false, // Is used in order to highlight the card
        };
        const updatedElements = [...elements.slice(0, index), newElement, ...elements.slice(index)];
        setElements(updatedElements);
    };

    const removeElement = (id) => {
        setElements(elements.filter((element) => element.id !== id));
    };
 

    return (
        
        <div className="w-screen h-full flex flex-col p-4 justify-center items-center gap-8">

            <div className="max-w-full h-[1/4] flex flex-row relative bg-gray-200 rounded-xl p-8 gap-4 items-center overflow-x-auto overflow-y-visible z-10">

                {elements.map((element, index) => (
                    <div className="flex flex-row gap-4" key={element.id + index}>
                        {index < elements.length && ( // Render "+" button between elements except for the last one
                            <div className="flex items-center">
                                <IconButton
                                    size="sm"
                                    variant="text"
                                    className="rounded-full bg-gray-300 text-gray-600 hover:bg-green-500 hover:text-white hover:font-bold"
                                    onClick={() => handleAddElementAtIndex(index)}
                                >
                                    <PlusCircleIcon className="w-6 h-6" />
                                </IconButton>
                            </div>
                        )}

                        <StreetElementCard
                            element={element}
                            elements={elements}
                            setElements={setElements}
                            handleIconChange={handleIconChange}
                            handleIsHovered={handleIsHovered}
                            handleIsNotHoveredAnymore={handleIsNotHoveredAnymore}
                            removeElement={removeElement}
                            predefinedElements={predefinedElements}
                        />
                    </div>
                ))}
                <div className="flex items-center">
                    <IconButton
                        size="sm"
                        variant="text"
                        className="rounded-full bg-gray-300 text-gray-600 hover:bg-green-500 hover:text-white hover:font-bold"
                        onClick={() => handleAddElementAtIndex(elements.length)}
                    >
                        <PlusCircleIcon className="w-6 h-6" />
                    </IconButton>
                </div>
            </div>


            <div className="max-w-full h-[3/4] flex flex-row items-center relative overflow-x-auto  gap-8">

                <div className="flex flex-col justify-center items-center inline-flex">
                    <div className="border-4 border-gray-300 hover:border-green-300 rounded-md p-4 m-2 text-lg font-bold text-center w-auto">
                        <EditableTextDiv label={streetName} changeText={handleStreetNameChange} />
                    </div>
                    <div id={printAreaId} className="flex flex-col justify-center items-center inline-flex pb-8 px-8">


                        <div className="justify-center items-center inline-flex ">
                            <div className="transform -rotate-90 border-4 border-gray-300 hover:border-green-300 rounded-md p-4 m-2 text-lg font-bold text-center w-auto">
                                <EditableTextDiv label={streetNumberL} changeText={handleStreetNumberL} />
                            </div>

                            <div className="flex flex-row items-end">

                                {StartHouseSVG({ height: 400 })}
                                {elements.map((element) => (
                                    <div key={element.id}>
                                        <StreetElement
                                            id={element.id}
                                            type={element.streetElement}
                                            width={element.width}
                                            iconIndex={element.iconIndex}
                                            icons={element.icons}
                                            isHovered={element.isHovered}
                                            handleIsHovered={handleIsHovered}
                                            handleIsNotHoveredAnymore={handleIsNotHoveredAnymore}
                                            zoomFactor={zoomFactor}
                                        />
                                    </div>
                                ))}
                                {EndHouseSVG({ height: 400 })}
                            </div>
                            <div className="transform -rotate-90 border-4 border-gray-300 hover:border-green-300 rounded-md p-4 m-2 text-lg font-bold text-center w-auto">
                                <EditableTextDiv label={streetNumberR} changeText={handleStreetNumberR} />
                            </div>
                        </div>
                        <div className=" justify-center  inline-flex ">
                            {VerticalDashedLineSVG}
                            {elements.map((element) => (
                                <div key={element.id}>
                                    <StreetElementMeasurement
                                        elementType={element.streetElement}
                                        label={element.label}
                                        width={element.width}
                                        zoomFactor={zoomFactor}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};




export default MobilityCutView;
