import React, { useState } from 'react';
import { Select, Option } from "@material-tailwind/react";


const CustomSelect = ({ label, name, value, onChange, setValidity, options }) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);

  // A simple validation function for select input
  const validateInput = (value) => {
    return value !== ''; // Basic validation: non-empty value
  };

  // Mark the select as touched when it loses focus
  const handleBlur = () => {
    setTouched(true);
  };

  const handleChange = (selection) => {
    const value = selection; // Get the value from the event object
    setSelectedValue(value);
    onChange(value); // Pass only the value, not the entire event or object
    
    const valid = validateInput(value);
    setIsValid(valid);
    // Report validity back to the parent component
    setValidity(name, valid);
  };

  return (
    <Select
      color={'blue-gray'}
      name={name}
      label={label}
      value={selectedValue}
      className="dark:text-white"
      onChange={handleChange}
      onBlur={handleBlur}
      error={!isValid && touched}
      success={isValid && touched}
    >
      {/* Map over the options prop to create Option components */}
      {options.map((option, index) => (
        <Option key={index} value={option.value} color="blue">
          {option.label}
        </Option>
      ))}

    </Select>
  );
};

export default CustomSelect;

