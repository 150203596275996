import React from "react";
import {
    CardBody,
    Card,
    Button,
} from "@material-tailwind/react";
import Container from "../components/container";
import { useTranslation } from 'react-i18next';


function Training() {
    const { t } = useTranslation();
    return (
        <Container className="flex flex-col gap-6 ">
            <a href="/request-training" className="self-end">
                <Button className="flex flex-row gap-4 text-lg" to="/requesttraining">
                    {t('training-request-access')}
                </Button>
            </a>
            
            <div className="text-2xl font-bold text-blue-800 dark:text-white">
                {t('training-routes-title')}
            </div>

            <div className="text-gray-700 dark:text-white">
                {t('training-routes-desc')}
            </div>

            <div className="flex flex-row flex-wrap gap-4 justify-center">
                { // Map through an array of training courses to generate List Items
                    [
                        { title: t('training-route-1-title'), description: t('training-route-1-desc'), },
                        { title: t('training-route-2-title'), description: t('training-route-2-desc'), },
                        { title: t('training-route-3-title'), description: t('training-route-3-desc'), },
                        { title: t('training-route-4-title'), description: t('training-route-4-desc'), },

                    ].map((course, index) => (
                        <Card className="mt-6 overflow-hidden w-full bg-blue-gray-50 dark:bg-blue-900">
                            <CardBody>
                                <div className="text-lg font-bold mb-2 text-blue-800 dark:text-yellow-800">
                                    {course.title}
                                </div>
                                <div className="text-gray-700 dark:text-white">
                                    {course.description}
                                </div>
                            </CardBody>
                        </Card>
                    ))
                }
            </div>

            <div className="text-2xl font-bold text-blue-800 dark:text-white">
                {t('training-tailored-title')}
            </div>

            <div className="text-gray-700 dark:text-white">
                {t('training-tailored-desc')}
            </div>

            <a href="/request-training" className="self-end">
                <Button className="flex flex-row gap-4 text-lg" to="/requesttraining">
                            {t('training-request-access')} 
                </Button>
            </a>

            <div className="text-2xl font-bold text-blue-800 dark:text-white">
                {t('training-docs-title')}
            </div>

            <div className="text-gray-700 dark:text-white">
                {t('training-docs-desc')}
            </div>
            <a href="/manual" className="inline-block">
                <Button size="sm" variant="text" className="flex items-center gap-2 dark:text-yellow-800">
                    {t('training-access-manual')}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-4 w-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                    </svg>
                </Button>
            </a>
        </Container>
    );
}

export default Training;
