import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTheme } from '../DarkThemeContext';  

const ThemeChanger = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div className="flex items-center">
      {theme === "dark" ? (
        <button className="rounded-full" onClick={toggleTheme}>
          <SunIcon className="h-6 w-6 text-white" />
        </button>
      ) : (
        <button className="rounded-full" onClick={toggleTheme}>
          <MoonIcon className="h-6 w-6 text-blue-800" />
        </button>
      )}
    </div>
  );
};

export default ThemeChanger;
