import { Menu, MenuHandler, MenuList, MenuItem, Button } from "@material-tailwind/react";
import {GlobeEuropeAfricaIcon} from "@heroicons/react/24/outline";

function LanguageDropdown({ languages, currentLanguage, onLanguageChange }) {

    return (
        <Menu>
            <MenuHandler >
                 <Button variant="text" size="sm" className="flex items-center gap-3 text-blue-800 dark:text-white dark:hover:bg-blue-1000">
                   <GlobeEuropeAfricaIcon className="h-6 w-6" />
                    {currentLanguage}
                  </Button>
            </MenuHandler>
            <MenuList className=" text-blue-800 dark:text-white dark:bg-blue-950 ">
                {languages.map((language, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => onLanguageChange(language.code)}
                        className="dark:hover:bg-blue-1000 dark:text-white"
                    >
                        {language.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
}

export default LanguageDropdown;




