import React from "react";
import { Card, Typography } from "@material-tailwind/react";

import { useTranslation } from 'react-i18next';
import dataModel from "../../img/api/dataModel.png";
import dataSchema from "../../img/api/dataSchema.png";

function ApiTechnicalDataModels() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t("api-technical-datamodels-title")}
      </div>

      <p className="text-lg text-gray-800 dark:text-gray-200">
        {t("api-technical-datamodels-1")}
      </p>

      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t("api-technical-datamodels-subtitle-0")}
      </div>

      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>

              <th className="border-b  border-blue-gray-100 bg-blue-gray-50 p-4">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-bold leading-none opacity-70"
                >
                  Nom de la table
                </Typography>
              </th>
              <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-bold leading-none opacity-70"
                >
                  Description
                </Typography>
              </th>
            </tr>
          </thead>

          <tbody>

            <tr class="p-4 border-b border-blue-gray-50">
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                 {t("api-technical-datamodels-3")}
                </Typography>
              </td>
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                  {t("api-technical-datamodels-3b")}
                </Typography>
              </td>
            </tr>

            <tr class="p-4 border-b border-blue-gray-50">
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                 {t("api-technical-datamodels-4")}
                </Typography>
              </td>
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                  {t("api-technical-datamodels-4b")}
                </Typography>
              </td>
            </tr>
            
            <tr class="p-4 border-b border-blue-gray-50">
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                  {t("api-technical-datamodels-5")}
                </Typography>
              </td>
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                  {t("api-technical-datamodels-5b")}
                </Typography>
              </td>
            </tr>
            
            <tr class="p-4 border-b border-blue-gray-50">
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                  {t("api-technical-datamodels-6")}
                </Typography>
              </td>
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                  {t("api-technical-datamodels-6b")}
                </Typography>
              </td>
            </tr>
            
            <tr class="p-4 border-b border-blue-gray-50">
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                 {t("api-technical-datamodels-7")}
                </Typography>
              </td>
              <td class="p-4 border-b border-blue-gray-50">
                <Typography
                  variant="medium"
                  color="blue-gray"
                  className="font-normal"
                >
                  {t("api-technical-datamodels-7b")}
                </Typography>
              </td>
            </tr>


          </tbody>
        </table>
      </Card>


      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t("api-technical-datamodels-subtitle-2")}
      </div>

      <p className="text-lg text-gray-800 dark:text-gray-200">
        {t("api-technical-datamodels-10")}
      </p>

      <img
        className="max-w-[800px] object-cover object-center"
        src={dataSchema}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t("api-technical-datamodels-subtitle-1")}
      </div>

      <p className="text-lg text-gray-800 dark:text-gray-200">
        {t("api-technical-datamodels-8")}
      </p>

      <p className="text-lg text-gray-800 dark:text-gray-200">
        {t("api-technical-datamodels-9")}
      </p>

      <img
        className="max-w-[800px] object-cover object-center"
        src={dataModel}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

    </div>
  );
}

export default ApiTechnicalDataModels;
