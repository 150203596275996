import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardBody,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { CustomPhoneInput } from '../components/formInputs/CustomPhoneInput';
import { TooltipWithHelperIcon } from '../components/infoTooltip';
import CustomFileUpload from '../components/formInputs/CustomFileUpload';
import { Message } from '../components/message';
import { CustomRoadPartsSelector } from '../components/formInputs/CustomRoadPartsSelector';
import { CustomTrainingsSelector } from '../components/formInputs/CustomTrainingsSelector';
import { CustomAddressInput } from '../components/formInputs/CustomAddressInput';
import { initialFormData } from '../data/initialFormData';
import mailService from '../services/mailService';
import captchaService from '../services/captchaService';
import CustomTextInput from '../components/formInputs/CustomTextInput';
import CustomTextarea from '../components/formInputs/CustomTextArea';
import CustomEmailInput from '../components/formInputs/CustomEmailInput';
import CustomDateRangePicker from '../components/formInputs/CustomDateRangePicker';
import CustomSelect from '../components/formInputs/CustomSelect';
import { useTranslation } from 'react-i18next';
import { getTypeOfInterventions, getTypesOfUser } from '../data/contactData';
import CustomYesNoSwitch from '../components/formInputs/CustomYesNoSwitch';
import { signup_url_v2 } from '../data/navigationData';
import { useTheme } from '../DarkThemeContext';
import CustomMultiEmailInput from '../components/formInputs/CustomMultiEmailInput';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CustomCheckBox from '../components/formInputs/CustomCheckBox';
import { useUserType } from '../UserContext';

export default function ContactForm({ formType }) {

  const { userType } = useUserType();

  const mail_cdco = process.env.REACT_APP_MAIL_CDCO;
  const mail_guichet = process.env.REACT_APP_MAIL_GUICHET;
  const mail_training = process.env.REACT_APP_MAIL_TRAINING;

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { theme } = useTheme();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // Initial state setup
  const [formData, setFormData] = useState(initialFormData);
  const [showCreateAccountSuggestion, setShowCreateAccountSuggestion] = useState(false);

  // State for the overall validity of the form
  const [isFormValid, setIsFormValid] = useState(false);

  // State to hold the validity of individual fields
  const [fieldValidity, setFieldValidity] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    address: false,
    roadParts: false,
    B1: false,
    interventionType: false,
    userType: false,
    length: false,
    versionUsed: false,
    trainings: false,
    companyName: false,
    acceptTerms: false
  });

  const areFieldsValidForFormType = useCallback(() => {
    switch (formType) {
      case 'bug':
        return (
          fieldValidity.firstName &&
          fieldValidity.lastName &&
          fieldValidity.email &&
          fieldValidity.versionUsed &&
          (userType !== "citizen" ? fieldValidity.companyName : true) &&
          fieldValidity.message
        );
      case 'moveform':
        return (
          fieldValidity.firstName &&
          fieldValidity.lastName &&
          fieldValidity.email &&
          fieldValidity.phone &&
          fieldValidity.roadParts &&
          fieldValidity.address &&
          fieldValidity.message &&
          fieldValidity.length
        );
      case 'inquiry':
        return (
          fieldValidity.firstName &&
          fieldValidity.lastName &&
          fieldValidity.email &&
          fieldValidity.message
        );
      case 'trainingRequest':
        return (
          fieldValidity.firstName &&
          fieldValidity.lastName &&
          fieldValidity.email &&
          fieldValidity.message &&
          fieldValidity.userType &&
          fieldValidity.trainings &&
          fieldValidity.acceptTerms
        );
      case 'assistance':
        return (
          fieldValidity.firstName &&
          fieldValidity.lastName &&
          fieldValidity.email &&
          fieldValidity.phone &&
          (userType !== "instcomp" ? fieldValidity.interventionType : true) &&
          (userType !== "citizen" ? fieldValidity.companyName : true) &&
          fieldValidity.message
        );
      default:
        return (
          fieldValidity.firstName &&
          fieldValidity.lastName &&
          fieldValidity.email

        ); // Default form, checks for firstName, lastName, and email
    }
  }, [fieldValidity, formType, userType]);


  // Handle changes in field validity
  const handleValidityChange = (fieldName, isValid) => {
    setFieldValidity(prev => ({ ...prev, [fieldName]: isValid }));
  };

  // Check the overall form validity whenever field validity changes
  useEffect(() => {
    setIsFormValid(areFieldsValidForFormType());
  }, [fieldValidity, areFieldsValidForFormType]);



  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [messageType, setMessageType] = useState('');
  const [message, setMessage] = useState('');

  const answers = [
    { label: t('yes'), value: "oui" },
    { label: t('no'), value: "non" },
  ];

  const versionUsed = [
    { label: "osiris.brussels", value: "V1" },
    { label: "apps.osiris.brussels", value: "V2" },
  ];



  // Generic input change handler
  const handleInputChange = (field, value) => {

    setFormData(prevData => ({ ...prevData, [field]: value }));
  };

  // Submit handler
  const handleSubmit = async (event) => {

    event.preventDefault();

    if (!executeRecaptcha) {
      console.log("not available to execute recaptcha");
      return;
    }

    const gRecaptchaToken = await executeRecaptcha('inquirySubmit');
    let response;
    try {
      response = await captchaService(gRecaptchaToken);

      console.log("response in contact form ", response);

      if (response?.success === true) {
        console.log("success with score : ", response.score);

        const email = formData.email;
        const name = `${formData.firstName} ${formData.lastName}`;
        let subject = "";
        let body = "";
        let recipient = mail_guichet;
        const files = formData.files;

        switch (formType) {
          case "bug":
            subject = "Bug report";
            if (userType !== "citizen") {
              body += `
                Nom de l'entreprise: ${formData.companyName}`;
            }
            body += `
              Email : ${formData.email}

              Langue : ${currentLanguage}

              Version d'osiris: ${formData.versionUsed}

              Description du bug: 
              ${formData.message}`;
            recipient = mail_cdco;
            break;
          case "inquiry":
            subject = "Demande générale";
            body += `
              Email : ${formData.email}

              Demande:  ${formData.message}`;
            break;
          case "trainingRequest":
            subject = "Demande de formations";
            body += `
            Email : ${formData.email}

            Autres participants:  ${formData.emails.join('; ')}

            Type d'utilisateur : ${formData.userType}

            Langue : ${currentLanguage}

            Modules: ${formData.trainings}

            Message: 
            ${formData.message}
        `;
            recipient = mail_training;
            break;
          case "assistance":
            subject = "Demande d'assistance";
            if (userType !== "citizen") {
              body += `
                Email : ${formData.email}

                Nom de l'entreprise: ${formData.companyName}`;
            }
            body += `
              B1 number: ${formData.B1}`;
            if (userType !== "instcomp") {
              body += `
              Email : ${formData.email}

              Type d'intervention: ${formData.interventionType}`;
            }
            body += `

              Email : ${formData.email}

              Numéro de téléphone: ${formData.countryCode} ${formData.phone}

              Type d'utilisateur: ${userType !== "citizen" ? "Citizen" : "Non-institutional user"}

              Message: 
              ${formData.message}`;
            break;
          case "moveform":
            subject = "Demande de déménagement";
            body += `
              Email : ${formData.email}

              Numéro de téléphone: ${formData.countryCode} ${formData.phone}

              Adresse: ${formData.address}

              Dates: ${formData.startDate} - ${formData.endDate}

              Impacts: ${formData.roadParts}

              Longueur: ${formData.length}

              J'ai déjà un compte osiris:  ${formData.alreadyAccount}

              Message: 
              ${formData.message}`;
            break;
          default:
            subject = "Contact Us";
            break;
        }



        if (isFormValid) {

          console.log(email, name, recipient, subject, body, files);

          setHasSubmitted(true);
          try {
            await mailService(email, name, recipient, subject, body, files);
            setMessage(t('cf-successMessage'));
            setMessageType('success');
            console.log("succeeded with score : ", response?.data?.score);

          } catch (error) {

            setMessage(t('cf-errorMessage'));
            setMessageType('error');
          }
        }

      } else {

        console.log("Captcha verification failed");
        setHasSubmitted(true);
        setMessage(t('cf-errorMessageCaptcha'));
        setMessageType('error');
      }

    } catch (error) {
      console.log("failed with score : ", response?.data?.score);
      setHasSubmitted(true);
      setMessage(t('cf-errorMessageCaptcha'));
      setMessageType('error');
      return;
    }

  };

  // Render conditionally based on formType
  const renderConditionalFields = () => {
    switch (formType) {
      case 'bug':
        return (
          <>
            <div className="text-blue-gray-600 dark:text-white">{t('cf-versionUsed')}</div>
            <CustomYesNoSwitch onChange={(value) => handleInputChange('versionUsed', value)} options={versionUsed} setValidity={handleValidityChange} />
            {userType !== "citizen" && (
              <CustomTextInput
                label={t('cf-company')}
                name="companyName"
                value={formData.companyName}
                onChange={(value) => handleInputChange('companyName', value)}
                setValidity={handleValidityChange}
                isNumber={false}
              />
            )}
            <CustomTextarea
              label={t('cf-reportABug')}
              name="message"
              value={formData.message}
              onChange={(value) => handleInputChange('message', value)}
              setValidity={handleValidityChange}
            />
            <CustomFileUpload text={t('upload-file')} files={formData.files} onChange={(value) => handleInputChange('files', value)} />
          </>
        )
      case 'moveform':
        return (
          <>
            <CustomPhoneInput
              name="phone"
              label={t('cf-phone')}
              countryCode={formData.countryCode}
              phone={formData.phone}
              onCountryCodeChange={(value) => handleInputChange('countryCode', value)}
              onPhoneChange={(value) => handleInputChange('phone', value)}
              setValidity={handleValidityChange}
            />

            <CustomRoadPartsSelector roadParts={formData.roadParts} onSelect={(value) => handleInputChange('roadParts', value)} setValidity={handleValidityChange} />

            <CustomAddressInput
              label={t('cf-address-move')}
              onChange={(value) => handleInputChange('address', value.address)}
              setValidity={handleValidityChange}
            />
            <CustomTextInput
              label={t('cf-length')}
              autoComplete=" "
              name="length"
              value={formData.length}
              onChange={(value) => handleInputChange('length', value)}
              setValidity={handleValidityChange}
              isNumber={true}
            />
            <div className="flex flex-row p-2 justify-between">

              <Dialog open={showCreateAccountSuggestion} handler={setShowCreateAccountSuggestion}>
                <DialogBody>
                  <div className="text-blue-gray-600 dark:text-white gap-2"> {t('cf-createAnAccountSuggestion')} {t('cf-createAnAccountSuggestion2')}</div>
                </DialogBody>
                <DialogFooter>
                  <div className="flex flex-row gap-2 justify-between">
                    <a href={signup_url_v2} target="_blank" rel="noreferrer">
                      <Button variant="filled" onClick={() => setShowCreateAccountSuggestion(false)} color={theme === "dark" ? "white" : "blue"} fullWidth className="dark:text-blue-900 dark:hover:bg-blue-gray-50 dark:focus:bg-blue-gray-50">
                        {t('yes')}
                      </Button>
                    </a>
                    <Button variant="outlined" onClick={() => setShowCreateAccountSuggestion(false)} color={theme === "dark" ? "white" : "blue"} size="sm" fullWidth className="dark:text-blue-900 dark:hover:bg-blue-gray-50 dark:focus:bg-blue-gray-50">
                      {t('no')}
                    </Button>
                  </div>
                </DialogFooter>
              </Dialog>

              <div className="text-blue-gray-600 dark:text-white">{t('cf-hasAccount')}</div>
              <CustomYesNoSwitch onChange={(value) => handleInputChange('alreadyAccount', value)} options={answers} setShowCreateAccountSuggestion={setShowCreateAccountSuggestion} setValidity={handleValidityChange} />
            </div>
            <CustomDateRangePicker
              name="dates"
              initialStartDate={formData.startDate}
              initialEndDate={formData.endDate}
              onStartDateChange={(value) => handleInputChange('startDate', value)}
              onEndDateChange={(value) => handleInputChange('endDate', value)}
            />
            <CustomTextarea
              label={t('cf-needs')}
              name="message"
              value={formData.message}
              onChange={(value) => handleInputChange('message', value)}
              setValidity={handleValidityChange}
            />
            <CustomFileUpload text={t('upload-file')} files={formData.files} onChange={(value) => handleInputChange('files', value)} />

          </>
        );
      case 'inquiry':
        return (
          <>
            <CustomTextarea
              label={t('cf-inquiry')}
              name="message"
              value={formData.message}
              onChange={(value) => handleInputChange('message', value)}
              setValidity={handleValidityChange}
            />
            <CustomFileUpload text={t('upload-file')} files={formData.files} onChange={(value) => handleInputChange('files', value)} />
          </>
        );
      case 'trainingRequest':
        return (
          <>
            <CustomSelect
              label={t('cf-userType')}
              name="userType"
              value={formData.interventionType}
              onChange={(value) => handleInputChange('userType', value)}
              setValidity={handleValidityChange}
              options={getTypesOfUser(t)}
            />
            <CustomMultiEmailInput onEmailChange={(emails) => handleInputChange('emails', emails)} label={t('cf-mail')} title={t('cf-add-mail-title')} />
            <CustomTrainingsSelector trainings={formData.trainings} onSelect={(value) => handleInputChange('trainings', value)} setValidity={handleValidityChange} />

            <CustomTextarea
              label={t('cf-needs')}
              name="message"
              value={formData.message}
              onChange={(value) => handleInputChange('message', value)}
              setValidity={handleValidityChange}
            />
            <CustomCheckBox name='acceptTerms' label1={t('cf-IDeclare1')} label2={t('cf-IDeclare2')} label3={t('cf-IDeclare3')} setValidity={handleValidityChange} />
          </>);
      case 'assistance':
        return (
          <>
            <CustomPhoneInput
              label={t('cf-phone')}
              name="phone"
              countryCode={formData.countryCode}
              phone={formData.phone}
              onCountryCodeChange={(value) => handleInputChange('countryCode', value)}
              onPhoneChange={(value) => handleInputChange('phone', value)}
              setValidity={handleValidityChange}
            />
            {userType !== "instcomp" && (
              <CustomSelect
                label={t('cf-interventionType')}
                name="interventionType"
                value={formData.interventionType}
                onChange={(value) => handleInputChange('interventionType', value)}
                setValidity={handleValidityChange}
                options={getTypeOfInterventions(t)}
              />
            )}
            {userType !== "citizen" && (
              <CustomTextInput
                label={t('cf-company')}
                name="companyName"
                autoComplete=" "
                value={formData.companyName}
                onChange={(value) => handleInputChange('companyName', value)}
                setValidity={handleValidityChange}
                isNumber={false}
              />
            )}
            <div className="flex items-center gap-2">
              <CustomTextInput
                label={t('cf-B1Number')}
                name="B1"
                autoComplete=" "
                value={formData.B1}
                onChange={(value) => handleInputChange('B1', value)}
                setValidity={handleValidityChange}
                isNumber={true}
              />
              <TooltipWithHelperIcon title={t('B1-tooltip-title')} body={t('B1-tooltip-desc')} />
            </div>
            <CustomTextarea
              label={t('cf-needs')}
              name="message"
              value={formData.message}
              onChange={(value) => handleInputChange('message', value)}
              setValidity={handleValidityChange}
            />
            <CustomFileUpload text={t('upload-file')} files={formData.files} onChange={(value) => handleInputChange('files', value)} />
          </>
        );
      default:
        return null;
    }
  };

  return (

    <div className="flex justify-center items-center px-4 py-4">
      <Card className="w-full max-w-[30rem]">
        <div className="bg-blue-600 dark:bg-blue-950 p-4 rounded-t-lg">
          <div className="text-2xl font-bold text-white dark:text-yellow-800">
            {formType === 'bug' ? t('cf-reportABug') :
              formType === 'inquiry' ? t('cf-HowCanWeHelpYou') :
                formType === 'assistance' ? t('cf-requestAssistance') :
                  formType === 'moveform' ? t('cf-moveForm') :
                    formType === 'trainingRequest' ? t('training-request-access') : t('cf-contactUs')
            }
          </div>
        </div>
        <CardBody className="dark:bg-blue-900">

          {!hasSubmitted ? (
            <form encType="multipart/form-data" className="flex flex-col gap-6" onSubmit={handleSubmit}>
              <div className="my-4 flex items-center gap-4">
                <CustomTextInput
                  label={t('cf-firstName')}
                  name="firstName"
                  autoComplete="given-name"
                  value={formData.firstName}
                  onChange={(value) => handleInputChange('firstName', value)}
                  setValidity={handleValidityChange}
                  isNumber={false}
                />
                <CustomTextInput
                  label={t('cf-lastName')}
                  name="lastName"
                  autoComplete="family-name"
                  value={formData.lastName}
                  onChange={(value) => handleInputChange('lastName', value)}
                  setValidity={handleValidityChange}
                  isNumber={false}
                />
              </div>
              <CustomEmailInput
                name="email"
                label={t('cf-mail')}
                value={formData.email}
                onChange={(value) => handleInputChange('email', value)}
                setValidity={handleValidityChange}
              />
              {renderConditionalFields()}

              <Button size="md" type="submit" disabled={!isFormValid}>{t('cf-send')}</Button>

            </form>) : (

            <Message type={messageType} message={message} />

          )}
        </CardBody>
      </Card>
    </div>
  );
}


