import React, { useState } from "react";
import { Input, Tooltip} from "@material-tailwind/react";
import { DocumentDuplicateIcon, EyeIcon, EyeSlashIcon, CheckIcon } from "@heroicons/react/24/outline";
import { useCopyToClipboard } from "usehooks-ts";
import { useTranslation } from 'react-i18next';

export function HiddenKey({ input, label }) {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const [copied, setCopied] = useState(false);
  const inputVisibilityIcon = showInput ? <EyeSlashIcon className="w-3 h-3" /> : <EyeIcon className="w-3 h-3" />; // Icon for toggling API key visibility
  const copiedyIcon = copied ? <CheckIcon className="w-full h-full" /> : <DocumentDuplicateIcon className="w-full h-full" />; // Icon for toggling API key visibility
  const [value, copy] = useCopyToClipboard('');

  const toggleInputVisibility = () => {
    setShowInput(!showInput);
  };


  return (
    <div className="relative flex w-full max-w-[24rem]">
      <Input
        type={showInput ? "text" : "password"}
        label={label}
        value={input}
        onChange={() => { }}
        className="border border-gray-400 p-2 pr-10 w-full"
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 gap-3">
        <div onClick={toggleInputVisibility} className="cursor-pointer">
          {inputVisibilityIcon}
        </div>
        <div onClick={() => {
          copy(input);
          setCopied(true);
        }}
          onMouseLeave={() => setCopied(false)}
          className="w-4 h-4 flex-shrink-0 cursor-pointer">
             <Tooltip content={copied ? t('copied') : t('copy')}>
          {copiedyIcon}
          </Tooltip>
        </div>
      </div>
    </div>

  );
}

