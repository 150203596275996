import { useTranslation } from 'react-i18next';

const AccessibilityCharter = () => {
  
  const tel_sprb = process.env.REACT_APP_TEL_SPRB;
  const mail_sprb = process.env.REACT_APP_MAIL_SPRB;
  const mail_osiris = process.env.REACT_APP_MAIL_OSIRIS;
  const site_irisbox = process.env.REACT_APP_SITE_IRISBOX;

  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col gap-4 text-justify p-8">
        <div className="mt-3 font-bold leading-snug text-blue-900 text-2xl lg:text-4xl dark:text-yellow-800">{t('acc-title-main')} </div>
        <div className="dark:text-white">
          {t('acc-body-main')}
          <a href="/">my.osiris.brussels</a>
        </div>
        <div className="mt-3 font-bold leading-snug text-blue-700 text-xl lg:text-2xl dark:text-yellow-800">{t('acc-title-conf')} </div>
        <div className="dark:text-white">{t('acc-body-conf')} </div>
        <div className="mt-3 font-bold leading-snug text-blue-700 text-xl lg:text-2xl dark:text-yellow-800">{t('acc-title-prep')} </div>
        <div className="dark:text-white">{t('acc-body-prep')} </div>
        <div className="mt-3 font-bold leading-snug text-blue-700 text-xl lg:text-2xl dark:text-yellow-800">{t('acc-title-contact')} </div>
        <div className="dark:text-white">{t('acc-body-contact-l1')} </div>
        <div className="p-4">
          <div className="font-bold dark:text-white">{t('acc-body-contact-l2')} </div>
          <div className="dark:text-white">{t('acc-body-contact-l3')} </div>
          <div className="dark:text-white">{t('acc-body-contact-l4')} </div>
          <div className="dark:text-white">{t('acc-body-contact-l6')} <a href={`mailto:${mail_osiris}`}> {mail_osiris}</a>  </div>
        </div>
        <div className="dark:text-white">{t('acc-body-contact-l7')} </div>
        <div className="p-4">
          <div className="font-bold dark:text-white">{t('acc-body-contact-l8')} </div>
          <div className="dark:text-white">{t('acc-body-contact-l9')} </div>
          <div className="dark:text-white">{t('acc-body-contact-l10')} </div>
          <div className="dark:text-white">{t('acc-body-contact-l11')} </div>
          <div className="dark:text-white">{t('acc-body-contact-l12')} </div>
          <div className="dark:text-white">{t('acc-body-contact-l13')}{tel_sprb} </div>
          <div className="dark:text-white">{t('acc-body-contact-l14')} <a href={`mailto:${mail_sprb}`}> {mail_sprb}</a> </div>
          <div className="dark:text-white">{t('acc-body-contact-l15')} <a href={site_irisbox}> IRISbox </a> </div>
        </div>
        <div className="mt-3 font-bold leading-snug text-blue-700 text-xl lg:text-2xl dark:text-yellow-800">{t('acc-title-improve')} </div>
        <div className="dark:text-white">{t('acc-body-improve-l1')} </div>
        <div className="dark:text-white">{t('acc-body-improve-l2')} </div>
        <div className="dark:text-white">{t('acc-body-improve-l3')} </div>
      </div>
    </>
  );
}
export default AccessibilityCharter;
