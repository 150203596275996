import React, { createContext, useContext, useState, useEffect } from 'react';
import { setCookie, getCookie } from './handlers/cookieHandler'; // Import your cookie handling methods

// Create the UserContext
const UserContext = createContext();

export function UserProvider({ children }) {
  const [userType, setUserType] = useState('citizen'); // Default user type

  useEffect(() => {
    // Retrieve the user type from cookies or local storage if needed
    const storedUserType = getCookie("user_type");
    if (storedUserType) {
      setUserType(storedUserType); 
    } else {
      setUserType("citizen");
    }
  }, []);

  const updateUserType = (newUserType) => {
    // Update user type and save it to cookies or local storage if needed
    setCookie("user_type", newUserType, 365);
    setUserType(newUserType);
  };

  return (
    <UserContext.Provider value={{ userType, updateUserType }}>
      {children}
    </UserContext.Provider>
  );
}

// Create a custom hook to access the user type and update function
export function useUserType() {
  return useContext(UserContext);
}
