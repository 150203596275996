import React, { useState } from 'react';
import {
  Button
} from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';
import Container from '../components/container';
import proceduresData from '../data/proceduresData';
import MunicipalityCard from '../components/municipalityCard';
import CustomAvatar from '../components/customAvatar';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';


function Municipalities() {
  const { t, i18n } = useTranslation();
   const currentLang = i18n.language;
  const [selectedMunicipality, setSelectedMunicipality] = useState(null);

  const handleMunicipalityClick = (municipality) => {
    setSelectedMunicipality(municipality);
  };

    const handleBackClick = () => {
    setSelectedMunicipality(null);
  };

  const renderMunicipalities = proceduresData.map((municipality, key) => {

    const label = municipality.label[currentLang] || municipality.label[currentLang]; // Localize label

    const content = (
      <Button onClick={() => handleMunicipalityClick(municipality)} variant="outlined" color="gray" className="flex items-center gap-3 rounded-lg bg-gray-50 w-full dark:hover:bg-blue-1000 dark:bg-blue-900">
        <CustomAvatar src={municipality.img} alt={label} size="sm" />
        <div className="text-sm text-blue-800 dark:text-white">
          {label}
        </div>
      </Button>
    );

    return (
      <div key={key} className="flex flex-col items-center gap-2 py-2 pr-4">
        {content}
      </div>
    );
  });

  return (
    <Container className="flex flex-col gap-6">
      {selectedMunicipality ? (
        <>
          {/* Back to all Municipalities Button */}
          <Button onClick={handleBackClick} className="max-w-[400px] flex items-center gap-3">
            <ArrowUturnLeftIcon className="h-5 w-5" />
            {t('procedures-back-to-mun')}
          </Button>

          {/* Render MunicipalityCard if a municipality is selected */}
           <MunicipalityCard
            municipality={selectedMunicipality}
          />
        </>
      ) : (

        //Title and mosaic of Municipalities
        <>
          <div className="text-2xl font-bold text-blue-900 dark:text-yellow-800">
            {t('procedures-select-mun')}
          </div>
          {t('procedures-select-mun-explanation')}

          <div className={`grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4`}>
            {renderMunicipalities}
          </div>
        </>
      )}
    </Container>
  );
}

export default Municipalities;

