import React from "react";
import { useTranslation } from 'react-i18next';

import qgisNew from "../../img/api/qgis-new.png";
import qgisLayer from "../../img/api/qgis-layer.png";
import qgisParams from "../../img/api/qgis-params.png";
import qgisAdd from "../../img/api/qgis-add.png";
import qgisView from "../../img/api/qgis-view.png";



function ApiUseCaseQGIS() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-start gap-6">

      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('api-tutorial-qgis-title')}
      </div>


      <p className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-1')}
      </p>

      <p className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-2')}
      </p>

      
      <img
        className="max-w-[800px] object-cover object-center"
        src={qgisNew}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

      <p className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-3')}
      </p>

      
      <img
        className="max-w-[800px] object-cover object-center"
        src={qgisLayer}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

      <p className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-4')}
      </p>

      <p className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-5')}
      </p>

      <li className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-6')}
      </li>

      <li className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-7')}
      </li>

      <li className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-8')}
      </li>

      <li className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-9')}
      </li>




      <p className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-10')}
      </p>

      <img
        className="max-w-[800px] object-cover object-center"
        src={qgisParams}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />   

      <p className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-11')}
      </p>


      <img
        className="max-w-[800px] object-cover object-center"
        src={qgisAdd}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

      <p className="text-lg  text-gray-600 dark:text-gray-400">
        {t('api-tutorial-qgis-12')}
      </p>



      <img
        className="max-w-[800px] object-cover object-center"
        src={qgisView}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

    </div>


  );
}

export default ApiUseCaseQGIS;

