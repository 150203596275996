
const releasesData = [

   {
      releaseYear: "2024",
      releases: [
         {
            number: "3.64",
            tags: {
               nl:  "Nieuwe kaartlagen wegbeheerder artikel 32 gemeentegrenzen herziening van de interactieve kaart bij het aanvragen van een bezetting van de openbare weg",
               fr: "Nouvelles couches cartograhpiques carto limite des communes administrateur de voirie  article 32 refonte de la carte interactive lors de la demande d'occupation en version simplifiée"
            },
            url: "/releases/3-64.pdf"
         },
         {
            number: "3.63",
            tags: {
               nl:  "Hervorming van de bouwplaatsstatussen Bijwerken van de datums van de fasen in de cockpit Verwijdering van knoppen met betrekking tot de bankgarantie Hervorming van de inschrijving in de vereenvoudigde versie van Osiris Informatie over 'dossierrechten' Aanpassing van de sjablonen voor de beslissing van het CCC-advies en de beslissingen Toezichtmodule: Aanpassing van de ingebrekestelling",
               fr: "Refonte des statuts de chantier Mise à jour des dates des phases dans le cockpit Suppression des boutons relatifs à la garantie bancaire Refonte de l’inscription dans la version simplifiée d’Osiris Information droits de dossiers  Modification des templates de décision de avis CCC et des décisions Module surveillance : Adaptation de la mise en demeure"
            },
            url: "/releases/3-63.pdf"
         },
         {
            number: "3.62",
            tags: {
               nl:  "Tolerantiezone: Introductie van een tekenhulpmiddel waarmee 'kleine' delen van de bouwplaats of fase kunnen worden geïdentificeerd door WB ToezichtModule: Integratie van opmerkingen in de details van fijnberekeningen gekoppeld aan een rapport Wijziging van de rapport-/rapportsjablonen",
               fr: "Zone de tolérance : Introduction d’une aide au dessin permettant d’identifier les « petites » portions de chantier ou de phase par AV. Module surveillance : Intégration des commentaires dans le détail des calculs des amendes liées à un procès-verbal Modification des templates Constat/Procès-verbal",
            },
            url: "/releases/3-62.pdf"
         },
         {
            number: "3.61",
            tags: {
               nl: "Module Waarschuwing: Aanpassing van de termen PV met/zonder boetes naar Verwittiging/Proces-verbaal",
               fr: "Module surveillance Modification des termes PV avec/sans amendes par Avertissement / Procès-verbal"
            },
            url: "/releases/3-61.pdf"
         },
         {
            number: "3.60",
            tags: {

               nl: "Wijziging van het CCB-advies mechanisme",
               fr: "Modification du mécanisme des avis CCC"
            },
            url: "/releases/3-60.pdf"
         }
      ]
   },
   {
      releaseYear: "2023",
      releases: [
         {
            number: "3.51",
            tags: {
               nl: "Herschikking van de notulen van gewone sessie Wijziging van de CCB-kennisgeving en WB-besluitsjablonen",
               fr: "Refonte des Procès-Verbaux des séances ordinaires Modification des templates des avis CCC et décision AV"
            },
            url: "/releases/3-51.pdf"
         },
         {
            number: "3.48",
            tags: {
               nl: "Preadvies van een bouwplaats Mobiris : naamswĳziging Oprichting van een burgerorganisatie De resultaten van een ruimtelijke analyse weergeven Wijziging van een signaleringsplan voor een fase in een vergunningsaanvraag",
               fr: "Pré-avis de chantier Mobiris : changement de nom Création d'organisation citoyenne Affichage des résultats d'une analyse spatiale Modification d’un plan de signalisation pour une phase en cours de demande d’autorisation"
            },
            url: "/releases/3-48.pdf"
         },
         {
            number: "3.47",
            tags: {

               nl: "Reorganisatie van de modules en samenvoeging van de modules 'V-Wizard' en 'Type UV' Geplande Type UV",
               fr: "Réorganisation des modules et fusion des modules 'I-Wizard' et 'Type D' Type D planifié"
            },
            url: "/releases/3-47.pdf"
         },
         {
            number: "3.46",
            tags: {
               nl: "Wijziging van de module om wachtwoorden te veranderen Aanpassing Import GML - Optie om de partijen buiten de weg te behouden Identificatie van bouwplaatsen volgens de hypercoördinatiezone Nummering van de vergunningsaanvragen Aanpassing van de voorstellen van beslissing van de wegbestuurder Mailbox: Identificatie van belangrijke berichten Wijziging van de module omleidingsplannen en -lijnen Deblokkeringsaanvraag: ATG sCCB Uitbreiding van de termijnen voor de meervoudige ondertekening",
               fr: "Modification du module de changement de mot de passe Adaptation Import GML - Option pour garder les parties Hors-voirie Identification de chantier selon la zone d’hypercoordination Numérotation des demandes d'autorisation Adaptation des propositions de décision de l’administrateur de voirie Boite à message : Identification des messages importants Modification du module « plans et lignes de déviation » Demande de dégel : ATG sCCC Extension du délai de signature des documents situés dans le signataire par lot Modification des données cartographiques utilisées dans l’application Type D"
            },
            url: "/releases/3-46.pdf"
         },
         {
            number: "3.45",
            tags: {
               nl: "Wijziging van de status Geregistreerd naar Geregistreerd (Ontwerp) Toevoeging van een nieuwe bouwplaatsstatus Gepland Toevoeging van een nieuw type bouwplaats Parkeerplaats Toevoeging van de mogelijkheid om een bouwplaats over te hevelen naar een andere organisatie Vertraagde verwerking van de sectorwijzigingen van de organisaties Toevoeging van een functionaliteit waarmee de soorten bouwplaatsen geselecteerd kunnen worden waarop de ruimtelijke analyse van toepassing is Toevoeging van een informatieve tekst over de reservatie van taxistaanplaatsen in de documenten Voorstel van beslissing, Advies CCB en Beslissing Gewijzigde verwerking van de handtekening van de document",
               fr: "Statut Enregistré et Brouillon Statut de chantier Planifié Nature de chantier Stationnement Transfert de chantier entre organisations Modifications de secteurs des organisations Types de chantiers impactés par l'analyse spatiale Réservation des emplacements de taxis Traitement différé de la signature des documents Génération de MindMap simplifiée Export (xlsx) dans les séances de préparation Ajout de titres aux séances extraordinaires"
            },
            url: "/releases/3-45.pdf"
         }
      ]
   }

];

export default releasesData;
