import React from 'react';


const strokeWidth = 4; // Adjust this for consistent stroke width

export const HorizontalLineSVG = ({ horizontalLineHeight, elementWidth }) => (
  <svg width={elementWidth} height={horizontalLineHeight} xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1={horizontalLineHeight} x2={elementWidth} y2={horizontalLineHeight} stroke="black" strokeWidth={strokeWidth} />
  </svg>
);

export const ElevatedSVG = ({ horizontalLineHeight, elementWidth }) => {
  // Calculate the bottom-right corner coordinates based on horizontalLineHeight and elementWidth
  const bottomRightY = horizontalLineHeight;

  // Construct the path data dynamically
  const pathData = `M0 ${bottomRightY}V0H${elementWidth}V${bottomRightY}`;

  return (
    <svg width={elementWidth} height={horizontalLineHeight} viewBox={`0 0 ${elementWidth} ${horizontalLineHeight}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d={pathData} stroke="black" strokeWidth={strokeWidth} />
    </svg>
  );
};

// SVG component for the vertical dashed line
export const VerticalDashedLineSVG = () => (
  <svg width="1" height="50" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="0" x2="0" y2="50" stroke="gray" strokeWidth="2" strokeDasharray="2" />
  </svg>
);

// SVG component for the start house
export const StartHouseSVG = ({ height }) => {
  const roofHeight = height / 8; // Height of the roof (1/8 of the total height)
  const roofWidth = height / 8; // Width of the roof (same as the height/8)

  return (
    <svg width={roofWidth} height={height} viewBox={`0 0 ${roofWidth} ${height}`} xmlns="http://www.w3.org/2000/svg">
      {/* Draw the oblique line */}
      <line x1="0" y1="0" x2={roofWidth} y2={roofHeight} stroke="black" strokeWidth="2" />
      {/* Draw the vertical line */}
      <line x1={roofWidth} y1={roofHeight} x2={roofWidth} y2={height} stroke="black" strokeWidth="4" />
    </svg>
  );
};

// SVG component for the end house
export const EndHouseSVG = ({ height }) => {
  const roofHeight = height / 8; // Height of the roof (1/8 of the total height)
  const roofWidth = height / 8; // Width of the roof (same as the height/8)

  return (
    <svg width={roofWidth} height={height} xmlns="http://www.w3.org/2000/svg">
      {/* Draw the vertical line */}
      <line x1={0} y1={roofHeight} x2={0} y2={height} stroke="black" strokeWidth="4" />
      {/* Draw the oblique line */}
      <line x1={roofWidth} y1="0" x2={0} y2={roofWidth} stroke="black" strokeWidth="2" />
    </svg>
  );
};

// SVG component for scaffolding
export const ScaffoldingSVG = ({ width, pedestrianPassage }) => {
  const scaffoldWidth = width*65 || 200; // Default width if not provided (40 is the zoom factor)
  const scaffoldHeight = 350;
  const numLevels = 4;

  // Calculate the thickness of bars
  const thickBarWidth = 3;
  const thinBarWidth = 2;

  // Generate horizontal bars for each level
  let effectiveLevels;
  if(pedestrianPassage){effectiveLevels = numLevels-1;}
  else {effectiveLevels = numLevels;}

  const levelBars = [];
  for (let i = 0; i <= effectiveLevels; i++) {
    const y = scaffoldHeight / numLevels * i; // Calculate y-coordinate


    // Add three thin bars between each level
    if (i < effectiveLevels) {
      levelBars.push(
        <line key={`level-${i}-1`} x1={0} y1={y + 1 / 3 * scaffoldHeight / numLevels} x2={scaffoldWidth} y2={y + 1 / 3 * scaffoldHeight / numLevels} stroke="gray" strokeWidth={thinBarWidth} />,
        <line key={`level-${i}-2`} x1={0} y1={y + 2 / 3 * scaffoldHeight / numLevels} x2={scaffoldWidth} y2={y + 2 / 3 * scaffoldHeight / numLevels} stroke="gray" strokeWidth={thinBarWidth} />,
      );
      // Add thick bars at the level
      levelBars.push(<line key={`level-${i}-thick`} x1={0} y1={y} x2={scaffoldWidth} y2={y} stroke="gray" strokeWidth={thickBarWidth} />);
    }
  }

  // Generate vertical bars
  const verticalBars = [
    <line key="left-vertical" x1={0} y1={0} x2={0} y2={scaffoldHeight} stroke="gray" strokeWidth={thinBarWidth} />,
    <line key="right-vertical" x1={scaffoldWidth} y1={0} x2={scaffoldWidth} y2={scaffoldHeight} stroke="gray" strokeWidth={thinBarWidth} />
  ];

  return (
    <svg width={scaffoldWidth + thickBarWidth} height={scaffoldHeight} viewBox={`0 0 ${scaffoldWidth} ${scaffoldHeight}`} xmlns="http://www.w3.org/2000/svg">
      {levelBars}
      {verticalBars}
    </svg>
  );
};
