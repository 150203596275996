import React, {useState} from "react";
import {
  Input,
  Button,
} from "@material-tailwind/react";
import releasesData from "../data/releasesData";
import { useTranslation } from 'react-i18next';
import { useTheme } from '../DarkThemeContext';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { PageViewTracker } from "../handlers/GoogleAnalyticsHandler";
import { CustomChip } from "../components/customChip";

function Releases() {
  // Track page view for Google Analytics
  PageViewTracker({ path: "/releases", title: "RELEASES" });

  // Get translation and theme information
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { theme } = useTheme();

  // State variables
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
 
  // Handler for updating the search term
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handler for selecting a year
  const handleYearSelect = (value) => {
    setSelectedYear(value);

  };

  // Handler for going back to years
  const handleBackToYears = () => {
    setSelectedYear(null);
  };

  // Filter releases based on the search term and current language
  const filteredReleases = (releases, currentLang) => {
    return releases.filter(release =>
      currentLang
      && release.tags[currentLang]
      && release.tags[currentLang].toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Get recommended years based on the search term and current language
  const recommendedReleases = releasesData.flatMap(({ releases }) => filteredReleases(releases, currentLang));

  return (
    <div className="flex flex-col gap-6 m-2 sm:m-8">
      {/* First Row */}
      <div className="flex flex-col md:flex-row gap-6">
        {/* Search Input or Back button - full width on mobile, 1/3 width on larger screens */}
        <div className="w-full md:w-1/3">
          {selectedYear ? (
            <Button className="w-full flex items-center gap-3" onClick={handleBackToYears}>
              <ArrowUturnLeftIcon className="h-5 w-5" />
              {t('releases-back-all')}
            </Button>
          ) : (
            <div className="bg-gray-100 dark:bg-blue-900 rounded p-4">
              <div className="text-gray-800 mb-2 dark:text-white">{t('releases-search')}</div>
              <Input
                name="search"
                type="text"
                placeholder={t('releases-search-input')}
                color={theme === "dark" ? "white" : "gray"}
                onChange={handleSearch}
              />
            </div>
          )}
        </div>
        {/* year Buttons or releases Accordions - full width on mobile, 2/3 width on larger screens */}
        <div className="w-full md:w-2/3 flex flex-col gap-6 sm:p-4">
          {searchTerm || selectedYear ? (
            <div className="flex flex-col gap-6 rounded">
              <div className="text-2xl font-bold text-blue-900 dark:!text-yellow-800 mb-4">{t('releases-filtered')}</div>
              {(searchTerm ? recommendedReleases : releasesData.filter(({ releaseYear }) => releaseYear === selectedYear)[0]?.releases || []).map((release, index) => (
                <a href={release.url} id={`release${index}`} key={`release${index}`}>
                  <CustomChip text={release.number}/>
                </a>

              ))}
            </div>
          ) : (
            <div>
              <div className="text-blue-800 text-2xl font-bold mb-4 dark:!text-yellow-800">{t('releases-year')}</div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {releasesData.map(({ releaseYear }) => (
                  <Button
                    variant="outlined"
                    color="blue-gray"
                    key={releaseYear}
                    className="rounded text-sm md:text-base overflow-ellipsis overflow-hidden whitespace-nowrap dark:text-white dark:hover:bg-blue-1000 dark:bg-blue-900"
                    onClick={() => handleYearSelect(releaseYear)}
                  >
                    {releaseYear}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Releases;
