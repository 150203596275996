import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from "@material-tailwind/react";
import osirisService from "../../services/osirisService";
import CustomTextInput from "../formInputs/CustomTextInput";
import CustomSelect from "../formInputs/CustomSelect";
import { MapContainer, WMSTileLayer, ZoomControl, Polygon, Popup } from 'react-leaflet';
import { Message } from '../message'; // Import Message component for displaying errors
 
//added comment for merge fix
 
function ApiDemo() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    limit: '1',
    item: '',
    filter:'',
    responseJSON: null,
    errorMessage: '',
    successMessage: '',
    isLoading: false,
  });
 
  const { t } = useTranslation();
  const [mapCenter, setMapCenter] = useState([50.85, 4.35]);
  const defaultZoomLevel = 13;
  const [zoomLevel, setZoomLevel] = useState(defaultZoomLevel);
 
 
  const areFieldsValidForFormType = () => {
    return fieldValidity.username && fieldValidity.password &&  fieldValidity.item && fieldValidity.limit;
  }
 
  const handleValidityChange = (fieldName, isValid) => {
    setFieldValidity(prev => ({ ...prev, [fieldName]: isValid }));
  };
 
  const [isFormValid, setIsFormValid] = useState(false);
 
  const [fieldValidity, setFieldValidity] = useState({
    username: false,
    password: false,
    limit: true,
    item: false,
    filter: false,
  });
 
  useEffect(() => {
    setIsFormValid(areFieldsValidForFormType());
  }, [fieldValidity]);
 
  const handleInputChange = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }));
  };
 
 
 
  const handleRequest = async () => {
    try {
      setFormData(prevData => ({ ...prevData, isLoading: true, successMessage:'', errorMessage:'' })); // Set loading state
      const response = await osirisService.fetchWSData(formData.username, formData.password, formData.limit, formData.item, formData.filter);
      if (response) {
        const responseString = JSON.stringify(response, null, 2);
        setFormData(prevData => ({ ...prevData, responseJSON: response, responseText: responseString, successMessage: t('api-dataRetrieved'), isLoading: false  })); // Clear error message
      }
    } catch (error) {
      console.error("Error in fetching data:", error);
      if (error.message === 'Unauthorized') {
        setFormData(prevData => ({ ...prevData, errorMessage: t('api-unauthorized'), isLoading: false}));
      } else {
        setFormData(prevData => ({ ...prevData, errorMessage: t('api-errorFetchingData', { errorMessage: error.message }) ,  isLoading: false}));
      }
    }
  }
 
  const getItemTypes = (t) => [
    { key: "1", value: "PHASES_IN_PROGRESS", label: t('api-phases') },
    { key: "2", value: "WORKSITES", label: t('api-worksite') },
  ];
  // Separate rendering logic for phases
  const renderPhaseShapes = () => {
    if (formData.responseJSON) {
      return formData.responseJSON.features.map((feature, index) => {
        // Extract coordinates from the feature geometry
        const coordinates = feature.geometry.coordinates[0][0]; // Access the coordinates nested within arrays
 
        // If coordinates are valid, continue with rendering the polygon
        if (coordinates && coordinates.length >= 3) { // At least 3 points required to form a polygon
          // Transform coordinates to Leaflet's format (reverse lat/lng and wrap in an array)
          const positions = coordinates.map(coord => [coord[1], coord[0]]);
 
          // Extract properties for popup content
          const { ID_WS, STATUS_FR, TYPE, IMPETRANT_FR, AUTO_START_DATE, AUTO_END_DATE } = feature.properties;
 
          // Build popup content
          const popupContent = `
            <div>
              <h2>${STATUS_FR}</h2>
              <p><strong>ID_WS:</strong> ${ID_WS}</p>
              <p><strong>Impétrant:</strong> ${IMPETRANT_FR}</p>
              <p><strong>Type:</strong> ${TYPE}</p>
              <p><strong>Début:</strong> ${AUTO_START_DATE}</p>
              <p><strong>Fin:</strong> ${AUTO_END_DATE}</p>
            </div>
          `;
 
          return (
            <Polygon key={index} positions={positions}>
              <Popup>
                <div dangerouslySetInnerHTML={{ __html: popupContent }} />
              </Popup>
            </Polygon>
          );
        } else {
          console.error("Invalid coordinates for polygon:", coordinates);
          return null;
        }
      });
    }
    return null;
  };
  // Separate rendering logic for phases
const renderWorksitesShapes = () => {
  if (formData.responseJSON) {
    return formData.responseJSON.features.map((feature, index) => {
      // Extract coordinates from the feature geometry
      const coordinates = feature.geometry.coordinates[0][0]; // Access the coordinates nested within arrays
 
      // If coordinates are valid, continue with rendering the polygon
      if (coordinates && coordinates.length >= 3) { // At least 3 points required to form a polygon
        // Transform coordinates to Leaflet's format (reverse lat/lng and wrap in an array)
        const positions = coordinates.map(coord => [coord[1], coord[0]]);
 
        // Extract properties for popup content
        const {NATURE_FR, ID_WS, PROCEDURE, GV_FR, IMP_FR } = feature.properties;
 
        // Build popup content
        const popupContent = `
        <div>
        <h2>${NATURE_FR}</h2>
        <p><strong>ID_WS:</strong> ${ID_WS}</p>
        <p><strong>IMPETRANT:</strong> ${IMP_FR}</p>
        <p><strong>PROCEDURE:</strong> ${PROCEDURE}</p>
        <p><strong>AV:</strong> ${GV_FR}</p>
    </div>
        `;
 
        return (
          <Polygon key={index} positions={positions}>
            <Popup>
              <div dangerouslySetInnerHTML={{ __html: popupContent }} />
            </Popup>
          </Polygon>
        );
      } else {
        console.error("Invalid coordinates for polygon:", coordinates);
        return null;
      }
    });
  }
  return null;
};
 
 
// Combine both rendering functions based on the selected item type
const renderShapes = () => {
  if (formData.item === 'PHASES_IN_PROGRESS') {
    return renderPhaseShapes();
  } else if (formData.item === 'WORKSITES') {
    return renderWorksitesShapes();
  }
};
 
 
  return (
    <div className="flex flex-row h-full w-full">
      <div className="flex w-3/4 stretch relative z-[1]" style={{ height: '70vh', width: '60vw' }} >
        <MapContainer center={mapCenter} zoom={zoomLevel} style={{ height: '100%', width: '100%' }} zoomControl={false}>
          <WMSTileLayer
            url="https://geoservices-urbis.irisnet.be/geoserver/ows?"
            layers="urbisFR"
            format="image/png"
            transparent={true}
            version="1.0.0"
            opacity={0.5}
          />
          <ZoomControl position="topright" />
          {renderShapes()}
        </MapContainer>
      </div>
      <div>
        <div className="flex w-1/4 flex-col items-center gap-4 p-4 h-full w-full">
          <div className="uppercase text-2xl font-bold text-blue-800 dark:!text-yellow-800">API DEMO</div>
 
          <div className="flex flex-col items-center gap-6 p-4  w-full">
            <CustomSelect
              label={t('api-itemType')}
              name="item"
              value={formData.item}
              onChange={(value) => handleInputChange('item', value)}
              setValidity={handleValidityChange}
              options={getItemTypes(t)}
            />
            <CustomTextInput
              label={t('api-amountItems')}
              name="limit"
              value={formData.limit}
              onChange={(value) => handleInputChange('limit', value)}
              setValidity={handleValidityChange}
              isNumber={true}
            />
             <CustomTextInput
              label={t('api-filter')}
              name="filter"
              value={formData.filter}
              onChange={(value) => handleInputChange('filter', value)}
              setValidity={handleValidityChange}
            />
          </div>
          <div className="flex flex-col items-center gap-6 p-4  w-full">
            <CustomTextInput
              label="Username"
              name="username"
              value={formData.username}
              onChange={(value) => handleInputChange('username', value)}
              setValidity={handleValidityChange}
            />
            <CustomTextInput
              label="Password"
              name="password"
              value={formData.password}
              onChange={(value) => handleInputChange('password', value)}
              setValidity={handleValidityChange}
              type="password"
            />
          </div>
          {formData.isLoading && (<Spinner color="green" />)}
          {formData.errorMessage && <Message type="error" message={formData.errorMessage} />}
          {formData.successMessage && <Message type="success" message={formData.successMessage} />}
          <Button size="md" disabled={!isFormValid} onClick={handleRequest}>{t('api-request')}</Button>
        </div>
 
 
      </div>
    </div>
  );
}
 
export default ApiDemo;