import React, { useState, useEffect } from 'react';
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  FolderIcon,
  LifebuoyIcon,
} from "@heroicons/react/24/outline";

import { getNavListRessourceItems, getNavListSupportItems, colors, login_url_v1, login_url_v2, signup_url_v1, signup_url_v2, osiris_at_a_glance_url, languages } from '../data/navigationData';
import LanguageDropdown from "./languageDropDown";
import { Link } from "react-router-dom";
import ThemeChanger from './darkSwitch';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../DarkThemeContext';
import { setCookie, getCookie } from '../handlers/cookieHandler';
import TopSignature from './topSignature';
import CustomUserTypeSwitch from './customUserTypeSwitch';
import { useUserType } from '.././UserContext';
import debounce from 'lodash/debounce'; // assuming lodash is available


function NavListMenu({ title, icon, sublist, closeMobileMenu }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // Function to handle menu item click
  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
    setIsMobileMenuOpen(false);
    closeMobileMenu();
  };


  const renderItems = sublist.map(({ icon, title, description, color, href, internal }, key) => {
    const content = (
      <MenuItem className="flex items-center gap-3 rounded-lg dark:hover:bg-blue-1000">
        <div className={`rounded-lg p-5 ${colors[color]}`}>
          {React.createElement(icon, {
            strokeWidth: 2,
            className: "h-6 w-6",
          })}
        </div>
        <div>
          <Typography
            variant="h6"
            className="flex items-center text-sm text-blue-800 dark:text-white"
          >
            {title}
          </Typography>
          <Typography variant="small" className="font-normal  text-blue-800 dark:text-white">
            {description}
          </Typography>
        </div>
      </MenuItem>
    );

    return internal ?
      (
        <Link to={href} key={key} onClick={handleMenuItemClick}>
          {content}
        </Link>
      ) :
      (
        <a href={href} target="_blank" rel="noopener" key={key} onClick={handleMenuItemClick}>
          {content}
        </a>
      );
  });



  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-normal text-blue-800 dark:text-white dark:hover:bg-blue-1000">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 dark:hover:bg-blue-1000 dark:text-white"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              {icon}
              {title}
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? "rotate-180" : ""
                  }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? "rotate-180" : ""
                  }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl  lg:block text-blue-800 dark:text-white dark:bg-blue-950 ">
          <ul className="grid grid-cols-3 gap-y-2 ">{renderItems}</ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen} >{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function NavList({ closeMobileNav }) {

  const { t } = useTranslation();
  const navListRessourceItems = getNavListRessourceItems(t);
  const navListSupportItems = getNavListSupportItems(t);
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography
        as="a"
        href="/#whatisosiris"
        variant="small"
        className="font-normal text-blue-800 dark:text-white"
        onClick={closeMobileNav}
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 dark:hover:bg-blue-1000 dark:text-white">
          {t('whatisosiris')}
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href="/#whyosiris"
        variant="small"
        className="font-normal text-blue-800 "
        onClick={closeMobileNav}
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 dark:hover:bg-blue-1000 dark:text-white">
          {t('whyosiris')}
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href={osiris_at_a_glance_url}
        variant="small"
        className="font-normal text-blue-800"
        onClick={closeMobileNav}
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 dark:hover:bg-blue-1000 dark:text-white">
          {t('osi-at-a-glance')}
        </ListItem>
      </Typography>
      <NavListMenu sublist={navListRessourceItems} title={t('resources')} icon={<FolderIcon className="h-[18px] w-[18px]" />} closeMobileMenu={closeMobileNav} />
      <NavListMenu sublist={navListSupportItems} title={t('support')} icon={<LifebuoyIcon className="h-[18px] w-[18px]" />} closeMobileMenu={closeMobileNav} />
    </List>
  );
}

export function NavbarWithMegaMenu() {



  const { theme } = useTheme();

  const { userType } = useUserType(); // Get the user type

  // Define the login URLs based on user type
  const loginUrl = userType === 'instEnterprise' ? login_url_v1 : login_url_v2;
  // Define the create account URLs based on user type
  const signUpUrl = userType === 'instEnterprise' ? signup_url_v1 : signup_url_v2;


  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState({ label: "Français", code: "fr" }); // default to French

  const closeMobileNav = () => {
    setOpenNav(false);
  }



  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode);

    // Set the language cookie
    setCookie('user_lang', languageCode, 365); // Storing for 365 days

    // Update the selected language state
    const newSelectedLanguage = languages.find(language => language.code === languageCode);
    setSelectedLanguage(newSelectedLanguage);
  };



  useEffect(() => {
    const getLanguageFromURL = () => {
      const params = new URLSearchParams(window.location.search);
      return params.get('lang');
    };

    const languageFromURL = getLanguageFromURL();
    const storedLangCode = getCookie("user_lang");
    const initialLanguage = languageFromURL || storedLangCode || 'fr'; // Default to 'fr' if no preference is found

    i18n.changeLanguage(initialLanguage);
    setCookie('user_lang', initialLanguage, 365);

    // Update the selected language state
    const newSelectedLanguage = languages.find(language => language.code === initialLanguage);
    if (newSelectedLanguage) {
      setSelectedLanguage(newSelectedLanguage);
    }

    // Remove the 'lang' query parameter from the URL
    const newURL = new URL(window.location.href);
    newURL.searchParams.delete('lang');
    window.history.replaceState({}, document.title, newURL.toString());
  }, [i18n]);


  // Add this useEffect to update the i18n instance language whenever selectedLanguage changes
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.code);
  }, [selectedLanguage, i18n]);


  const [lastScrollY, setLastScrollY] = useState(0);
  const [showTopSignature, setShowTopSignature] = useState(true);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const currentScrollY = window.scrollY;

      // Hide topSignature when scrolling down past 100px
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setShowTopSignature(false);
      }
      // Show topSignature only when the user has scrolled to the very top of the page
      else if (currentScrollY === 0) {
        setShowTopSignature(true);
      }

      // Update lastScrollY
      setLastScrollY(currentScrollY);
    }, 100); // Debounce for 100ms

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const navbarStyle = {
    paddingTop: showTopSignature ? '2.66667em' : '0'
  };

  const [openNav, setOpenNav] = React.useState(false);

  // It listens to changes in the openNav state and accordingly sets the overflow property of the body. This disables the scroll on the body. Hence focusing on the scroll on the navigation pane. 
  useEffect(() => {
    if (openNav) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openNav]);
  return (
    <>
      {showTopSignature && <TopSignature />}
      <Navbar style={navbarStyle} className="mx-auto max-w-screen-3xl px-6 py-2 dark:bg-blue-950 sticky top-0 z-50">
        <div className="flex items-center justify-between text-blue-900">
          <Typography
            as="a"
            href="/"
            variant="h4"
            className="mr-4 cursor-pointer py-1.5 xl:ml-2 dark:!text-yellow-800"
          >
            My Osiris
          </Typography>
          <div className="hidden items-center xl:block">
            <NavList closeMobileNav={closeMobileNav} />
          </div>
          <div className="flex gap-4">
            <LanguageDropdown
              languages={languages}
              currentLanguage={selectedLanguage.code}
              onLanguageChange={handleLanguageChange}
            />

            <ThemeChanger />


            <div className="hidden items-center xl:block p-1">
              <CustomUserTypeSwitch />
            </div>

            <div className="hidden items-center xl:flex gap-2">
              <a href={loginUrl} target="_blank" rel="noreferrer">
                <Button variant="outlined" color={theme === "dark" ? "white" : "blue"} size="sm">
                  {t('signin')}
                </Button>
              </a>
              <a href={signUpUrl} target="_blank" rel="noreferrer">
                <Button variant="filled" color={theme === "dark" ? "white" : "blue"} size="sm" className="dark:text-blue-900 dark:hover:bg-blue-gray-50 dark:focus:bg-blue-gray-50">
                  {t('signup')}
                </Button>
              </a>
            </div>
            <IconButton
              variant="text"
              color="blue-gray"
              className="xl:hidden"
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <XMarkIcon className="h-6 w-6 text-blue-900 dark:!text-yellow-800" strokeWidth={2} />
              ) : (
                <Bars3Icon className="h-6 w-6  text-blue-900 dark:!text-yellow-800" strokeWidth={2} />
              )}
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav} className="collapse-nav-menu" >
          <div className="xl:hidden ">
            <NavList closeMobileNav={closeMobileNav} />
            <div className="pb-4 xl:hidden">
              <CustomUserTypeSwitch />
            </div>
          </div>
          <div className="flex w-full flex-nowrap items-center gap-2 xl:hidden">
            <a href={loginUrl} target="_blank" rel="noreferrer">
              <Button variant="outlined" color={theme === "dark" ? "white" : "blue"} size="sm" fullWidth>
                {t('signin')}
              </Button>
            </a>
            <a href={signUpUrl} target="_blank" rel="noreferrer">
              <Button variant="filled" color={theme === "dark" ? "white" : "blue"} size="sm" fullWidth className="dark:text-blue-900 dark:hover:bg-blue-gray-50 dark:focus:bg-blue-gray-50">
                {t('signup')}
              </Button>
            </a>
          </div>
        </Collapse>
      </Navbar>
    </>
  );
}
