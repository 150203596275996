import React from "react";
import { useTranslation } from 'react-i18next';

import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  Typography,
} from "@material-tailwind/react";

function ApiTechnicalRoadMap() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-start gap-6">

      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('API Osiris - Roadmap')}
      </div>

      <Timeline>
        <TimelineItem className="h-28">
          <TimelineConnector className="!w-[78px]" />
          <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
            <TimelineIcon />
            <div className="flex flex-col gap-1">
              <Typography variant="h6" color="blue-gray">

                {t('api-technical-roadmap-0')}

              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                JANVIER 2024
              </Typography>
            </div>
          </TimelineHeader>
        </TimelineItem>
        <TimelineItem className="h-28">
          <TimelineConnector className="!w-[78px]" />
          <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
            <TimelineIcon />
            <div className="flex flex-col gap-1">
              <Typography variant="h6" color="blue-gray">
                {t('api-technical-roadmap-1')}
              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                FEVRIER 2024
              </Typography>
            </div>
          </TimelineHeader>
        </TimelineItem>
        <TimelineItem className="h-28">
          <TimelineConnector className="!w-[78px]" />
          <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
            <TimelineIcon />
            <div className="flex flex-col gap-1">
              <Typography variant="h6" color="blue-gray">
                {t('api-technical-roadmap-2')}
              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                AVRIL 2024
              </Typography>
            </div>
          </TimelineHeader>
        </TimelineItem>
        <TimelineItem className="h-28">
          <TimelineConnector className="!w-[78px]" />
          <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
            <TimelineIcon />
            <div className="flex flex-col gap-1">
              <Typography variant="h6" color="blue-gray">
                {t('api-technical-roadmap-3')}
              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                JUILLET 2024
              </Typography>
            </div>
          </TimelineHeader>
        </TimelineItem>
        <TimelineItem className="h-28">
          <TimelineConnector className="!w-[78px]" />
          <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
            <TimelineIcon />
            <div className="flex flex-col gap-1">
              <Typography variant="h6" color="blue-gray">
                {t('api-technical-roadmap-4')}
              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                SEPTEMBRE 2024
              </Typography>
            </div>
          </TimelineHeader>
        </TimelineItem>
        <TimelineItem className="h-28">
          <TimelineConnector className="!w-[78px]" />
          <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
            <TimelineIcon />
            <div className="flex flex-col gap-1">
              <Typography variant="h6" color="blue-gray">
                {t('api-technical-roadmap-5')}
              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                NOVEMBRE 2024
              </Typography>
            </div>
          </TimelineHeader>
        </TimelineItem>
        <TimelineItem className="h-28">
          <TimelineHeader className="relative rounded-xl border border-blue-gray-50 bg-white py-3 pl-4 pr-8 shadow-lg shadow-blue-gray-900/5">
            <TimelineIcon />
            <div className="flex flex-col gap-1">
              <Typography variant="h6" color="blue-gray">
                {t('api-technical-roadmap-6')}
              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                JANVIER 2025
              </Typography>
            </div>
          </TimelineHeader>
        </TimelineItem>
      </Timeline>
    </div>


  );
}

export default ApiTechnicalRoadMap;

