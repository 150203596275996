const StreetConfigurations = {
    streetConfiguration1: [
      { elementName: 'sidewalk', elementWidth: '1.0', iconIndex:0 },
      { elementName: 'carLane', elementWidth: '3.0', iconIndex: 1 },
      { elementName: 'parkingLane', elementWidth: '2.0', iconIndex: 1},
      { elementName: 'sidewalk', elementWidth: '1.0', iconIndex:0}
    ],
    streetConfiguration2: [
      { elementName: 'sidewalk', elementWidth: '1.0', iconIndex:0 },
      { elementName: 'parkingLane', elementWidth: '2.0', iconIndex:0 },
      { elementName: 'carLane', elementWidth: '3.0', iconIndex:0 },
      { elementName: 'carLane', elementWidth: '3.0', iconIndex: 1 },
      { elementName: 'parkingLane', elementWidth: '2.0', iconIndex: 1 },
      { elementName: 'sidewalk', elementWidth: '1.0', iconIndex:0 }
    ],
    streetConfiguration3: [
      { elementName: 'sidewalk', elementWidth: '1.0', iconIndex:0 },
      { elementName: 'parkingLane', elementWidth: '2.0', iconIndex:0 },
      { elementName: 'cycleLane', elementWidth: '1.5', iconIndex:0 },
      { elementName: 'carLane', elementWidth: '3.0', iconIndex:0 },
      { elementName: 'carLane', elementWidth: '3.0', iconIndex: 1 },
      { elementName: 'cycleLane', elementWidth: '1.5', iconIndex: 1 },
      { elementName: 'parkingLane', elementWidth: '2.0', iconIndex: 1 },
      { elementName: 'sidewalk', elementWidth: '1.0', iconIndex:0 }
    ],
    streetConfiguration4: [
      { elementName: 'sidewalk', elementWidth: '1.0', iconIndex:0 },
      { elementName: 'parkingLane', elementWidth: '2.0', iconIndex:0 },
      { elementName: 'busAndCycleLane', elementWidth: '3.0', iconIndex:0 },
      { elementName: 'carLane', elementWidth: '3.0', iconIndex:0 },
      { elementName: 'carLane', elementWidth: '3.0', iconIndex:0 },
      { elementName: 'tramLane', elementWidth: '2.5', iconIndex:0 },
      { elementName: 'tramLane', elementWidth: '2.5', iconIndex:0 },
      { elementName: 'carLane', elementWidth: '3.0', iconIndex: 1 },
      { elementName: 'carLane', elementWidth: '3.0', iconIndex: 1 },
      { elementName: 'busAndCycleLane', elementWidth: '3.0', iconIndex: 1 },
      { elementName: 'parkingLane', elementWidth: '2.0', iconIndex: 1 },
      { elementName: 'sidewalk', elementWidth: '1.0', iconIndex:0}
    ]
  };
  
  export default StreetConfigurations;