import React from "react";
import { useTranslation } from 'react-i18next';
import { Message } from "../message";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";


function ApiDocAccess() {
  const { t } = useTranslation();

  const demoMessage = (

    <div>
      {t('api-general-demo-1')} <a href="/osiris-api/demo" className="underline"> {t('api-general-demo-2')} <ArrowTopRightOnSquareIcon className="items-center h-4 v-4 inline-block" style={{ marginTop: '-2px' }} /></a>
      {t('api-general-demo-3')}
    </div>
  );
  return (
    <div className="flex flex-col justify-start gap-6 mr-8">

      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('api-general-credentials-title')}
      </div>
      <div className="text-lg text-gray-600 dark:text-gray-400">


        {t('api-general-credentials')}

        <div className="flex flex-col m-6 gap-4">

          <div> 1. {t('api-general-credentials-step-1-substep-1')} </div>
          <div> 2. {t('api-general-credentials-step-1-substep-2')} </div>
          <Message type="info" message={t('api-general-credentials-step-1-substep-3')} />
          <div>{t('api-general-credentials-step-2')}</div>
          <div>{t('api-general-credentials-step-3')}</div>
          <Message type="info" message={t('api-general-credentials-step-4')}/>
        </div>



      </div>
      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('api-general-demo-title')}
      </div>
      <Message type="doc" message={demoMessage} />



    </div>

  );
}

export default ApiDocAccess;
