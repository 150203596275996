import React, { useState} from "react";
import Container from "../components/container";
import { useTranslation } from 'react-i18next';
import { getProcedureTable } from "../data/procedureTypesData";
import AImg_nl from "../img/explanation/Type A - NL.svg";
import AImgMobile_nl from "../img/explanation/Mobile-TypeA-NL.svg";
import EImg_nl from "../img/explanation/Type E - NL.svg";
import EImgMobile_nl from "../img/explanation/Mobile-TypeE-NL.svg";
import DImg_nl from "../img/explanation/Type D - NL.svg";
import DImgMobile_nl from "../img/explanation/Mobile-TypeD-NL.svg";
import AImg_fr from "../img/explanation/Type A - FR.svg";
import AImgMobile_fr from "../img/explanation/Mobile-TypeA-FR.svg";
import EImg_fr from "../img/explanation/Type E - FR.svg";
import EImgMobile_fr from "../img/explanation/Mobile-TypeE-FR.svg";
import DImg_fr from "../img/explanation/Type D - FR.svg";
import DImgMobile_fr from "../img/explanation/Mobile-TypeD-FR.svg";
import AImg_en from "../img/explanation/Type A - EN.svg";
import AImgMobile_en from "../img/explanation/Mobile-TypeA-EN.svg";
import EImg_en from "../img/explanation/Type E - EN.svg";
import EImgMobile_en from "../img/explanation/Mobile-TypeE-EN.svg";
import DImg_en from "../img/explanation/Type D - EN.svg";
import DImgMobile_en from "../img/explanation/Mobile-TypeD-EN.svg";

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
} from "@material-tailwind/react";

function ExplanationPage() {
    const { t, i18n } = useTranslation();
    const [activeTab, setActiveTab] = useState(); // Initial state is 'procedure'

    const handleMoreInfoClick = (tabValue) => {
        setActiveTab(tabValue);
    };

    const handleBackClick = () => {
        setActiveTab(null); // Set back to null to show all cards
    };
    const isMobileDevice = () => {
        return (
            typeof window.orientation !== "undefined" ||
            navigator.userAgent.indexOf("IEMobile") !== -1
        );
    };
    const isPhone = isMobileDevice();

    const imageSources = {
        nl: {
            A: isPhone ? AImgMobile_nl : AImg_nl,
            E: isPhone ? EImgMobile_nl : EImg_nl,
            D: isPhone ? DImgMobile_nl : DImg_nl,
        },
        fr: {
            A: isPhone ? AImgMobile_fr : AImg_fr,
            E: isPhone ? EImgMobile_fr : EImg_fr,
            D: isPhone ? DImgMobile_fr : DImg_fr,
        },
         en: {
            A: isPhone ? AImgMobile_en : AImg_en,
            E: isPhone ? EImgMobile_en : EImg_en,
            D: isPhone ? DImgMobile_en : DImg_en,
        },
    };

    // Determine current language, default to French for English
    const currentLang = i18n.language;

    return (
        <>
            <Container className="flex flex-col gap-6">
                <div className="font-bold text-3xl text-left text-blue-900 dark:text-yellow-800">
                    {t('exp-title')}
                </div>
                <div className=" text-xl font-bold text-left text-blue-800 dark:text-yellow-800">
                    {t('exp-intro-title')}
                </div>


                <div className="text-sm text-left font-normal text-gray-700 dark:text-white">
                    {t('exp-intro-body')}
                </div>
                {activeTab ? (
                    // Show image when a tab is active
                    <div className="flex flex-col gap-4">
                        <Button onClick={handleBackClick}>
                            {t('exp-back')}
                        </Button>
                        <img
                            className="object-center"
                            src={imageSources[currentLang][activeTab]}
                            alt={`Procedure ${activeTab} steps`}
                        />
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {getProcedureTable(t).map((proc, index) => (
                            <Card key={index} className="flex flex-col justify-between">
                                <CardHeader
                                    floated={false}
                                    shadow={false}
                                    color="transparent"
                                    className="m-0 rounded rounded-t-lg rounded-b-none"
                                >
                                    <img src={proc.img} alt="procedure-drawing" />
                                </CardHeader>
                                <CardBody className="text-left gap-2">
                                    <div className=" font-bold text-xl text-blue-900  mb-2">
                                        {proc.title}
                                    </div>
                                    <ul>
                                        {proc.characteristics.map((characteristic, charIndex) => (
                                            <div key={charIndex} className="text-blue-gray-700 font-medium">
                                                <li>{characteristic}</li>
                                            </div>
                                        ))}
                                    
                                    </ul>
                                    <div  className="text-blue-gray-700 font-medium">
                                        {proc.needs}
                                    </div>
                                    <div className="text-blue-gray-700 font-medium">
                                        {proc.delay}
                                    </div>
                                    <div  className="text-blue-gray-700 font-medium">
                                        {proc.costs} <a href="/faq/fees/cost"><strong>FAQ</strong></a>
                                    </div>
                                </CardBody>

                                <CardFooter className="flex justify-center gap-7 pt-2">
                                    <Button onClick={() => handleMoreInfoClick(proc.tabValue)}>
                                        {t('exp-more-info')}
                                    </Button>
                                </CardFooter>
                            </Card>
                        ))}
                    </div>
                )}
            </Container>
        </>
    );
}

export default ExplanationPage;
