// CustomEmailInput.js
import React from 'react';
import { Checkbox } from "@material-tailwind/react";



import { useTranslation } from 'react-i18next';




const CustomCheckBox = ({ name, label1, label2, label3, setValidity }) => {

    const notice_link_map = {
        'en': "/pdf/PRIVACY_POLICY/Notice_information-Formation.pdf",
        'fr': "/pdf/PRIVACY_POLICY/Notice_information-Formation.pdf",
        'nl': "/pdf/PRIVACY_POLICY/Informatief_formulier-Opleiding_vert.pdf",
    };


    const { i18n } = useTranslation();
    const currentLang = i18n.language;
    const current_notice_link = notice_link_map[currentLang];



    const handleChange = (e) => {
        console.log(e.target.checked);
        setValidity(name, e.target.checked);
    };

    return (
        <div className="flex flex-row items-center">
            <Checkbox
                color='blue'
                name={name}
                label={label1}
                onChange={handleChange}
                className="dark:text-white"
            />
            <a target="_blank" rel="noreferrer" href={current_notice_link} className="font-medium text-blue-700 hover:text-blue-600 dark:text-white">
                    &nbsp;{label2}
            </a>
            &nbsp;{label3}
        </div>
    );
};

export default CustomCheckBox;
