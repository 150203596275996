import React from 'react';
import sidewalkImg from "../../img/move/sidewalk.jpg";
import parkingImg from "../../img/move/parking.jpg";
import busImg from "../../img/move/bus.jpg";
import streetImg from "../../img/move/street.jpg";
import cyclingImg from "../../img/move/cycling.jpg";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

import { useTranslation } from 'react-i18next';



export function CustomRoadPartsSelector({ roadParts, onSelect, setValidity }) {

  const { t } = useTranslation();

  const handleRoadPartSelection = (event,part) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedParts = roadParts.includes(part.label)
      ? roadParts.filter((selectedPart) => selectedPart !== part.label)
      : [...roadParts, part.label];
    onSelect(updatedParts);
    const valid = updatedParts.length > 0;
    
    console.log(updatedParts.length,updatedParts);
    setValidity('roadParts', valid);
  };


  return (
    <div className="flex flex-col gap-2">
      <div className="dark:text-white">  {t('cf-roadPartsTitle')} </div>
      <div className="flex items-center gap-1">
        {[
          { label: t('cf-roadPart-sidewalk') , img: sidewalkImg },
          { label: t('cf-roadPart-cyclingLane'), img: cyclingImg },
          { label: t('cf-roadPart-parking'), img: parkingImg },
          { label: t('cf-roadPart-busAndTram'), img: busImg },
          { label: t('cf-roadPart-street'), img: streetImg },
          
        ].map((part) => (
          <button
            key={part.label}
            className={`relative flex items-center justify-center w-30 h-40 overflow-hidden border-4 border-gray-300 transition-colors duration-300 ease-in-out ${roadParts.includes(part.label) ? 'bg-blue-500 text-white border-green-400' : 'bg-white text-gray-400'
              }`}
            onClick={(e) => handleRoadPartSelection(e,part)}
          >
          
            <img
              src={part.img}
              alt={part.label}
              className="w-auto h-full object-cover"
            />
            {roadParts.includes(part.label) && (
              <div className="absolute top-2 right-2 text-green-400">
                <CheckCircleIcon className="h-6 w-6" />
              </div>
            )}
          </button>
        ))}
      </div>
      {/* Display the selected road parts */}
      {roadParts.length > 0 &&
        <div className="mt-4  dark:text-white">
          <div className="dark:text-white"> 
             {t('cf-roadPart-selected')}
          </div>
          <ul>
            {roadParts.map((part, index) => (
              <li key={index}>{part}</li>
            ))}
          </ul>
        </div>
      }
    </div>
  );
}
