import React from "react";

import { useTranslation } from 'react-i18next';
import Container from "../components/container";


function Credits() {
  const { t } = useTranslation();

  return (
    <Container className="flex flex-col gap-6">
        <div className="mt-3 font-bold leading-snug text-blue-900 text-3xl dark:text-yellow-800">{t('footer-credits')} </div>

        <div> {t('credits-makina-1')} <a className="text-blue-800" href="https://makina-corpus.com/"  target="_blank" rel="noreferrer">Makina Corpus </a> {t('credits-makina-2')} </div>    
       
        <div> {t('credits-gate')}   <a   className="text-blue-800" href="https://www.gate-16.com/"  target="_blank" rel="noreferrer"> Gate-16</a>. </div>
     
        {t('credits-paradigm')}  
     
    </Container>
  );
}

export default Credits;
