import React from "react";

import { useTranslation } from 'react-i18next';
import Container from "../components/container";
import NOT_FOUND from  "../img/notfound.webp";

function NotFound() {
  const { t } = useTranslation();

  return (
    <Container className="flex flex-col items-center gap-6">
        <img className="max-w-[30%] object-cover object-center" src={NOT_FOUND} alt="not found page" loading="eager" placeholder="blur"/>
        <div className="text-5xl font-bold uppercase text-blue-900 dark:!text-yellow-800 mb-4"> {t('not_found_title')} </div>  
        <div className="text-xl font-bold text-blue-800 dark:!text-yellow-800 mb-4"> {t('not_found_desc')} </div>  
    </Container>
  );
}

export default NotFound;
