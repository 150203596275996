export const getTypeOfInterventions= (t) => [
  { key: "1", value: "Stationnement", label: t('cf-intType-parking')},
  { key: "2", value: "Conteneur", label: t('cf-intType-container')  },
  { key: "3", value: "Echaffaudage", label: t('cf-intType-scaffolding') },
  { key: "4", value: "Grue", label: t('cf-intType-crane') },
  { key: "5", value: "Déménagement", label: t('cf-intType-move') },
  { key: "6", value: "Autre", label: t('cf-intType-other') },
 ]
  
 

export const getTypesOfUser = (t) => [
  { key: "1", value: "Administrateur de voirie", label: t('cf-userType-administration')},
  { key: "2", value: "Entreprise non-institutionnelle", label:  t('cf-userType-company')},
  { key: "3", value: "Entreprise institutionnelle", label:  t('cf-userType-instCompany')}
]
