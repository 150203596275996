import React, { useState, useEffect } from 'react';
import { HorizontalLineSVG, ElevatedSVG, ScaffoldingSVG } from './svgComponents';

const StreetElement = ({ id, type, width, iconIndex, icons, isHovered, handleIsHovered, handleIsNotHoveredAnymore, zoomFactor }) => {
  const curbHeight = 10; // Adjust this as needed

  const [elementWidth, setElementWidth] = useState(width * zoomFactor);
  const [elementType, setElementType] = useState(type);
  const [elementIcon, setElementIcon] = useState(icons[iconIndex]);

  useEffect(() => {
    setElementWidth(width * zoomFactor);
    setElementType(type);
    setElementIcon(icons[iconIndex]);
    
    if (type === 'scaffolding') {
      setElementIcon(<div className="object-center flex justify-center"> <ScaffoldingSVG width={width} pedestrianPassage={false} /> </div>);
    }
    if (type === 'scaffoldingPedestrianTunnel') {
      setElementIcon(<div className="object-center flex justify-center"> <ScaffoldingSVG width={width} pedestrianPassage={true} /> </div>);
    }
    if (type === 'workzone') {
      setElementIcon(<div className="bg-blue-800 w-full h-20" style={{ backgroundImage: 'repeating-linear-gradient(to right, #1b3e9b, #1b3e9b 42px,  #fed325 42px, #fed325 84px)' }}></div>);
    }

  }, [type, width, iconIndex, icons, zoomFactor, isHovered]);




  return (
    <div>
      <div className="flex flex-col items-center">

        {elementType === 'sidewalk' || elementType === 'publicTransportStop' || elementType === 'middleBerm' || elementType === 'scaffolding' || elementType === 'scaffoldingPedestrianTunnel' || elementType === 'sidewalkWithContainer' ?
          <div className="grow w-full">
            <div className={`${isHovered ? ' border-4 border-green-300 rounded-lg' : ''}`} onMouseEnter={() => handleIsHovered(id)} onMouseLeave={() => handleIsNotHoveredAnymore(id)}>
              {elementIcon}
            </div>
          </div> :


          <div className="grow w-full" style={{ marginBottom: `-${curbHeight}px` }}>
            <div className={`${isHovered ? ' border-4 border-green-300 rounded-lg' : ''}`} onMouseEnter={() => handleIsHovered(id)} onMouseLeave={() => handleIsNotHoveredAnymore(id)}>



              {elementType === 'bypassCorridor' ? (
                <div className="relative flex justify-center items-center">
                  <div className="absolute left-0 bottom-0  w-1 bg-blue-800 " style={{ height: '80px' }} />
                  <div className="absolute left-1 bottom-0  w-1 bg-yellow-800 " style={{ height: '80px' }} />
                  <div className="absolute left-2 bottom-0  w-1 bg-blue-800 " style={{ height: '80px' }} />
                  {elementIcon}
                  <div className="absolute right-2 bottom-0  w-1 bg-blue-800 " style={{ height: '80px' }} />
                  <div className="absolute right-1 bottom-0  w-1 bg-yellow-800" style={{ height: '80px' }} />
                  <div className="absolute right-0 bottom-0  w-1 bg-blue-800" style={{ height: '80px' }} />
                </div>
              ) : (
                elementIcon
              )}
            </div>
          </div>
        }



        <div>
          {elementType === 'sidewalk' || elementType === 'publicTransportStop' || elementType === 'middleBerm' || elementType === 'scaffolding' || elementType === 'scaffoldingPedestrianTunnel' || elementType === 'sidewalkWithContainer' ?
            ElevatedSVG({ horizontalLineHeight: curbHeight, elementWidth: elementWidth }) :
            HorizontalLineSVG({ horizontalLineHeight: curbHeight, elementWidth: elementWidth })
          }
        </div>
      </div>
    </div>
  );
};

export default StreetElement;
