import {
  Card,
  CardBody,
  Button,
} from "@material-tailwind/react";
import {
  PhoneIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  ChatBubbleLeftRightIcon,
  BugAntIcon,
  CalendarIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";

export function CustomSpeedDial({showSpeedDialMessage}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

 const opening_guichet = process.env.REACT_APP_OPENING_GUICHET;
 const tel_guichet = process.env.REACT_APP_TEL_GUICHET;

  const [cardIsVisible, setCardIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);

  useEffect(() => {
     if (showSpeedDialMessage) {
         // Use a setTimeout to delay showing the card
      const timer = setTimeout(() => {
        setCardIsVisible(true);
      }, 3000); // 3 seconds delay
      // Clear the timer when the component unmounts (cleanup)
      return () => clearTimeout(timer);
    }
  }, [showSpeedDialMessage]);

  const LinkButton = ({ title, link, internal, icon, isPhone }) => {
    const isMobileDevice = () => {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
      );
    };

    const handleClick = (event) => {
      event.stopPropagation();
      if (isPhone) {
        if (isMobileDevice()) {
          window.location.href = `tel:${link}`;
        } else {
          setOpenPopover(true);
        }
      } else if (internal) {
        navigate(link);
      } else {
        window.open(link, '_blank', 'noopener noreferrer');
      }
      setOpen(false);
    };


    return (
      <>

        <Button
          variant="outlined"
          size="sm"
          className="mx-4 border-blue-800/10 bg-blue-800/10 text-blue-800 dark:hover:text-white flex gap-4 text-left items-center rounded text-xs normal-case sm:text-base dark:text-white dark:hover:bg-blue-900 dark:bg-blue-800"
          onClick={(e) => handleClick(e)}
        >
          {icon} {title}
        </Button>
      </>
    );
  }

  const handleDial = () => {
    setCardIsVisible(false);
    setOpen(!open);
    setOpenPopover(false);
  };


  return (
    <>

      <div className="fixed bottom-4 right-4 z-50 ">
        <div className="flex flex-row items-center gap-4">
          {cardIsVisible && (
            <div className=" card-bounce ml-4 bg-blue-800 dark:bg-yellow-800 font-bold text-white rounded p-4 shadow-2xl border border-5 border-white">
              {t('sd-hi')}
            </div>
          )}
          <div onClick={handleDial} className="bg-blue-800 dark:bg-yellow-800  text-white rounded-full shadow-2xl border border-5 border-white">
            <QuestionMarkCircleIcon className="h-12 w-12 transition-transform group-hover:rotate-45" />
          </div>
        </div>
      </div>

      {open &&

        <div className="custom-speeddial fixed bottom-20 right-4 z-40">
          <div className="mt-6 bg-white overflow-hidden rounded-lg shadow-lg gap-2">
            <div
              floated={false}
              shadow={false}
              className="m-0 rounded-none bg-blue-800  dark:bg-blue-950 p-4"
            >
              <div className="font-bold text-xl text-white mb-6">
                {t('sd-title')}
              </div>
              <div className="text-lg text-white mb-6">
                {t('sd-subtitle')}
              </div>
            </div>
            <div className="flex flex-col gap-4 text-sm sm:text-base my-4">

              <LinkButton icon={<PhoneIcon className="h-5 w-5" />} title={t('sd-callHelpDesk')} link={tel_guichet} internal={false} isPhone={true} />
              <LinkButton icon={<EnvelopeIcon className="h-5 w-5" />} title={t('sd-contactHelpDesk')} link="/help-inquiry" internal={true} isPhone={false} />
              <LinkButton icon={<CalendarIcon className="h-5 w-5" />} title={t('sd-meeting')} link="/simply-meet" internal={true} isPhone={false} />
              <LinkButton icon={<ChatBubbleLeftRightIcon className="h-5 w-5" />} title={t('sd-accessHelpDesk')} link="/helpdesk" internal={true} isPhone={false} />
              <LinkButton icon={<QuestionMarkCircleIcon className="h-5 w-5" />} title={t('sd-faq')} link="/faq" internal={true} isPhone={false} />
              <LinkButton icon={<BugAntIcon className="h-5 w-5" />} title={t('sd-reportbug')} link="/bug-report" internal={true} isPhone={false} />

            </div>
          </div>
        </div>

      }

      <div className="fixed bottom-20 right-4 z-40">
        {openPopover &&
          <div className="fixed bottom-20 right-4 z-40">
            <Card>
                <CardBody className="relative">
                  <div className="flex flex-row gap-4 items-center">
                    <PhoneIcon className="h-5 w-5" />
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row gap-2">{t('sd-phonepart')} <strong>{tel_guichet}</strong></div>
                      <div>{t('sd-phonepart2')} : {opening_guichet}</div>
                    </div>
                  </div>
                  <button onClick={() => setOpenPopover(false)} className="absolute top-0 right-0 p-2">
                    <XCircleIcon className="h-5 w-5" />
                  </button>
                </CardBody>
              </Card>
          </div>
        }
      </div>
    </>
  );
}