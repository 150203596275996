// Importing the ReactGA library and necessary dependencies
import ReactGA from 'react-ga4';
import { useState, useEffect } from 'react';
import { deleteCookie } from './cookieHandler';
import { useLocation } from 'react-router-dom';

// Custom hook to manage Google Analytics tracking state
function useGATracking (){

  const GA_tracking_code = process.env.REACT_APP_GA_TRACKING_CODE;

  // State to keep track of whether Google Analytics tracking is enabled
  const [isGATrackingEnabled, setIsGATrackingEnabled] = useState(false);

  // Function to initialize Google Analytics
  const initializeGA = () => {
    ReactGA.initialize(GA_tracking_code);
    setIsGATrackingEnabled(true);
  };

  // Function to deinitialize Google Analytics
  const deinitializeGA = () => {
    setIsGATrackingEnabled(false);
    // Deleting a specific cookie related to Google Analytics
    deleteCookie("_ga");
  };

  // useEffect to handle initialization when isGATrackingEnabled changes
  useEffect(() => {
    // Initializing Google Analytics when tracking is enabled
    if (isGATrackingEnabled) {
      ReactGA.initialize(GA_tracking_code);
    }
  }, [isGATrackingEnabled]);

  // Return state and functions for external use
  return { isGATrackingEnabled, initializeGA, deinitializeGA };
};

// This component contains a useEffect to track the initial page view when the page location changes.
export const InitialPageViewTracker = () => {
  // Accessing isGATrackingEnabled from the custom hook
  const { isGATrackingEnabled } = useGATracking();
  // Accessing the current location from React Router
  const location = useLocation();

  // useEffect to send a pageview event when isGATrackingEnabled or location changes
  useEffect(() => {
    // Sending a pageview event to Google Analytics when tracking is enabled
    if (isGATrackingEnabled) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [isGATrackingEnabled, location]);

  // This component doesn't render anything
  return null;
};

// Component to track page views with additional information like path and title
export const PageViewTracker = ({ path, title }) => {
  // Accessing isGATrackingEnabled from the custom hook
  const { isGATrackingEnabled } = useGATracking();

  // useEffect to send a pageview event with path and title when isGATrackingEnabled or props change
  useEffect(() => {
    // Sending a pageview event with additional information when tracking is enabled
    if (isGATrackingEnabled) {
      ReactGA.send({ hitType: "pageview", page: path, title: title });
    }
  }, [isGATrackingEnabled, path, title]);
};



export default useGATracking;