import React from "react";
import Container from "./container";
import SectionTitle from './sectionTitle';
import { useTranslation } from 'react-i18next';

const Stats = () => {
 const { t } = useTranslation();
  return (
    <>
      <Container className="flex flex-row flex-wrap lg:flex-nowrap ">
        <SectionTitle  title={"120 000 +"} posttitle={t('stat_road_usage')}>
         {t('stat_road_usage_desc')}
        </SectionTitle>
        <SectionTitle  title={"19 000 +"} posttitle={t('stat_subscribed_users')} >
        {t('stat_subscribed_users_desc')}
        </SectionTitle>
        <SectionTitle  title={"5 000 +"} posttitle={t('stat_subscribed_enterprises')} >
          {t('stat_subscribed_enterprises_desc')}
        </SectionTitle>
        <SectionTitle  title={"400+"} posttitle={t('stat_daily_users')} className="dark: text-white">
          {t('stat_daily_users_desc')} 
        </SectionTitle>
      </Container>
    </>
  );
};

export default Stats;


