import { useTranslation } from 'react-i18next';


 const PrivacyPolicy = () => {
  
  const { t } = useTranslation();
  
  return (
    <>
       <div className="flex flex-col gap-4 text-justify p-8">
          <div  className="text-3xl font-bold text-blue-900">{t('cookies-pol-title')} </div>
          <div className="text-xl font-bold text-blue-700">{t('cookies-pol-cookie-title')} </div>
          <div >{t('cookies-pol-cookie-desc')} </div>
          <div className="text-xl font-bold text-blue-700">{t('cookies-pol-what-title')} </div>
          <div>{t('cookies-pol-what-desc')} </div> 
          <div className="text-xl font-bold text-blue-700">{t('cookies-pol-func-title')} </div>
          <div>{t('cookies-pol-func-desc')} </div> 
          <div className="text-xl font-bold text-blue-700">{t('cookies-pol-ess-title')} </div>
          <div>{t('cookies-pol-ess-desc')} </div> 
          <div className="text-xl font-bold text-blue-700">{t('cookies-pol-perf-anal-title')} </div>
          <div>{t('cookies-pol-perf-anal-desc')} </div> 
          <div className="text-xl font-bold text-blue-700">{t('cookies-pol-mon-title')} </div>
          <div>{t('cookies-pol-mon-desc')} </div> 
      </div>
    </>
  );
}
export default PrivacyPolicy;
