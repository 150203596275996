// initialFormData.js
export const initialFormData = {
    firstName: '',
    lastName: '',
    phone: '',
    countryCode:'+32',
    email: '',
    emails: [],
    address: '',
    roadParts: [],
    files: [],
    message: '',
    companyName:'',
    interventionType:'',
    userType:'',
    amountOfUsers:'',
    alreadyAccount: 'non',
    length:'',
    trainings: [],
    versionUsed:'',
    B1:'',
    startDate: new Date(),
    endDate: new Date(),
};
