const mailService = async (email, name, recipient, subject, body, files) => {

    const formData = new FormData();
    formData.append("toEmail", recipient);
    formData.append("subject", subject);
    formData.append("text", body);
    formData.append("replyTo", email);
    formData.append("fromName", name);

    files.forEach((file) => {
        formData.append('files', file);
    });

    try {
    const response = await fetch('api/send-email', {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
    }

    const data = await response.json();
    console.log(data);
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        throw error;
    }

};
export default mailService;