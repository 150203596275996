import React, { useRef, useEffect, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke, Icon } from 'ol/style';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import roadDirectionGeoJSON from '../data/brr_segments_direction.json';

const DeviationMap = ({ arrowZoomLevel = 16 }) => {
  const mapElement = useRef();
  const [map, setMap] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(12); // Default zoom level

  const createStyleFunction = (feature) => {
    const coordinates = feature.getGeometry().getCoordinates();
    const auto_direction = feature.get('auto_direction');
    

    let styles = [
      new Style({
        stroke: new Stroke({
          color: auto_direction < 3 ? 'lightblue' : 'blue',
          width: 2,
        }),
      }),
    ];

    if (currentZoom < arrowZoomLevel || (auto_direction === 3)) {
      // Either zoom level is too low, or it's a bi-directional road (no arrows)
      return styles;
    }

    coordinates.forEach((linestring) => {
      for (let i = 0; i < linestring.length - 1; i++) {
        const start = linestring[i];
        const end = linestring[i + 1];
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = Math.atan2(dy, dx);
        const midX = (start[0] + end[0]) / 2;
        const midY = (start[1] + end[1]) / 2;

        let arrowRotation = rotation;
        if (auto_direction === 1 ) {
          // One-way from end to beginning
          arrowRotation += Math.PI;  // Reverse direction
        } 

        const arrowStyle = new Style({
          geometry: new Point([midX, midY]),
          image: new Icon({
            src: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20"><polygon points="0,0 20,10 0,20" fill="blue" /></svg>',
            scale: 0.5,
            rotation: arrowRotation, // Rotate to match the segment direction
            anchor: [0.5, 0.5],
          }),
        });

        styles.push(arrowStyle);
      }
    });

    return styles;
  };

  useEffect(() => {
    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(roadDirectionGeoJSON, {
        featureProjection: 'EPSG:3857',  // Use the appropriate projection
      }),
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: createStyleFunction,
    });

    const map = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: fromLonLat([4.3517, 50.8503]), // Centering on Brussels
        zoom: 12,
      }),
    });

    // Update current zoom level
    map.getView().on('change:resolution', () => {
      const zoom = map.getView().getZoom();
      setCurrentZoom(zoom);
    });

    setMap(map);

    // Clean up the map on unmount
    return () => {
      if (map) {
        map.setTarget(null);
      }
    };
  }, []);

  useEffect(() => {
    if (map) {
      map.getLayers().item(1).setStyle((feature) => createStyleFunction(feature));
    }
  }, [currentZoom]);

  return (
    <div
      ref={mapElement}
      style={{ width: '100%', height: '400px' }}
    ></div>
  );
};

export default DeviationMap;
