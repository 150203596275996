import { useTranslation } from 'react-i18next';
import ann_all_users_img from "../img/annex4/ann_all_users.png";
import ann_ped_users_img from "../img/annex4/ann_ped_users.png";
import ann_cycl_users_img from "../img/annex4/ann_cycl_users.png";
import ann_pmr_users_img from "../img/annex4/ann_pmr_users.png";

const BypassLane = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="flex flex-col gap-4 text-justify p-8">
                <div className="text-3xl font-bold text-blue-900">{t('legal-bypass-lane-title')} </div>
                <div className="text-xl font-bold text-blue-700">{t('legal-bypass-lane-subtitle1')} </div>
                <div >{t('legal-bypass-lane-p1-1')} </div>
                <div className="flex flex-col px-8 gap-2">
                    <div >1º {t('legal-bypass-lane-p1-2')} </div>
                    <div >2º {t('legal-bypass-lane-p1-3')} </div>
                    <div >3º {t('legal-bypass-lane-p1-4')} </div>
                    <div >4º {t('legal-bypass-lane-p1-5')} </div>
                    <div >5º {t('legal-bypass-lane-p1-6')} </div>
                    <div >6º {t('legal-bypass-lane-p1-7')} </div>
                    <div >7º {t('legal-bypass-lane-p1-8')} </div>
                    <div >8º {t('legal-bypass-lane-p1-9')} </div>
                    <div >9º {t('legal-bypass-lane-p1-10')} </div>
                    <div >10º {t('legal-bypass-lane-p1-11')} </div>
                    <div >11º {t('legal-bypass-lane-p1-12')} </div>
                </div>
                <div className="text-xl font-bold text-blue-700">{t('legal-bypass-lane-subtitle2')} </div>
                <div >{t('legal-bypass-lane-p2-1')} </div>
                <div className="flex flex-col px-8 gap-2">
                    <div >1º {t('legal-bypass-lane-p2-2')} </div>
                    <div >2º {t('legal-bypass-lane-p2-3')} </div>
                    <div >3º {t('legal-bypass-lane-p2-4')} </div>
                </div>
                <div className="text-xl font-bold text-blue-700">{t('legal-bypass-lane-subtitle3')} </div>
                <div className="font-bold">{t('legal-bypass-lane-p3-1')} </div>
                <div >{t('legal-bypass-lane-p3-2')} </div>
                <div >-{t('legal-bypass-lane-p3-3')} </div>
                <div >-{t('legal-bypass-lane-p3-4')} </div>
                <div className="text-xl font-bold text-blue-700">{t('legal-bypass-lane-subtitle4')} </div>
                <div className="font-bold"> {t('legal-bypass-lane-p4-1')} </div>
                <div >1º {t('legal-bypass-lane-p4-2')} </div>
                <div >2º {t('legal-bypass-lane-p4-3')} </div>
                <div className="flex flex-col px-8 gap-2">
                    <div >a) {t('legal-bypass-lane-p4-4')} </div>
                    <div >b) {t('legal-bypass-lane-p4-5')} </div>
                    <div >c) {t('legal-bypass-lane-p4-6')} </div>
                </div>
                <div className="text-xl font-bold text-blue-700">{t('legal-bypass-lane-subtitle5')} </div>
                <div >{t('legal-bypass-lane-p5-1')} </div>
                <div >1º {t('legal-bypass-lane-p5-2')} </div>
                <div >2º {t('legal-bypass-lane-p5-3')} </div>
                <div >3º {t('legal-bypass-lane-p5-4')} </div>
                <div >4º {t('legal-bypass-lane-p5-5')} </div>
                <div >5º {t('legal-bypass-lane-p5-6')} </div>
                <div >6º {t('legal-bypass-lane-p5-7')} </div>
                <div >{t('legal-bypass-lane-p5-8')} </div>
                <div className="text-xl font-bold text-blue-700">{t('legal-bypass-lane-subtitle6')} </div>
                <div >{t('legal-bypass-lane-p6-1')} </div>
                <div >{t('legal-bypass-lane-p6-2')} </div>
                <div className="text-xl font-bold text-blue-700">{t('legal-bypass-lane-subtitle7')} </div>
                <div >{t('legal-bypass-lane-p7-1')} </div>
                <div >{t('legal-bypass-lane-p7-2')} </div>
                <img src={ann_all_users_img} alt="all users"/>
                <div >{t('legal-bypass-lane-p7-3')} </div>
                <img src={ann_ped_users_img} alt="cyclists"/>
                <div >{t('legal-bypass-lane-p7-4')} </div>
                <img src={ann_cycl_users_img} alt="pedestirans"/>
                <div >{t('legal-bypass-lane-p7-5')} </div>
                <img src={ann_pmr_users_img} alt="reduced mobility users"/>
            </div>
        </>
    );
}
export default BypassLane;
