import Container from "./container";
import heroImg from "../img/home/hero.png";
import { login_url_v1, login_url_v2, signup_url_v1, signup_url_v2 } from '../data/navigationData';
import { useTranslation } from 'react-i18next';
import { Button } from "@material-tailwind/react";
import { useTheme } from '../DarkThemeContext';
import { useUserType } from '.././UserContext';

const Hero = () => {

  const { theme } = useTheme();
  const { t } = useTranslation();
  const navigate = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const { userType } = useUserType(); // Get the user type

  // Define the login URLs based on user type
  const loginUrl = userType === 'instEnterprise' ? login_url_v1 : login_url_v2;
  // Define the create account URLs based on user type
  const signUpUrl = userType === 'instEnterprise' ? signup_url_v1 : signup_url_v2;

  return (
    <>
      <Container className="flex flex-wrap">
        <div id="why" className="flex items-center w-full lg:w-1/2">
          <div className="max-w-2xl flex flex-col gap-8">
            <div className="text-4xl font-bold leading-snug tracking-tight text-blue-900 lg:text-4xl lg:leading-tight xl:text-6xl xl:leading-tight dark:text-white">
              {t('hero-title')}
            </div>
            <div className="py-5 text-xl leading-normal text-gray-700 lg:text-xl xl:text-2xl dark:text-gray-200">
              {t('hero-desc')}
            </div>
            <div className="flex flex-col justify-evenly space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row">
              <Button
                className="w-full dark:text-blue-800 dark:hover:bg-blue-gray-50"
                size="lg"
                color={theme === "dark" ? "white" : "blue"}
                onClick={() => navigate(loginUrl)}
              >
                {t('signin')}
              </Button>

              <Button
                className="w-full "
                size="lg"
                variant="outlined"
                color={theme === "dark" ? "white" : "blue"}
                onClick={() => navigate(signUpUrl)}
              >
                {t('signup')}
              </Button>
            </div>
          </div>
        </div>
        <div className="pl-4 flex items-center justify-center w-full lg:w-1/2">
          <div className="">
            <img
              className="h-100 w-full object-cover object-center"
              src={heroImg}
              loading="eager"
              placeholder="blur"
              alt="City under construction"
            />
          </div>
        </div>
      </Container>
    </>
  );
}
export default Hero;
