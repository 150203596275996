import React from "react";
import { useTranslation } from 'react-i18next';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import CodeSnippet from "../codeSnippet";
import { CopyButton } from "../copyButton";
import { Message } from "../message";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { CustomChip } from "../customChip";

function ApiUseCaseEndpoint() {
  const { t } = useTranslation();
  const data = [
    {
      label: "cURL",
      value: "curl",
      desc: <div className="mt-6">
        <CodeSnippet language="bash" code={`
          curl -X 'GET' \\
          'https://api.osiris.brussels/geoserver/ogc/features/v1/collections/api%3APHASES_IN_PROGRESS/items' \\
          -H 'accept: application/geo+json' \\
          -H 'Authorization: Basic base64encodedusernamepassword' \\
          --data-urlencode 'limit=10' \\
          --data-urlencode 'filter-lang=ecql-text' \\
          --data-urlencode 'filter=ID_WS=155059'          
        `} />
      </div>
    },
    {
      label: "Node.js",
      value: "node",
      desc: <div className="mt-6">
        <CodeSnippet language="javascript" code={`
const axios = require('axios');

const url = 'https://api.osiris.brussels/geoserver/ogc/features/v1/collections/api%3APHASES_IN_PROGRESS/items';
const credentials = Buffer.from(\`\${username}:\${password}\`).toString('base64');

try {
  const response = await axios.get(url, {
    params: {
      limit: 10,
      'filter-lang': 'ecql-text',
      filter: 'ID_WS=155059'
    },
    headers: {
      'Authorization': \`Basic \${credentials}\`,
      'accept': 'application/geo+json'
    }
  });
  console.log(response.data);
} catch (error) {
  console.error(error);
}
`} />

      </div>
    },
    {
      label: "Python",
      value: "python",
      desc: <div className="mt-6">
        <CodeSnippet language="python" code={`
         import requests

         url = 'https://api.osiris.brussels/geoserver/ogc/features/v1/collections/api%3APHASES_IN_PROGRESS/items'
         params = {
           'limit': 10,
           'filter-lang': 'ecql-text',
           'filter': 'ID_WS=155059'
         }
         headers = {
           'accept': 'application/geo+json',
           'Authorization': 'Basic base64encodedusernamepassword'
         }
         response = requests.get(url, params=params, headers=headers)
         print(response.json())
         
        `} />
      </div>
    },
    {
      label: "PHP",
      value: "php",
      desc: <div className="mt-6">
        <CodeSnippet language="php" code={`
          <?php
          $url = 'https://api.osiris.brussels/geoserver/ogc/features/v1/collections/api%3APHASES_IN_PROGRESS/items';
          $params = http_build_query(array(
            'limit' => 10,
            'filter-lang' => 'ecql-text',
            'filter' => 'ID_WS=155059'
          ));
          $headers = array(
            'accept: application/geo+json',
            'Authorization: Basic base64encodedusernamepassword'
          );
          $ch = curl_init();
          curl_setopt($ch, CURLOPT_URL, $url);
          curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
          curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
          curl_setopt($ch, CURLOPT_POSTFIELDS, $params);
          $response = curl_exec($ch);
          curl_close($ch);
          echo $response;
          ?>          
        `} />
      </div>
    },
    {
      label: "C#",
      value: "csharp",
      desc: <div className="mt-6">
        <CodeSnippet language="csharp" code={`
using System;
using System.Net.Http;

class Program
{
    static async System.Threading.Tasks.Task Main(string[] args)
    {
        using HttpClient client = new HttpClient();
        var url = "https://api.osiris.brussels/geoserver/ogc/features/v1/collections/api%3APHASES_IN_PROGRESS/items";
        var parameters = new System.Collections.Generic.Dictionary<string, string>
        {
            { "limit", "10" },
            { "filter-lang", "ecql-text" },
            { "filter", "ID_WS=155059" }
        };
        var response = await client.GetAsync(url + "?" + string.Join("&", parameters.Select(kvp => $"{kvp.Key}={kvp.Value}")));
        var responseString = await response.Content.ReadAsStringAsync();
        Console.WriteLine(responseString);
    }
}
`} />

      </div>
    },
  ];

  const moreParamMessage = (

    <div>
      {t('api-endpoint-step3-b6')} <a target="_blank" href="http://10.80.49.10:8080/geoserver/ogc/features/v1/openapi?f=text%2Fhtml" className="underline">  {t('api-endpoint-step3-b6-bis')} <ArrowTopRightOnSquareIcon className="items-center h-4 v-4 inline-block" style={{ marginTop: '-2px' }} /></a>
    </div>
  );

  return (
    <div className="flex flex-col justify-start gap-6">
      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('api-endpoint-title')}
      </div>
      {t('api-endpoint-getting-started-body')}
      <div className="text-l font-bold text-gray-700 dark:text-white">
        {t('api-endpoint-prerequisites-title')}
      </div>
      <ul className="ml-4">
        <li>&bull; {t('api-endpoint-prerequisites-b1')}</li>
        <li>&bull; {t('api-endpoint-prerequisites-b2')}</li>
      </ul>


      <div className="text-xl font-bold text-gray-700 dark:text-white">
        {t('api-endpoint-step1-title')}
      </div>
      <p> {t('api-endpoint-step1-b1')}</p>
      <p> {t('api-endpoint-step1-b2')}</p>
      <CopyButton text={"https://api.osiris.brussels/geoserver/ogc/features/v1/collections/"} />
      <p> {t('api-endpoint-step1-b3')}</p>
      <ul className="ml-4">
        <li>&bull; WORKSITES</li>
        <li>&bull; PHASES_IN_PROGRESS</li>
        <li>&bull; DOCUMENTS</li>
        <li>&bull; SURV_PROCEDURE</li>
        <li>&bull; CONSTATS</li>
      </ul>

      <div className="text-xl font-bold text-gray-700 dark:text-white">
        {t('api-endpoint-step2-title')}
      </div>
      <p> {t('api-endpoint-step2-b1')}</p>
      <div className="text-xl font-bold text-gray-700 dark:text-white">
        {t('api-endpoint-step3')}
      </div>
      <p> {t('api-endpoint-step3-b1')}</p>
      <p> {t('api-endpoint-step3-b2')}</p>
      <ul className="ml-4">
        <li>&bull;<CustomChip text="'limit'" /> : {t('api-endpoint-step3-b3')}</li>
        <li>&bull;<CustomChip text="'filter'" /> : {t('api-endpoint-step3-b4')}</li>
        <li>&bull;<CustomChip text="'filter-lang'" /> : {t('api-endpoint-step3-b5')} <CustomChip text="'ecql-text'" /> ) </li>
      </ul>
      <Message type="info" message={moreParamMessage} />
      <p>  {t('api-endpoint-step3-b7')}</p>
      <p>{t('api-endpoint-step3-b8')}</p>
      <ul className="ml-4">
        <li>&bull;<CustomChip text="'application/json'" /> : {t('api-endpoint-step3-b9')}</li>
        <li>&bull;<CustomChip text="'application/xml'" /> : {t('api-endpoint-step3-b10')}</li>
      </ul>
      <p> {t('api-endpoint-step3-b11')}</p>
      <p> {t('api-endpoint-step3-b12')}</p>


      <Tabs value="curl" className="max-w-[40rem]">
        <TabsHeader
          className="bg-transparent"
          indicatorProps={{
            className: "bg-gray-900/10 shadow-none !text-gray-900",
          }}
        >
          {data.map(({ label, value }) => (
            <Tab key={value} value={value}>
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>

      <div className="text-xl font-bold text-gray-700 dark:text-white">
        {t('api-endpoint-step4')}
      </div>
      <p> {t('api-endpoint-step4-b1')}</p>
      <Message type="success" message= {t('api-endpoint-step4-b2')}/>
      
    </div>
  );
}

export default ApiUseCaseEndpoint;
