
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from "@material-tailwind/react";
import { DayPicker } from "react-day-picker";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { frCA, nlBE, enGB } from 'date-fns/locale';

const CustomDatePicker = ({ onDateChange, label, setDate, initialDate, disabledDays, isPopoverOpen, togglePopover }) => {

  const { i18n } = useTranslation();

  useEffect(() => {
    if (initialDate) {
      onDateChange(initialDate);
    }
  }, [initialDate, onDateChange]);

  const handleDateSelect = (selectedDate) => {
    if (selectedDate) { // Check if a date is selected
      setDate(selectedDate);
    }
    togglePopover(false);
  }

  const formatDateToDDMMYYYY = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Convert month to 2 digits
    const day = date.getDate().toString().padStart(2, '0'); // Convert day to 2 digits
    return `${day}-${month}-${year}`;
  };

  // Function to map application language to date-fns locale
  const getLocale = (language) => {
    const languageLocaleMap = {
      'fr': frCA, // Assuming 'fr' is the language code for French in your app
      'nl': nlBE, // Assuming 'nl' is the language code for Dutch
      'en': enGB, // Assuming 'en' is the language code for English
      // Add other language mappings as needed
    };
    return languageLocaleMap[language] || enGB; // Default to English if no mapping found
  };

  return (

    <div>
      <Input
        color='blue-gray'
        className="dark:text-white"
        label={label}
        readOnly
        value={initialDate ? formatDateToDDMMYYYY(initialDate) : ""}
        onFocus={() => togglePopover(true)}
      />

      {isPopoverOpen && (
        <div className="dark:bg-blue-900 bg-white absolute mt-2 z-50 border rounded-lg shadow-lg p-2">
          <DayPicker
            mode="single"
            selected={initialDate}
            disabled={disabledDays}
            locale={getLocale(i18n.language)}
            onSelect={handleDateSelect}
            showOutsideDays
            className="border-0 "
            classNames={{
              caption: "flex justify-center py-2 mb-4 relative items-center",
              caption_label: "text-sm font-medium text-gray-900 dark:text-white",
              nav: "flex items-center",
              nav_button:
                "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
              nav_button_previous: "absolute left-1.5 dark:text-white",
              nav_button_next: "absolute right-1.5 dark:text-white",
              table: "w-full border-collapse ",
              head_row: "flex font-medium text-gray-900 dark:text-yellow-700",
              head_cell: "m-0.5 w-9 font-normal text-sm",
              row: "flex w-full mt-2",
              cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
              day: "h-9 w-9 p-0 font-normal dark:text-white",
              day_range_end: "day-range-end",
              day_selected:
                "rounded-md bg-blue-700 dark:bg-yellow-700 text-white hover:bg-blue-700 hover:text-white focus:bg-blue-700 focus:text-white dark:text-white",
              day_today: "rounded-md dark:bg-blue-gray-600 bg-gray-200",
              day_outside:
                "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
              day_disabled: "text-gray-500 opacity-50",
              day_hidden: "invisible",
            }}
            components={{
              IconLeft: ({ ...props }) => (
                <ChevronLeftIcon {...props} className="h-4 w-4 stroke-2" />
              ),
              IconRight: ({ ...props }) => (
                <ChevronRightIcon {...props} className="h-4 w-4 stroke-2" />
              ),
            }}
          />
        </div>
      )}
    </div>
  );
}
export default CustomDatePicker;

