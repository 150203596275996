import React, { useState } from 'react';
import {ArrowDownTrayIcon} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
            
const PrintButton = ({generatePDF, streetName, displayMessage, displayPopup, streetSizeBefore, streetSizeDuring}) => {
    const { t } = useTranslation();
    const [isClickedOnce, setIsClickedOnce] = useState(false);
   
    const handleClick = () => {
        if(streetName === t('mobility-cut-app-streetNameDefault')){
            displayMessage('error', t('mobility-cut-app-streetNameNotValid', {streetName: streetName}));
        }else if(streetSizeBefore !== streetSizeDuring){
            displayPopup('info', t('mobility-cut-app-streetSizeMismatch', {streetSizeBefore: streetSizeBefore, streetSizeDuring: streetSizeDuring}));
        } else {
            launchPDFGeneration();
        }        
    };

    const launchPDFGeneration = () => {
        if (isClickedOnce){
            setIsClickedOnce(false);
             generatePDF();
        }else {
            setIsClickedOnce(true);
            setTimeout(() => {
                setIsClickedOnce(false);
            }, 5000); 
        }
    };

    const handleIsHovered = () => {
        setIsClickedOnce(true);
    };

    const handleIsNotHoveredAnyMore = () => {
        setIsClickedOnce(false);
    };

    const icon = <ArrowDownTrayIcon className="w-6 h-6" />
  
    return (
        <button
            className={`rounded-full p-2 bg-blue-800 text-white ${isClickedOnce? 'border border-yellow-500 text-yellow-800' : ''}`}
            onClick={handleClick}
            onMouseEnter={handleIsHovered}
            onMouseLeave={handleIsNotHoveredAnyMore}
        >
            {isClickedOnce? (
                <span className="flex items-center">
                    <span className="mr-2">{icon}</span>
                    <span className="text-yellow-800 font-bold">{t('mobility-cut-app-printButton')}</span>
                </span>
            ) : (
                icon
            )}
        </button>
    );
};

export default PrintButton;