import { useState, useEffect } from 'react';
import { CheckIcon, ClipboardIcon } from '@heroicons/react/24/outline';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
const CodeSnippet = ({ language, code }) => {

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };


  return (
    <div className="flex flex-col bg-gray-800 rounded-md">
      {/* Header */}
      <div className="flex justify-between items-center p-2">
        <div className="text-sm text-gray-300 font-bold">{language}</div>
        <div onClick={handleCopy} className="flex flex-row text-sm text-gray-300 gap-2">
          {copied ? <CheckIcon className="h-5 w-5" /> : <ClipboardIcon className="h-5 w-5" />}
          {copied ? 'Copied!' : 'Copy code'}
        </div>
      </div>

      {/* Body (Code snippet) */}
      <div className="flex flex-row items-center justify-between">
        <SyntaxHighlighter language={"bash"} style={tomorrow} showLineNumbers={true}>
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default CodeSnippet;

