import moveImg from "../img/explanation/moving.png";
import scaffoldingImg from "../img/explanation/scaffolding.png";
import roadRepairImg from "../img/explanation/road_repair.png";

export const getProcedureTable = (t) => [
  { tabValue:"D", title: t('exp-D-title'), img : moveImg, characteristics : [t('exp-D-char-sup'),t('exp-D-impact'),t('exp-D-duration')], needs: t('exp-D-needs'),delay: t('exp-D-delay'),costs: t('exp-D-cost')},
  { tabValue:"E", title: t('exp-E-title'), img : scaffoldingImg, characteristics : [t('exp-E-char-sup'),t('exp-E-impact'),t('exp-E-duration')], needs: t('exp-E-needs'),delay: t('exp-E-delay'),costs:t('exp-E-cost')},
  { tabValue:"A", title: t('exp-A-title'), img : roadRepairImg, characteristics : [t('exp-A-char-sup')], needs: t('exp-A-needs'), delay: t('exp-A-delay'), costs: t('exp-A-cost')}
 ]
  
