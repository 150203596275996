const captchaService = async (recaptchaToken) => {
    if (!recaptchaToken) return { error: 'Invalid reCAPTCHA token' };

    try {
        const response = await fetch(`/api/captcha-validation?recaptchaToken=${recaptchaToken}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const result = await response.json();
        console.log('Google captcha response:', result);
        return result;
    } catch (error) {
        console.error('Error during reCAPTCHA verification:', error);
        return { error: 'Internal Server Error' };
    }
};

export default captchaService;
