import React, { useState } from "react";
import { Button, Dialog, Card, CardBody } from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';
import { Message } from '../message';

const PopupCancelValidate = ({ messageType, message, onValidate, onCancel}) => {
  const [open, setOpen] = useState(true);

  const { t } = useTranslation();

  const handleOpen = () => setOpen((cur) => !cur);

  const handleCancel = () => {
    onCancel();
  };

  const handleValidate = () => {
    onValidate();
  };

  return (
    <>
      <Dialog size="xs" open={open} handler={handleOpen} className="bg-transparent shadow-none">
        <Card className="mx-auto w-full max-w-[60rem]">
          <CardBody className="flex flex-col gap-4">
            <Message type={messageType} message={message} />
            <div className="flex flex-row gap-8 justify-evenly">

              <Button variant="outlined" color="blue" size="sm" onClick={handleCancel}>
                {t('mobility-cut-app-cancel')}
              </Button>
              <Button variant="filled" color="blue" size="sm" onClick={handleValidate}>
                {t('mobility-cut-app-validate')}
              </Button>

            </div>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
};

export default PopupCancelValidate;
