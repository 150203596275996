import Hero from '../components/hero';
import Benefits from '../components/benefits';
import Stats from '../components/stats';
import {getBenefits}   from '../data/benefitsData';
import SectionTitle from '../components/sectionTitle';
import { useTranslation } from 'react-i18next';
import Container from '../components/container';

const Home = () => {

  const { t } = useTranslation();
  const benefitOne = getBenefits(t)[0];
  const benefitTwo = getBenefits(t)[1];

  return (
    <>
    
    <Hero/>
    <div id="whatisosiris">
    <Container>
    <SectionTitle
        title={t('whatisosiris1-sectiontitle-title')}>
        {t('whatisosiris1-sectiontitle-body')}
    </SectionTitle>
    </Container>
    </div>
    <Stats/>
    
    <SectionTitle
        title={t('whatisosiris2-sectiontitle-title')}>
        {t('whatisosiris2-sectiontitle-body')}
    </SectionTitle>
    
    
    <div id="whyosiris">
    <Benefits data={benefitOne} />
    <Benefits data={benefitTwo} />
    </div>
    </>
  );
}
export default Home;