import React from "react";
import { useTranslation } from 'react-i18next';
import { Message } from "../message";

import apiArchiImg from "../../img/api/api_archi.png";
import apiGeoserver from "../../img/api/api_geoserver.png";
import apiOgcapi from "../../img/api/api_ogcapi.png";

function ApiTechnicalIntro() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-start gap-6">

<Message type="warning" message={t('api-general-warrning')} />

      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('api-technical-title')}
      </div>

      <p className="text-lg  text-gray-800 dark:text-gray-200">
        {t('api-technical-intro-1')}
      </p>

      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t('api-technical-subtitle-1')}
      </div>

      <p className="text-lg  text-gray-800 dark:text-gray-200">
        {t('api-technical-archi-1')}
      </p>

      <p className="text-lg  text-gray-800 dark:text-gray-200">
        {t('api-technical-archi-2')}
      </p>

      <Message type="warning" message={t('api-general-geoServer-warrning')} />

      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t('api-technical-schema-archi')}
      </div>

      <img
        className="max-w-[800px] object-cover object-center"
        src={apiArchiImg}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

      

      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t('api-technical-subtitle-2')}
      </div>

      <p className="text-lg  text-gray-800 dark:text-gray-200">
      {t('api-technical-data')}
      </p>


      <p className="text-lg  text-gray-800 dark:text-gray-200">
      {t('api-technical-data-1')}
      </p>

      <img
        className="max-w-[800px] object-cover object-center"
        src={apiGeoserver}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />

      <p className="text-lg  text-gray-800 dark:text-gray-200">
      {t('api-technical-data-2')}
      </p>

      <img
        className="max-w-[800px] object-cover object-center"
        src={apiOgcapi}
        loading="eager"
        placeholder="blur"
        alt="Archi"
      />



      <div className="text-xl font-bold text-blue-700 dark:text-white">
        {t('api-technical-subtitle-3')}
      </div>

      <p className="text-lg  text-gray-800 dark:text-gray-200">
      {t('api-technical-security')}
      </p>






    </div>
  );
}

export default ApiTechnicalIntro;