import React from "react";
import Container from "./container";

const SectionTitle = (props) => {
  return (
    <Container className={"flex w-full flex-col mt-4 items-center justify-justified text-justified"}>
      

      {props.title && (
        <h2 className="max-w-2xl mt-3 text-3xl text-justified font-bold leading-snug tracking-tight text-blue-900 lg:leading-tight lg:text-4xl dark:text-white">
          {props.title}
        </h2>
      )}

      {props.posttitle && (
        <div className="text-sm text-justified font-bold tracking-wider text-gray-600 uppercase dark:text-white">
          {props.posttitle}
        </div>
      )}

      {props.children && (
        <p className="max-w-2xl py-4 text-justified text-lg leading-normal text-gray-700 lg:text-xl xl:text-xl dark:text-white">
          {props.children}
        </p>
      )}
    </Container>
  );
}

export default SectionTitle;