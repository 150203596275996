const trainingVideos = [
  { 
    id: "0", 
    src: 
    {
    en: "/vids/training_fr/A_fr.mp4",
    fr: "/vids/training_fr/A_fr.mp4",
    nl: "/vids/training_nl/A_nl.mp4"
    },  
    title: {
      en: "Modules 1 and 4  Introduction", 
      fr: "Modules 1 et 4  Introduction", 
      nl: "Modulen 1 en 4  Inleiding"
    }
  },
  { 
    id: "1", 
    src: 
    {
    en: "/vids/training_fr/B_fr.mp4",
    fr: "/vids/training_fr/B_fr.mp4",
    nl: "/vids/training_nl/B_nl.mp4"
    },   
    title: {
      en: "Module 2 Before the worksite", 
      fr: "Module 2 Avant Chantier", 
      nl: "Module 2 Voor de Bouwplaats"
    }
  },
  { 
    id: "2", 
    src: 
    {
    en: "/vids/training_fr/C_fr.mp4",
    fr: "/vids/training_fr/C_fr.mp4",
    nl: "/vids/training_nl/C_nl.mp4"
    },   
    title: {
      en: "Modules 5 and 7  Worksite management in Osiris", 
      fr: "Modules 5 et 7  Gestion des chantiers en OSIRIS", 
      nl: "Modulen 5 en 7  Bouwplaatsen in OSIRIS"
    }
  },
  { 
    id: "3", 
    src: 
    {
    en: "/vids/training_fr/D_fr.mp4",
    fr: "/vids/training_fr/D_fr.mp4",
    nl: "/vids/training_nl/D_nl.mp4"
    },  
    title: {
      en: "Modules 6 and 11  During and after the worksite", 
      fr: "Modules 6 et 11  Pendant et Après chantiers", 
      nl: "Module 6 en 11 Tijdens en na de bouwplaats"
    }
  },
   { 
    id: "4", 
    src: 
    {
    en: "/vids/training_fr/E_fr.mp4",
    fr: "/vids/training_fr/E_fr.mp4",
    nl: "/vids/training_nl/E_nl.mp4"
    },  
    title: {
      en: "Module 13 Extensions OSIRIS", 
      fr: "Module 13 Extensions OSIRIS", 
      nl: "Module 13 Uitbreidingen OSIRIS"
    }
  }
];

export default trainingVideos;