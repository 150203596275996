import React from "react";
import Container from "../components/container";
import { useTranslation } from 'react-i18next';
import { pdfSlides } from "../data/slidesData";
import { pdfManuals } from "../data/manualData";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  List,
  ListItem,
} from "@material-tailwind/react";


import PDFImg from "../img/icons/pdfIcon.svg"

import { FilmIcon, BookOpenIcon, PresentationChartBarIcon } from "@heroicons/react/24/outline";
import Videos from "./videos";
import { useUserType } from '../UserContext';

function Manual() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const { userType } = useUserType();

  return (
    <>
      <Tabs value="vids">

        <TabsHeader className="my-10 mx-10 flex flex-col md:flex-row">
          <Tab value={"vids"}>
            <div className="flex gap-4 text-blue-800">
              <FilmIcon className="w-6 h-6" />
              <div>{t('manual-videos')}</div>
            </div>
          </Tab>
          <Tab value={"slides"}>
            <div className="flex  gap-4 text-blue-800">
              <PresentationChartBarIcon className="w-6 h-6" />
               <div>{t('manual-slides')}</div>
              </div>
          </Tab>
          <Tab value={"manual"}>
            <div className="flex gap-4 text-blue-800">
              <BookOpenIcon className="w-6 h-6" />
               <div>{t('manual-manual')}</div>
              </div>
          </Tab>

        </TabsHeader>

        <TabsBody>

          <TabPanel key={"slides"} value={"slides"}>
            <Container className="flex flex-col gap-6">
              <div className="text-xl text-blue-800 dark:text-white">
                {t('manual-slides-title')}
              </div>
              <div className="text-gray-700 dark:text-white">
                {t('manual-slides-word')}
              </div>
              <List>
                {pdfSlides.map((pdfFile, index) => {
                  // Check if the PDF file should be shown for the current user type
                  const shouldShowPdf = pdfFile.userType === 'all' ||
                    (pdfFile.userType === 'instEnterprise' && userType === 'instEnterprise') ||
                    (pdfFile.userType === 'non-instEnterprise' && userType !== 'instEnterprise');

                  return shouldShowPdf && (
                    <a key={index} href={pdfFile.url[currentLang]} download>
                      <ListItem className="dark:hover:bg-blue-1000">
                        <div className="flex flex-row gap-6 items-center dark:text-white">
                          <div className="w-10 h-10 flex-shrink-0">
                            <img src={PDFImg}  alt="PDF Icon" className="w-full h-full" />
                          </div>
                          <div className="flex flex-col gap-2">
                            <div  className="font-bold text-blue-700 dark:text-white">
                              Module {pdfFile.numb} : {pdfFile.name[currentLang]}
                            </div>
                          </div>
                        </div>
                      </ListItem>
                    </a>
                  );
                })}
              </List>
            </Container>
          </TabPanel>

          <TabPanel key={"manual"} value={"manual"}>
            <Container className="flex flex-col gap-6">
              <div className="text-xl text-blue-800 dark:text-white">
                {t('manual-manual-title')}
              </div>
              <div className="text-gray-700 dark:text-white">
                {t('manual-manual-word')}
              </div>
              <List>
                {pdfManuals.map((pdfFile, index) => {
                  return(
                    <a key={index} href={pdfFile.url[currentLang]} download>
                      <ListItem className="dark:hover:bg-blue-1000">
                        <div className="flex flex-row gap-6 items-center dark:text-white">
                        <div className="w-10 h-10 flex-shrink-0">
                            <img src={PDFImg}  alt="PDF Icon" className="w-full h-full" />
                          </div>
                          <div className="flex flex-col gap-2">
                            <div  className="font-bold text-blue-700 dark:text-white">
                              {pdfFile.name[currentLang]}
                            </div>
                          </div>
                        </div>
                      </ListItem>
                    </a>
                  );
                })}
              </List>
            </Container>
          </TabPanel>

          <TabPanel key={"vids"} value={"vids"}>
            {<Videos isTrainingMode={true} />}
          </TabPanel>

        </TabsBody>
      </Tabs>
    </>
  );
}

export default Manual;


