import React from 'react';
import { useTranslation } from 'react-i18next';




const  SimplyMeet = () => {

  
  const { i18n } = useTranslation();

  const currentLang = i18n.language;

  const meetingLinkMap = {
      'en': "https://app.simplymeet.me/osiris-fr?is_widget=1&view=compact",
      'fr': "https://app.simplymeet.me/osiris-fr?is_widget=1&view=compact",
      'nl': "https://app.simplymeet.me/osiris-nl?is_widget=1&view=compact",
    };

    const currentmeetingLink = meetingLinkMap[currentLang] ||"https://app.simplymeet.me/osiris-fr?is_widget=1&view=compact"

  return (
    <div className="osiris-iframe-container">
      <iframe 
        src= {currentmeetingLink}
        style={{ width: '100%', height: '650px' }} 
        title="Book a meeting with simply meet">
      </iframe>
    </div>
  );
};

export default SimplyMeet;
