import { NavbarWithMegaMenu } from './components/navBarWithMegaMenu';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { customTheme } from './data/themeData';
import { DarkThemeProvider } from './DarkThemeContext';
import { UserProvider } from './UserContext';
import ScrollToTop from './handlers/scrollToTop';

import { useState } from 'react';

import CookiesDialog from './components/cookiesDialog';
import { CustomSpeedDial } from './components/customSpeedDial';
import Footer from './components/footer';

import SimplyMeet from './pages/simplyMeet';
import Credits from './pages/credits';
import Legal from './pages/legal';
import BypassLane from './pages/bypassLane';
import Manual from './pages/manual';
import Faq from './pages/faq';
import Home from './pages/home';
import Municipalities from './pages/municipalities';
import PrivacyPolicy from './pages/privacypolicy';
import HelpDesk from './pages/helpDesk';
import Training from './pages/training';
import Releases from './pages/releases';
import Videos from './pages/videos';
import ContactForm from './pages/contactForm';
import NotFound from './pages/notfound';
import Api from './pages/api';
import Map from './pages/map';
import ExplanationPage from './pages/explanation';
import DeviationMap from './pages/deviationMap';
import MobilityCutApp from './pages/mobilityCutApp';
import AccessibilityCharter from './pages/accessibilityCharter';
import ApiDemo from "./components/api/apiDemo";
import ApiTechnicalIntro from "./components/api/apiTechnicalIntro";
import ApiDocAccess from "./components/api/apiDocAccess";
import ApiKeys from "./components/api/apiKeys";
import ApiTermsOfUse from "./components/api/apiTermsOfUse";
import ApiGeneralPresentation from "./components/api/apiGeneralPresentation";
import ApiUseCaseQGIS from "./components/api/apiUseCaseQGIS";
import ApiUseCaseEndpoint from "./components/api/apiUseCaseEndpoint";
import ApiTechnicalDataModels from "./components/api/apiTechnicalDataModels";
import ApiTechnicalGeoserver from "./components/api/apiTechnicalGeoserver";
import ApiTechnicalOGC from "./components/api/apiTechnicalOGC";
import ApiTechnicalRoadMap from "./components/api/apiTechnicalRoadMap";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderContext";


// Importing the useGATracking hook 
import useGATracking, { InitialPageViewTracker } from './handlers/GoogleAnalyticsHandler';

// Import google captcha provider and site key from global variables
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';




function App() {

  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  // State to control the visibility of the CookiesDialog
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  // State to control the visibility of the Speeddial message
  const [showSpeedDialMessage, setShowSpeedDialMessage] = useState(false);
  // Gets the functions from the GA handler and pass them down in the Cookies Dialog
  const { initializeGA, deinitializeGA } = useGATracking();

  return (

    <Router>
      <Auth0ProviderWithNavigate>
        <InitialPageViewTracker />
        <ScrollToTop />
        <UserProvider>
          <DarkThemeProvider>
            <ThemeProvider value={customTheme}>
              <NavbarWithMegaMenu />
              <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
                <Routes>
                  <Route path="/mobility-cut-app" element={<MobilityCutApp />} />
                  <Route path="/osiris-at-a-glance" element={<ExplanationPage />} />
                  <Route path="/map" element={<Map />} />
                  <Route path="/manual" element={<Manual />} />
                  <Route path="/training" element={<Training />} />
                  <Route path="/releases" element={<Releases />} />
                  <Route path="/deviation-map" element={<DeviationMap />} />
                  <Route path="/credits" element={<Credits />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/bypass-lane" element={<BypassLane />} />
                  <Route path="/faq/:sectionValue/:faqValue" element={<Faq />} />
                  <Route path="/faq/:sectionValue" element={<Faq />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/accessibility-charter" element={<AccessibilityCharter />} />
                  <Route path="/simply-meet" element={<SimplyMeet />} />
                  <Route path="/legal" element={<Legal />} />
                  <Route path="/tutorials" element={<Videos isTrainingMode={false} />} />
                  <Route path="/procedures" element={<Municipalities />} />
                  <Route path="/helpdesk" element={<HelpDesk />} />
                  <Route path="/move" element={<ContactForm formType="moveform" />} />
                  <Route path="/bug-report" element={<ContactForm formType="bug"/>} />
                  <Route path="/request-training" element={<ContactForm formType="trainingRequest"/>} />
                  <Route path="/help-inquiry" element={<ContactForm formType="inquiry" userType="citizen" />} />
                  <Route path="/help-assistance" element={<ContactForm formType="assistance" userType="citizen" />} />
                  <Route path="/osiris-api" element={<Api content={ApiGeneralPresentation} />} />
                  <Route path="/osiris-api/demo" element={<Api content={ApiDemo} />} />
                  <Route path="/osiris-api/technical-intro" element={<Api content={ApiTechnicalIntro} />} />
                  <Route path="/osiris-api/technical-geoserver" element={<Api content={ApiTechnicalGeoserver} />} />
                  <Route path="/osiris-api/technical-ogc" element={<Api content={ApiTechnicalOGC} />} />
                  <Route path="/osiris-api/technical-data-models" element={<Api content={ApiTechnicalDataModels} />} />
                  <Route path="/osiris-api/technical-roadmap" element={<Api content={ApiTechnicalRoadMap} />} />
                  <Route path="/osiris-api/access" element={<Api content={ApiDocAccess} />} />
                  <Route path="/osiris-api/terms-of-use" element={<Api content={ApiTermsOfUse} />} />
                  <Route path="/osiris-api/use-case-qgis" element={<Api content={ApiUseCaseQGIS} />} />
                  <Route path="/osiris-api/use-case-endpoint" element={<Api content={ApiUseCaseEndpoint} />} />
                  <Route path="/osiris-api/keys" element={<Api content={ApiKeys} />} />
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<NotFound />} />{/* This is the 404 route */}
                </Routes>
              </GoogleReCaptchaProvider>
              <Footer showCookieBanner={showCookieBanner} setShowCookieBanner={setShowCookieBanner} />
              <CookiesDialog showCookieBanner={showCookieBanner} setShowCookieBanner={setShowCookieBanner} setShowSpeedDialMessage={setShowSpeedDialMessage} initializeGA={initializeGA} deinitializeGA={deinitializeGA} />
              {<CustomSpeedDial showSpeedDialMessage={showSpeedDialMessage} />}
            </ThemeProvider>
          </DarkThemeProvider>
        </UserProvider>
      </Auth0ProviderWithNavigate>
    </Router >

  );
}

export default App;
