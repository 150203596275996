import React, { useState, useEffect } from 'react';

const StreetElementMeasurement = ({ elementType, label, width, zoomFactor }) => {
  const verticalDashedLineWidth = 1;
  const [elementWidth, setElementWidth] = useState(width * zoomFactor - verticalDashedLineWidth*1);


  useEffect(() => {
    setElementWidth(width * zoomFactor - verticalDashedLineWidth*1);
  }, [width,zoomFactor]);

  const verticalDashedLineSVG = (
    <svg width={verticalDashedLineWidth} height="50" xmlns="http://www.w3.org/2000/svg">
      <line x1="0" y1="0" x2="0" y2="50" stroke="gray" strokeWidth="2" strokeDasharray="2" />
    </svg>
  );

  const doubleArrowSVG = (
    <svg width={elementWidth} height="20" xmlns="http://www.w3.org/2000/svg">
      <polygon points={`0,10 5,5 5,15 0,10`} fill="gray" />
      <line x1="5" y1="10" x2={elementWidth - 5} y2="10" stroke="gray" strokeWidth="1" />
      <polygon points={`${elementWidth},10  ${elementWidth - 5},5 ${elementWidth - 5},15 ${elementWidth},10`} fill="gray" />
    </svg>
  );

  return (
    <div className="flex flex-row">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="flex flex-col">
            {doubleArrowSVG}
            <div className="flex justify-center" style={{ width: elementWidth }}>
              {elementType === 'middleBerm' ? null : width}</div>
            <div className="flex justify-center text-center" style={{ maxWidth: elementWidth }}>
              {elementType === 'middleBerm' ? null : label} </div>
          </div>
          {verticalDashedLineSVG}
        </div>
      </div>
    </div>
  );
};

export default StreetElementMeasurement;


