import React, { useState, useEffect } from "react";
import { Button, Dialog, Card, CardBody, Input } from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';

const EditableTextDiv = ({ label, changeText }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(label); // State to manage input value

  const { t } = useTranslation();

  // Sync inputValue with label prop whenever label changes
  useEffect(() => {
    setInputValue(label);
  }, [label]);

  const handleOpen = () => setOpen((cur) => !cur);

  const handleInputChange = (e) => setInputValue(e.target.value); // Update input value

  const handleCancel = () => {
    handleOpen(); // Close the dialog
  };

  const handleValidate = () => {
    changeText(inputValue); // Pass the new text to the parent component
    handleOpen(); // Close the dialog
  };

  return (
    <>
      <div onClick={handleOpen}>{label}</div>
      <Dialog size="xs" open={open} handler={handleOpen} className="bg-transparent shadow-none">
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            <Input
              value={inputValue}
              onChange={handleInputChange} // Update input value on change
            />
            <div className="flex flex-row gap-8 justify-evenly">

              <Button variant="outlined" color="blue" size="sm" onClick={handleCancel}>
                {t('mobility-cut-app-cancel')}
              </Button>
              <Button variant="filled" color="blue" size="sm" onClick={handleValidate}>
                {t('mobility-cut-app-validate')}
              </Button>

            </div>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
};

export default EditableTextDiv;
