import React, { useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

import CustomTextInput from "./CustomTextInput";

export function CustomPhoneInput({ name, label, countryCode, phone, onCountryCodeChange, onPhoneChange, setValidity }) {

  const [maxDigits, setMaxDigits] = useState(10); // Default to 10 digits for Belgium (including the 0)


  const countries = [
    { name: 'Austria', code: 'AT', countryCallingCode: '+43', maxDigits: 14 },
    { name: 'Belgium', code: 'BE', countryCallingCode: '+32', maxDigits: 10 },
    { name: 'Bulgaria', code: 'BG', countryCallingCode: '+359', maxDigits: 10 },
    { name: 'Croatia', code: 'HR', countryCallingCode: '+385', maxDigits: 10 },
    { name: 'Cyprus', code: 'CY', countryCallingCode: '+357', maxDigits: 9 },
    { name: 'Czech Republic', code: 'CZ', countryCallingCode: '+420', maxDigits: 10 },
    { name: 'Denmark', code: 'DK', countryCallingCode: '+45', maxDigits: 9 },
    { name: 'Estonia', code: 'EE', countryCallingCode: '+372', maxDigits: 8 },
    { name: 'Finland', code: 'FI', countryCallingCode: '+358', maxDigits: 13 },
    { name: 'France', code: 'FR', countryCallingCode: '+33', maxDigits: 10 },
    { name: 'Germany', code: 'DE', countryCallingCode: '+49', maxDigits: 12 },
    { name: 'Greece', code: 'GR', countryCallingCode: '+30', maxDigits: 11 },
    { name: 'Hungary', code: 'HU', countryCallingCode: '+36', maxDigits: 10 },
    { name: 'Iceland', code: 'IS', countryCallingCode: '+354', maxDigits: 7 },
    { name: 'Ireland', code: 'IE', countryCallingCode: '+353', maxDigits: 10 },
    { name: 'Italy', code: 'IT', countryCallingCode: '+39', maxDigits: 11 },
    { name: 'Latvia', code: 'LV', countryCallingCode: '+371', maxDigits: 9 },
    { name: 'Lithuania', code: 'LT', countryCallingCode: '+370', maxDigits: 9 },
    { name: 'Luxembourg', code: 'LU', countryCallingCode: '+352', maxDigits: 10 },
    { name: 'Malta', code: 'MT', countryCallingCode: '+356', maxDigits: 9 },
    { name: 'Netherlands', code: 'NL', countryCallingCode: '+31', maxDigits: 10 },
    { name: 'Norway', code: 'NO', countryCallingCode: '+47', maxDigits: 9 },
    { name: 'Poland', code: 'PL', countryCallingCode: '+48', maxDigits: 10 },
    { name: 'Portugal', code: 'PT', countryCallingCode: '+351', maxDigits: 10 },
    { name: 'Romania', code: 'RO', countryCallingCode: '+40', maxDigits: 11 },
    { name: 'Slovakia', code: 'SK', countryCallingCode: '+421', maxDigits: 10 },
    { name: 'Slovenia', code: 'SI', countryCallingCode: '+386', maxDigits: 9 },
    { name: 'Spain', code: 'ES', countryCallingCode: '+34', maxDigits: 10 },
    { name: 'Sweden', code: 'SE', countryCallingCode: '+46', maxDigits: 11 },
    { name: 'Switzerland', code: 'CH', countryCallingCode: '+41', maxDigits: 11 },
    { name: 'United Kingdom', code: 'GB', countryCallingCode: '+44', maxDigits: 12 }
  ];

  // Call this function when a new country is selected from the dropdown
  const handleCountryCodeChange = (newCountryCode) => {
    onCountryCodeChange(newCountryCode);
    const selectedCountry = countries.find(c => c.countryCallingCode === countryCode);
    if (selectedCountry) {
      setMaxDigits(selectedCountry.maxDigits);
    }
  };

  return (
    <div className="my-4 flex items-center gap-4">
      <Menu placement="bottom-start">
        <MenuHandler>
          <Button
            ripple={false}
            variant="text"
            color="blue-gray"
            className="flex-shrink-0 h-10 items-center rounded-xl border border-blue-gray-200 bg-blue-gray-500/10 pl-3 pr-6 dark:active:border-blue-gray-500 dark:peer-focus:border-blue-gray-500 dark:text-white"
          >
            {countryCode}
          </Button>
        </MenuHandler>
        <MenuList className="max-h-[20rem] max-w-[18rem]">
          {countries.map((country, index) => (
            <MenuItem
              key={country.name}
              value={country.name}
              className="flex items-center gap-2"
              onClick={() => handleCountryCodeChange(country.countryCallingCode)}
            >
              {country.name} <span className="ml-auto">{country.countryCallingCode}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <CustomTextInput
        label={label}
        name={name}
        value={phone}
        autoComplete="tel"
        onChange={onPhoneChange}
        setValidity={setValidity}
        isNumber={true}
      />
    </div>
  );
}

