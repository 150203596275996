/* Structuring the Text

    Paragraphs: Use <p> tags to create paragraphs for separating different sections of your FAQ content, like the question, answer, or any additional notes.

    New Lines: For a new line within the same paragraph, you can use <br /> tags. This is useful for breaking up long sentences or separating points within an answer.

    Indentation: For indentation, you can use CSS. Apply a class to the HTML element and use the padding-left or margin-left properties in your CSS.

    List Formatting: If you have lists within your FAQs, use <ul> for unordered lists and <li> for list items. For ordered lists, use <ol>.

    Headings: Utilize <h1>, <h2>, etc., for headings and subheadings to structure the content clearly. For instance, the question can be a subheading.

    Bold and Italics: Use <strong> for bold and <em> for italicized text to emphasize certain parts of your FAQ content. */



const faqData = [

   {
      topic: "general",
      label: {
         en: "General information",
         nl: "Algemene informatie",
         fr: "Informations générales",
      },
      faqs: [
         {
            value: "diff-auth-agreement",
            question: {
               en: 'What is the difference between an authorization, an agreement, and a waiver?',
               nl: 'Wat is het verschil tussen een autorisatie, een overeenkomst en een ontheffing?',
               fr: 'Quel est la différence entre une autorisation, un accord et une dispense ?'
            }, answer: {
               en: '<p>Authorization is a validation from the Administrator after a complete examination of the request. This type of request generally concerns long-term construction sites or those impacting mobility.</p><p> An agreement is more easily granted as soon as the applicant undertakes to comply with certain conditions (derogations) to escape the ordinary treatment of the Administrator. It often involves short-term construction sites related to parking or sidewalk occupation.</p><p> The exemption is extremely restrictive. It only concerns the use of parking or sidewalk for a maximum of 24 hours and less than 50m².</p>',
               nl: '<p>De vergunning is een goedkeuring van de beheerder na volledige beoordeling van de aanvraag. Dit type aanvraag heeft meestal betrekking op langdurige bouwplaatsen of bouwplaatsen die de mobiliteit beïnvloeden.</p><p> Een vergunning wordt gemakkelijker verleend zodra de aanvrager instemt met het naleven van bepaalde voorwaarden (ontheffingen) om aan de gewone behandeling van de beheerder te ontsnappen. Het betreft vaak bouwplaatsen van korte duur met betrekking tot het bezetten van parkeerplaatsen of trottoirs.</p><p> De vrijstelling is uiterst beperkt. Het betreft alleen het gebruik van parkeerplaatsen of trottoirs gedurende maximaal 24 uur en minder dan 50 m².</p>',
               fr: '<p>L’autorisation est une validation de l‘Administrateur après l’examen complet de la demande. Ce type de demande concerne généralement des chantiers de longue durée ou impactant la mobilité.</p><p> Un accord est donné plus facilement à partir du moment où le demandeur s’engage à respecter certaines conditions (dérogations) pour échapper au traitement ordinaire de l’Administrateur. Il s’agit souvent de chantier de courte durée concernant l’occupation du stationnement ou du trottoir.</p><p> La dispense est extrêmement restrictive. Elle ne concerne qu’une utilisation du stationnement ou du trottoir de maximum 24 h et de moins de 50m².</p>'
            }
         },
         {
            value: "approver",
            question: {
               en: 'Who approves requests on OSIRIS?',
               nl: 'Wie keurt aanvragen op OSIRIS goed?',
               fr: 'Qui valide les demandes sur OSIRIS ?'
            }, answer: {
               en: '<p>Requests are analyzed and, if necessary, validated by one or more Road Administrators via the platform. Depending on the cases, other actors may be involved in the decision.<p>',
               nl: '<p>De aanvragen worden geanalyseerd en indien nodig goedgekeurd door een of meerdere Wegbeheerders via het platform. Afhankelijk van de situatie kunnen ook andere actoren betrokken zijn bij de beslissing.<p>',
               fr: '<p>Les demandes sont analysées et si besoin validées par un ou plusieurs Administrateurs de Voirie via la plateforme. Selon les cas, d’autres acteurs peuvent être impliqués dans la décision.<p>'
            }
         },
         {
            value: "physical-registration",
            question: {
               en: 'Can I go to a physical counter to submit my occupancy declaration?',
               nl: 'Kan ik naar een fysiek loket gaan om mijn weginnameverklaring in te dienen?',
               fr: 'Puis-je me rendre à un guichet physique pour faire ma déclaration d’occupation ?'
            }, answer: {
               en: '<p>Yes, but by <a href="/simply-meet"><strong>appointment</strong></a>. In case of impossibility to carry out computer procedures, we invite you to contact the OSIRIS support desk via 02/204.24.96.</p>',
               nl: '<p>Ja, maar op <a href="/simply-meet"><strong>afspraak</strong></a>. Als het onmogelijk is om de online procedures uit te voeren, nodigen we u uit om contact op te nemen met het Ondersteuningsloket voor het gebruik van het OSIRIS-platform, het OSIRIS-loket, via 02/204.24.96.</p>',
               fr: '<p>Oui, mais sur <a href="/simply-meet"><strong>rendez-vous</strong></a>. En cas d’impossibilité à effectuer les démarches informatiques, nous vous invitons à contacter le Guichet de support à l’utilisation de la plateforme, le Guichet OSIRIS, via le 02/204.24.96.</p>'
            }
         },
         {
            value: "contact-admin",
            question: {
               en: 'How can I contact the Road Administrator?',
               nl: 'Hoe kan ik contact opnemen met de Wegbeheerder?',
               fr: 'Comment puis-je contacter l’Administrateur de Voirie ?'
            }, answer: {
               en: '<p>In the contact tab of your request, you will find its phone number. Its contact details are also generally included in its decision issued in the documents tab.</p>',
               nl: '<p>In het contactgedeelte van uw verzoek vindt u zijn telefoonnummer. Zijn contactgegevens worden meestal ook vermeld in zijn besluit in het tabblad documenten.</p>',
               fr: '<p>Dans l’onglet contact de votre demande vous trouverez son numéro de téléphone. Ses coordonnées sont aussi généralement reprises dans sa décision délivrée dans l’onglet documents.</p>'
            }
         },
         {
            value: "contact-bug",
            question: {
               en: 'I have a technical issue/bug on the platform. Who can I contact?',
               nl: 'Ik heb een technisch probleem/bug op het platform. Wie kan ik contacteren?',
               fr: 'J’ai un problème technique/bug sur la plateforme. Qui puis-je contacter ?'
            }, answer: {
               en: '<p>For purely technical problems (error 500, area in private domain, ...), we invite you to contact the Osiris support team through the form available on the <strong><a href=/bug-report> Support tab > Report a bug </strong>.</p>',
               nl: "<p>Voor puur technische problemen (fout 500, gebied in privédomein, ...), nodigen wij u uit om contact op te nemen met het Osiris-ondersteuningsteam via het formulier dat beschikbaar is op het tabblad <strong><a href=/bug-report> Ondersteuning > Een bug melden </strong>.</p>",
               fr: "<p>Pour des problèmes purement techniques (erreur 500, emprise en domaine privé, ...), nous vous invitons à contacter l'équipe de support d'Osiris via le formulaire disponible dans l'onglet <strong><a href=/bug-report> Support > Signaler un bug</strong>.</p>"
            }
         },
      ]
   },
   {
      topic: "registration",
      label: {
         en: "Registration",
         nl: "Inschrijving",
         fr: "Inscription",
      },
      faqs: [
         {
            value: "registration-error",
            question: {
               en: "I can't open an account. I don't have a national registry number. How can I open my account?",
               nl: 'Ik kan geen account openen. Ik heb geen nationaal registratienummer. Hoe kan ik mijn account openen?',
               fr: 'Je n’arrive pas à ouvrir un compte. Je n’ai pas de registre national. Comment ouvrir mon compte ?'
            }, answer: {
               en: "<p>Via <a href=https://www.osiris.brussels><strong>osiris.brussels</strong></a>, it is still possible to register without a national number by following this path:" +
                  "<ul>" +
                  "<li><strong>If your company is not yet registered,<a href=https://apps.osiris.brussels/Organization/#/imp/my_actor>click here</a> > Impetrant > Impetrant Non Institutionnel > fill in the fields.</strong> You will then receive an email in your inbox (or your spam folder depending on your email settings) with the link to validate your account. If it is an account for a Non-Institutional Impetrant, it will be necessary for it to be validated after checking the company number. This step may take up to two business days.</li>" +
                  "</ul></p>",
               nl: "<p>Via <a href=https://www.osiris.brussels><strong>osiris.brussels</strong></a> is het nog steeds mogelijk om u zonder nationaal nummer in te schrijven door de volgende stappen te volgen:" +
                  "<ul>" +
                  "<li><strong>Als uw bedrijf nog niet is geregistreerd, <a href=https://apps.osiris.brussels/Organization/#/imp/my_actor>klik hier</a> > Verzoeker > Niet-institutionele verzoeker > vul de velden in</strong>. U ontvangt vervolgens een e-mail in uw inbox (of spammap, afhankelijk van de configuratie van uw e-mail) met de validatielink voor uw account. Als het een account voor een niet-institutionele impetrant is, moet het worden goedgekeurd na controle van het ondernemingsnummer. Deze stap kan tot twee werkdagen duren.</li>" +
                  "</ul></p>",
               fr: "<p>Via <a href=https://www.osiris.brussels><strong>osiris.brussels</strong></a>, il reste possible de s'inscrire sans numéro national en suivant le chemin suivant:" +
                  "<ul>" +
                  "<li><strong> Si votre entreprise n'est pas encore enregistrée, <a href=https://apps.osiris.brussels/Organization/#/imp/my_actor>cliquez ici</a> > Impétrant > Impétrant Non Institutionnel > remplissez les champs</strong>. Vous recevrez ensuite un mail dans votre boîte (ou vos spams selon la configuration de votre messagerie) avec le lien de validation de votre compte. Si c'est un compte pour Impétrant Non Institutionnel, il sera nécessaire qu'il soit validé après contrôle du numéro d'entreprise. Cette étape peut prendre jusqu'à deux jours ouvrables.</li>" +
                  "</ul></p>"
            }
         },
         {
            value: "authentification-error",
            question: {
               en: "Itsme or my EID card reader is not working. What's happening?",
               nl: 'Itsme of mijn EID-kaartlezer werkt niet. Wat is er aan de hand?',
               fr: 'Itsme ou mon lecteur de carte EID ne fonctionnent pas. Que se passe-t-il?'
            }, answer: {
               en: '<p>Itsme or EID is only available for authenticating to the platform. This means that for opening the account, you must always manually fill in the fields. Once the account is validated, you can log in with Itsme or EID. If you already had another account, see FAQ <p><a href=/faq/registration/login-failed><strong>I made three failed login attempts</strong></a></p>' +
                  "<p>For more information, please refer to the <a href=/tutorials><strong>tutorial</strong></a></p>",
               nl: '<p>Itsme of EID is alleen beschikbaar voor authenticatie op het platform. Dit betekent dat u bij het openen van de rekening altijd de velden handmatig moet invullen. Zodra de account is goedgekeurd, kunt u zich aanmelden met Itsme of EID. Als u al een ander account had, zie dan FAQ <p><a href=/faq/registration/login-failed><strong>Ik heb drie mislukte aanmeldpogingen gedaan</strong></a>/p>' +
                  "<p>Raadpleeg voor meer informatie de  <a href=/tutorials><strong>tutorial</strong></a></p>",
               fr: '<p>Itsme ou EID est uniquement disponible pour s’authentifier à la plateforme. Cela signifie que pour l’ouverture du compte vous devez toujours remplir manuellement les champs. Une fois le compte validé vous pourrez vous connecter avec Itsme ou EID.</p> <p>Si vous disposiez déjà d’un autre compte voir FAQ <p><a href=/faq/registration/login-failed><strong>J\'ai fait trois tentatives de connections qui ont échouées</strong></a></p>' +
                  "<p>Pour plus de précision, veuillez vous référer au <a href=/tutorials><strong>tutoriel</strong></a></p>"
            }
         },
         {
            value: "login-failed",
            question: {
               en: 'I have made three unsuccessful login attempts. How can I access my account again?',
               nl: 'Ik heb drie onsuccesvolle aanmeldpogingen gedaan. Hoe kan ik weer toegang krijgen tot mijn account?',
               fr: 'J’ai fait trois tentatives de connections qui ont échouées. Comment puis-je à nouveau accéder à mon compte ?'
            }, answer: {
               en: '<p>The platform automatically disables your access after three failed login attempts. To restore your access as quickly as possible, we invite you to contact the Counter at 02/204 24 96 or by email at <a href="mailto:guichetosirisloket@sprb.brussels" style="color: blue; text-decoration: underline;">guichetosirisloket@sprb.brussels</a>.</p>',
               nl: '<p>Het platform schakelt uw toegang automatisch uit na drie mislukte aanmeldpogingen. Om uw toegang zo snel mogelijk te herstellen, nodigen we u uit contact op te nemen met de balie op 02/204 24 96 of per e-mail naar <a href="mailto:guichetosirisloket@sprb.brussels" style="color: blue; text-decoration: underline;">guichetosirisloket@sprb.brussels</a>.</p>',
               fr: '<p>La plateforme désactive automatiquement votre accès après trois tentatives de connections échouées. Afin de rétablir votre accès dans les meilleurs délais nous vous invitons à contacter le Guichet au 02/204 24 96 ou par email à  <a href="mailto:guichetosirisloket@sprb.brussels" style="color: blue; text-decoration: underline;">guichetosirisloket@sprb.brussels</a>.</p>'
            }
         },
         {
            value: "login-failed",
            question: {
               en: 'We are a company. What type of account should I have in order to submit our requests?',
               nl: 'Wij zijn een bedrijf. Welk type account moet ik hebben om onze aanvragen in te dienen?',
               fr: 'Nous sommes une société. Quel type de compte dois- je posséder pour encoder nos demandes ?'
            }, answer: {
               en: '<p>You must open an account as a Non-Institutional Impetrant. Be sure to indicate the company number. Account verification may take up to two business days.</p>',
               nl: '<p>U moet een account openen als niet-institutionele impetrant. Zorg ervoor dat u het ondernemingsnummer goed invult. Het verifiëren van het account kan twee werkdagen duren.</p>',
               fr: '<p>Vous devez ouvrir un compte en tant qu’entreprise (Impétrant Non Institutionnel). Veuillez à bien indiquer le n° d’entreprise. La vérification du compte peut nécessiter deux jours ouvrables.</p>'
            }
         },
         {
            value: "registration-control-value",
            question: {
               en: "I'm trying to open an account on the platform. I can't find the control value to finalize the operation. Where can I find it?",
               nl: 'Ik probeer een account te openen op het platform. Ik kan de controlewaarde niet vinden om de operatie af te ronden. Waar kan ik het vinden?',
               fr: 'J’essaye d’ouvrir un compte sur la plateforme. Je ne trouve pas la valeur de contrôle pour finaliser l’opération. Où puis- je la trouver ?'
            }, answer: {
               en: '<p>First of all, make sure that each field of the form is filled in (except for the photo). Then click on the clock to get the control value.</p>',
               nl: '<p>Controleer eerst of elk veld van het formulier is ingevuld (met uitzondering van de foto). Klik vervolgens op het klokpictogram om de controlevaarde te verkrijgen.</p>',
               fr: '<p>Tout d’abord veillez à vérifier que chaque champ du formulaire est rempli (à l’exception de la photo). Ensuite cliquez sur l’horloge pour obtenir la valeur de contrôle.</p>'
            }
         },
      ]
   },
   {
      "topic": "new_demand",
      "label": {
         en: "New demand",
         nl: "Nieuwe aanvraag",
         fr: "Nouvelle demande"
      },
      "faqs": [
         {
            value: "peak-hours",
            question: {
               en: "What is meant by 'peak hours'?",
               nl: "Wat wordt bedoeld met 'spitsuren'?",
               fr: "Qu'entend-on par heure de pointe?"
            },
            answer: {
               en: "<p>For each roadway of class A0 to A3, the peak hours are the time slots recorded by the Directorate Coordination of Construction Sites of Brussels Mobility in the computer system as being usually those during which the roadway reaches its maximum capacity of use. </p> <p>In case of modification of the peak hours by the Directorate Coordination of Construction Sites of Brussels Mobility, the new peak hours are applicable three months after their validation in the computer system. </p> <p>Subsidiarily, the applicable peak hours are as follows:</p> <p><strong> On roadways of class A0 and A1 </strong></p> <p class=\"indented-1\"> from 7am to 9:30am and from 3pm to 7pm from Monday to Thursday and from 7am to 9:30am and from 2:30pm to 7pm on Friday</p> <p><strong>On roadways of class A2 and A3</strong></p> <p class=\"indented-1\"> from 7am to 9:30am and from 4pm to 6:30pm from Monday to Friday. </p> ",
               nl: "<p>Voor elke weg van klasse A0 tot A3, de spitsuren zijn de tijdslots geregistreerd door de Directie Coördinatie van de Bouwplaatsen van Brussel Mobiliteit in het computersysteem als zijnde gewoonlijk die tijden waarin de weg zijn maximale capaciteit van gebruik bereikt. </p> <p>In geval van wijziging van de piekuren door de Directie Coördinatie van Bouwplaatsen van Brussel Mobiliteit, zijn de nieuwe piekuren van toepassing drie maanden na hun validatie in het computersysteem. </p> <p>Subsidiair zijn de toepasselijke piekuren als volgt:</p> <p><strong> Op wegen van klasse A0 en A1 </strong></p> <p class=\"indented-1\"> van 7u tot 9u30 en van 15u tot 19u van maandag tot donderdag en van 7u tot 9u30 en van 14u30 tot 19u op vrijdag</p> <p><strong>Op wegen van klasse A2 en A3</strong></p> <p class=\"indented-1\"> van 7u tot 9u30 en van 16u tot 18u30 van maandag tot vrijdag. </p> ",
               fr: "<p>Pour chaque voirie de classe A0 à A3, les heures de pointe sont les plages horaires reprises par la Direction Coordination des Chantiers de Bruxelles Mobilité dans le système informatique comme étant habituellement celles au cours desquelles la chaussée atteint sa capacité maximale d’utilisation. </p> <p>En cas de modification des heures de pointe par la Direction Coordination des Chantiers de Bruxelles Mobilité, les nouvelles heures de pointe sont d’application trois mois après leur validation dans le système informatique. </p> <p>À titre subsidiaire, les heures de pointes applicables sont les suivantes :</p> <p><strong> En voirie de classe A0 et A1 </strong></p> <p class=\"indented-1\"> de 7h à 9h30 et de 15h à 19h du lundi au jeudi et de 7h à 9h30 et de 14h30 à 19h le vendredi</p> <p><strong>En voirie de classe A2 et A3</strong></p> <p class=\"indented-1\"> de 7h à 9h30 et de 16h à 18h30 du lundi au vendredi. </p> "
            }
         },
         {
            value: "disabled-parking",
            question: {
               en: "Can one occupy a disabled parking space?",
               nl: "Kan men een gehandicaptenparkeerplaats gebruiken?",
               fr: "Peut-on occuper une place PMR?"
            },
            answer: {
               en: "<p>Yes, provided that the relocation of these disabled parking spaces is planned. For example, if one wants to use a disabled parking space, it is necessary to reserve a space within the designated area to enable a new disabled parking spot.</p>",
               nl: "<p>Ja, op voorwaarde dat het verplaatsen van deze gehandicaptenparkeerplaatsen wordt voorzien. Bijvoorbeeld: als men een gehandicaptenparkeerplaats wil gebruiken, is het noodzakelijk om een plaats te reserveren in het daarvoor bestemde gebied om een nieuwe gehandicaptenparkeerplaats mogelijk te maken.</p>",
               fr: "<p>Oui, à condition de prévoir le déplacement de ces places PMR. Exemple: si l’on veut occuper une place PMR, il est nécessaire de réserver une place dans l’emprise dessinée pour permettre un nouveau stationnement PMR.</p>"
            }
         },
         {
            value: "taxi-parking",
            question: {
               en: "Can one occupy occupy a taxi stand?",
               nl: "Kan men een taxiplaats innemen?",
               fr: "Peut-on occuper une place de taxi?"
            },
            answer: {
               en: "<p>Yes, you will need to contact the taxi company concerned to obtain their agreement.</p>",
               nl: "<p>Ja, dan moet je contact opnemen met het taxibedrijf om een overeenkomst van hun kant te krijgen.</p>",
               fr: "<p>Oui, il est alors nécessaire de contacter la société de taxi concernée pour obtenir un accord d’occupation de leur part.</p>"
            }
         },
         {
            value: "dc-parking",
            question: {
               en: "Can one occupy diplomatic cours",
               nl: "Kan men een diplomatieke corps (CD) parkeerplaats bezetten?",
               fr: "Peut-on occuper une place C.D?"
            },
            answer: {
               en: "<p>Yes, you need to contact the concerned users to get a usage agreement from them.</p>",
               nl: "<p>Ja, u moet contact opnemen met de betrokken gebruikers om van hen een gebruiksovereenkomst te krijgen.</p>",
               fr: "<p>Oui, il est alors nécessaire de contacter les utilisateurs concernés pour obtenir un accord d’occupation de leur part.</p>"
            }
         },
         {
            value: "bus-stop-parking",
            question: {
               en: "Can we occupy a bus stop?",
               nl: "Kan men een bushalte innemen?",
               fr: "Peut-on occuper un arrêt de bus?"
            },
            answer: {
               en: "<p>Yes, it is then necessary to contact the relevant transport company (TEC, STIB, De Lijn).</p>",
               nl: "<p>Ja, u moet contact opnemen met de betrokken vervoersmaatschappij (TEC, MIVB, De Lijn).</p>",
               fr: "<p>Oui, il est alors nécessaire de contacter la société de transport concernée (TEC, STIB, De Lijn).</p>"
            }
         },
         {
            value: "b1-number",
            question: {
               en: "What is the B1 number, and how do I obtain it?",
               nl: "Wat is het nummer B1 en hoe kan ik het verkrijgen?",
               fr: "Qu’est-ce que le numéro B1? Comment l’obtenir?"
            },
            answer: {
               en: "<p>The B1 number is the identification number of an OSIRIS request. This number is obtained once a road occupancy request is encoded on the OSIRIS platform.</p>",
               nl: "<p>Het nummer B1 is het identificatienummer van een OSIRIS-aanvraag. Dit nummer wordt verkregen nadat een aanvraag voor het bezetten van de weg is ingevoerd op het OSIRIS-platform.</p>",
               fr: "<p>Le numéro B1 est le numéro d’identification d’une demande OSIRIS. Ce numéro s’obtient une fois qu’une demande d’occupation de la voirie est encodée sur la plateforme OSIRIS.</p>"
            }
         },
         {
            value: "delivery-parking",
            question: {
               en: "What do I need to occupy a delivery zone?",
               nl: "Wat heb ik nodig om een leveringszone in te nemen?",
               fr: "De quoi ai-je besoin pour occuper une zone de livraison ?"
            },
            answer: {
               en: "<p>If the occupation is limited to loading and unloading during a short period, it can be done without authorization and without additional signs. In the case of privatization for parking purposes, E3 signs and an OSIRIS agreement will be required.</p>",
               nl: "<p>Als de bezetting beperkt blijft tot laden en lossen gedurende een korte periode, kan de bezetting zonder toestemming en zonder extra borden plaatsvinden. Bij privatisering voor parkeerdoeleinden zijn E3-borden en een OSIRIS-goedkeuring vereist.</p>",
               fr: "<p>Si l’occupation se limite à charger et décharger pendant une courte période de temps, l’occupation peut se faire sans autorisation et sans panneaux additionnels. En cas de privatisation à des fins de stationnement il faudra des panneaux E3 et un accord OSIRIS.</p>"
            }
         },
         {
            value: "move",
            question: {
               en: "I am moving. It's not a construction site, and it won't take longer than an hour. Does this concern me?",
               nl: "Ik ga verhuizen. Het is geen bouwplaats en het zal niet langer dan een uur duren. Betreft dit mij echt?",
               fr: "Je déménage. Ce n’est pas un chantier et cela ne va durer moins d’une heure. Tout cela me concerne-t-il vraiment ?"
            },
            answer: {
               en: "<p>Yes. The current regulations do not distinguish between a construction site, a move, a delivery, road works, etc. In the Brussels region, any occupation of public roads for moving, construction works, etc., undergoes an authorization procedure managed by the OSIRIS application.</p>",
               nl: "<p>Ja. De geldende regelgeving maakt geen onderscheid tussen een bouwplaats, een verhuizing, een levering, wegwerkzaamheden, enz. In het Brussels Gewest is elke bezetting van de openbare weg voor verhuizingen, werkzaamheden, enz., onderworpen aan een autorisatieprocedure die wordt beheerd door de OSIRIS-applicatie.</p>",
               fr: "<p>Oui. La règlementation en vigueur ne fait pas de différence entre un chantier, un déménagement, une livraison, des travaux de voirie etc... En région bruxelloise, toute occupation de voirie publique à des fins de déménagements, de travaux, etc. fait l’objet d’une procédure d’autorisation pilotée par l’application OSIRIS.</p>"
            }
         },
         {
            value: "bus-stop-parking",
            question: {
               en: "The platform informs me that the part I want to reserve is in private domain. Should I keep it as suggested?",
               nl: "Het platform meldt dat het deel dat ik wil reserveren zich in een privédomein bevindt. Moet ik het behouden zoals voorgesteld?",
               fr: "La plateforme me signale que la partie que je veux réserver est en domaine privé. Dois-je la conserver comme proposé ?"
            },
            answer: {
               en: "<p>In the case of certain pipeline works, it may be necessary to keep that part private. Otherwise, we recommend answering no to avoid unnecessarily obtaining a construction site with two Administrators.</p>",
               nl: "<p>In het geval van bepaalde rioolwerkzaamheden kan het nodig zijn om dit privé te houden. Anders raden we u aan om nee te antwoorden om onnodig een bouwplaats met twee beheerders te krijgen.</p>",
               fr: "<p>Dans le cas de certains travaux de canalisations, il peut être nécessaire de garder cette partie privée. Sinon nous vous recommandons de répondre non afin de ne pas obtenir inutilement un chantier avec deux Administrateurs.</p>"
            }
         },
         {
            value: "bus-stop-parking",
            question: {
               en: "What is a mobility cut? Do I need one? Where can I find it?",
               nl: "Wat is een mobiliteitsdoorsnede? Heb ik er een nodig? Waar kan ik het vinden?",
               fr: "C’est quoi une coupe de mobilité ? En ai-je besoin ? Où en trouver ?"
            },
            answer: {
               en: "<p>A mobility cut is a cross-sectional view of the street where you intend to occupy before and during construction. The dimensions of the road and your occupation, as well as the residual width for users in the event of an impact on mobility, must be indicated. This cut is necessary for certain procedures. When it is necessary, the platform will inform you when you try to send the construction execution declaration.</p>",
               nl: "<p>Een mobiliteitsdoorsnede is een dwarsdoorsnede van de straat waar u uw bezetting wilt uitvoeren vóór en tijdens de bouw. De afmetingen van de weg en uw bezetting, evenals de resterende breedte voor gebruikers in geval van impact op mobiliteit, moeten worden aangegeven. Deze doorsnede is vereist volgens bepaalde procedures. Als dit nodig is, zal het platform u hiervan op de hoogte stellen wanneer u probeert de bouwuitvoeringsverklaring te verzenden.</p>",
               fr: "<p>Une coupe de mobilité est une vue transversale de la rue où vous désirez faire votre occupation avant et pendant le chantier. Les dimensions de la voirie et de votre occupation doivent être indiqués ainsi que la largeur résiduelle pour les usagers en cas d’impact sur la mobilité. Cette coupe est nécessaire suivant certaines procédures. Lorsqu’elle est nécessaire, la plateforme vous en informera lorsque vous tenterez d’envoyer la déclaration d’exécution de chantier.</p>"
            }
         },
         {
            value: "citizen-platform",
            question: {
               en: "I am a citizen. I don't understand why I have to use the platform. Isn't it the responsibility of my contractor to handle these procedures?",
               nl: "Ik ben een burger. Ik begrijp niet waarom ik het platform moet gebruiken. Is het niet de verantwoordelijkheid van mijn aannemer om deze procedures af te handelen?",
               fr: "Je suis citoyen. Je ne comprends pas pourquoi je dois passer par la plateforme. N’est-ce pas à mon entrepreneur à faire ces démarches ?"
            },
            answer: {
               en: "<p>Your contractor can negotiate with you to transfer responsibility for the authorization and therefore the proper conduct of the construction site. If so, we strongly recommend demanding a detailed occupation plan to have all the information necessary for future steps.</p>",
               nl: "<p>Uw aannemer kan met u onderhandelen om u de verantwoordelijkheid voor de vergunning en dus voor het goede verloop van de bouwplaats over te dragen. Als dat het geval is, raden we u ten zeerste aan om een gedetailleerd bezettingsplan te eisen om alle benodigde informatie voor toekomstige stappen te hebben.</p>",
               fr: "<p>Votre entrepreneur peut négocier avec vous pour vous remettre la responsabilité de l’autorisation et donc de la bonne tenue du chantier. Si c’est le cas nous vous recommandons fortement d’exiger un plan d’occupation détaillé pour détenir toutes les informations nécessaires aux futures démarches.</p>"
            }
         },
         {
            value: "advice-prep",
            question: {
               en: "What advice can you give me to prepare my procedures on OSIRIS?",
               nl: "Welk advies kunt u mij geven om mijn procedures op OSIRIS voor te bereiden?",
               fr: "Quels conseils pouvez-vous me donner pour préparer mes démarches sur OSIRIS?"
            },
            answer: {
               en: "<p>First of all, make sure you know the details and the impact on the road. Then, provide a safety margin in the dates to overcome any setbacks. If necessary, you can contact the Counter to guide you to the most appropriate OSIRIS procedure for your situation.</p>",
               nl: "<p>Zorg er eerst voor dat u alle details kent en de impact op de weg begrijpt. Plan vervolgens een veiligheidsmarge in de datums om mogelijke vertragingen op te vangen. Indien nodig kunt u contact opnemen met het Guichet om u te begeleiden naar de meest geschikte OSIRIS-stap voor uw situatie.</p>",
               fr: "<p>Tout d’abord veillez à bien en connaître les détails et les impacts sur la voirie. Ensuite prévoyez une marge de sécurité dans les dates afin de palier à d’éventuels contretemps. Si besoin vous pouvez faire appel au Guichet pour vous guider vers la démarche sur OSIRIS la plus adéquate à votre situation.</p>"
            }
         },
         {
            value: "last-minute-delivery",
            question: {
               en: "I have a delivery tomorrow. I can't postpone it. What can I do to be in compliance?",
               nl: "Ik heb morgen een levering. Ik kan het niet uitstellen. Wat kan ik doen om in orde te zijn?",
               fr: "J’ai une livraison demain. Je ne peux pas reporter. Que puis-je faire pour être en ordre ?"
            },
            answer: {
               en: "<p>When entering your request, it is possible to declare that the road (parking or sidewalk) will only be used for a period of <strong>24 hours within the following 20 business days on less than 50m²</strong>. Click yes and follow each module until you save your declaration. See also FAQ \"What is the difference between an authorization, an agreement, and an exemption?\"</p>",
               nl: "<p>Bij het invoeren van uw aanvraag is het mogelijk om aan te geven dat u de weg (parkeren of trottoir) slechts gedurende <strong>24 uur in de komende 20 werkdagen zult gebruiken op minder dan 50 m²</strong>. Klik op ja en volg elke module totdat u uw verklaring hebt opgeslagen. Zie ook FAQ \"Wat is het verschil tussen een vergunning, een akkoord en een vrijstelling?\"</p>",
               fr: "<p>Lors de l’encodage de votre demande il est possible de déclarer que l’on utilisera la voirie (stationnement ou trottoir) que pour une durée de <strong>24h dans les 20 jours ouvrables suivants sur moins de 50m²</strong>. Cliquez sur oui et suivez chaque module jusqu’à sauvegarder votre déclaration. Voir aussi FAQ “Quel est la différence entre une autorisation, un accord -et une dispense ?”</p>"
            }
         },
         {
            value: "emergency",
            question: {
               en: "It's an EMERGENCY! There is a risk to the safety of people or property!",
               nl: "Het is een NOODGEVAL! Er is een risico voor de veiligheid van personen of eigendommen!",
               fr: "C’est une URGENCE ! Il y a un risque pour la sécurité des personnes ou des biens !"
            },
            answer: {
               en: "<p>In case of risk to the safety of people or property, it is obviously necessary to act as quickly as possible to secure the premises. Then, an urgent type request can be declared during encoding.</p>" +
                  "An urgent request is possible whenever a situation may affect people, property, utilities, emergency services or even the environment. When these risks are confirmed by experts or emergency services, it is authorized to carry out emergency work on the road to prevent these dangers from occurring or worsening.The conditions for such a request are precise. These are indicated by the platform in the module corresponding to the declaration of urgency.</p>",
               nl: "<p>Bij risico's voor de veiligheid van personen of goederen moet u uiteraard snel handelen om de locatie te beveiligen. Vervolgens kan een dringende aanvraag worden ingediend bij het invoeren van de aanvraag.</p>" +
                  "<p>Een drigende aanvrag is mogelijk wanneer een situatie gevolgen kan hebben voor mensen, eigendommen, openbare diensten, hulpdiensten of zelfs het milieu. Wanneer deze risico's door deskundigen of hulpdiensten worden bevestigd, kunnen er noodwerkzaamheden aan de weg worden uitgevoerd om te voorkomen dat deze gevaren zich voordoen of verergeren. De voorwaarden voor een dergelijk verzoek zijn specifiek en worden door het platform vermeld in het betreffende module voor het melden van dringendheid.",
               fr: "<p>En cas de risque pour la sécurité des personnes ou des biens il faut évidemment agir au plus vite pour sécuriser les lieux. Ensuite une demande de type Urgent pourra être déclarée lors de l’encodage. </p>" +
                  "<p>Une demande de type urgente est possible dés lors qu'une situation peut affecter les personnes, les biens, les services publics, les services d'urgence, ou même l'environnement. Lorsque ces risques sont confirmés par des experts ou des services d'urgence, il est autorisé de faire des travaux d'urgence sur la route pour éviter que ces dangers ne se produisent ou ne s'aggravent. Les conditions d’une telle demande sont précises. Ces dernières sont indiquées par la plateforme dans le module correspondant à la déclaration de l’urgence.</p>"
            }
         },
         {
            value: "no-precise-dates",
            question: {
               en: "I can't precisely set the date of my construction. Is there a solution?",
               nl: "Ik kan de datum van mijn bouwproject niet precies instellen. Is er een oplossing?",
               fr: "Je ne sais pas fixer précisément la date de mon chantier. Existe-t-il une solution ?"
            },
            answer: {
               en: "<p>Yes. In the case of authorization requests (see FAQ <p><a href=/faq/general/diff-auth-agreement><strong>What is the difference between an authorization, an agreement, and an exemption?</strong></a>), it is possible to request a certain number of days to be carried out over a long period.</p>" +
                  "<p>For example: 2 business days between 01/01/2025 and 01/05/2025. This is not possible in the case of an agreement.</p>",
               nl: "<p>Ja. In het geval van vergunningsaanvragen (zie FAQ <p><a href=/faq/general/diff-auth-agreement><strong>Wat is het verschil tussen een vergunning, een akkoord en een vrijstelling?</strong></a>), is het mogelijk om een bepaald aantal dagen aan te vragen om over een langere periode uit te voeren. Bijvoorbeeld: 2 werkdagen tussen 01/01/2025 en 01/05/2025. Dit is niet mogelijk in het geval van een akkoord.</p>",
               fr: "<p>Oui. Dans le cas des demandes d’autorisation (voir FAQ <p><a href=/faq/general/diff-auth-agreement><strong>Quel est la différence entre une autorisation, un accord et une dispense ?</strong></a>) il est possible de demander un certain nombre de jours à effectuer sur une longue période. Par exemple : 2 jours ouvrables entre le 01/01/2025 et le 01/05/2025. Ceci n’est pas possible dans le cas d’un accord.</p>"
            }
         },
         {
            value: "parking-container",
            question: {
               en: "I need to reserve parking space for a container. What should I plan?",
               nl: "Ik moet parkeerruimte reserveren voor een container. Wat moet ik plannen?",
               fr: "Je dois réserver un emplacement de stationnement pour un container. Que dois- je prévoir ?"
            },
            answer: {
               en: "<p>In the case of a container, you must ensure that it does not exceed the width of the parking area to be reserved. Your request will be encoded under the \"storage area\" construction type E <strong>if the duration does not exceed 60 business days, and a mobility cut will be mandatory</strong>.</p> <p>We recommend including a larger length to allow for maneuvering.</p>",
               nl: "<p>In het geval van een container moet u er absoluut voor zorgen dat deze niet breder is dan de breedte van de te reserveren parkeerzone. Uw aanvraag wordt gecodeerd onder de aard van de bouwplaats \"opslagzone\" in type E <strong>als de duur niet meer dan 60 werkdagen bedraagt, en een mobiliteitssectie is verplicht</strong>.</p>  <p>We raden aan een grotere lengte op te nemen om manoeuvreerruimte te bieden bij het plaatsen van de container.</p>",
               fr: "<p>Dans le cas d’un container vous devez impérativement vous assurer qu’il ne dépassera pas la largeur de la zone de stationnement à réserver. Votre demande sera à encoder sous la nature de chantier “zone de stockage” en type E <strong>si la durée ne dépasse pas 60 jours ouvrables et une coupe de mobilité sera obligatoire</strong>.</p>  <p>Nous vous recommandons d’inclure une longueur plus grande pour permettre une marge de manœuvre lors du placement.</p>"
            }
         },
         {
            value: "reserve-sidewalk",
            question: {
               en: "I need to reserve sidewalk space for scaffolding. What should I plan?",
               nl: "Ik moet trottoirruimte reserveren voor steigers. Wat moet ik plannen?",
               fr: "Je dois réserver un emplacement de trottoir pour un échafaudage. Que dois-je prévoir ?"
            },
            answer: {
               en: "<p>In the case of scaffolding, you must ensure that a passage of 2m (minimum 1m50) is possible for pedestrians. Your request will be encoded under the \"storage area\" construction type E <strong>if the duration does not exceed 60 business days</strong>.</p> <p>A signaling plan and a mobility cut will be mandatory. We recommend including a longer duration for the work to allow for a safety margin in case of unforeseen events.</p>",
               nl: "<p>In het geval van steigers moet u er absoluut voor zorgen dat een doorgang van 2 m (minimaal 1,50 m) mogelijk is voor voetgangers. Uw aanvraag wordt gecodeerd onder de aard van de bouwplaats \"opslagzone\" in type E <strong>als de duur niet meer dan 60 werkdagen bedraagt</strong>.</p><p> Een signaleringsplan en een mobiliteitssectie zijn verplicht. We raden aan een langere duur voor de werken op te nemen om een veiligheidsmarge te bieden in geval van onvoorziene omstandigheden.</p>",
               fr: "<p>Dans le cas d’un échafaudage vous devez impérativement vous assurer qu'un passage de de 2m (1m50 minimum) est possible pour le passage des piétons. Votre demande sera à encoder sous la nature de chantier “zone de stockage” en type E <strong>si la durée ne dépasse pas 60 jours ouvrables</strong>.</p><p> Un plan de signalisation et une coupe de mobilité seront obligatoires. Nous vous recommandons d’inclure une durée de travaux plus longue pour permettre une marge de sécurité en cas d’imprévu.</p>"
            }
         },
         {
            value: "deviation",
            question: {
               en: "The platform indicates that I need to enter a deviation. How can I do that?",
               nl: "Het platform geeft aan dat ik een afwijking moet invoeren. Hoe kan ik dat doen?",
               fr: "La plateforme indique je dois encoder une déviation. Comment puis –je le faire ?"
            },
            answer: {
               en: "<p><a href=https://apps.osiris.brussels><strong>The Osiris application</strong></a> allows many operations but encoding the diversion. Please go to your request via <a href=https://www.osiris.brussels><strong>osiris.brussels</a> Actions > Execution > Deviation Plan.</strong></p>",
               nl: "<p><a href=https://apps.osiris.brussels><strong>De Osiris-applicatie</strong></a> biedt veel mogelijkheden, maar niet voor het coderen van afwijkingen. Ga alstublieft naar uw verzoek via <a href=https://www.osiris.brussels><strong>osiris.brussels</a> Acties > Uitvoering > Afwijkingenplan.</strong></p>",
               fr: "<p><a href=https://apps.osiris.brussels><strong>L'application Osiris</strong></a> permet de nombreuses opérations mais pas l’encodage de la déviation. Veuillez-vous rendre dans votre demande via <a href=https://www.osiris.brussels><strong>osiris.brussels</a> Actions > Exécution > Plan de déviation.</strong></p>"
            }
         },
         {
            value: "siamu",
            question: {
               en: "Do I meet the conditions of SIAMU? What impact does it have on my request?",
               nl: "Voldoe ik aan de voorwaarden van DBDMH? Welke impact heeft dit op mijn aanvraag?",
               fr: "Est-ce que je remplis les conditions du SIAMU ? Quel impact cela a t’il sur ma demande ?"
            },
            answer: {
               en: "<p><strong> Art. 35.</strong> Circulation of emergency vehicles.\nA circulation area is permanently kept clear of any obstacles between the construction site area and the opposite sidewalk to allow for the circulation of vehicles providing fire or emergency medical services. Except in streets with a width of 4 meters or less, this area has a width of:" +
                  "<ol><li class=\"indented-1\"><span>&#8226;</span> Minimum 3 meters when the length of the construction site area does not exceed 20 meters;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> Minimum 3.5 meters when the length of the construction site area exceeds 20 meters but does not exceed 50 meters;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> Minimum 4 meters when the length of the construction site area exceeds 50 meters.</li></ol>" +
                  "<p>When the construction site area is located on or near a place where emergency vehicles are likely to maneuver, a turning area with a radius of 11 meters on the inner side and 15 meters on the outer side is permanently kept clear of any obstacles.</p>" +
                  "<p>If these prescriptions cannot be respected, the impetrant, or the impetrant-pilot in the case of a coordinated construction site, requests the opinion of the SIAMU and attaches it to his construction site execution authorization request, \nThe limit of the circulation area cannot be more than 10 meters from the facades' plane. \nIf you cannot respect the required width as indicated by the platform, we invite you to contact the SIAMU:<a href='mailto:plans-plannen@firebru.brussels' style='color: blue; text-decoration: underline;'>plans-plannen@firebru.brussels</a>.</p>",
               nl: "<p><strong> Art. 35.</strong> Verkeer van noodvoertuigen \nEen verkeerszone wordt permanent vrijgehouden van obstakels tussen het terrein van de bouwplaats en het tegenoverliggende trottoir, om het verkeer van brandweer- of spoeddienstvoertuigen mogelijk te maken. Behalve in wegen met een breedte van 4 meter of minder heeft deze zone een breedte van:" +
                  "<ol>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> Minimaal 3 meter, wanneer de lengte van het bouwterrein niet meer dan 20 meter bedraagt;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> Minimaal 3,5 meter, wanneer de lengte van het bouwterrein meer dan 20 meter bedraagt maar niet meer dan 50 meter;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> Minimaal 4 meter, wanneer de lengte van het bouwterrein meer dan 50 meter bedraagt.</li>" +
                  "</ol>" +
                  "<p>Wanneer het bouwterrein zich bevindt op of in de buurt van een locatie waar hulpvoertuigen kunnen manoeuvreren, wordt permanent een draaizone van 11 meter binnenstraal en 15 meter buitenstraal vrijgehouden van obstakels. De grens van de verkeerszone mag niet meer dan 10 meter van het gevelvlak zijn.</p>" +
                  "<p>Als deze voorschriften niet kunnen worden nageleefd, vraagt de aanvrager, of de aanvrager-chauffeur in geval van een gecoördineerde bouwplaats, het advies van de DBDMH en voegt het toe aan zijn aanvraag voor de uitvoering van de bouwplaatsvergunning. Als u de gevraagde breedte niet kunt respecteren zoals aangegeven door het platform, nodigen wij u uit contact op te nemen met de DBDMH: <a href='mailto:plans-plannen@firebru.brussels' style='color: blue; text-decoration: underline;'>plans-plannen@firebru.brussels</a>.</p>",
               fr: "<p><strong> Art. 35.</strong> Circulation des véhicules d’urgence Une zone de circulation est, en permanence, maintenue libre de tout obstacle entre l'emprise du chantier et le trottoir opposé, en vue de permettre la circulation des véhicules assurant des services d'incendie ou d'aide médicale urgente. Sauf dans les voiries présentant une largeur de 4 mètres ou moins, cette zone présente une largeur de :" +
                  "<ol>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> Minimum 3 mètres, lorsque la longueur de l’emprise du chantier n’excède pas 20 mètres;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> Minimum 3,5 mètres, lorsque la longueur de l’emprise du chantier excède 20 mètres mais n’excède pas 50 mètres;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> Minimum 4 mètres, lorsque la longueur de l’emprise du chantier excède 50 mètres.</li>" +
                  "</ol>" +
                  "<p>Lorsque l’emprise du chantier se trouve sur ou à proximité d’un endroit où les véhicules de secours sont susceptibles de manœuvrer, une zone de braquage de 11 mètres de rayon intérieur et de 15 mètres de rayon extérieur est, en permanence, maintenue libre de tout obstacle. La limite de la zone de circulation ne peut pas être située à plus de 10 mètres du plan des façades.</p>" +
                  "<p>Si ces prescriptions ne peuvent pas être respectées, l'impétrant, ou l'impétrant-pilote en cas de chantier coordonné, requiert l'avis du SIAMU et le joint à sa demande d'autorisation d'exécution de chantier, Si vous ne pouvez pas respecter la largeur demandée comme indiquée par la plateforme, nous vous invitons à prendre contact avec le SIAMU : <a href='mailto:plans-plannen@firebru.brussels' style='color: blue; text-decoration: underline;'>plans-plannen@firebru.brussels</a>.</p>",
            }
         },
         {
            value: "parking-pedestrian",
            question: {
               en: "I want to reserve a spot in a pedestrian zone. What should I do?",
               nl: "Ik wil een plek reserveren in een voetgangerszone. Wat moet ik doen?",
               fr: "Je veux réserver un emplacement dans une zone piétonne. Que dois-je faire ?"
            },
            answer: {
               en: "<p>If you leave a passage respecting the SIAMU conditions (see FAQ <p><a href=/faq/new_demand/siamu><strong>Do I meet the SIAMU conditions? What impact does this have on my request?</strong></a>), then, in addition to a request for occupation tailored to your needs, you will need a pass to access the pedestrian area. Outside of permitted delivery hours (04h and 11h) the occupation can be done without any of these steps on the territory of the City of Brussels. Other procedures and conditions may apply in the municipalities.</p>",
               nl: "<p>Als u een doorgang laat die voldoet aan de voorwaarden van de DBDMH (zie FAQ <p><a href=/faq/new_demand/siamu><strong>Voldoe ik aan de voorwaarden van de DBDMH? Wat is het effect hiervan op mijn aanvraag? </strong></a>), dan heeft u naast een aanvraag voor bezetting aangepast aan uw behoeften ook een vergunning nodig om toegang te krijgen tot de voetgangerszone. Buiten de toegestane leveringsuren (04.00 en 11.00 uur) kan de bezetting zonder een van deze stappen plaatsvinden op het grondgebied van de Stad Brussel. Andere procedures en voorwaarden kunnen van toepassing zijn in de gemeenten.</p>",
               fr: "<p>Si vous laissez un passage respectant les conditions du SIAMU (voir FAQ <p><a href=/faq/new_demand/siamu><strong>Est-ce que je remplis les conditions du SIAMU ? Quel impact cela a t'il sur ma demande ?</strong></a>) alors en plus d’une demande d’occupation adaptée à vos besoins vous aurez besoin d’un laisser – passer afin d'accéder à la zone  piétonne. En dehors des heures de livraison permis (04h et 11h), l’occupation pourra se faire sans aucune de ces démarches sur le territoire de la Ville de Bruxelles. D’autres procédures et conditions peuvent être applicables selon les Communes.</p>"
            }
         }
      ]
   },
   {
      "topic": "follow_up_demand",
      "label": {
         en: "Follow up of the demand",
         nl: "Opvolging van de aanvraag",
         fr: "Suivi de la demande"
      },
      "faqs": [
         {
            value: "change-date-after-approval",
            question: {
               en: "I have obtained approval. I need to change the dates because the requested ones are no longer possible, or the obtained construction duration will no longer be sufficient. What should I do?",
               nl: "Ik heb goedkeuring gekregen. Ik moet de data wijzigen omdat de gevraagde data niet meer mogelijk zijn, of de verkregen duur van de bouw is niet meer voldoende. Wat moet ik doen?",
               fr: "J’ai obtenu un accord. Il faut en changer les dates car celles demandées ne sont plus possible ou la durée du chantier obtenue ne sera plus suffisante. Que dois- je faire ?"
            },
            answer: {
               en: "<p>There is no extension or modification of dates as such. You will need to submit a new request with new dates following the procedure.</p>",
               nl: "<p>Er is geen sprake van verlenging of wijziging van datums. U moet een nieuwe aanvraag indienen met nieuwe datums en de procedure volgen.</p>",
               fr: "<p>Il n’existe pas à proprement parler de prolongation ou de modification de dates. Vous devrez introduire une nouvelle demande avec de nouvelles dates en respectant la procédure.</p>"
            }
         },
         {
            value: "many-admin",
            question: {
               en: "My construction site is divided. My construction site has two Administrators. What should I do?",
               nl: "Mijn bouwplaats is opgesplitst. Mijn bouwplaats heeft twee beheerders. Wat moet ik doen?",
               fr: "Mon chantier est subdivisé. Mon chantier a deux Administrateurs.  Que dois-je faire ?"
            },
            answer: {
               en: "<p>If your construction site is in this situation, it is on an \"administrative border.\" You will need to ask each party for permission. This also means that the sending costs will be double.</p>",
               nl: "<p>Als uw bouwplaats zich in deze situatie bevindt, betekent dit dat het zich op een \"administratieve grens\" bevindt. U moet dus toestemming vragen aan beide partijen. Dit betekent ook dat de verzendkosten dubbel zullen zijn.</p>",
               fr: "<p>Si votre chantier se trouve dans cette situation c’est qu’il se trouve sur “une frontière administrative”. Vous devrez donc demander à chaque partie son autorisation. Cela signifie aussi que les frais d’envoi seront doubles.</p>"
            }
         },
         {
            value: "request-sent-notif",
            question: {
               en: "How can I know if my request has been sent successfully?",
               nl: "Hoe weet ik of mijn aanvraag succesvol is verstuurd?",
               fr: "Comment savoir si ma demande est bien envoyée ?"
            },
            answer: {
               en: "<p>In the dynamic sheet, the status of the request should indicate \"request transmitted\" or \"execution declaration sent.\"</p>",
               nl: "<p>In het dynamische formulier moet de status van de aanvraag worden weergegeven als \"aanvraag ingediend\" of \"verklaring van uitvoering verzonden\".</p>",
               fr: "<p>Dans la fiche dynamique le statut de la demande doit indiquer “demande transmise” ou “déclaration d’exécution envoyée”.</p>"
            }
         },
         {
            value: "admin-decision",
            question: {
               en: "Where and when can I find the Administrator's decision?",
               nl: "Waar en wanneer kan ik de beslissing van de beheerder vinden?",
               fr: "Où et quand trouver la décision de l’Administrateur ?"
            },
            answer: {
               en: "<p>The decisions of the Administrators, such as agreements and authorizations, can be found in the documents section of your request. To check an estimate of the maximum response time he has, click again on the send button for the execution declaration or authorization request.</p>",
               nl: "<p>De beslissingen van de beheerders, zoals goedkeuringen en vergunningen, kunnen worden gevonden in het documentengedeelte van uw aanvraag. Om een schatting te krijgen van de maximale responstijd van de beheerder, kunt u opnieuw op de knop voor het verzenden van de uitvoeringsverklaring of vergunningsaanvraag klikken.</p>",
               fr: "<p>Les décisions de l’Administrateurs tels que les accords et autorisations peuvent être retrouvées dans la partie documents de votre demande. Pour consulter une estimation du temps de délais maximal de réponse dont ce dernier dispose veuillez cliquer à nouveau sur le bouton d’envoi de déclaration d’exécution ou de demande d’autorisation.</p>"
            }
         },
         {
            value: "early-finish",
            question: {
               en: "The construction is completed earlier than expected. Can I finish the construction on the platform as well?",
               nl: "Het bouwproject is eerder klaar dan verwacht. Kan ik het bouwproject ook op het platform afronden?",
               fr: "Le chantier est terminé plus tôt que prévu. Puis- je terminer le chantier aussi sur la plateforme ?"
            },
            answer: {
               en: "<p>Yes. This is even highly recommended. Click on Actions and then on request the end of the construction. The status will change to 'provisionally terminated.' There you go!</p>",
               nl: "<p>Ja. Dit wordt zelfs sterk aanbevolen. Klik op Acties en vervolgens op het einde van de bouwplaats vragen. De status wordt dan 'tijdelijk voltooid'. Zo simpel is het!</p>",
               fr: "<p>Oui. Cela est même fortement recommandé. Cliquez sur Actions et ensuite sur demander le terme du chantier. Le statut passera en ‘provisoirement terminé’. Voilà c’est fait!</p>"
            }
         },
         {
            value: "date-layout-modif",
            question: {
               en: "I need to make changes to the dates or the layout of my occupancy. Can I make these modifications?",
               nl: "Ik moet wijzigingen aanbrengen in de data of het ontwerp van mijn weginname. Kan ik deze wijzigingen doorvoeren?",
               fr: "Il y a des modifications à faire dans les dates ou dans le dessin de mon occupation. Puis- je effectuer ces modifications ?"
            },
            answer: {
               en: "<p>In the case of a basic request for parking or sidewalk, it will not be possible to make these modifications. Whether before or after sending.</p>",
               nl: "<p>In het geval van een eenvoudig verzoek voor parkeren of het trottoir is het niet mogelijk om deze wijzigingen aan te brengen, zowel vóór als na het verzenden.</p>",
               fr: "<p>Dans le cas d’une demande basique de stationnement ou de trottoir il ne sera pas possible de faire ces modifications. Que ce soit avant l’envoi ou après.</p>"
            }
         },
         {
            value: "police-auth",
            question: {
               en: "The Police Authorization and Inspection tab remain red. How can I turn them green?",
               nl: "Het tabblad Politieautorisatie en Inspectie blijft rood. Hoe kan ik ze groen maken?",
               fr: "L’onglet Autorisation de Police et Etat des lieux restent rouges. Comment les faire passer en vert ?"
            },
            answer: {
               en: "<p>The Police authorization tab theoretically serves to obtain specific authorizations from the Police, and the Inventory concerns generally large construction sites. Unless you want to use your own signs (see FAQ <p><a href=/faq/signs/own-signs><strong>Can I use my own signs ?</strong></a>) or have noticed damage to the road that you want to mention, these two tabs will not be necessary.</p>",
               nl: "<p>Het tabblad Politievergunning wordt theoretisch gebruikt om specifieke toestemmingen van de politie te verkrijgen, en de Stand van Zaken heeft over het algemeen betrekking op grote bouwplaatsen. Tenzij u uw eigen borden wilt gebruiken (zie FAQ <p><a href=/faq/signs/own-signs><strong>Kan ik mijn eigen borden gebruiken ?</strong></a>), of tenzij u schade aan de weg heeft opgemerkt die u wilt melden, zijn deze twee tabbladen niet nodig.</p>",
               fr: "<p>L’onglet autorisation de Police sert théoriquement à obtenir des autorisations spécifiques de la Police et l’Etat des lieux concerne généralement des gros chantiers. A moins que vous ne souhaitiez utiliser vos propres panneaux (voir FAQ <p><a href=/faq/signs/own-signs><strong>Puis- je utiliser mes propres panneaux ?</strong></a>) ou que vous ayez constaté des dégâts sur la voirie que vous désirez mentionner ces deux onglets ne seront pas nécessaires.</p>"
            }
         },
         {
            value: "site-cancelled",
            question: {
               en: "My construction will not take place. It's cancelled. How can I cancel my request on OSIRIS?",
               nl: "Mijn bouwproject gaat niet door. Het is geannuleerd. Hoe kan ik mijn aanvraag op OSIRIS annuleren?",
               fr: "Mon chantier n’aura pas lieu. Il est annulé. Comment puis- je annuler ma demande sur OSIRIS ?"
            },
            answer: {
               en: "<p>It is possible to request the end of a construction site via the request on OSIRIS. Choose in Actions => request the end of the construction site. As a reminder, sending costs are charged for the Administrator for any construction site sent.</p>",
               nl: "<p>Het is mogelijk om het einde van een bouwplaats via de aanvraag op OSIRIS te vragen. Kies in Acties => vraag het einde van de bouwplaats aan. Ter herinnering, er worden kosten in rekening gebracht voor de verzending van de beheerder voor elke verzonden bouwplaats.</p>",
               fr: "<p>Il est possible de demander le terme d’un chantier via la demande sur OSIRIS. Choisissez  dans Actions => demander le terme du chantier. Pour rappel des frais d’envois sont facturés pour l’Administrateur pour tout chantier envoyé.</p>"
            }
         },
         {
            value: "request-rejected",
            question: {
               en: "My OSIRIS request is rejected. What should I do?",
               nl: "Mijn OSIRIS-aanvraag is afgewezen. Wat moet ik doen?",
               fr: "Ma demande OSIRIS est refusée. Que dois–je faire ?"
            },
            answer: {
               en: "<p>The first thing to do is to consult the Administrator's decision in the documents tab of the request. You can then enter a new construction execution declaration or resend the authorization request after modification.</p>",
               nl: "<p>Het eerste wat u moet doen, is de beslissing van de beheerder te raadplegen in het tabblad documenten van de aanvraag. Vervolgens kunt u een nieuwe bouwuitvoeringsverklaring invoeren of de vergunningsaanvraag opnieuw verzenden na wijziging.</p>",
               fr: "<p>La première chose à faire est de consulter la décision de l’Administrateur dans l’onglet documents de la demande. Vous pourrez ensuite encoder une nouvelle déclaration d’exécution ou renvoyer la demande d’autorisation après modification.</p>"
            }
         },
         {
            value: "exe-declaration-submitted",
            question: {
               en: "I have submitted my execution declaration. I have no response. What's happening?",
               nl: "Ik heb mijn uitvoeringsverklaring ingediend. Ik heb geen reactie. Wat is er aan de hand?",
               fr: "J’ai envoyé ma déclaration d’exécution. Je n’ai aucune réponse. Que se passe-t-il ?"
            },
            answer: {
               en: "<p>The Road Administrator has 8 business days to receive your request. After this period, you will find his decision in the documents tab of the request. See also FAQ \"How to know if my request is sent correctly?\"",
               nl: "<p>De Wegbeheerder heeft 8 werkdagen de tijd om uw aanvraag te ontvangen. Na deze periode vindt u zijn beslissing in het documentgedeelte van de aanvraag. Zie ook FAQ \"Hoe weet ik of mijn aanvraag goed is verzonden?\"",
               fr: "<p>L’Administrateur de voirie dispose de 8 jours ouvrables pour réceptionner votre demande. Après ce délai vous trouverez sa décision dans l’onglet document de la demande. Voir aussi FAQ “Comment savoir si ma demande est bien envoyée ?”"
            }
         },
         {
            value: "auth-request-submitted",
            question: {
               en: "I have submitted my authorization request. I have no response. What's happening?",
               nl: "Ik heb mijn autorisatieaanvraag ingediend. Ik heb geen reactie. Wat is er aan de hand?",
               fr: "J’ai envoyé ma demande d’autorisation. Je n’ai aucune réponse. Que se passe-t-il ?"
            },
            answer: {
               en: "<p>The Road Administrator has a very long total time to receive, validate, and authorize the request. Although the theoretical maximum time is between three and six months depending on whether the construction site is subject to the Construction Coordination Commission, the average processing time is between one and a half and three months.</p>",
               nl: "<p>De Wegbeheerder heeft een zeer lange totale termijn om uw aanvraag te ontvangen, goed te keuren en goed te keuren. Hoewel de maximale termijn theoretisch tussen drie en zes maanden ligt, afhankelijk van of de bouwplaats onderworpen is aan de Commissie Coördinatie Werven, ligt de gemiddelde verwerkingstijd tussen anderhalve maand en drie maanden.</p>",
               fr: "<p>L’administrateur de Voirie dispose d’un délai total très long pour réceptionner, valider et autoriser la demande. Si théoriquement le délai maximal se situe entre trois et six mois selon que le chantier est soumis à la Commission de Coordination des Chantiers, le temps de traitement moyen se situe entre 1 mois et demi et trois mois.</p>"
            }
         },
         {
            value: "submitted-to-ccc",
            question: {
               en: "In the dynamic sheet of my construction, it says Submitted to the CCC: Yes. What does that imply in concrete terms?",
               nl: "In het dynamische blad van mijn bouwproject staat: Ingediend bij de CCC: Ja. Wat betekent dat concreet?",
               fr: "Dans la fiche dynamique de mon chantier il est indiqué Soumis à la CCC : Oui. Qu’est-ce que cela implique concrètement ?"
            },
            answer: {
               en: "<p>Concretely, your request must obtain the opinion of the Construction Coordination Commission before the Road Administrator can accept it. This also means costs and delays higher than for an ordinary authorization request.</p>",
               nl: "<p>Concreet moet uw aanvraag het advies van de Commissie Coördinatie Werven krijgen voordat de Wegbeheerder deze kan goedkeuren. Dit betekent ook hogere kosten en langere doorlooptijden dan bij een gewone vergunningsaanvraag.</p>",
               fr: "<p>Concrètement votre demande devra obtenir l’avis de la Commission de Coordination des Chantiers avant que l’Administrateur de Voirie puisse l’accepter. Cela signifie aussi des frais et des délais supérieurs à une demande d’autorisation ordinaire.</p>"
            }
         },
         {
            value: "accidental-cancel",
            question: {
               en: "I accidentally requested the end of my construction. Does that mean I no longer have approval/authorization?",
               nl: "Ik heb per ongeluk het einde van mijn bouwproject aangevraagd. Betekent dit dat ik geen goedkeuring/autoriteit meer heb?",
               fr: "J’ai demandé accidentellement le terme de mon chantier. Cela signifie-t-il que je n’ai plus d’accord/ d’autorisation ?"
            },
            answer: {
               en: "<p>If the status of the construction site is 'completed,' it means that the agreement is completed. If it is indicated 'provisionally completed,' then urgently contact the Road Administrator to report your error.</p>",
               nl: "<p>Als de status van de bouwplaats 'voltooid' is, betekent dit dat het akkoord is afgerond. Als het wordt aangegeven als 'tijdelijk voltooid', neem dan onmiddellijk contact op met de Wegbeheerder om uw fout te melden.</p>",
               fr: "<p>Si le statut du chantier est ‘terminé’ cela signifie que l’accord est terminé. Si c’est indiqué ‘provisoirement terminé’ alors prenez contact en urgence avec l’Administrateur de Voirie pour lui signaler votre erreur.</p>"
            }
         },
         {
            value: "passed-commission",
            question: {
               en: "I have passed the Commission for Site Coordination. The opinion is favorable. Can I proceed with my construction?",
               nl: "Ik ben geslaagd voor de Commissie Coördinatie Bouwplaatsen. Het advies is gunstig. Kan ik doorgaan met mijn bouwproject?",
               fr: "Je suis en passé en Commission de Coordination des Chantiers. L’avis est favorable. Je peux donc effectuer mon chantier ?"
            },
            answer: {
               en: "<p>The Commission has given its favorable opinion. It is up to the Road Administrator to grant the authorization. The latter has 20 business days.</p>",
               nl: "<p>De Commissie heeft een gunstig advies gegeven. Het is aan de Wegbeheerder om de vergunning te verlenen. Deze laatste heeft 20 werkdagen.</p>",
               fr: "<p>La Commission a donné son avis favorable. C’est à l’Administrateur de Voirie que revient la compétence d’accorder l’autorisation. Ce dernier dispose de 20 jours ouvrables.</p>"
            }
         },
         {
            value: "inactive-site",
            question: {
               en: "My construction site is inactive. What should I do?",
               nl: "Mijn bouwplaats is inactief. Wat moet ik doen?",
               fr: "Mon chantier est inactif. Que faire ?"
            },
            answer: {
               en: "<p>An inactive construction site must be reactivated to be sent for authorization. You must log in to the classic version of Osiris (<a href=https://www.osiris.brussels><strong>osiris.brussels</strong></a>), enter below the cockpit in the search the 6 digits of your Osiris file number, <strong>click on enter > actions > initialization > enter the module \"authorization\"</strong>. Please contact the Counter to assist you in this process.</p>",
               nl: "<p>Een inactieve bouwplaats moet worden geactiveerd om voor vergunning te worden ingediend. U moet inloggen op de klassieke versie van Osiris (<a href=https://www.osiris.brussels><strong>osiris.brussels</strong></a>), Voer hieronder cockpit in het zoekvak de 6 cijfers van uw Osiris-dossiernummer in, <strong>klik op enter > acties > initialisatie > ga naar de module \"vergunning\"</strong>. Neem contact op met het Loket om u te laten assisteren bij deze procedure.</p>",
               fr: "<p>Un chantier inactif doit être réactivé pour pouvoir être envoyé pour autorisation. Vous devez vous connectez à la version classique d’Osiris (<a href=https://www.osiris.brussels><strong>osiris.brussels</strong></a>), Introduire en dessous de cockpit dans recherche les 6 chiffres de votre numéro de dossier Osiris, <strong>cliquez sur enter >actions > initialisation > rentrer dans la module “ autorisation “</strong>.  Merci de contacter le Guichet pour vous faire assister dans cette démarche.</p>"
            }
         }
      ]
   },
   {
      "topic": "fees",
      "label": {
         en: "Fees, taxes, costs",
         nl: "Kosten, belastingen, uitgaven",
         fr: "Frais, taxes, coûts"
      },
      "faqs": [
         {
            value: "cost",
            question: {
               en: "How much does a road occupancy request cost?",
               nl: "Hoeveel kost een aanvraag voor weginname?",
               fr: "Combien coute une demande d’occupation de la voirie?"
            },
            answer: {
               en: "<p>The cost associated with a road occupancy request depends on the procedure followed and the municipality , and are defined under article 87, paragraph 2 of <a href='http://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=nl&la=F&cn=2018050308&table_name=loi' style='color: blue; text-decoration: underline;'>  ordinance of 3 May 2018</a>. These amounts are revised annually on the basis of the consumer price index. If the work is not carried out, the application fee remains due and is not refunded.Please also note that in addition to these fees, there are communal occupation taxes. Please contact your local council for further details.</p>" +
                  "<ul>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 60 € for a request for authorisation to carry out an uncoordinated worksite. An additional fee of €20 is charged for a coordinated worksite;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 40 € for a second request for authorisation;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 20 € for a request requiring the opinion of the committee, which is added to the amount of the authorisation request;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 20 € for a declaration that a construction site is under construction and is not exempt from specific conditions or for a notice of commencement of work.</li>" +
                  "</ul>" +
                  "<br>" +
                  "<p> Road occupations of type D are not associated with any costs.</p>"+
                  "<p> Don't know which procedure is assigned to your road occupation? Take a look at the <a href=/osiris-at-a-glance><strong>Osiris at a Glance</strong></a> page</p>"+
                  "<p> To find out whether your road occupation is subject to the Worksite Coordination Commission's (WCC) review or not, in accordance with Article 32 of the <a href='https://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=fr&amp;la=F&amp;cn=2018050308&amp;table_name=loi' style='color: blue; text-decoration: underline;'>ordinance of 3 May 2018</a>, refer to the following <a href='/map' style='color: blue; text-decoration: underline;'>link</a>, which provides a map representing regional and communal highways of regional interest</p>" +
                  "<p> Generally, type D and E road occupations do not require advice from the WCC.</p>" ,
                  

               nl: "<p>De kosten voor een aanvraag voor het bezetten van de weg hangen af van de gevolgde procedure en de gemeente. en woorden bepaald in artikel 87, lid2 van <a href='https://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=nl&la=N&cn=2018050308&table_name=wet' style='color: blue; text-decoration: underline;'> ordonnantie van 3 mei 2018</a>. Deze bedragen worden jaarlijks herzien op basis van de consumentenprijsindex. Als de werkzaamheden niet worden uitgevoerd, blijven de aanvraagkosten verschuldigd en worden deze niet terugbetaald.Houd er ook rekening mee dat er naast deze vergoedingen ook gemeentelijke bezettingsbelastingen zijn. Neem contact op met je gemeente voor meer informatie.</p>" +
                  "<ul>" +
                  "<br>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 60 € voor een verzoek om toestemming om een niet-gecoördineerde bouwplaats uit te voeren. Voor een gecoördineerde werf wordt een extra vergoeding van €20 aangerekend;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 40 € voor een tweede autorisatieaanvraag;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 20 € voor een aanvraag waarvoor het advies van de commissie vereist is; dit bedrag wordt bij het bedrag van de machtigingsaanvraag opgeteld;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 20 € voor een verklaring dat een bouwplaats in aanbouw is en niet is vrijgesteld van specifieke voorwaarden of voor een aankondiging van start van de werkzaamheden.</li>" +
                  "</ul>" +
                  "<br>" +
                  "<p> Wegbezettingen van type UV zijn niet onderworpen aan enige dossierkosten.</p>"+
                  "<p> Weet u niet welke procedure is toegewezen aan uw bezetting van de weg? Neem een kijkje op de pagina <a href=/osiris-at-a-glance><strong>Osiris in een oogopslag</strong></a></p>"+
                  "<br>" +
                  "<p> Om te weten of uw wegbezetting al dan niet onderworpen is aan het advies van de Coördinatiecommissie van de Bouwplaatsen (CCB), in overeenstemming met artikel 32 van de <a href='https://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=nl&la=N&cn=2018050308&table_name=wet' style='color: blue; text-decoration: underline;'>ordonnantie van 3 mei 2018</a> kunt u de volgende <a href='/map' style='color: blue; text-decoration: underline;'>link</a> raadplegen, met een kaart die de gewestelijke en gemeentelijke wegen van gewestelijk belang weergeeft</p>" +
                  "<p> Over het algemeen vereisen wegbezettingen van type UV en U geen advies van de CCB.</p>",
                  

               fr: "<p>Le cout lié à une demande d’occupation de voirie dépend de la procédure suivie et de la commune et sont définis en vertu de l'article 87, alinéa 2 de <a href='https://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=fr&amp;la=F&amp;cn=2018050308&amp;table_name=loi' style='color: blue; text-decoration: underline;'> ordonnance du 3 mai 2018</a>. Ces montants sont révisés annuellement sur la base de l’indice des prix à la consommation. En cas de non-exécution du chantier, le droit de dossier reste dû et n’est pas remboursé. Veuillez également prendre note qu’à ces frais s’ajoutent les taxes communales d’occupation. Pour plus de détails concernant ces dernières, veuillez contacter votre commune.</p>" +
                  "<ul>" +
                  "<br>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 60 € pour une demande d’autorisation d’exécution d’un chantier non coordonné. Des frais de 20 € supplémentaires sont demandés dans le cadre d'un chantier coordonné;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 40 € pour une seconde demande d'autorisation;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 20 € pour une demande nécessitant l'avis de la comission qui s'ajoute au montant de la demande d'autorisation;</li>" +
                  "<li class=\"indented-1\"><span>&#8226;</span> 20 € pour une déclaration d'exécution de chantier qui n'est pas dispensée de conditions particulières d'exécution ou pour un avis de démarrage de chantier.</li>" +
                  "</ul>" +
                 "<br>" +
                  "<p> Les occupations de la voirie de type D ne sont soumis à aucun frais de dossier</p>"+
                  "<p> Vous ne savez pas quelle procédure est assignée à votre occupation de la voirie? Faites un tour sur la page <a href=/osiris-at-a-glance><strong>Osiris en bref</strong></a></p>"+
                  "<br>" +
                  "<p> Pour savoir si votre chantier est soumis à l'avis de la Commission de Coordination des Chantiers (CCC) ou non, en vertu de l'article 32 de l'<a href='https://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=fr&amp;la=F&amp;cn=2018050308&amp;table_name=loi' style='color: blue; text-decoration: underline;'>Ordonnance du 3 mai 2018</a>, référer au lien <a href='/map' style='color: blue; text-decoration: underline;'>suivant</a> qui met à disposition une carte représentant les voiries régionales et communales à intérêt régional.</p>" +
                  "<p> En règle générale, les chantiers de types D et E ne requièrent pas d'avis de la CCC.</p>"+
                  "<br>",
                  
            }
         },
         {
            value: "when-pay-fee",
            question: {
               en: "When can I pay the Administrator's dispatch fees?",
               nl: "Wanneer kan ik de verzendkosten van de beheerder betalen?",
               fr: "Quand puis- je payer les frais d’envoi de l’Administrateur ?"
            },
            answer: {
               en: "<p>The Administrator's sending costs will be invoiced by the latter according to its terms. In general, invoicing is done quarterly.</p>",
               nl: "<p>De verzendkosten van de beheerder worden door hemzelf gefactureerd volgens zijn modaliteiten. Over het algemeen worden facturaties per kwartaal uitgevoerd.</p>",
               fr: "<p>Les frais d’envois de l’Administrateur seront facturés par ce dernier selon ses modalités. En règle générale les facturations se font par trimestre.</p>"
            }
         },
         {
            value: "contest-fees",
            question: {
               en: "How can I contest the dispatch fees charged by the Administrator?",
               nl: "Hoe kan ik de verzendkosten die door de beheerder in rekening zijn gebracht, betwisten?",
               fr: "Comment puis- je contester les frais d’envois facturés par l’Administrateur ?"
            },
            answer: {
               en: "<p>If you notice an error or want to assert a right of objection, it is advisable to contact the service that sent you the invoice.</p>",
               nl: "<p>Als u een fout opmerkt of uw recht op bezwaar wilt uitoefenen, is het raadzaam contact op te nemen met de dienst die u de factuur heeft gestuurd.</p>",
               fr: "<p>Si vous constatez une erreur ou désirez faire valoir un droit de contestation, il est préférable de prendre contact avec le service vous ayant envoyé la facture.</p>"
            }
         },
         {
            value: "contest-fees",
            question: {
               en: "I contacted my municipality for my construction/move. They asked me to pay occupancy taxes. Do I still need to do other steps on OSIRIS?",
               nl: "Ik heb contact opgenomen met mijn gemeente voor mijn bouw-/verhuisproject. Ze vroegen me om wegbelasting te betalen. Moet ik nog andere stappen ondernemen op OSIRIS?",
               fr: "J’ai contacté ma Commune pour mon chantier/ déménagement. Elle m’a demandé de payer les taxes d’occupations. Dois- je encore faire d’autres démarches sur OSIRIS ?"
            },
            answer: {
               en: "<p>Some municipalities include in their services the request for agreement/authorization. Others expect you to take care of it yourself. It is best to ensure this by contacting the Municipality directly.</p>",
               nl: "<p>Sommige gemeenten nemen de aanvraag om toestemming/vergunning op in hun diensten. Anderen verwachten dat u het nodige doet vanuit uw eigen kant. Het is het beste om dit te controleren door rechtstreeks contact op te nemen met de gemeente.</p>",
               fr: "<p>Certaines Communes incluent dans leurs services la demande d’accord/ autorisation. D’autres attendent de vous que vous fassiez le nécessaire de votre côté. Le mieux est de vous en assurer en contactant directement la Commune.</p>"
            }
         },
         {
            value: "exemption-from-fees",
            question: {
               en: "Am I entitled to an exemption from dispatch fees?",
               nl: "Heb ik recht op een vrijstelling van verzendkosten?",
               fr: "Ai-je droit à une exemption des frais d’envois ?"
            },
            answer: {
               en: "<p>We recommend checking the exemption conditions of the Municipality where you plan your work.</p>",
               nl: "<p>We raden aan de vrijstellingsvoorwaarden van de gemeente waar u van plan bent te werken, te raadplegen.</p>",
               fr: "<p>Nous vous conseillons de consulter les conditions d’exemption de la Commune où vous prévoyez vos travaux.</p>"
            }
         }
      ]
   },
   {
      "topic": "signs",
      "label": {
         en: "Road signs",
         nl: "Verkeersborden",
         fr: "Panneaux de signalisation"
      },
      "faqs": [
         {
            value: "signs-sidewalk",
            question: {
               en: "Do I need signs to occupy the sidewalk?",
               nl: "Heb ik borden nodig om het trottoir in te nemen?",
               fr: "Ai-je besoin de panneaux pour occuper le trottoir?"
            },
            answer: {
               en: "<p>No E1 or E3 signs are placed on the sidewalk because the sidewalk is not an area intended for stopping or parking vehicles. However, other types of traffic signs can be placed, designed for pedestrian signaling, for example, when the sidewalk is inaccessible and a pedestrian diversion is necessary.</p>",
               nl: "<p>Er worden geen E1- of E3-borden op het trottoir geplaatst omdat het trottoir niet is bedoeld voor het stoppen of parkeren van voertuigen. Er kunnen echter andere soorten verkeersborden worden geplaatst die bedoeld zijn voor de signalering van voetgangers, bijvoorbeeld wanneer het trottoir ontoegankelijk is en een omleiding voor voetgangers nodig is.</p>",
               fr: '<p>On ne met pas de panneaux E1 ou E3 sur le trottoir car le trottoir n’est pas une zone prévue pour l’arrêt ou le stationnement de véhicules. Toutefois on peut placer d’autres types de panneaux de signalisation qui sont prévus pour la signalisation des piétons par exemple lorsque le trottoir est inaccessible et qu’une déviation piétonne est nécessaire.</p>'
            }
         },
         {
            value: "traffic-signs",
            question: {
               en: "When will OSIRIS place my traffic signs?",
               nl: "Wanneer plaatst OSIRIS mijn verkeersborden?",
               fr: "Quand OSIRIS va-t-il placer mes panneaux de signalisation ?"
            },
            answer: {
               en: "<p>OSIRIS is not related to any order for signs. For anything related to signs, please contact the competent signaling service of your municipality or a specialized signaling company.</p>",
               nl: "<p>OSIRIS heeft geen verband met een specifieke bestelling van borden. Voor alles met betrekking tot verkeersborden kunt u contact opnemen met de bevoegde verkeerssignalisatiedienst van uw gemeente of een gespecialiseerd bedrijf in verkeerssignalisatie.</p>",
               fr: "<p>OSIRIS est sans lien avec une quelconque commande de panneaux. Pour tout ce qui concerne les panneaux veuillez prendre contact avec le service de signalisation compétent de votre commune ou une société specialisée en signalisation.</p>"
            }
         },
         {
            value: "own-signs",
            question: {
               en: "Can I use my own signs?",
               nl: "Kan ik mijn eigen borden gebruiken?",
               fr: "Puis- je utiliser mes propres panneaux ?"
            },
            answer: {
               en: "<p>If you have appropriate signs, you can use your own signs if you have an OSIRIS agreement or authorization valid and complete, and the Police zone has authorized their use(for informational purposes: for type D requests, it is not possible to associate a signage plan with the application and have it validated by a police zone. As a result, you cannot place your own signs, and this placement must be communal).</p>",
               nl: "<p>Als u geschikte borden heeft, kunt u uw eigen borden gebruiken als u een geldige en volledige OSIRIS-toestemming of autorisatie  heeft en de politiezone u heeft toegestaan ze te gebruiken (ter informatie: voor aanvragen van het type D is het onmogelijk om een signalisatieplan te associëren en het dus te laten valideren door een politiezone. Bijgevolg kunt u geen eigen borden plaatsen en is deze plaatsing verplicht gemeenschappelijk).</p>",
               fr: "<p>Si vous disposez de panneaux adéquats vous pouvez utiliser vos propres panneaux si vous disposez d’un accord ou d’une autorisation OSIRIS  valide et complète et que la zone de Police vous a autorisé à les utiliser (a titre informatif : pour les demandes de type D il est impossible d'associer un plan de signalisation et donc de le faire valider par une zone de police. Par conséquent, vous ne pouvez pas placer vos propres panneaux et ce placement est obligatoirement communal).</p>"
            }
         },
         {
            value: "chairs-for-reservation",
            question: {
               en: "Can I reserve my spot by placing chairs and indicating myself that I reserved it?",
               nl: "Kan ik mijn plek reserveren door stoelen te plaatsen en zelf aan te geven dat ik het heb gereserveerd?",
               fr: "Puis- je réserver mon emplacement en plaçant des chaises et en signalant moi-même que je l’ai réservé ?"
            },
            answer: {
               en: "<p>No, the law prohibits this. You cannot decide to privatize the public road without OSIRIS authorization and without traffic signs.</p>",
               nl: "<p>Nee, dat is bij wet verboden. U kunt niet besluiten om de openbare weg te privatiseren zonder OSIRIS-toestemming en zonder verkeersborden.</p>",
               fr: "<p>Non, la loi interdit cela. Vous ne pouvez pas décider de privatiser la voie publique sans autorisation Osiris et sans panneaux de signalisation.</p>"
            }
         }
      ]
   },
   {
      topic: "expert",
      label: {
         en: "Mandated expert",
         nl: "Aangestelde expert",
         fr: "Expert mandaté",
      },
      faqs: [
         {
            value: "expert-appointed",
            question: {
               en: "A company would like to appoint me as an expert, can I use the same account I usually use?",
               nl: "Een bedrijf wil mij benoemen als expert, kan ik hetzelfde account gebruiken als dat ik normaal gebruik?",
               fr: "Une entreprise voudrait me mandater en tant qu'expert, puis-je utiliser le même compte que celui que j'utilise habituellement?"
            }, answer: {
               en: "<p>No, you need to create a specific organization for the appointed expert. To do this, create a new organization as an applicant, then select the option 'Expert appointed by an applicant.'</p>",
               nl: "<p>Nee, je moet een specifieke organisatie aanmaken voor de aangestelde expert. Om dit te doen, maak een nieuwe organisatie aan als aanvrager en selecteer vervolgens de optie 'Expert aangesteld door een aanvrager.</p>",
               fr: "<p>Non, il faut créer une organisation spécifique expert mandaté. Pour ce faire, créez un nouvelle organisation en tant qu'impétrant, ensuite choisissez l'option 'Expert mandaté par un impétrant'.</p>"
            }
         }
      ]
   }
];

export default faqData;
