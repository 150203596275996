import React, { useState} from 'react';
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const TransferButton = ({ activeSection, sourceElements, setDestinationElements, displayMessage}) => {
    const { t } = useTranslation();
    const [isClickedOnce, setIsClickedOnce] = useState(false);

    let icon;
    if (activeSection === "during") {
        icon = <ArrowLeftCircleIcon className="w-6 h-6" />;
    } else {
        icon = <ArrowRightCircleIcon className="w-6 h-6" />;
    }

    const handleCopyFromOtherSection = () => {

        if (isClickedOnce){
        setIsClickedOnce(false);
        setDestinationElements(sourceElements);
        displayMessage('success', t('mobility-cut-app-dataTransfered'));
        }else {
            setIsClickedOnce(true);
            setTimeout(() => {
                setIsClickedOnce(false);
            }, 5000); 
        }
    };
    const handleIsHovered = () => {
        setIsClickedOnce(true);
        
    };
    const handleIsNotHoveredAnyMore = () => {
        setIsClickedOnce(false);
    };

    return (
        <div>
        <button
            className={`rounded-full p-2 bg-blue-800 text-white ${isClickedOnce ? 'border border-yellow-500 text-yellow-800' : ''}`}
            onClick={handleCopyFromOtherSection}
            onMouseEnter={handleIsHovered}
            onMouseLeave={handleIsNotHoveredAnyMore}
        >
            {isClickedOnce ? (
                <span className="flex items-center">
                    <span className="mr-2">{icon}</span>
                    <span className="text-yellow-800 font-bold">{t('mobility-cut-app-dataTransferButton')}</span>
                </span>
            ) : (
                icon
            )}
        </button>
        </div>
    );
};

export default TransferButton;
