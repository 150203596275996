import React from "react";
import { useTranslation } from 'react-i18next';

function ApiTermsOfUse() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-start gap-6">
      <div className="text-2xl font-bold text-blue-800 dark:text-yellow-800">
        {t('api-terms-title')}
      </div>
      <div className="text-lg text-gray-600 dark:text-gray-400">
        {t('api-terms-last-update')}: [20-03-2024]
      </div>
      <p>
        {t('api-terms-welcome-message')}
      </p>
      <div className="text-xl font-bold text-blue-700 dark:text-white">{t('api-terms-usage-title')}</div>
      <div className="text-blue-700 dark:text-white">{t('api-terms-objective')}</div>
      <p>
        {t('api-terms-objective-details')}
      </p>
      <div className="text-blue-700 dark:text-white">{t('api-terms-restrictions')}</div>
      <p>
        {t('api-terms-restrictions-details')}
      </p>
      <div className="text-xl font-bold text-blue-700 dark:text-white">{t('api-terms-api-access')}</div>
      <div className="text-blue-700 dark:text-white">{t('api-terms-registration')}</div>
      <p>
        {t('api-terms-registration-details')}
      </p>
      <div className="text-blue-700 dark:text-white">{t('api-terms-credentials')}</div>
      <p>
        {t('api-terms-credentials-details')}
      </p>
      <div className="text-xl font-bold text-blue-700 dark:text-white">{t('api-terms-intellectual-property')}</div>
      <div className="text-blue-700 dark:text-white">{t('api-terms-copyright')}</div>
      <p>
        {t('api-terms-copyright-details')}
      </p>
      <div className="text-blue-700 dark:text-white">{t('api-terms-trademarks')}</div>
      <p>
        {t('api-terms-trademarks-details')}
      </p>
      <div className="text-xl font-bold text-blue-700 dark:text-white">{t('api-terms-responsibilities')}</div>
      <div className="text-blue-700 dark:text-white">{t('api-terms-user-responsibility')}</div>
      <p>
        {t('api-terms-user-responsibility-details')}
      </p>
      <div className="text-blue-700 dark:text-white">{t('api-terms-availability')}</div>
      <p>
        {t('api-terms-availability-details')}
      </p>
      <div className="text-xl font-bold text-blue-700 dark:text-white">{t('api-terms-conditions-modifications')}</div>
      <p>
        {t('api-terms-modifications-details')}
      </p>
      <p>
        {t('api-terms-acceptance')}
      </p>
      <p>
        {t('api-terms-contact-support')} {process.env.REACT_APP_MAIL_OSIRIS} .
      </p>
    </div>
  );
}

export default ApiTermsOfUse;
