// services/addressService.js

const fetchAddressSuggestions = async (inputString) => {
  let suggestionsData = [];

  if (inputString.length > 0) {
    try {
      const response = await fetch(`/api/address-suggestions?q=${encodeURIComponent(inputString)}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();

      console.log(data);
      // Extract 'topSuggestions' directly from the response data
      suggestionsData = data.topSuggestions || [];
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  }

  return suggestionsData;
};

// Export the function
export const addressService = {
  fetchAddressSuggestions,
};
